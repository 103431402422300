import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'
import { useTimedNotifications } from './state/modules/notifications'
import ConfirmationModal from './components/ConfirmationModal'
import NotificationsToast from './components/NotificationsToast'
import Header from './containers/Header'
import Login from './containers/Login'
import { useAuthInfo, useGetIDToken } from './state/modules/auth'
import { useCallOnceOnAuthenticationVerified } from './state/modules/common'
import WithAuthenticationRequired from './state/modules/helpers/WithAuthenticationRequired'
import TOSProvider from './state/modules/helpers/TOSProvider'
import { usePreloadForBBEUsersOrSuperAdmins } from './state/modules/preloads'
import { Home } from './containers/Home'
import { ManageDocuments } from './containers/ManageDocuments'
import { DocumentExchange } from './containers/DocumentExchange'
import { ManageUsers } from './containers/ManageUsers'
import { UserImport } from './containers/UserImport'
import { BidHistory } from './containers/BidHistory'
import { SiteUpload } from './containers/SiteUpload'
import { stringToBoolean } from './utils/string'
import { Projects } from './containers/Projects'
import { Dashboard } from './containers/Dashboard'

function App() {
  const getIDToken = useGetIDToken()

  useCallOnceOnAuthenticationVerified(getIDToken)
  usePreloadForBBEUsersOrSuperAdmins()
  useTimedNotifications()

  const {
    isLoading,
    settings: { enableProjectsPage, enableDashboard },
  } = useAuthInfo()

  return (
    <Router>
      <Header />
      <ConfirmationModal />
      <NotificationsToast />
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <TOSProvider>
          <Route path="/">
            <Switch>
              <Route path="/rfps">
                <WithAuthenticationRequired Component={Home} />
              </Route>
              <Route path="/manage/users">
                <WithAuthenticationRequired Component={ManageUsers} />
              </Route>
              <Route path="/manage/documents">
                <WithAuthenticationRequired Component={ManageDocuments} />
              </Route>
              {stringToBoolean(
                process.env.REACT_APP_FEATURE_FLAG_DOCUMENT_EXCHANGE
              ) && (
                <Route path="/document-exchange">
                  <WithAuthenticationRequired Component={DocumentExchange} />
                </Route>
              )}
              <Route path="/manage/projects">
                <WithAuthenticationRequired
                  Component={Projects}
                  loading={isLoading}
                  redirectOn={() => !isLoading && !enableProjectsPage}
                  redirectTo="/"
                />
              </Route>
              <Route path="/user-import">
                <WithAuthenticationRequired Component={UserImport} />
              </Route>
              {stringToBoolean(
                process.env.REACT_APP_FEATURE_FLAG_BID_HISTORY
              ) && (
                <Route path="/bid/history">
                  <WithAuthenticationRequired Component={BidHistory} />
                </Route>
              )}
              <Route path="/sites/upload">
                <WithAuthenticationRequired Component={SiteUpload} />
              </Route>
              <Route path="/">
                <WithAuthenticationRequired
                  Component={Dashboard}
                  loading={isLoading}
                  redirectOn={() => !isLoading && !enableDashboard}
                  redirectTo="/rfps"
                />
              </Route>
            </Switch>
          </Route>
        </TOSProvider>
      </Switch>
    </Router>
  )
}

export default App

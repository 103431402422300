import { Reducer, useCallback, useMemo, useReducer } from 'react'
import { ITableCellChange } from './tableTypes'

export type BootstrapTableRecordChange<T, K extends keyof T> = Partial<T> &
  Pick<T, K>

export type BootstrapTableChangesMap<T, K extends keyof T> = ReadonlyMap<
  string | number,
  BootstrapTableRecordChange<T, K>
>

export interface Opts {
  readOnly?: boolean
}

export const makeTableRecordChangesHook = <T, K extends keyof T>(
  rowKeyPropName: K
) => {
  type Change = BootstrapTableRecordChange<T, K>
  type Map = ReadonlyMap<Change[K], Change>

  const listToMap = (list: readonly Change[]): Map => {
    const map = new Map<Change[K], Change>()
    for (const change of list) {
      const rowKey = change[rowKeyPropName]
      const existingChange = map.get(rowKey)
      if (existingChange) {
        map.set(rowKey, {
          ...existingChange,
          ...change,
        })
      } else {
        map.set(rowKey, change)
      }
    }
    return map
  }

  type ChangesDictAction =
    | { name: 'change'; change: Change }
    | { name: 'reset' }
    | { name: 'set'; changes: readonly Change[] }

  const changesMapReducer: Reducer<Map, ChangesDictAction> = (
    state,
    action
  ) => {
    if (action.name === 'change') {
      const newState = new Map(state)
      const rowKey = action.change[rowKeyPropName]
      newState.set(rowKey, {
        ...state.get(rowKey),
        ...action.change,
      })
      return newState
    }

    if (action.name === 'reset') {
      return new Map()
    }

    if (action.name === 'set') {
      return listToMap(action.changes)
    }

    throw new Error(`Unknown action`)
  }

  const useTableRecordChanges = (opts: Opts = {}) => {
    const { readOnly = false } = opts

    const [changesMap, reduceChangesDict] = useReducer(
      changesMapReducer,
      new Map()
    )

    const onChange = useCallback(
      (change: Change) =>
        reduceChangesDict({
          name: 'change',
          change,
        }),
      []
    )

    const handleTableChanges = useMemo(
      () =>
        !readOnly
          ? (changes: readonly ITableCellChange[]) => {
              for (const change of changes) {
                reduceChangesDict({
                  name: 'change',
                  change: {
                    [change.propName]: change.newValue,
                    [rowKeyPropName]: change.rowKey,
                  } as Change,
                })
              }
            }
          : undefined,
      [readOnly]
    )

    const resetChanges = useCallback(
      () => reduceChangesDict({ name: 'reset' }),
      []
    )

    const setChanges = useCallback(
      (changes: readonly Change[]) =>
        reduceChangesDict({ name: 'set', changes }),
      []
    )

    const changedRecordsKeys = useMemo<readonly Change[K][]>(
      () => Array.from(changesMap.keys()),
      [changesMap]
    )

    return {
      rowKeyPropName,
      changesMap,
      changedRecordsKeys,
      onChange,
      handleTableChanges,
      resetChanges,
      setChanges,
    }
  }

  return useTableRecordChanges
}

import { useMemo } from 'react'
import {
  IReportData,
  IUserReport,
  REPORT_ACCESS_GLOBAL,
  useUserReportSharing,
} from '../../state/modules/userReports'
import {
  IAuthUser,
  isChannelPartnerUser,
  isEntityUser,
  isBBEUserOrSuperAdmin,
  IAuthUserWithId,
  useUser,
} from '../../state/modules/auth'
import { useImpersonatingUser } from '../../state/modules/impersonations'

export type Access =
  | 'My own'
  | 'My own (impersonated)'
  | 'Organization'
  | 'Global'

export const accessesOrder = [
  undefined,
  'My own',
  'My own (impersonated)',
  'Organization',
  'Global',
]

export const getReportAccess = (
  report: IReportData,
  user?: IAuthUserWithId,
  impersonatingUser?: IAuthUser
): string => {
  const { ownerId, access } = report

  const currentUser = impersonatingUser || user
  if (!currentUser) return 'Unknown'

  if (access) {
    if (access === REPORT_ACCESS_GLOBAL) return 'Global'
    if (
      isEntityUser(currentUser) &&
      access === `EntityUser:${currentUser.relatedEntity}`
    )
      return 'Organization'
    if (
      isChannelPartnerUser(currentUser) &&
      access === `ChannelPartner:${currentUser.relatedChannelPartner}`
    )
      return 'Organization'

    return access
  }

  if (ownerId && ownerId === user?.id) return 'My own'
  else if (ownerId && impersonatingUser && ownerId === impersonatingUser.id)
    return 'My own (impersonated)'

  return 'Hidden'
}

export const getAccessesInfo = (
  report: IReportData,
  user?: IAuthUserWithId,
  impersonatingUser?: IAuthUser
) => {
  const availableAccesses: Access[] = []

  const { ownerId } = report

  const isUserOwnership = ownerId && ownerId === user?.id

  const isImpersonatedOwnership =
    !isUserOwnership && ownerId && ownerId === impersonatingUser?.id

  const isOwnReport = isUserOwnership || isImpersonatedOwnership

  const canChange = isOwnReport || (!!user && isBBEUserOrSuperAdmin(user))

  if (isUserOwnership) availableAccesses.push('My own')
  if (isImpersonatedOwnership) availableAccesses.push('My own (impersonated)')

  if (
    canChange &&
    user &&
    (isEntityUser(impersonatingUser || user) ||
      isChannelPartnerUser(impersonatingUser || user))
  )
    availableAccesses.push('Organization')

  if (user && isBBEUserOrSuperAdmin(user)) availableAccesses.push('Global')

  const reportAccess = user
    ? getReportAccess(report, user, impersonatingUser)
    : undefined

  return {
    isOwnReport,
    isUserOwnership,
    isImpersonatedOwnership,
    canChange,
    availableAccesses,
    reportAccess,
    visibleByImpersonated:
      !!impersonatingUser &&
      reportAccess !== undefined &&
      ['My own (impersonated)', 'Organization', 'Global'].includes(
        reportAccess
      ),
  }
}

export type UserReportAccessInfo = ReturnType<typeof getAccessesInfo>

export const useUserReportAccess = () => {
  const { shareGlobal, shareWithOrg } = useUserReportSharing()

  const user = useUser()

  return useMemo(
    () =>
      user && shareWithOrg && shareGlobal
        ? (report: IUserReport, access: Access) => {
            if (access === 'Organization') {
              return shareWithOrg(report._id).catch(() => {
                throw new Error('shareWithOrg failed')
              })
            }

            if (access === 'Global') {
              return shareGlobal(report._id).catch(() => {
                throw new Error('shareGlobal failed')
              })
            }

            if (
              user &&
              (access === 'My own' || access === 'My own (impersonated)') &&
              report.access === 'Global' &&
              isBBEUserOrSuperAdmin(user)
            ) {
              return shareGlobal(report._id, true).catch(() => {
                throw new Error('shareGlobal failed')
              })
            } else if (
              access === 'My own' ||
              access === 'My own (impersonated)'
            ) {
              return shareWithOrg(report._id, true).catch(() => {
                throw new Error('shareWithOrg failed')
              })
            }
          }
        : undefined,
    [user, shareWithOrg, shareGlobal]
  )
}

export const useUserReportWithAccess = (reports: readonly IUserReport[]) => {
  const user = useUser()
  const impersonatingUser = useImpersonatingUser()

  return useMemo(
    () =>
      reports
        .map((report) => ({
          report,
          ...getAccessesInfo(report, user, impersonatingUser),
        }))
        .sort(
          (
            {
              report: { name: nameA },
              reportAccess: accessA,
              visibleByImpersonated: viImpA,
            },
            {
              report: { name: nameB },
              reportAccess: accessB,
              visibleByImpersonated: viImpB,
            }
          ) => {
            if (!viImpA && viImpB) return 1
            if (viImpA && !viImpB) return -1

            const accessShift =
              accessesOrder.indexOf(accessA as Access) -
              accessesOrder.indexOf(accessB as Access)

            if (accessShift !== 0) return accessShift

            if (accessA !== undefined && accessB === undefined) return -1
            else if (accessA === undefined && accessB !== undefined) return 1

            if ((accessA || '') < (accessB || '')) return 1
            if ((accessA || '') > (accessB || '')) return -1

            if ((nameA || '') < (nameB || '')) return 1

            return 0
          }
        ),
    [reports, user, impersonatingUser]
  )
}

export const useUserReportsWithAccess = (reports: readonly IUserReport[]) => {
  const user = useUser()
  const impersonatingUser = useImpersonatingUser()

  return useMemo(
    () =>
      reports
        .map(
          (report) =>
            [report, getAccessesInfo(report, user, impersonatingUser)] as const
        )
        .sort(
          (
            [
              { name: nameA },
              { reportAccess: accessA, visibleByImpersonated: viImpA },
            ],
            [
              { name: nameB },
              { reportAccess: accessB, visibleByImpersonated: viImpB },
            ]
          ) => {
            if (!viImpA && viImpB) return 1
            if (viImpA && !viImpB) return -1

            const accessShift =
              accessesOrder.indexOf(accessA as Access) -
              accessesOrder.indexOf(accessB as Access)

            if (accessShift !== 0) return accessShift

            if (accessA !== undefined && accessB === undefined) return -1
            else if (accessA === undefined && accessB !== undefined) return 1

            if ((accessA || '') < (accessB || '')) return 1
            if ((accessA || '') > (accessB || '')) return -1

            if ((nameA || '') < (nameB || '')) return 1

            return 0
          }
        ),
    [reports, user, impersonatingUser]
  )
}

import styled from 'styled-components'
import { FunctionComponent } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { Checklist } from '@black-bear-energy/black-bear-energy-common'
import { MetricsContainer, MetricsContainerProps } from './MetricsContainer'
import { ChecklistDocumentCard } from './ChecklistDocumentCard'

export const ChecklistCard: FunctionComponent<ChecklistCardProps> = ({
  checklist,
}: ChecklistCardProps) => {
  const eventKey = `checklist-card-${checklist.order.toString()}`
  const { required, reviewed } = countDocuments(checklist)

  return (
    <StyledCard>
      <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
        <StyledHeaderContainer>
          {checklist.name}
          <MetricsContainer required={required} reviewed={reviewed} />
        </StyledHeaderContainer>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <StyledCardBody>
          {checklist.documents.map((document) => (
            <ChecklistDocumentCard
              key={document.id}
              checklistDocument={document}
            />
          ))}
        </StyledCardBody>
      </Accordion.Collapse>
    </StyledCard>
  )
}

interface ChecklistCardProps {
  checklist: Checklist
}

const StyledCard = styled(Card)`
  border-color: #cccccc;
`

const StyledHeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`

const StyledCardBody = styled(Card.Body)`
  padding: 0 1rem;
`

function countDocuments(checklist: Checklist): MetricsContainerProps {
  const required = checklist.documents.filter((document) => document.required)
  const requiredAndUploaded = required.filter((document) => document.uploaded)
  const uploaded = checklist.documents.filter((document) => document.uploaded)
  const reviewed = checklist.documents.filter((document) => document.approvedBy)

  return {
    required: {
      numerator: requiredAndUploaded.length,
      denominator: required.length,
    },
    reviewed: {
      numerator: reviewed.length,
      denominator: uploaded.length,
    },
  }
}

import { formatPhoneNumber } from '../../utils/validatePhoneNumber'
import { rendererTdArg } from '../BootstrapTable/ColumnSettings'

export const phoneNumberRenderer = (
  td: rendererTdArg,
  row: string,
  value: unknown
) => {
  function getStringCellValue<T>(cellVal: T): string {
    return cellVal === null ? '' : String(cellVal)
  }
  try {
    const phoneNumber = formatPhoneNumber(getStringCellValue(value))
    return phoneNumber
  } catch (err) {}
}

import createSlice from './helpers/createSlice'
import { RFPDocumentSummary } from '@black-bear-energy/black-bear-energy-common'

// Slice Name
export const sliceName = 'rfpDocumentSummaries'

// Slice URL
export const url = '/rfp-document-summaries'

// Abstract Slice Instance
export const rfpDocumentSummariesSlice = createSlice<RFPDocumentSummary[]>(
  sliceName,
  url
)

// Initial state
export const initialState = rfpDocumentSummariesSlice.initialState

// Slice Selectors Hooks
export const useRFPDocumentSummaries = rfpDocumentSummariesSlice.useSlice

// Slice Actions Hooks
export const useLoadRFPDocumentSummaries =
  rfpDocumentSummariesSlice.useLoadSlicePayload

export const useDismissRFPDocumentSummariesHTTPErrors =
  rfpDocumentSummariesSlice.useDismissHTTPErrors

// Slice reducer
export default rfpDocumentSummariesSlice.reducer

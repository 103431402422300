import { mapValues } from 'lodash'

export const propertiesTemplateCommon = {
  units: '',
}
export const propertiesTemplateByType = {
  default: propertiesTemplateCommon,
  numeric: {
    ...propertiesTemplateCommon,
    zeroMeansEmpty: false,
    numberFormat: 0,
    decimalPlaces: null,
    currencySymbol: '',
    currencyFormat: 'left',
    commaStart: 3,
  },
  text: { ...propertiesTemplateCommon, multiline: false },
}
export const inheritProperties = [
  'numberFormat',
  'currencyFormat',
  'currencySymbol',
  'commaStart',
  'decimalPlaces',
  'units',
]
const mappers = {
  commaStart: [
    [3, 'Show separator after 3 places'],
    [4, 'Show separator after 4 places'],
  ],
  numberFormat: [
    [0, '12345678.00'],
    [3, '12,345,678.00'],
    [8, '1,23,45,678.00'],
    [7, '12345678,00'],
    [6, '12.345.678,00'],
    [10, '1.23.45.678,00'],
  ],
  currencyFormat: [
    ['left', 'Before number'],
    ['middle', `Between '-' and the number`],
    ['right', 'After number'],
  ],
}
const mapperKeys = Object.keys(mappers)
export const humanize = (props: {
  [key: string]: unknown
}): { [key: string]: unknown } => {
  return mapValues(props, (value, key) => {
    if (!mapperKeys.includes(key)) {
      return value
    }
    const mapper = mappers[key as keyof typeof mappers]
    return mapper.find(
      (v: (string | number)[]) => v[0] === value
    )?.[1] as string
  })
}
export const normalize = (props: {
  [key: string]: unknown
}): { [key: string]: unknown } =>
  mapValues(props, (value, key) => {
    if (!mapperKeys.includes(key)) {
      return value
    }
    const mapper = mappers[key as keyof typeof mappers]
    return mapper.find((v: (string | number)[]) => v[1] === value)?.[0] as
      | string
      | number
  })
export const schema = {
  type: 'object',
  title: 'Field Properties',
  properties: {
    ...mapValues(mappers, (value) => ({
      type: 'string',
      enum: value.map((v) => v[1]),
    })),
  },
}
export const schemaDefinitions = {}

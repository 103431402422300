import { FunctionComponent, useMemo, useState } from 'react'
import {
  FilterProps,
  isFilterPropsType,
  ParseType,
  StringifyType,
} from './Props'
import { BsPlus, BsX } from 'react-icons/bs'
import { Button, FormControl } from 'react-bootstrap'

export const filterType = 'List' as const

export type Value = readonly string[]

export const parseList: ParseType<Value> = (valueRes): Value | undefined => {
  if (!valueRes) return undefined
  if (Array.isArray(valueRes)) return valueRes as Value

  if (typeof valueRes !== 'string') return undefined

  try {
    const values = JSON.parse(valueRes) as unknown
    if (!Array.isArray(values)) return undefined
    if (values.some((v) => typeof v !== 'string')) return undefined
    return values as Value
  } catch (_ignore) {
    return undefined
  }
}

export const stringifyList: StringifyType<Value> = (value) =>
  value.length ? JSON.stringify(value) : ''

export type FilterPropsList = FilterProps<typeof filterType, Value>

export const isFilterPropsList = isFilterPropsType<FilterPropsList>(filterType)

export const FilterList: FunctionComponent<FilterPropsList> = (props) => {
  const { value = [], onChange, onReset } = props

  const handleRemoveItem = useMemo(
    () =>
      onChange
        ? (index: number) => () => {
            const newValue = [
              ...value.slice(0, index),
              ...value.slice(index + 1),
            ]

            if (newValue.length === 0 && onReset) onReset()
            else onChange(newValue)
          }
        : undefined,
    [onChange, onReset, value]
  )

  const items = value.map((item, index) => (
    <span
      key={`${item}-${index}`}
      style={{
        border: '1px solid grey',
        borderRadius: '4px',
        paddingLeft: '4px',
        display: 'inline-block',
        cursor: 'default',
      }}
    >
      {item}
      {!!handleRemoveItem && (
        <>
          &nbsp;
          <Button onClick={handleRemoveItem?.(index)}>
            <BsX />
          </Button>
        </>
      )}
    </span>
  ))

  const [newItem, setNewItem] = useState('')

  const addInput = onChange && (
    <FormControl
      value={newItem}
      onChange={(e) => setNewItem(e.target.value || '')}
    />
  )

  const handleAdd = useMemo(
    () =>
      onChange
        ? () => {
            if (value.includes(newItem)) return undefined
            onChange([...value, newItem].sort())
            setNewItem('')
          }
        : undefined,
    [onChange, value, newItem]
  )

  const addButton = onChange && (
    <Button tabIndex={0} onClick={handleAdd}>
      <BsPlus />
    </Button>
  )

  return (
    <div>
      {items}
      <div style={{ display: 'flex', flexFlow: 'row', alignItems: 'center' }}>
        {addInput}
        {addButton}
      </div>
    </div>
  )
}

import { FunctionComponent } from 'react'
import {
  FilterBoolean,
  isFilterPropsBoolean,
  parseBoolean,
  stringifyBoolean,
} from './Boolean'
import {
  FilterContains,
  isFilterPropsContains,
  parseContains,
  stringifyContains,
} from './Contains'
import {
  FilterDateRange,
  isFilterPropsDateRange,
  parseDateRange,
  stringifyDateRange,
} from './DateRange'
import { FilterList, isFilterPropsList, parseList, stringifyList } from './List'
import {
  FilterNumericRange,
  isFilterPropsNumericRange,
  parseNumericRange,
  stringifyNumericRange,
} from './NumericRange'
import { FilterProps } from './Props'

const Components = [
  [
    FilterNumericRange,
    isFilterPropsNumericRange,
    parseNumericRange,
    stringifyNumericRange,
  ],
  [FilterDateRange, isFilterPropsDateRange, parseDateRange, stringifyDateRange],
  [FilterList, isFilterPropsList, parseList, stringifyList],
  [FilterContains, isFilterPropsContains, parseContains, stringifyContains],
  [FilterBoolean, isFilterPropsBoolean, parseBoolean, stringifyBoolean],
]

export const Filter: FunctionComponent<FilterProps<string, string>> = (
  props
) => {
  const { value, onChange } = props

  for (const [
    Component,
    isComponentsProps,
    parse,
    stringify,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ] of Components as any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (isComponentsProps(props))
      return (
        <Component
          {...props}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
          value={parse(value)}
          onChange={
            onChange
              ? // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                (newValue: unknown) => onChange(stringify(newValue) as string)
              : undefined
          }
        />
      )
  }

  return null
}

import FileDrop from '../common/FileDrop'
import {
  EntityPortalAttachmentUploadDownload,
  IEntityPortalAttachment,
  useEntityPortalAttachments,
  useUploadAttachment,
} from '../../state/modules/entityPortalAttachments'
import { IStoreContextValue, StateProvider } from '../../state/store'
import { IFile, RequestStatus } from '../../common/types'

interface IUploadAttachmentButtonPropTypes {
  entityPortalAttachment: IEntityPortalAttachment
}

function UploadAttachmentButton({
  entityPortalAttachment,
}: IUploadAttachmentButtonPropTypes) {
  const uploadAttachment = useUploadAttachment()
  const { status } = useEntityPortalAttachments()

  function handleFileDrop(files: IFile[]) {
    uploadAttachment(entityPortalAttachment, files[0]).catch(() => {
      throw new Error('uploadAttachment failed')
    })
  }

  return (
    <FileDrop
      onDrop={handleFileDrop}
      disabled={
        status === RequestStatus.LOADING ||
        entityPortalAttachment.uploadDownload !==
          EntityPortalAttachmentUploadDownload.UPLOAD
      }
      maxFiles={1}
    />
  )
}

export default function uploadAttachmentRenderer(
  context: IStoreContextValue,
  entityPortalAttachment: IEntityPortalAttachment
) {
  if (
    entityPortalAttachment?.uploadDownload ===
    EntityPortalAttachmentUploadDownload.UPLOAD
  ) {
    return (
      <StateProvider value={context}>
        <UploadAttachmentButton
          entityPortalAttachment={entityPortalAttachment}
        />
      </StateProvider>
    )
  }
}

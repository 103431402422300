import styled from 'styled-components'
import {
  Spinner as BootstrapSpinner,
  ProgressBar as BootstrapProgressBar,
} from 'react-bootstrap'
import { IoIosArrowDown } from 'react-icons/io'

export const WHITE = 'var(--white)'
export const GRAY = 'var(--gray)'
export const PRIMARY = 'var(--primary)'
export const PRIMARY_BOX_SHADOW = 'var(--primary-shadow)'
export const PRIMARY_ACTIVE = 'var(--primary-active)'

export const DownIcon = styled(IoIosArrowDown)`
  float: right;
  margin-top: 5px;
`

export const ProgressBar = styled(BootstrapProgressBar)`
  .progress-bar {
    background-color: ${PRIMARY};
  }
`

export const Spinner = styled(BootstrapSpinner)`
  background-color: ${PRIMARY} !important;
  color: ${PRIMARY} !important;
`

import { Dispatch, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import update from 'immutability-helper'
import { makeLocalStorageJSON } from '../../utils/useLocalStorageJSON'
import { IColumnSettings } from './ColumnSettings'
import { cloneDeep, isUndefined, merge, omitBy } from 'lodash'
import { useSetObjectField } from '../../utils/useSetObjectField'
import {
  decodeSortQuery,
  encodeSortObject,
  getQueryParmas,
  SortDirection,
} from '../helpers/query'
import { useQuery } from '../../state/modules/common'

export interface IBootstrapTableUserSettings {
  readonly columnsOrder?: readonly string[]
  readonly hiddenColumns?: readonly string[]
  readonly stickyHeaders?: boolean
  readonly stickyColumns?: number
  readonly sortBy?: string
  readonly filter?: { [x: string]: string | undefined }
  readonly columnsStyles?: {
    readonly [propName: string]: Partial<CSSStyleDeclaration>
  }
}

export const userSettingsKeys: readonly (keyof IBootstrapTableUserSettings)[] =
  [
    'columnsOrder',
    'columnsStyles',
    'hiddenColumns',
    'stickyHeaders',
    'stickyColumns',
    'sortBy',
    'filter',
  ]

interface Opts {
  columns: readonly IColumnSettings[]
  autoSanitize: boolean
  value?: IBootstrapTableUserSettings
  onChange: Dispatch<IBootstrapTableUserSettings>
}

/**
 * The IBootstrapTableUserSettings['columnsOrder'] setting is not guaranteed to contain all the columns.
 * This utility creates the order array that guarantees to include the required columns.
 */
const getColumnsOrderWithColumns = (
  columnsOrder: IBootstrapTableUserSettings['columnsOrder'],
  columnsSequence: readonly string[],
  requiredColumns: readonly string[]
) => {
  const requiredColumnsIndexesInOrder = requiredColumns.map((c) =>
    columnsOrder?.indexOf(c)
  )
  if (
    requiredColumnsIndexesInOrder.every(
      (index) => index !== -1 && index !== undefined
    )
  ) {
    return columnsOrder || []
  }

  const sequenceWithRequired = requiredColumns.reduce(
    (result, propName) =>
      result.includes(propName) ? result : [...result, propName],
    columnsSequence
  )
  const requiredColumnsIndexesInSequence = requiredColumns.map((c) =>
    sequenceWithRequired.indexOf(c)
  )
  return sequenceWithRequired.slice(
    0,
    Math.max(...requiredColumnsIndexesInSequence) + 1
  )
}

export const getColumnsSequence = (
  allColumnsPropNames: readonly string[],
  {
    columnsOrder = [],
    hiddenColumns,
  }: Pick<IBootstrapTableUserSettings, 'columnsOrder' | 'hiddenColumns'>
) =>
  allColumnsPropNames
    .filter((propName) => !hiddenColumns?.includes(propName))
    .sort((a, b) => {
      const ia = columnsOrder.indexOf(a)
      const ib = columnsOrder.indexOf(b)
      if (ia === ib) return 0

      if (ia === -1) return 1 // Move unordered column to the end
      else if (ib === -1) return -1 // Move unordered column to the end

      if (ia > ib) return 1
      return -1
    })

export const useUserSettings = (opts: Opts) => {
  const { value, onChange, columns, autoSanitize } = useMemo(
    () => merge({}, cloneDeep(opts)),
    [opts]
  )

  const setField = useSetObjectField<IBootstrapTableUserSettings>(
    value,
    onChange
  )

  const allColumnsPropNames = useMemo(
    () => columns.map((c) => c.propName),
    [columns]
  )

  const {
    columnsOrder = allColumnsPropNames,
    hiddenColumns,
    sortBy,
    filter,
    columnsStyles,
  } = value || {}

  // This Effect removes unknown columns from user settings.
  useEffect(() => {
    if (!autoSanitize) return undefined
    if (allColumnsPropNames.length === 0) return undefined

    const isValidColumn = (propName: IColumnSettings['propName']) =>
      allColumnsPropNames.includes(propName)
    const filteredColumnsOrder = columnsOrder.filter(isValidColumn)
    const filteredHiddenColumns = hiddenColumns?.filter(isValidColumn)

    if (
      filteredColumnsOrder.length !== columnsOrder.length ||
      filteredHiddenColumns?.length !== hiddenColumns?.length
    ) {
      onChange({
        columnsOrder: filteredColumnsOrder,
        hiddenColumns: filteredHiddenColumns,
      })
    }
  }, [autoSanitize, allColumnsPropNames, columnsOrder, hiddenColumns, onChange])

  const setColumnsOrder = useMemo(() => setField?.('columnsOrder'), [setField])
  const setHiddenColumns = useMemo(
    () => setField?.('hiddenColumns'),
    [setField]
  )
  const setStickyHeaders = useMemo(
    () => setField?.('stickyHeaders'),
    [setField]
  )
  const setStickyColumns = useMemo(
    () => setField?.('stickyColumns'),
    [setField]
  )

  const sort = useMemo(() => decodeSortQuery(sortBy || ''), [sortBy])

  const setSort = useMemo(
    () =>
      setField
        ? (newSort: typeof sort) =>
            setField?.('sortBy')(encodeSortObject(newSort))
        : undefined,
    [setField]
  )

  const setColumnSort = useMemo(
    () =>
      setSort
        ? (propName: IColumnSettings['propName']) =>
            (direction?: SortDirection, index = 0) => {
              const newSort = sort.filter(({ key }) => key !== propName)

              if (direction)
                newSort.splice(index, 0, { key: propName, direction })

              setSort(newSort)
            }
        : undefined,
    [sort, setSort]
  )

  const setFilter = useMemo(() => setField?.('filter'), [setField])
  const setColumnFilter = useSetObjectField(filter || {}, setFilter)

  const columnsSequence = useMemo(
    () =>
      getColumnsSequence(allColumnsPropNames, {
        columnsOrder,
        hiddenColumns,
      }),
    [allColumnsPropNames, columnsOrder, hiddenColumns]
  )

  const moveColumn = useCallback(
    (
      dragColumnPropName: IColumnSettings['propName'],
      hoverColumnPropName: IColumnSettings['propName']
    ) => {
      const columnsOrderWithAffectedColumns = getColumnsOrderWithColumns(
        columnsOrder,
        columnsSequence,
        [hoverColumnPropName, dragColumnPropName]
      )

      const hoverIndexInOrder =
        columnsOrderWithAffectedColumns.indexOf(hoverColumnPropName)
      const dragIndexInOrder =
        columnsOrderWithAffectedColumns.indexOf(dragColumnPropName)

      setColumnsOrder?.(
        update(columnsOrderWithAffectedColumns, {
          $splice: [
            [dragIndexInOrder, 1],
            [hoverIndexInOrder, 0, dragColumnPropName],
          ],
        })
      )
    },
    [columnsOrder, columnsSequence, setColumnsOrder]
  )

  const changeColumnsSequence = useCallback(
    (newColumnsSequence: readonly IColumnSettings['propName'][]) => {
      const newHiddenColumns = allColumnsPropNames?.filter(
        (name) => !newColumnsSequence.includes(name)
      )
      const newColumnsOrder = [
        ...newColumnsSequence,
        ...columnsOrder.filter((name) => newColumnsSequence.includes(name)),
      ]
      onChange({
        ...value,
        columnsOrder: newColumnsOrder,
        hiddenColumns: newHiddenColumns,
      })
    },
    [value, columnsOrder, allColumnsPropNames, onChange]
  )

  const toggleColumn = useCallback(
    (columns: string | readonly string[], show?: boolean) => {
      const columnsArr = typeof columns === 'string' ? [columns] : columns
      if (columnsArr.length === 0) {
        return undefined
      }
      const firstColumn = columnsArr[0]
      const toShow =
        show === undefined ? hiddenColumns?.includes(firstColumn) : !!show
      if (toShow) {
        setHiddenColumns?.([
          ...(hiddenColumns || []).filter((c) => !columnsArr.includes(c)),
        ])
      } else {
        setHiddenColumns?.([
          ...(hiddenColumns || []),
          ...columnsArr.filter((c) => !hiddenColumns?.includes(c)),
        ])
      }
    },
    [hiddenColumns, setHiddenColumns]
  )

  const setColumnsStyles = useMemo(
    () => setField?.('columnsStyles'),
    [setField]
  )
  const setColumnStyle = useSetObjectField(
    columnsStyles || {},
    setColumnsStyles
  )

  return {
    settings: value,
    tableProps: {
      ...value,
      sort,
      setSort,
      setColumnSort,
      setColumnStyle,
      filter,
      setColumnFilter,
      columns,
      columnsSequence,
      changeColumnsSequence,
      moveColumn,
      toggleColumn,
      setStickyHeaders,
      setStickyColumns,
      columnsStyles,
    },
    onChange,
    setColumnsSequence: setColumnsOrder,
  }
}

export const makeBootstrapTableUserSettingsStorage = (tableName: string) =>
  makeLocalStorageJSON<IBootstrapTableUserSettings>(
    `BootstrapTable-${tableName}-UserSettings`,
    (raw: unknown): raw is IBootstrapTableUserSettings => {
      if (typeof raw !== 'object' || !raw) return false

      return true
    }
  )

export const makeBootstrapTableUserSettings = (tableName: string) => {
  const useStorage = makeBootstrapTableUserSettingsStorage(tableName)

  return (
    opts: Omit<Opts, 'value' | 'onChange'>,
    defaultUserSettings: IBootstrapTableUserSettings = {}
  ) => {
    const [value, onChange] = useStorage()

    const { tableProps } = useUserSettings({
      ...opts,
      value: value || defaultUserSettings,
      onChange,
    })

    return {
      tableName,
      ...tableProps,
    }
  }
}

export const useUserSettingsQuery = () => {
  const query = useQuery()
  const history = useHistory()

  const sortBy = query.get('sort') || ''
  const filter = useMemo(() => getQueryParmas(query, ['sort']), [query])

  const currentSettings = useMemo(() => ({ sortBy, filter }), [sortBy, filter])

  const [nextSettings, setNextSettings] =
    useState<IBootstrapTableUserSettings>()

  useEffect(() => {
    if (!nextSettings) return undefined

    const timeout = setTimeout(() => {
      const { sortBy = '', filter = {} } = nextSettings

      const newQuery = new URLSearchParams({
        ...omitBy(filter, isUndefined),
        sort: sortBy,
      })

      history.push({
        search: `?${newQuery.toString()}`,
      })
    }, 1000)

    return () => clearTimeout(timeout)
  }, [nextSettings, history])

  return [nextSettings || currentSettings, setNextSettings] as const
}

export type BootstrapTableUserSettingsProps = ReturnType<
  ReturnType<typeof makeBootstrapTableUserSettings>
>

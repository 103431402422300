import React, {
  FunctionComponent,
  useState,
  useEffect,
  useMemo,
  KeyboardEventHandler,
  ChangeEventHandler,
  useRef,
  useCallback,
  RefObject,
} from 'react'
import classnames from 'classnames'
import { Overlay } from 'react-bootstrap'
import { identity, isPlainObject } from 'lodash'
import { firstDefined } from '../../utils/typeGuards'
import { ITableCellProps } from './tableProps'
import { IField } from '../../common/types'

const tableDefaultStyle = {
  width: '100%',
}

export interface ITextInputProps extends ITableCellProps {
  value: string
  type?: string
  placeholder?: string
  onKeyPress?: (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  sanitizer?: (
    rawValue: string | number | null | undefined,
    fancy?: boolean
  ) => string | undefined
  selectOnFocus?: boolean
  properties?: IField['properties']
}

export const TextInput: FunctionComponent<ITextInputProps> = ({
  value,
  handleTableChanges,
  rowKey,
  propName,
  columnStyle,
  invalid,
  placeholder,
  onKeyPress,
  type = 'text',
  dirty,
  tdProps,
  sanitizer = identity,
  properties: { multiline } = {},
  selectOnFocus = !multiline,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null)
  const displayRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null)
  const [newValue, setNewValue] = useState<string>()
  const [resetNewValue, setResetNewValue] = useState(false)

  const [editMode, setEditMode] = useState(false)

  const valueStrFallback = useMemo(
    () => (isPlainObject(value) ? JSON.stringify(value) : undefined),
    [value]
  )

  useEffect(() => {
    if (
      resetNewValue &&
      newValue !== undefined &&
      sanitizer(value) === sanitizer(newValue) &&
      !editMode
    ) {
      setNewValue(undefined)
      setResetNewValue(false)
    }
  }, [resetNewValue, value, newValue, sanitizer, editMode])

  const commit = useMemo(
    () =>
      newValue !== undefined
        ? () => {
            handleTableChanges?.([
              {
                rowKey,
                propName,
                oldValue: value,
                newValue: sanitizer(newValue),
              },
            ])
          }
        : undefined,
    [rowKey, propName, value, newValue, sanitizer, handleTableChanges]
  )

  const handleChange = useMemo<
    ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
  >(
    () =>
      handleTableChanges && !valueStrFallback
        ? (e) => {
            const newValueRaw = e.target.value
            const newValue = sanitizer(newValueRaw)
            if (newValue !== undefined) {
              setNewValue(newValueRaw)
            }
          }
        : undefined,
    [handleTableChanges, valueStrFallback, sanitizer]
  )

  const handleKeyPress = useMemo<
    KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
  >(
    () =>
      commit
        ? (e) => {
            if (e.key === 'Enter') {
              setResetNewValue(true)
              commit()
            } else {
              onKeyPress?.(e)
            }
          }
        : onKeyPress,
    [commit, onKeyPress]
  )

  const handleFocus = useMemo(
    () => (handleTableChanges ? () => setEditMode(true) : undefined),
    [handleTableChanges]
  )

  useEffect(() => {
    if (editMode && selectOnFocus) inputRef.current?.select()
  }, [editMode, selectOnFocus])

  const handleBlur = useCallback(() => {
    setEditMode(false)
    setResetNewValue(true)
    commit?.()
  }, [commit])

  const componentStyle: React.CSSProperties = {
    borderStyle: 'none',
    backgroundColor: 'transparent',
    flex: 1,
    textAlign: 'center',
    wordWrap: 'break-word',
  }

  const displayValue = firstDefined(
    newValue,
    valueStrFallback,
    sanitizer(value),
    ''
  )

  const readOnly = !handleChange

  let inputEl
  if (multiline)
    inputEl = (
      <div
        style={{
          position: 'relative',
          ...componentStyle,
          ...tableDefaultStyle,
          ...columnStyle,
          textAlign: 'left',
          whiteSpace: 'pre-wrap',
        }}
      >
        <div
          ref={overlayRef}
          style={{ position: 'absolute', left: 0, top: 0 }}
        />
        <div
          ref={displayRef}
          tabIndex={0}
          onFocus={readOnly ? undefined : handleFocus}
          style={{ minHeight: 30 }}
        >
          {displayValue}
        </div>
        <Overlay
          target={overlayRef.current}
          show={editMode}
          placement="bottom-start"
        >
          <div style={{ zIndex: 1 }}>
            <textarea
              value={editMode ? displayValue : sanitizer(displayValue, true)}
              onChange={handleChange}
              placeholder={placeholder}
              style={{
                textAlign: 'left',
                whiteSpace: 'pre-wrap',
                width: displayRef?.current?.offsetWidth,
                minHeight: displayRef?.current?.offsetHeight,
              }}
              readOnly={readOnly}
              onKeyPress={handleKeyPress}
              onFocus={handleFocus}
              onBlur={handleBlur}
              ref={inputRef as RefObject<HTMLTextAreaElement>}
              autoFocus={editMode}
            />
          </div>
        </Overlay>
      </div>
    )
  else
    inputEl = (
      <input
        type={type}
        value={editMode ? displayValue : sanitizer(displayValue, true)}
        onChange={handleChange}
        placeholder={placeholder}
        style={{ ...componentStyle, ...tableDefaultStyle, ...columnStyle }}
        readOnly={readOnly}
        onKeyPress={handleKeyPress}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={inputRef as RefObject<HTMLInputElement>}
      />
    )

  let inputWrapped = inputEl
  if (type === 'date')
    inputWrapped = <div className="input-date-shrinkable">{inputEl}</div>

  return (
    <td
      {...tdProps}
      className={classnames(
        invalid && 'invalidCell',
        dirty && 'dirty-cell',
        tdProps?.className
      )}
      style={{ ...columnStyle, ...tdProps?.style, overflow: 'hidden' }}
    >
      {inputWrapped}
    </td>
  )
}

import React, {
  ChangeEventHandler,
  FunctionComponent,
  useMemo,
  useState,
  useEffect,
} from 'react'
import { Button, Form } from 'react-bootstrap'
import { IChannelPartner } from '../../state/modules/channelPartners'
import { IEntity } from '../../state/modules/entities'
import { UserTypes } from '../../state/modules/users'
import { Variant, RequestStatus } from '../../common/types'
import {
  isSuperAdmin,
  useImpersonatingUserOrUser,
} from '../../state/modules/auth'
import { RelatedChannelPartnerSelect } from '../invite-user/RelatedChannelPartnerSelect'
import { RelatedEntitySelect } from '../invite-user/RelatedEntitySelect'
import {
  useImpersonations,
  useCreateImpersonation,
  IImpersonatingDetails,
} from '../../state/modules/impersonations'
import {
  ConfirmationStatus,
  IConfirmation,
  useConfirmation,
  useSendConfirmation,
} from '../../state/modules/confirmation'

interface IStartImpersonationConfirmation extends IConfirmation {
  metadata?: IImpersonatingDetails
}

export const ImpersonationInputs: FunctionComponent = () => {
  const user = useImpersonatingUserOrUser()
  const { status: impersonationsStatus } = useImpersonations()
  const [relatedChannelPartner, setRelatedChannelPartner] =
    useState<IChannelPartner>()
  const [relatedEntity, setRelatedEntity] = useState<IEntity>()
  const [userType, setUserType] = useState(UserTypes.NONE)

  const createImpersonation = useCreateImpersonation()
  const confirmation: IStartImpersonationConfirmation | undefined =
    useConfirmation()
  const [confirmationReceiptID, setConfirmationReceiptID] = useState<string>()
  const sendConfirmation = useSendConfirmation()

  useEffect(() => {
    if (userType !== UserTypes.CHANNEL_PARTNER && relatedChannelPartner)
      setRelatedChannelPartner(undefined)
    if (userType !== UserTypes.ENTITY && relatedEntity)
      setRelatedEntity(undefined)
  }, [relatedChannelPartner, relatedEntity, userType])

  const handleUserTypeChange = useMemo<
    ChangeEventHandler<HTMLInputElement> | undefined
  >(
    () =>
      setUserType
        ? (e) => setUserType((e.currentTarget.value || '') as UserTypes)
        : undefined,
    [setUserType]
  )

  // NOTE - Hook to listen for confirmations and proceed with the impersonation when approved!
  useEffect(() => {
    if (
      confirmation &&
      confirmation.id === confirmationReceiptID &&
      confirmation.status === ConfirmationStatus.APPROVED
    ) {
      createImpersonation(confirmation.metadata!).catch(() => {
        throw new Error('createImpersonation failed')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmation])

  const disabled = impersonationsStatus === RequestStatus.LOADING

  const onStart = () => {
    let metadata: IImpersonatingDetails
    if (userType === UserTypes.CHANNEL_PARTNER) {
      metadata = {
        relatedChannelPartner: relatedChannelPartner!.recordId,
      }
    } else if (userType === UserTypes.ENTITY) {
      metadata = {
        relatedEntity: relatedEntity!.recordId,
      }
    } else {
      metadata = {
        isBBEUserImpersonation: true,
      }
    }
    const receiptID = sendConfirmation({
      title: 'Impersonate user',
      message: `You will be impersonating "${
        userType === UserTypes.CHANNEL_PARTNER
          ? relatedChannelPartner?.channelPartnerName
          : userType === UserTypes.ENTITY
          ? relatedEntity?.entityName
          : 'Black Bear Energy User'
      }". This impersonation session will remain open until you close it. Would you like to proceed?`,
      declineLabel: 'Cancel',
      approveLabel: 'Yes',
      metadata,
    })
    setConfirmationReceiptID(receiptID)
  }

  return (
    <div className="py-2">
      <h6 className="pl-2 text-primary">Impersonate Users</h6>
      <Form className="form-inline">
        <Form.Group className="mx-2">
          <Form.Control
            name="user-type-select"
            as="select"
            onChange={handleUserTypeChange}
            disabled={disabled}
          >
            <option selected disabled>
              User Type
            </option>
            <option disabled={disabled} value={UserTypes.NONE} />
            {isSuperAdmin(user!) && (
              <option disabled={disabled} value={UserTypes.BBE_USER}>
                BBE User
              </option>
            )}
            <option disabled={disabled} value={UserTypes.CHANNEL_PARTNER}>
              Channel Partner
            </option>
            <option disabled={disabled} value={UserTypes.ENTITY}>
              Client
            </option>
          </Form.Control>
        </Form.Group>
        {userType === UserTypes.CHANNEL_PARTNER && (
          <Form.Group className="mx-2">
            <RelatedChannelPartnerSelect
              channelPartner={relatedChannelPartner}
              disabled={disabled}
              onChannelPartnerChange={setRelatedChannelPartner}
              showLabel={false}
            />
          </Form.Group>
        )}

        {userType === UserTypes.ENTITY && (
          <Form.Group className="mx-2">
            <RelatedEntitySelect
              entity={relatedEntity}
              disabled={disabled}
              onEntityChange={setRelatedEntity}
              showLabel={false}
            />
          </Form.Group>
        )}
        <Button
          disabled={disabled || userType === UserTypes.NONE}
          variant={
            userType === UserTypes.NONE ? Variant.LIGHT : Variant.PRIMARY
          }
          onClick={onStart}
          className="mr-2"
        >
          Start
        </Button>
      </Form>
    </div>
  )
}

import { useEffect, useState } from 'react'
import { IUserReport, useUserReports } from '../../state/modules/userReports'

export const useFreshReport = (reportId?: IUserReport['_id']) => {
  const { reports, load } = useUserReports()
  const [report, setReport] = useState<IUserReport>()

  useEffect(load, [load])

  useEffect(() => {
    if (!reportId) setReport(undefined)
    else setReport(reports.find((r) => r._id === reportId))
  }, [reports, reportId])

  return [report?._id === reportId ? report : undefined, setReport] as const
}

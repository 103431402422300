import { FunctionComponent, useRef, useState } from 'react'
import styled from 'styled-components'
import { Button, Card } from 'react-bootstrap'
import { ChecklistDocument } from '@black-bear-energy/black-bear-energy-common'
import { Variant } from '../../common/types'
import { useApiWithIDTokenGranted } from '../../state/modules/common'
import { IconLabel } from './IconLabel'
import { useNotifications } from '../../state/modules/notifications'

export const ChecklistDocumentCard: FunctionComponent<
  ChecklistDocumentCardProps
> = ({ checklistDocument }: ChecklistDocumentCardProps) => {
  const [isDragging, setIsDragging] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [isUploaded, setIsUploaded] = useState(checklistDocument.uploaded)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const api = useApiWithIDTokenGranted()
  const { send: sendNotification } = useNotifications()

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()

    if (event.type === 'dragenter' || event.type === 'dragover') {
      setIsDragging(true)
    } else if (event.type === 'dragleave') {
      setIsDragging(false)
    }
  }

  const uploadFile = async (file: File) => {
    setIsUploading(true)
    sendNotification({
      title: checklistDocument.name,
      message: `Uploading ${file.name}`,
      variant: Variant.INFO,
    })
    try {
      if (!api) {
        return
      }

      const url = `/documents/${checklistDocument.id}`
      const data = new FormData()
      data.append('file', file)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const response = await api.put<{ fileName: string }>(url, data, config)
      const { fileName } = response.data
      sendNotification({
        title: checklistDocument.name,
        message: `Uploaded ${fileName}`,
        variant: Variant.SUCCESS,
      })
      setIsUploaded(true)
    } catch (error) {
      const errorMessage = (error as Error).toString()
      const message = `Failed to upload ${file.name}: ${errorMessage}`
      sendNotification({
        title: checklistDocument.name,
        message,
        variant: Variant.DANGER,
      })
    } finally {
      fileInputRef.current!.value = ''
      setIsUploading(false)
    }
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setIsDragging(false)
    if (event.dataTransfer.files.length !== 1) {
      sendNotification({
        title: checklistDocument.name,
        message: 'More than one file dropped',
        variant: Variant.DANGER,
      })
      return
    }

    const file = event.dataTransfer.files[0]
    void uploadFile(file)
  }

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (!files || !files.length) {
      return
    }

    const file = files[0]
    void uploadFile(file)
  }

  const handleUploadClick = () => {
    fileInputRef.current?.click()
  }

  const uploadedIconName = isUploaded ? 'FileEarmarkCheckFill' : 'FileEarmark'
  const fileInputId = `checklistDocumentCardFileInput${checklistDocument.id}`

  return (
    <StyledChecklistDocumentCard
      className="d-flex flex-row justify-content-between my-3 p-3"
      data-testid="checklistDocumentCard"
      $isDragging={isDragging}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      <IconLabel iconName={uploadedIconName} label={checklistDocument.name} />
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="mx-3">
          {checklistDocument.required && !isUploaded && (
            <IconLabel iconName="ExclamationCircle" label="Required" />
          )}
          {checklistDocument.uploaded && !checklistDocument.approvedBy && (
            <IconLabel iconName="ExclamationCircle" label="Needs Approval" />
          )}
          {checklistDocument.approvedBy && (
            <IconLabel
              iconName="CheckCircle"
              label={`Approved by ${checklistDocument.approvedBy}`}
            />
          )}
        </div>
        <Button
          className="rounded"
          variant="outline-primary"
          onClick={handleUploadClick}
          disabled={isUploading}
        >
          <IconLabel iconName="Upload" label="Upload" />
        </Button>
        <input
          ref={fileInputRef}
          id={fileInputId}
          data-testid={fileInputId}
          className="d-none"
          type="file"
          onChange={handleFileInput}
        />
      </div>
    </StyledChecklistDocumentCard>
  )
}

export interface ChecklistDocumentCardProps {
  checklistDocument: ChecklistDocument
}

interface StyledChecklistDocumentCardProps {
  $isDragging: boolean
}

const StyledChecklistDocumentCard = styled(
  Card
)<StyledChecklistDocumentCardProps>`
  border-color: ${(props) =>
    props.$isDragging ? 'var(--primary)' : '#cccccc'};
`

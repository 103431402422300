import React, { FunctionComponent } from 'react'
import { ITableCellProps } from './tableProps'
import { Button } from 'react-bootstrap'
import { Variant } from '../../common/types'

export interface IUrlButtonProps extends ITableCellProps {
  buttonLabel?: string
  value: string | URL | undefined
}

export const UrlButton: FunctionComponent<IUrlButtonProps> = ({
  value,
  rowKey,
  columnStyle,
  buttonLabel,
}) => {
  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    e.preventDefault()
    return window.open(value, '_blank')
  }

  return (
    <td className="td" style={columnStyle} key={rowKey}>
      {value && (
        <Button variant={Variant.PRIMARY} onClick={onClick}>
          {buttonLabel || 'View Site'}
        </Button>
      )}
    </td>
  )
}

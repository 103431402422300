import useSize from '@react-hook/size'
import { useRef } from 'react'

/**
 * `marginBanners` is a two DIV elements, positioned to the left and right sides of the table,
 * Htese banners are used to clip the table content to required margins when table overflows.
 */
export const useBootstrapTableMarginBanners = (
  horizontalMargins?: string | number
) => {
  const tableRef = useRef<HTMLTableElement>(null)
  const size = useSize(horizontalMargins ? tableRef : null)

  const tableHeight = size[1]

  const marginBanners = horizontalMargins && (
    <>
      <div
        style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          height: tableHeight,
          width: horizontalMargins,
          background:
            'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,.5) 80%, rgba(255,255,255,.8) 100%)',
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: '100%',
          right: 0,
          height: tableHeight,
          width: horizontalMargins,
          background:
            'linear-gradient(-90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,.5) 80%, rgba(255,255,255,.8) 100%)',
        }}
      />
    </>
  )

  return {
    tableRef,
    marginBanners,
  }
}

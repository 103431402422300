import { Form } from 'react-bootstrap'

interface IReceivesRfpEmailsCheckboxPropTypes {
  onChange: () => void
  value: boolean
  disabled: boolean
}

// TODO - Consolidate this with the other ReadOnlyCheckbox.
export default function ReceivesRfpEmailsCheckbox({
  onChange,
  value,
  disabled,
}: IReceivesRfpEmailsCheckboxPropTypes) {
  return (
    <fieldset>
      <Form.Group className="fluid">
        <Form.Check type="checkbox" name="user-recieveRFPEmails">
          <Form.Check.Label>
            <Form.Check.Input
              type="checkbox"
              className="mt-1"
              name="user-recieveRFPEmails"
              checked={value}
              onChange={onChange}
              disabled={disabled}
            />
            Receives RFP Emails
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>
    </fieldset>
  )
}

export function fileComponents(name: string) {
  const dotIndex = name.search(/\./g)
  return {
    filename: name.substr(0, dotIndex),
    extension: name.substr(dotIndex),
  }
}

export const appendedFilename = (name = '', appendToFilename = '') => {
  const date = new Date()
  const { filename, extension } = fileComponents(name)
  const appendedFileName = `${date.getTime()}-${filename}${
    appendToFilename ? `-${appendToFilename}` : ''
  }`
  return `${appendedFileName}${extension ? `.${extension}` : ''}`
}

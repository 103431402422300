import { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { stringToBoolean } from 'deep-cuts'
import { Variant } from '../../common/types'

export interface IExportToCSVSettings {
  withFormats: boolean
  includeAll: boolean
  exportAllColumns: boolean
}
interface IExportToCSVSettingsModalPropTypes {
  show: boolean
  onSubmit: (settings: IExportToCSVSettings) => void
  onCancel: () => void
}
export function ExportToCSVSettingsModal({
  show,
  onSubmit,
  onCancel,
}: IExportToCSVSettingsModalPropTypes) {
  const [withFormats, setWithFormats] = useState(true)
  const [exportAllColumns, setExportAllColumns] = useState(false)
  function handleWithFormatsToggle() {
    setWithFormats(!withFormats)
  }
  function handleExportAllColumnsToggle() {
    setExportAllColumns(!exportAllColumns)
  }
  return (
    <Modal onHide={onCancel} animation={false} show={show} centered>
      <Modal.Header>
        <h4>Export To CSV</h4>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col>
              <Form.Group className="fluid">
                <Form.Check type="checkbox">
                  <Form.Check.Label>
                    <Form.Check.Input
                      type="checkbox"
                      className="mt-1"
                      checked={withFormats}
                      onChange={handleWithFormatsToggle}
                    />
                    Export With Formatting
                  </Form.Check.Label>
                </Form.Check>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="fluid">
                <Form.Check type="checkbox">
                  <Form.Check.Label>
                    <Form.Check.Input
                      type="checkbox"
                      className="mt-1"
                      checked={!withFormats}
                      onChange={handleWithFormatsToggle}
                    />
                    Export Raw Values
                  </Form.Check.Label>
                </Form.Check>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="fluid">
                <Form.Check type="checkbox">
                  <Form.Check.Label>
                    <Form.Check.Input
                      type="checkbox"
                      className="mt-1"
                      checked={!exportAllColumns}
                      onChange={handleExportAllColumnsToggle}
                    />
                    Export Columns Shown
                  </Form.Check.Label>
                </Form.Check>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="fluid">
                <Form.Check type="checkbox">
                  <Form.Check.Label>
                    <Form.Check.Input
                      type="checkbox"
                      className="mt-1"
                      checked={exportAllColumns}
                      disabled={
                        !stringToBoolean(
                          process.env
                            .REACT_APP_FEATURE_FLAG_ENABLE_COLUMNS_BUTTON
                        )
                      }
                      onChange={handleExportAllColumnsToggle}
                    />
                    Export All Columns
                  </Form.Check.Label>
                </Form.Check>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button variant={Variant.LIGHT} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className="px-4"
          onClick={() =>
            onSubmit({ withFormats, exportAllColumns, includeAll: true })
          }
        >
          Export
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import { Dispatch, useCallback, useEffect, useMemo, useState } from 'react'

/**
 * When having an Object as a controlled prop (value and onChange),
 * batching updates of the Object's properties need to be done,
 * otherwise consequent calls of onChange would overwrite previous:
 *
 * const Component = (\{value, onChange\}) =\> \{
 *
 *    const setFieldA = (newA) =\> \{
 *       onChange(\{...value, a: newA\})
 *    \}
 *
 *    const setFieldB = (newB) =\> \{
 *       onChange(\{...value, b: newB\})
 *    \}
 *
 *    const setBoth = (newA, newB) =\> \{
 *       setFieldA(newA) // \<-- this could be lost
 *       setFieldB(newB) // since setFieldB calls onChange with old value (without 'a' field set)
 *    \}
 * \}
 *
 *
 * Using this hook the changes can be accumulated:
 *    const setField = useSetObjectField(value, onChange)
 *
 *    const setFieldA = setField?.('a')
 *    const setFieldB = setField?.('b')
 *
 *    const setBoth = (newA, newB) =\> \{
 *       setFieldA(newA) // Now the hook will accumulate changes between renders.
 *       setFieldB(newB) //
 *    \}
 */
export const useSetObjectField = <T>(
  value: T | undefined,
  onChange?: Dispatch<T>
) => {
  const [newValue, setNewValue] = useState<Partial<T>>()

  useEffect(() => {
    if (value && newValue && onChange) onChange({ ...value, ...newValue })
    setNewValue(undefined)
  }, [value, newValue, onChange])

  const setField = useCallback(
    <F extends keyof T>(field: F) =>
      (fieldValue: T[F]) =>
        setNewValue((v) => ({ ...v, [field]: fieldValue })),
    []
  )

  return useMemo(
    () => (value && onChange ? setField : undefined),
    [value, onChange, setField]
  )
}

import { Dispatch, FunctionComponent, useMemo, useRef, useState } from 'react'
import { Variant } from '../../common/types'
import {
  IUserReport,
  IUserReportPatch,
  useUserReportsEdit,
} from '../../state/modules/userReports'
import { TextButton } from '../common/TextButton'
import { UserReportAccessInfo, useUserReportAccess } from './Access'
import { UserReportAccessInput } from './AccessInput'
import { useUserReportSaveUI } from './Control'
import './ReportCard.css'

interface Props {
  report?: IUserReport | null
  patch?: IUserReportPatch
  accessInfo?: UserReportAccessInfo
  isDefault?: boolean
  onUpdate?: (newReport: IUserReport) => void
  onResetPatch?: (() => void) | undefined
  onSetDefault?: Dispatch<IUserReport | undefined>
  onClose?: () => void
  allowDelete?: boolean
  light?: boolean
  onSelect?: () => void
  isSelected?: boolean
  layout?: 'default' | 'item'
}
export const ReportCard: FunctionComponent<Props> = (props) => {
  const {
    report,
    patch,
    accessInfo,
    isDefault,
    onUpdate,
    onResetPatch,
    onSetDefault,
    onClose,
    light,
    onSelect,
    isSelected,
    allowDelete,
    layout = 'default',
  } = props

  const { canChange, visibleByImpersonated } = accessInfo || {}

  const currentIsDefault = isDefault

  const [showDelete, setShowDelete] = useState(false)

  const { deleteById } = useUserReportsEdit()
  const changeAccess = useUserReportAccess()

  const reportId = report?._id

  const handleDelete = useMemo(
    () =>
      canChange && allowDelete && reportId && deleteById
        ? () => {
            setShowDelete(false)
            deleteById(reportId)
              .then(() => onClose?.())
              .catch(() => {
                throw new Error('deleteById failed')
              })
          }
        : undefined,
    [canChange, allowDelete, reportId, deleteById, onClose]
  )

  const ref = useRef<HTMLDivElement | null>(null)

  const { ui, newName, blurNameInput } = useUserReportSaveUI({
    report,
    patch,
    onResetPatch,
    onUpdate,
    canChange,
  })

  return (
    <div
      ref={ref}
      className={[
        'UserReportCard',
        `UserReportCard-layout-${layout}`,
        light && 'UserReportCard-light',
        onSelect && 'UserReportCard-selectable',
        isSelected && 'UserReportCard-selected',
      ]
        .filter(Boolean)
        .join(' ')}
      role={onSelect ? 'button' : undefined}
      tabIndex={onSelect ? 0 : undefined}
      onClick={(e) => {
        const { target } = e
        if (
          !(target instanceof Element) ||
          !target?.closest('.UserReportCard-clickBust')
        )
          onSelect?.()
      }}
    >
      {(!!report || !!patch) && (
        <div>
          <div
            className={[
              'UserReportCard-saveBlock',
              newName !== null && 'UserReportCard-clickBust',
            ]
              .filter(Boolean)
              .join(' ')}
            onKeyDown={(e) => {
              if (e.key === 'Escape') blurNameInput()
            }}
          >
            {ui}
          </div>

          {report && accessInfo && (
            <div
              style={{
                display: 'flex',

                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <UserReportAccessInput
                {...accessInfo}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onChange={(a) => changeAccess?.(report, a)}
                className="UserReportCard-clickBust"
              />

              <div>
                {visibleByImpersonated && (
                  <div
                    style={{
                      color: 'violet',
                    }}
                  >
                    Visible by impersonated user
                  </div>
                )}
              </div>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            {currentIsDefault && (
              <i
                title="Default report will be loaded at start"
                style={{ color: 'darkgreen' }}
              >
                Your default
              </i>
            )}
            {!currentIsDefault && !!onSetDefault && report && (
              <TextButton
                className="UserReportCard-light-control UserReportCard-clickBust"
                onClick={() => onSetDefault(report)}
                title="Default report shown at start"
              >
                Set as your default
              </TextButton>
            )}
            {onClose && report && (
              <div>
                <TextButton onClick={() => onClose()}>Close</TextButton>
              </div>
            )}
            {canChange && allowDelete && !showDelete && (
              <div
                className="UserReportCard-light-control UserReportCard-clickBust"
                style={{ visibility: handleDelete ? 'visible' : 'hidden' }}
              >
                <TextButton onClick={() => setShowDelete(true)}>
                  Delete
                </TextButton>
              </div>
            )}
            {handleDelete && showDelete && (
              <div
                style={{ textAlign: 'left' }}
                className="UserReportCard-clickBust"
              >
                <TextButton onClick={handleDelete} variant={Variant.DANGER}>
                  Yes, delete!
                </TextButton>{' '}
                <small>Are you sure you want to delete this report?</small>{' '}
                <TextButton onClick={() => setShowDelete(false)}>No</TextButton>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

import {
  CSSProperties,
  DetailedHTMLProps,
  HTMLAttributes,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'

type ColumnSettings = readonly [left: number, zIndex: number]
type ColumnsState = readonly ColumnSettings[]
const isEqualColumnsState = (a: ColumnsState, b: ColumnsState) =>
  a.length === b.length &&
  a.every((aa, i) => aa.every((aaa, ii) => aaa === b[i][ii]))

const getColumnLeft = (
  state: ColumnsState,
  leftOffset: string,
  columnIndex: number
) =>
  columnIndex < state.length
    ? `calc(  ${state
        .slice(0, columnIndex)
        .reduce((n, s) => n + s[0], 0)}px + ${leftOffset}  )`
    : undefined

type SetCellSettings = (
  left: string | undefined,
  zIndex: number | undefined,
  lastSticky?: boolean
) => void

interface CellInfo {
  readonly key: number
  readonly cell: HTMLTableCellElement
  readonly columnIndex: number
  readonly setCellSettings: SetCellSettings
}

let lastKey = 0
const genKey = () => lastKey++

interface Cells {
  readonly headers: readonly CellInfo[]
  readonly cells: readonly CellInfo[]
}

type CellAction =
  | ({ name: 'cell' } & CellInfo)
  | ({ name: 'header' } & CellInfo)
  | { name: 'unregister-header'; key: CellInfo['key'] }
  | { name: 'unregister-cell'; key: CellInfo['key'] }

const cellsReducer: Reducer<Cells, CellAction> = (prevState, action) => {
  if (action.name === 'cell') {
    const { key, cell, columnIndex, setCellSettings } = action

    return {
      ...prevState,
      cells: [...prevState.cells, { key, cell, columnIndex, setCellSettings }],
    }
  } else if (action.name === 'header') {
    const { key, cell, columnIndex, setCellSettings } = action

    return {
      ...prevState,
      headers: [
        ...prevState.headers,
        { key, cell, columnIndex, setCellSettings },
      ],
    }
  } else if (action.name === 'unregister-header') {
    const { key } = action
    const { headers, cells } = prevState
    return {
      headers: headers.filter((h) => h.key !== key),
      cells,
    }
  } else if (action.name === 'unregister-cell') {
    const { key } = action
    const { headers, cells } = prevState
    return {
      headers,
      cells: cells.filter((c) => c.key !== key),
    }
  } else {
    throw new Error(`Unknown action`)
  }
}

export const useStickyColumns = (stickyColumns = 0, leftOffset = '0px') => {
  const [observer, setObserver] = useState<ResizeObserver>()
  const [needRefresh, setNeedRefresh] = useState(false)

  useEffect(() => {
    setNeedRefresh(true)
  }, [stickyColumns])

  useEffect(() => {
    const newObserver = new ResizeObserver(() => setNeedRefresh(true))
    setObserver(newObserver)
    return () => newObserver.disconnect()
  }, [])

  const [cells, reduceCells] = useReducer(cellsReducer, undefined, () => ({
    headers: [],
    cells: [],
  }))
  const [columnsState, setColumnsState] = useState<ColumnsState>([])

  const refreshWidths = useCallback(() => {
    setNeedRefresh(false)
    const newColumnsState = [...cells.headers]
      .sort((a, b) => {
        if (a.columnIndex > b.columnIndex) return 1
        if (a.columnIndex < b.columnIndex) return -1
        return 0
      })
      .slice(0, stickyColumns)
      .map(
        (cellInfo, index) =>
          [cellInfo.cell.offsetWidth || 0, stickyColumns - index] as const
      )

    if (!isEqualColumnsState(columnsState, newColumnsState)) {
      setColumnsState(newColumnsState)
    }
  }, [stickyColumns, cells, columnsState])

  useEffect(() => {
    if (needRefresh) {
      const timeout = setTimeout(refreshWidths, 100)
      return () => clearTimeout(timeout)
    }
  }, [needRefresh, refreshWidths])

  useEffect(() => {
    for (const { columnIndex, setCellSettings } of cells.headers) {
      const left = getColumnLeft(columnsState, leftOffset, columnIndex)
      setCellSettings(
        left,
        columnsState[columnIndex]?.[1],
        stickyColumns === columnIndex + 1
      )
    }
    for (const { columnIndex, setCellSettings } of cells.cells) {
      const left = getColumnLeft(columnsState, leftOffset, columnIndex)
      setCellSettings(
        left,
        columnsState[columnIndex]?.[1],
        stickyColumns === columnIndex + 1
      )
    }
  }, [stickyColumns, cells, columnsState, leftOffset])

  return useCallback(
    (
      cell: HTMLTableCellElement,
      columnIndex: number,
      isHeader: boolean,
      setCellSettings: SetCellSettings
    ) => {
      if (!observer) {
        return undefined
      }

      const key = genKey()

      if (isHeader) {
        observer.observe(cell)
        reduceCells({
          name: 'header',
          key,
          cell,
          columnIndex,
          setCellSettings,
        })
      } else {
        reduceCells({
          name: 'cell',
          key,
          cell,
          columnIndex,
          setCellSettings,
        })
      }

      setNeedRefresh(true)

      return () => {
        observer.unobserve(cell)
        if (isHeader) {
          reduceCells({
            name: 'unregister-header',
            key,
          })
        } else {
          reduceCells({
            name: 'unregister-cell',
            key,
          })
        }
        setNeedRefresh(true)
      }
    },
    [observer]
  )
}

export type RegisterStickyColumn = ReturnType<typeof useStickyColumns>
export type StickyColumnsCellProps = DetailedHTMLProps<
  HTMLAttributes<HTMLTableCellElement>,
  HTMLTableCellElement
>
export interface StickyColumnsCellOpts {
  columnIndex: number
  isHeader?: boolean
  /**
   * When `leftOffset` is a non-integer values in pixels,
   * sticky cells can have a little horizontal shift due to rounding issues in CSS.
   * This can lead to one pixel vertical line glitch through which other cells can appear from background.
   * With `cellBorderColor`, props for these cells will include a little `box-shadow` style, to mask this glitch.
   *
   */
  leftBorderColor?: string
  lastStickyColStyle?: CSSProperties
}

const lastStickyColStyleDefault: CSSProperties = {
  borderRight: '2px solid black',
}

export const useStickyColumnCell = (
  opts: StickyColumnsCellOpts,
  register?: RegisterStickyColumn
) => {
  const {
    columnIndex,
    isHeader = false,
    leftBorderColor,
    lastStickyColStyle = lastStickyColStyleDefault,
  } = opts
  const [cell, setCell] = useState<HTMLTableCellElement | null>(null)

  const [left, setLeft] = useState<string>()
  const [zIndex, setZIndex] = useState<number>()
  const [lastSticky, setLastSticky] = useState<boolean>()

  const setCellSettings = useCallback<SetCellSettings>(
    (newLeft, newZIndex, lastSticky) => {
      setLeft(newLeft)
      setZIndex(newZIndex)
      setLastSticky(lastSticky)
    },
    []
  )

  useEffect(
    () =>
      cell
        ? register?.(cell, columnIndex, isHeader, setCellSettings)
        : undefined,
    [columnIndex, isHeader, register, cell, setCellSettings]
  )

  return useMemo(
    () =>
      register
        ? {
            ref: setCell,
            style:
              left === undefined
                ? undefined
                : {
                    position: 'sticky' as const,
                    left,
                    zIndex,
                    boxShadow:
                      leftBorderColor && columnIndex !== 0
                        ? `-2px 0px 0px 0px ${leftBorderColor}`
                        : undefined,
                    ...(lastSticky ? lastStickyColStyle : {}),
                  },
          }
        : undefined,
    [
      register,
      left,
      zIndex,
      leftBorderColor,
      columnIndex,
      lastSticky,
      lastStickyColStyle,
    ]
  )
}

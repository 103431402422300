/* eslint-disable max-lines */
import React, {
  useEffect,
  useState,
  FormEventHandler,
  useMemo,
  useCallback,
  ChangeEventHandler,
} from 'react'
import { Button, Form, Modal, Tab } from 'react-bootstrap'

import { RequestStatus, Variant } from '../../common/types'
import { NewLineParagraph } from '../common/Layout'
import { isDefined } from '../../utils/typeGuards'
import { useHistory } from 'react-router-dom'
import {
  AuthRole,
  isBBEUserOrSuperAdmin,
  useImpersonatingUserOrUser,
} from '../../state/modules/auth'
import { ComponentLoader } from '../common/Loaders'
import {
  IInviteUserBody,
  isInvitationShouldAlwaysHaveNearmapPermission,
  useDismissUserInvitationHTTPErrors,
  useInviteUser,
  useResetUserInvitation,
  UserTypes,
  useUserInvitationHTTPErrors,
  useUserInvitationStatus,
} from '../../state/modules/users'
import { BBEUserOrSuperAdminInviteUserInputs } from './BBEUserOrSuperAdminInviteUserInputs'
import RoleSelect from './RoleSelect'
import ReadOnlyCheckbox from './ReadOnlyCheckbox'
import EmailInput from './EmailInput'
import TextAreaInput from './TextAreaInput'
import {
  useCallOnce,
  useCallOnIDTokenGranted,
} from '../../state/modules/common'
import {
  useLoadUserInviteText,
  useUserInviteText,
} from '../../state/modules/userInviteText'
import PhoneInput from './PhoneInput'
import { InviteUserSummaryModalContent } from './Summary'
import {
  IChannelPartner,
  useChannelPartners,
  useLoadChannelPartners,
} from '../../state/modules/channelPartners'
import {
  IEntity,
  useEntities,
  useLoadEntities,
} from '../../state/modules/entities'
import ReceivesRfpEmailsCheckbox from './ReceivesRfpEmailsCheckbox'
import HasProspectorPermissionCheckbox from './HasProspectorPermissionCheckbox'
import { stringToBoolean } from 'deep-cuts'
import HasNearmapPermissionCheckbox from './HasNearmapPermissionCheckbox'
import { makeInviteBody } from './makeInviteBody'
import { AppModal } from '../common/Modal'

const appendDomainWhitelist = (relatedDomains: string[]): string[] =>
  relatedDomains.concat(
    (process.env.REACT_APP_EMAIL_DOMAIN_WHITELIST as string)?.split(',')
  )

export default function InviteUserModal() {
  const history = useHistory()
  const user = useImpersonatingUserOrUser()

  // These we initially select in the form for convenience
  const {
    relatedChannelPartner: userRelatedChannelPartnerId,
    relatedEntity: userRelatedEntityId,
  } = user || {}

  const {
    payload: defaultUserInviteText,
    status: defaultUserInviteTextStatus,
  } = useUserInviteText()
  const loadUserInviteText = useLoadUserInviteText()

  const userInvitationStatus = useUserInvitationStatus()
  const userInvitationHTTPErrors = useUserInvitationHTTPErrors()
  const dismissUserInvitationHTTPErrors = useDismissUserInvitationHTTPErrors()
  const resetUserInvitation = useResetUserInvitation()
  const inviteUser = useInviteUser()

  const [userInviteText, setUserInviteText] = useState<string>()
  const [userType, setUserType] = useState(UserTypes.CHANNEL_PARTNER)
  const [relatedChannelPartner, setRelatedChannelPartner] =
    useState<IChannelPartner>()
  const [relatedEntity, setRelatedEntity] = useState<IEntity>()
  const [email, setEmail] = useState<string>()
  const [emailValidationError, setEmailValidationError] = useState<string>()
  const [role, setRole] = useState<AuthRole.ADMIN | undefined>(AuthRole.ADMIN)
  const [readOnly, setReadOnly] = useState<boolean>(false)
  const [recieveRFPEmails, setRecieveRFPEmails] = useState<boolean>(true)
  const [hasProspectorPermission, sethasProspectorPermission] =
    useState<boolean>(false)
  const hasNearmapPermissionInputState = useState<boolean>(false)
  const [hasNearmapPermission, sethasNearmapPermission] =
    userType && isInvitationShouldAlwaysHaveNearmapPermission(userType)
      ? [true, undefined]
      : hasNearmapPermissionInputState
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [show_confirmation, set_show_confirmation] = useState(false)
  const [checkUserType, setCheckUserType] = useState(false)

  const prospectorPermissionFeatureFlag = stringToBoolean(
    process.env.REACT_APP_FEATURE_FLAG_EDITABLE_PROSPECTOR_PERMISSION
  )

  const { payload: channelPartners, status: channelPartnersStatus } =
    useChannelPartners()
  const loadChannelPartners = useLoadChannelPartners()

  const { payload: entities, status: entitiesStatus } = useEntities()
  const loadEntities = useLoadEntities()

  useCallOnIDTokenGranted(async () => {
    if (defaultUserInviteTextStatus === RequestStatus.IDLE) {
      await loadUserInviteText()
    }
  }, [defaultUserInviteTextStatus])

  useCallOnIDTokenGranted(async () => {
    if (channelPartnersStatus === RequestStatus.IDLE) {
      await loadChannelPartners()
    }
  }, [channelPartnersStatus])

  useCallOnIDTokenGranted(async () => {
    if (entitiesStatus === RequestStatus.IDLE) {
      await loadEntities()
    }
  }, [entitiesStatus])

  useEffect(() => {
    if (isDefined(userRelatedChannelPartnerId)) {
      const channelPartner = channelPartners.find(
        ({ recordId }) => recordId === userRelatedChannelPartnerId
      )
      setRelatedChannelPartner(channelPartner)
    }
  }, [channelPartners, userRelatedChannelPartnerId])

  useEffect(() => {
    if (isDefined(userRelatedEntityId)) {
      const entity = entities.find(
        ({ recordId }) => recordId === userRelatedEntityId
      )
      setRelatedEntity(entity)
    }
  }, [entities, userRelatedEntityId])

  const relatedEntityId = relatedEntity?.recordId
  const relatedEntityDomain = relatedEntity?.emailDomain as string
  const relatedChannelPartnerId = relatedChannelPartner?.recordId
  const relatedChannelPartnerDomain =
    relatedChannelPartner?.emailDomain as string

  useEffect(() => {
    if (
      userType !== UserTypes.CHANNEL_PARTNER &&
      relatedChannelPartner &&
      !isDefined(user?.impersonatingDetails)
    ) {
      setRelatedChannelPartner(undefined)
    }
    if (
      userType !== UserTypes.ENTITY &&
      relatedEntity &&
      !isDefined(user?.impersonatingDetails)
    ) {
      setRelatedEntity(undefined)
    }
    if (userType === UserTypes.ENTITY || userType === UserTypes.CHANNEL_PARTNER)
      setCheckUserType(true)
    if (userType === UserTypes.BBE_USER || userType === UserTypes.SUPER_ADMIN)
      setCheckUserType(false)
  }, [
    relatedChannelPartner,
    relatedEntity,
    userType,
    user?.impersonatingDetails,
  ])

  const exactMatch = (domainArray: string[], email: string) => {
    const withAllowList = appendDomainWhitelist(domainArray)
    return withAllowList.find((item: string) => item.trim() === email)
  }

  useEffect(() => {
    const emailCheck = (emailDomain: string, email: string) => {
      const domianArray = emailDomain.split(',')
      const domain = email?.substring(email.indexOf('@'))
      const domainAfter = email?.substring(email.indexOf('@') + 1)
      if (domain) {
        if (
          exactMatch(domianArray, domain) ||
          exactMatch(domianArray, domainAfter)
        ) {
          setEmailValidationError(undefined)
          setCheckUserType(false)
        } else {
          setEmailValidationError("Email doesn't exist in domain" as string)
        }
      } else {
        setEmailValidationError(undefined)
      }
    }

    if (isDefined(relatedEntityDomain) && isDefined(relatedEntity)) {
      emailCheck(relatedEntityDomain, email || '')
    } else if (
      isDefined(relatedChannelPartnerDomain) &&
      isDefined(relatedChannelPartner)
    ) {
      emailCheck(relatedChannelPartnerDomain, email || '')
    } else if (
      !isDefined(relatedChannelPartner) &&
      !isDefined(relatedEntity) &&
      checkUserType
    ) {
      setEmailValidationError(
        'You must choose either a Channel Partner or a Client from the list.'
      )
    } else {
      setEmailValidationError(undefined)
    }
  }, [
    relatedEntityDomain,
    relatedChannelPartnerDomain,
    relatedEntity,
    relatedChannelPartner,
    email,
    checkUserType,
  ])

  const disabled =
    userInvitationStatus === RequestStatus.LOADING ||
    defaultUserInviteTextStatus === RequestStatus.LOADING

  // Hook to reset user invitation httpErrors on component load
  useCallOnce(dismissUserInvitationHTTPErrors)

  // Hook to navigate back if invitation was sent successfully
  useEffect(() => {
    if (userInvitationStatus === RequestStatus.SUCCEEDED) {
      resetUserInvitation() // reset User Invitation for future use
      history.push('.')
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [userInvitationStatus])

  useEffect(() => {
    if (
      userInvitationHTTPErrors &&
      (userInviteText ||
        userType ||
        relatedChannelPartner ||
        relatedEntity ||
        email ||
        role ||
        hasProspectorPermission ||
        hasNearmapPermission)
    ) {
      dismissUserInvitationHTTPErrors()
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [
    userInviteText,
    userType,
    relatedChannelPartner,
    relatedEntity,
    email,
    role,
    hasProspectorPermission,
    hasNearmapPermission,
  ])

  // Hook to apply default user invite text once fetched from the service
  useEffect(() => {
    if (
      defaultUserInviteTextStatus === RequestStatus.SUCCEEDED &&
      isDefined(defaultUserInviteText)
    ) {
      setUserInviteText(defaultUserInviteText.text)
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [defaultUserInviteText, defaultUserInviteTextStatus])

  const prospectorPermissionsAllowed =
    !!prospectorPermissionFeatureFlag &&
    !!user &&
    !!user.hasProspectorPermission

  function onCancel() {
    // Block modal cancel if user inviation req is still in progress
    if (userInvitationStatus !== RequestStatus.LOADING) {
      resetUserInvitation() // reset User Invitation for future use
      history.push('.')
    }
  }

  const handleFirstNameChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >((e) => setFirstName(e.currentTarget.value || ''), [])

  const handleLastNameChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >((e) => setLastName(e.currentTarget.value || ''), [])

  function handleEmailChange(value: string) {
    const emailDomain: string =
      relatedChannelPartnerDomain || relatedEntityDomain || ''
    const emailDomainArray: string[] = emailDomain.split(',')
    const domain: string = value.substring(value.indexOf('@'))
    const domainAfter: string = value?.substring(value.indexOf('@') + 1)
    if (emailDomain) {
      if (
        exactMatch(emailDomainArray, domain) ||
        exactMatch(emailDomainArray, domainAfter)
      ) {
        setEmail(value)
        setEmailValidationError(undefined)
      } else {
        setEmailValidationError("Email doesn't exist in domain" as string)
      }
    } else {
      setEmail(value)
      setEmailValidationError(undefined)
    }
  }

  function handleRoleChange(value: AuthRole) {
    setRole(value === AuthRole.ADMIN ? AuthRole.ADMIN : undefined)
  }

  function handleReceivesRfpEmailsChange() {
    setRecieveRFPEmails(!recieveRFPEmails)
  }

  function handleHasProspectorPermissionChange() {
    sethasProspectorPermission(!hasProspectorPermission)
  }

  const handleHasNearmapPermissionChange =
    sethasNearmapPermission &&
    (() => sethasNearmapPermission(!hasNearmapPermission))

  function handleReadOnlyChange() {
    setReadOnly(!readOnly)
  }
  const handleSubmitConfirm = useMemo(
    () =>
      email && !emailValidationError
        ? async () => {
            set_show_confirmation(false)
            const body: IInviteUserBody = makeInviteBody(
              email,
              userInviteText,
              firstName,
              lastName,
              phoneNumber,
              recieveRFPEmails,
              role,
              userType,
              user,
              readOnly,
              relatedChannelPartnerId,
              relatedEntity,
              relatedEntityId,
              prospectorPermissionsAllowed,
              hasProspectorPermission,
              hasNearmapPermission
            )
            await inviteUser(body)
          }
        : undefined,
    [
      email,
      emailValidationError,
      firstName,
      inviteUser,
      lastName,
      phoneNumber,
      readOnly,
      recieveRFPEmails,
      relatedChannelPartnerId,
      relatedEntity,
      relatedEntityId,
      role,
      user,
      userInviteText,
      userType,
      prospectorPermissionsAllowed,
      hasProspectorPermission,
      hasNearmapPermission,
    ]
  )

  const handleCancelConfirmation = useCallback(
    () => set_show_confirmation(false),
    []
  )

  const confirmation_dialog = email && show_confirmation && (
    <InviteUserSummaryModalContent
      user={user!}
      email={email}
      firstName={firstName}
      lastName={lastName}
      phoneNumber={phoneNumber}
      role={role}
      userInviteText={userInviteText}
      userType={userType}
      relatedChannelPartner={relatedChannelPartner}
      relatedEntity={relatedEntity}
      readOnly={readOnly}
      onCancel={handleCancelConfirmation}
      onConfirm={handleSubmitConfirm}
    />
  )

  const handleSubmit = useMemo<FormEventHandler<HTMLFormElement> | undefined>(
    () =>
      handleSubmitConfirm
        ? (e) => {
            e.preventDefault()
            set_show_confirmation(true)
          }
        : undefined,
    [handleSubmitConfirm]
  )

  const form = (
    <Form onSubmit={handleSubmit}>
      <Modal.Body>
        <NewLineParagraph></NewLineParagraph>
        <EmailInput
          onChange={handleEmailChange}
          disabled={disabled}
          error={emailValidationError}
        />
        <TextAreaInput
          onChange={setUserInviteText}
          defaultValue={defaultUserInviteText?.text}
          disabled={disabled}
        />
        {isDefined(user) && isBBEUserOrSuperAdmin(user) && (
          <BBEUserOrSuperAdminInviteUserInputs
            user={user}
            disabled={disabled}
            userType={userType}
            onUserTypeChange={setUserType}
            entity={relatedEntity}
            onEntityChange={setRelatedEntity}
            autoSelectChannelPartnerId={userRelatedChannelPartnerId}
            channelPartner={relatedChannelPartner}
            onChannelPartnerChange={setRelatedChannelPartner}
            autoSelectEntityId={userRelatedEntityId}
          />
        )}
        <fieldset>
          <Form.Group>
            <Form.Label htmlFor="invite-user-name-input" className="required">
              First Name
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="John"
              id="invite-user-name-input"
              value={firstName}
              onChange={handleFirstNameChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label
              htmlFor="invite-user-last-name-input"
              className="required"
            >
              Last Name
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Doe"
              id="invite-user-last-name-input"
              value={lastName}
              onChange={handleLastNameChange}
            />
          </Form.Group>
        </fieldset>
        <PhoneInput phoneNumber={phoneNumber} onPhoneChange={setPhoneNumber} />
        {(userType === UserTypes.ENTITY ||
          userType === UserTypes.CHANNEL_PARTNER) && (
          <RoleSelect
            value={role}
            disabled={disabled}
            onChange={handleRoleChange}
          />
        )}
        <Form.Group>
          <Form.Label>Options</Form.Label>
          {(userType === UserTypes.ENTITY ||
            userType === UserTypes.CHANNEL_PARTNER) && (
            <>
              <ReceivesRfpEmailsCheckbox
                onChange={handleReceivesRfpEmailsChange}
                value={recieveRFPEmails}
                disabled={disabled}
              />
              {prospectorPermissionsAllowed && (
                <HasProspectorPermissionCheckbox
                  value={hasProspectorPermission}
                  disabled={disabled}
                  onChange={handleHasProspectorPermissionChange}
                />
              )}
              {role !== AuthRole.ADMIN && (
                <ReadOnlyCheckbox
                  value={readOnly}
                  disabled={disabled}
                  onChange={handleReadOnlyChange}
                />
              )}
            </>
          )}
          {prospectorPermissionsAllowed && (
            <HasNearmapPermissionCheckbox
              value={hasNearmapPermission}
              disabled={disabled || !handleHasNearmapPermissionChange}
              onChange={handleHasNearmapPermissionChange}
            />
          )}
        </Form.Group>
        {userInvitationStatus === RequestStatus.FAILED && (
          <p className="text-center text-danger">
            {userInvitationHTTPErrors?.message}
          </p>
        )}
        {userInvitationStatus === RequestStatus.LOADING && <ComponentLoader />}
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button
          type="submit"
          className="px-4"
          disabled={
            !!emailValidationError || !email || disabled || checkUserType
          }
        >
          Invite
        </Button>
        <Button variant={Variant.LIGHT} disabled={disabled} onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Form>
  )

  return (
    <AppModal
      header="Invite User"
      onHide={onCancel}
      animation={false}
      show={true}
      centered
    >
      <Tab.Container
        activeKey={confirmation_dialog ? 'confirmation_dialog' : 'form'}
        id="invite-wizard"
      >
        <Tab.Content>
          <Tab.Pane eventKey="form">{form}</Tab.Pane>
          <Tab.Pane eventKey="confirmation_dialog">
            {confirmation_dialog}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </AppModal>
  )
}

import { Reducer } from 'react'
import { AxiosError } from 'axios'
import {
  IAction,
  IAPIErrorFormat,
  RequestStatus,
  Variant,
} from '../../common/types'
import { AuthRole } from './auth'
import { useApiWithIDToken, useDispatch } from './common'
import { applyUpdates as applyUsersUpdates } from './helpers/usersUpdates'

import createSlice, { ISliceState } from './helpers/createSlice'
import { useNotifications } from './notifications'

// Types & Interfaces
export interface IUser {
  id: string
  email: string
  emailVerified: boolean
  firstName: string
  lastName: string
  password?: string
  phoneNumber: string
  provider: string
  recieveRFPEmails: boolean
  blocked: boolean
  readOnly: boolean
  role?: AuthRole
  relatedChannelPartner?: number
  relatedEntity?: number
  userType?: UserTypes
  hasProspectorPermission?: boolean
  hasNearmapPermission?: boolean
}

export type IUserUpdate = Partial<IUser>

export interface IUsers extends ISliceState<IUser[]> {
  userInvitationStatus: RequestStatus
  userInvitationHTTPErrors?: IAPIErrorFormat
}

export enum UserTypes {
  NONE = '',
  CHANNEL_PARTNER = 'Channel Partner',
  ENTITY = 'Entity',
  BBE_USER = 'BBE User',
  SUPER_ADMIN = 'Super Admin',
}

export enum UserProps {
  ID = 'id',
  EMAIL = 'email',
  EMAIL_VERIFIED = 'emailVerified',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PASSWORD = 'password',
  PHONE_NUMBER = 'phoneNumber',
  // TODO -
  RECIEVE_RFP_EMAILS = 'recieveRFPEmails',
  HAS_PROSPECTOR_PERMISSION = 'hasProspectorPermission',
  HAS_NEARMAP_PERMISSION = 'hasNearmapPermission',
  BLOCKED = 'blocked',
  ROLE = 'role',
  READ_ONLY = 'readOnly',
  ACTIONS = 'actions',
  USER_TYPE = 'userType',
}

export enum UserHeaders {
  EMAIL = 'Email',
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  PASSWORD = 'Password',
  PHONE_NUMBER = 'Phone Number',
  RECIEVE_RFP_EMAILS = 'Receives Notifications',
  HAS_PROSPECTOR_PERMISSION = 'Prospector',
  HAS_NEARMAP_PERMISSION = 'Near Map',
  STATUS = 'Status',
  ROLE = 'Role',
  READ_ONLY = 'Read Only',
  ACTIONS = 'Actions',
  USER_TYPE = 'Channel Partner / Client',
}

export type IInviteUserBody = {
  email: string
  text?: string
  role?: AuthRole
  readOnly?: boolean
  channelPartnerID?: number
  entityID?: number
  firstName?: string
  lastName?: string
  phoneNumber?: string
  // TODO - https://app.clickup.com/t/1e6ffh6
  recieveRFPEmails?: boolean
  hasProspectorPermission?: boolean
  hasNearmapPermission?: boolean
}

export type IResendInvitationBody = {
  id: string
  email: string
}

export const isInvitationShouldAlwaysHaveNearmapPermission = (
  userType: UserTypes
) => userType === UserTypes.SUPER_ADMIN || userType === UserTypes.BBE_USER

// Slice Name
export const sliceName = 'users'

// Abstract Slice Instance
export const usersSlice = createSlice<IUser[]>(sliceName)

// Initial state
// eslint-disable-next-line no-var
export var initialState: IUsers = {
  ...usersSlice.initialState,
  userInvitationStatus: RequestStatus.IDLE,
}

// Actions
const USERS_UPDATED = `${sliceName}/USERS_UPDATED`
const USER_DELETED = `${sliceName}/USER_DELETED`
const USER_INVITATION_LOADING = `${sliceName}/USER_INVITATION_LOADING`
const USER_INVITATION_SENT = `${sliceName}/USER_INVITATION_SENT`
const USER_INVITATION_FAILED = `${sliceName}/USER_INVITATION_FAILED`
const USER_INVITATION_HTTP_ERRORS_DISMISSED = `${sliceName}/USER_INVITATION_HTTP_ERRORS_DISMISSED`
const USER_INVITATION_RESET = `${sliceName}/USER_INVITATION_RESET`

// Action creators
export const usersUpdated = (users: IUser[]) => {
  return { type: USERS_UPDATED, payload: users }
}

export const userDeleted = (id: string) => {
  return { type: USER_DELETED, payload: id }
}

export const userInvitationLoading = () => {
  return { type: USER_INVITATION_LOADING }
}

export const userInvitationSent = () => {
  return { type: USER_INVITATION_SENT }
}

export const userInvitationSentBKP = (user: IUser) => {
  return { type: USER_INVITATION_SENT, payload: user }
}

export const userInvitationFailed = (error: IAPIErrorFormat) => {
  return { type: USER_INVITATION_FAILED, payload: error }
}

export const userInvitationHTTPErrorsDismissed = () => {
  return { type: USER_INVITATION_HTTP_ERRORS_DISMISSED }
}

export const userInvitationReset = () => {
  return { type: USER_INVITATION_RESET }
}

// Slice Selectors Hooks
// @ts-expect-error IUsers interface has properties that the ISliceState obj does not
export const useUsers: () => IUsers = usersSlice.useSlice
export const useUserInvitationStatus = () => useUsers().userInvitationStatus
export const useUserInvitationHTTPErrors = () =>
  useUsers().userInvitationHTTPErrors

// Slice Actions Hooks
export const useLoadUsers = usersSlice.useLoadSlicePayload
export const useDismissUsersHTTPErrors = usersSlice.useDismissHTTPErrors

export const useSaveUsersUpdates = () => {
  const dispatch = useDispatch()
  const { payload: users } = useUsers()
  const apiWithIDToken = useApiWithIDToken()
  return async (usersUpdates: IUserUpdate[]) => {
    dispatch(usersSlice.requestLoading())
    try {
      const updatedUsers = applyUsersUpdates(users, usersUpdates)
      await apiWithIDToken.put('/users', usersUpdates)
      dispatch(usersUpdated(updatedUsers))
    } catch (error) {
      dispatch(
        usersSlice.requestFailed((error as AxiosError)?.response?.data || error)
      )
    }
  }
}

export const useDeleteUser = () => {
  const dispatch = useDispatch()
  const apiWithIDToken = useApiWithIDToken()
  return async (userID: string) => {
    dispatch(usersSlice.requestLoading())
    try {
      await apiWithIDToken.delete(`/users/${userID}`)
      dispatch(userDeleted(userID))
    } catch (error) {
      dispatch(
        usersSlice.requestFailed((error as AxiosError)?.response?.data || error)
      )
    }
  }
}

export const useInviteUser = () => {
  const dispatch = useDispatch()
  const apiWithIDToken = useApiWithIDToken()
  const loadUsers = useLoadUsers()
  return async (body: IInviteUserBody) => {
    dispatch(userInvitationLoading())
    try {
      await apiWithIDToken
        .post(`/${sliceName}/invite`, body)
        .then(() => dispatch(userInvitationSent()))
        .then(() => loadUsers())
        .catch((error) => {
          dispatch(
            userInvitationFailed(
              (error as AxiosError<IAPIErrorFormat>)?.response?.data ||
                (error as IAPIErrorFormat)
            )
          )
        })
    } catch (error) {
      dispatch(
        userInvitationFailed(
          (error as AxiosError<IAPIErrorFormat>)?.response?.data ||
            (error as IAPIErrorFormat)
        )
      )
    }
  }
}

export const useResendUserInvitation = () => {
  const apiWithIDToken = useApiWithIDToken()
  const { send: sendNotification } = useNotifications()
  return async (body: IResendInvitationBody) => {
    sendNotification({
      title: 'Resend Invitation',
      message: `Resending invitation for ${body.email}`,
      variant: Variant.INFO,
    })
    try {
      await apiWithIDToken.put(`/${sliceName}/${body.id}/resend-invitation`)
      sendNotification({
        title: 'Resend Invitation',
        message: `Invitation resent successfully`,
        variant: Variant.SUCCESS,
      })
    } catch (error) {
      sendNotification({
        title: 'Resend Invitation',
        message:
          (error as AxiosError<IAPIErrorFormat>)?.response?.data.message ||
          (error as Error).toString(),
        variant: Variant.DANGER,
      })
    }
  }
}

export const useDismissUserInvitationHTTPErrors = () => {
  const dispatch = useDispatch()
  return () => {
    dispatch(userInvitationHTTPErrorsDismissed())
  }
}

export const useResetUserInvitation = () => {
  const dispatch = useDispatch()
  return () => {
    dispatch(userInvitationReset())
  }
}

// Slice reducer
const reducer: Reducer<IUsers, IAction> = (
  state = initialState,
  { type, payload }
): IUsers => {
  switch (type) {
    case USERS_UPDATED:
      return {
        ...state,
        status: RequestStatus.SUCCEEDED,
        payload: payload as IUser[],
        httpErrors: undefined,
      }
    case USER_DELETED:
      return {
        ...state,
        status: RequestStatus.SUCCEEDED,
        payload: state.payload.filter((user) => user.id !== payload),
        httpErrors: undefined,
      }
    case USER_INVITATION_LOADING:
      return {
        ...state,
        userInvitationStatus: RequestStatus.LOADING,
      }
    case USER_INVITATION_SENT:
      return {
        ...state,
        userInvitationStatus: RequestStatus.SUCCEEDED,
        userInvitationHTTPErrors: undefined,
      }
    case USER_INVITATION_FAILED:
      return {
        ...state,
        userInvitationStatus: RequestStatus.FAILED,
        userInvitationHTTPErrors: payload as IAPIErrorFormat,
      }
    case USER_INVITATION_RESET:
      return {
        ...state,
        userInvitationStatus: RequestStatus.IDLE,
        userInvitationHTTPErrors: undefined,
      }
    default:
      return {
        ...state,
        ...usersSlice.reducer(state, { type, payload: payload as IUser[] }),
      }
  }
}

export default reducer

import React, { FunctionComponent, ReactNode } from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap'
import { XLg } from 'react-bootstrap-icons'
import { Variant } from '../../common/types'

interface Props extends ModalProps {
  header?: ReactNode
}

export const AppModal: FunctionComponent<Props> = ({
  header,
  onHide,
  children,
  ...passThroughProps
}) => (
  <Modal onHide={onHide} {...passThroughProps}>
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <div style={{ flex: 1 }} className="m-2">
        {typeof header === 'string' ? <h4>{header}</h4> : header}
      </div>
      {onHide && (
        <Button
          className="px-4"
          onClick={() => onHide()}
          variant={Variant.LIGHT}
          style={{ border: 0 }}
        >
          <XLg />
        </Button>
      )}
    </div>
    {children}
  </Modal>
)

import React, { FunctionComponent } from 'react'
import { Button, Container } from 'react-bootstrap'
import { RequestStatus } from '../common/types'
import { displayAlerts } from '../components/helpers/displayAlerts'
import { useImportUsers, usePortalUsers } from '../state/modules/portalUsers'
import { stringToBoolean } from '../utils/string'

export const UserImport: FunctionComponent = () => {
  const importUsers = useImportUsers()
  const { status, httpErrors } = usePortalUsers()
  return (
    <Container className="my-4 offset-xl-1 col-xl-10" style={{ flex: 1 }}>
      {displayAlerts(
        httpErrors && httpErrors.errors && httpErrors.errors.length !== 0
          ? httpErrors.errors // Display httpErrors
          : [httpErrors?.message] // Fall back to error.message if no errors[] provided
      )}

      {stringToBoolean(process.env.REACT_APP_IMPORT_USERS_ENABLE) ? (
        <Button
          onClick={importUsers}
          disabled={status === RequestStatus.LOADING}
        >
          Import Users
        </Button>
      ) : (
        <Button disabled title="Import users is currently disabled.">
          Import Users
        </Button>
      )}
    </Container>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const isEmpty = (value: string) =>
  value === undefined || value === null || value === '';

function integer(key: string) {
  return (value: any): string | void => {
    if (isEmpty(value)) return;
    if (!Number.isInteger(Number(value))) {
      return `${key} must be an integer`;
    }
  };
}

export function email(value: string): string | void {
  if (isEmpty(value)) return;
  // Let's not start a debate on email regex. This is just for an example app!
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'Invalid email address';
  }
}

export function firstName(value: string): string | void {
  if (isEmpty(value)) return;
  if (!/^[a-zA-Z].*$/i.test(value)) {
    return 'First name should start with an alphabetical character';
  }
}

export function lastName(value: string): string | void {
  if (isEmpty(value)) return;
  if (!/^[a-zA-Z].*$/i.test(value)) {
    return 'Last name should start with an alphabetical character';
  }
}

export function password(value: string): string | void {
  if (isEmpty(value)) return;
  if (!/(?=.{8,})/i.test(value)) {
    return 'The password must be eight characters or longer';
  } else if (!/(?=.*[a-z])/i.test(value)) {
    return 'The password must contain at least 1 lowercase alphabetical character';
  } else if (!/(?=.*[A-Z])/i.test(value)) {
    return 'The password must contain at least 1 uppercase alphabetical character';
  } else if (!/(?=.*[0-9])/i.test(value)) {
    return 'The password must contain at least 1 numeric character';
  } else if (!/(?=.*[!@#$%^&-_*])/i.test(value)) {
    return 'The password must contain at least one special character';
  }
}

export function required(key: string | number = 'Key') {
  return (value: any): string | void => {
    if (isEmpty(value)) {
      return `${key} must be provided!`;
    }
  };
}

export function number(key: string) {
  return (value: any): string | void => {
    if (isEmpty(value)) return;
    const parsed = parseFloat(value);
    if (isNaN(parsed)) {
      return `${key} must be a number`;
    }
  };
}

export function bool(key: string) {
  return (value: any): string | void => {
    if (isEmpty(value)) return;
    if (typeof value !== 'boolean') {
      return `${key} must be a boolean`;
    }
  };
}

export function phoneNumber(value: string): string | void {
  if (isEmpty(value)) return;

  if (integer(value) === null) {
    return 'Phone number format should be numbers only (no special characters), example: 8001234567';
  }

  if (value.length !== 10) {
    return 'Phone number should be exactly 10 digits';
  }
}

export function fullName(value: string): string | void {
  if (isEmpty(value)) return;
  if (!/^\w+\s(\w+\s)?\w+$/.test(value)) {
    return 'Must be valid full name (First and Last, ex: John Doe)';
  }
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export function createObjValidator<T extends { [prop: string]: Function[] }>(
  blueprint: T
) {
  return (payload: any) => {
    const errors: string[] = [];
    Object.keys(blueprint).forEach((key) => {
      blueprint[key].forEach((rule) => {
        const error = rule(payload[key]);
        if (error) {
          errors.push(error);
        }
      });
    });
    return errors;
  };
}

import React, { ChangeEventHandler, FunctionComponent, useMemo } from 'react'
import { Form } from 'react-bootstrap'
import { IChannelPartner } from '../../state/modules/channelPartners'
import { IEntity } from '../../state/modules/entities'
import { UserTypes } from '../../state/modules/users'
import { IAuthUser, isSuperAdmin } from '../../state/modules/auth'
import { RelatedChannelPartnerSelect } from './RelatedChannelPartnerSelect'
import { RelatedEntitySelect } from './RelatedEntitySelect'

interface Props {
  user: IAuthUser
  userType: UserTypes
  disabled: boolean
  onUserTypeChange: (type: UserTypes) => void

  entity?: IEntity
  onEntityChange: (entity?: IEntity) => void
  autoSelectEntityId?: number

  channelPartner?: IChannelPartner
  onChannelPartnerChange: (channelPartner?: IChannelPartner) => void
  autoSelectChannelPartnerId?: number
}

export const BBEUserOrSuperAdminInviteUserInputs: FunctionComponent<Props> = (
  props
) => {
  const {
    user,
    userType,
    disabled,
    onUserTypeChange,

    entity,
    onEntityChange,
    autoSelectEntityId,

    channelPartner,
    onChannelPartnerChange,
    autoSelectChannelPartnerId,
  } = props

  const handleUserTypeChange = useMemo<
    ChangeEventHandler<HTMLInputElement> | undefined
  >(
    () =>
      onUserTypeChange
        ? (e) => onUserTypeChange((e.currentTarget.value || '') as UserTypes)
        : undefined,
    [onUserTypeChange]
  )

  return (
    <>
      <fieldset>
        <legend className="required">User Type</legend>
        <Form.Group>
          <Form.Check type="radio" id="user-type">
            <Form.Check.Label>
              <Form.Check.Input
                type="radio"
                name="user-type"
                value={UserTypes.CHANNEL_PARTNER}
                checked={userType === UserTypes.CHANNEL_PARTNER}
                onChange={handleUserTypeChange}
                disabled={disabled}
              />
              Channel Partner
            </Form.Check.Label>
          </Form.Check>
          <Form.Check type="radio" name="user-type">
            <Form.Check.Label>
              <Form.Check.Input
                type="radio"
                name="user-type"
                value={UserTypes.ENTITY}
                checked={userType === UserTypes.ENTITY}
                onChange={handleUserTypeChange}
                disabled={disabled}
              />
              Client
            </Form.Check.Label>
          </Form.Check>
          <Form.Check type="radio" name="user-type">
            <Form.Check.Label>
              <Form.Check.Input
                type="radio"
                name="user-type"
                value={UserTypes.BBE_USER}
                checked={userType === UserTypes.BBE_USER}
                onChange={handleUserTypeChange}
                disabled={disabled}
              />
              BBE User
            </Form.Check.Label>
          </Form.Check>
          {isSuperAdmin(user) && (
            <Form.Check type="radio" name="user-type">
              <Form.Check.Label>
                <Form.Check.Input
                  type="radio"
                  name="user-type"
                  value={UserTypes.SUPER_ADMIN}
                  checked={userType === UserTypes.SUPER_ADMIN}
                  onChange={handleUserTypeChange}
                  disabled={disabled}
                />
                Super Admin
              </Form.Check.Label>
            </Form.Check>
          )}
        </Form.Group>
      </fieldset>

      {userType === UserTypes.CHANNEL_PARTNER && (
        <RelatedChannelPartnerSelect
          channelPartner={channelPartner}
          disabled={disabled}
          onChannelPartnerChange={onChannelPartnerChange}
          autoSelectChannelPartnerId={autoSelectChannelPartnerId}
        />
      )}

      {userType === UserTypes.ENTITY && (
        <RelatedEntitySelect
          entity={entity}
          disabled={disabled}
          onEntityChange={onEntityChange}
          autoSelectEntityId={autoSelectEntityId}
        />
      )}
    </>
  )
}

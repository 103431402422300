import {
  ChangeEvent,
  ComponentProps,
  Dispatch,
  FunctionComponent,
  useCallback,
  useState,
  FocusEvent,
} from 'react'
import { FormControl } from 'react-bootstrap'

interface Props
  extends Omit<
    ComponentProps<typeof FormControl>,
    'value' | 'onChange' | 'onReset'
  > {
  value?: number
  onChange?: Dispatch<number>
  onReset?: () => void
  decimalPlaces?: number
}

export const InputNumber: FunctionComponent<Props> = (props) => {
  const { value, onChange, onReset, decimalPlaces, ...passThroughProps } = props

  const [hotValue, setHotValue] = useState<string>()

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = parseFloat(event.target.value)

      setHotValue(event.target.value)

      if (newValue !== undefined && !isNaN(newValue)) onChange?.(newValue)
      else onReset?.()
    },
    [onChange, onReset]
  )

  const displayValue =
    decimalPlaces !== undefined && value !== undefined
      ? value.toFixed(decimalPlaces)
      : value?.toString()

  return (
    <FormControl
      type="number"
      value={hotValue || displayValue || ''}
      onChange={handleChange}
      onFocus={(e: FocusEvent<HTMLInputElement>) => {
        const newHotValue = (value || 0).toFixed(decimalPlaces)
        setHotValue(newHotValue)
        e.currentTarget.value = newHotValue
        e.currentTarget.select()
      }}
      onBlur={() => setHotValue(undefined)}
      {...passThroughProps}
    />
  )
}

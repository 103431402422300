import styled from 'styled-components'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { SiteRFPDocumentSummary } from '@black-bear-energy/black-bear-energy-common'
import { MetricsContainer } from './MetricsContainer'

export const SiteRFPSection: FunctionComponent<SiteRFPSectionProps> = ({
  siteRFPDocumentSummary,
}: SiteRFPSectionProps) => {
  return (
    <StyledSiteRFPSection>
      <Link
        to={{
          pathname: `/document-exchange/properties/${siteRFPDocumentSummary.siteRFPId}`,
          state: {
            id: siteRFPDocumentSummary.siteRFPId,
            name: siteRFPDocumentSummary.name,
          },
        }}
      >
        <div>{siteRFPDocumentSummary.name}</div>
      </Link>
      <MetricsContainer
        required={{
          numerator: siteRFPDocumentSummary.uploadedCounts.required,
          denominator: siteRFPDocumentSummary.totalCounts.required,
        }}
        reviewed={{
          numerator: siteRFPDocumentSummary.uploadedCounts.reviewed,
          denominator: siteRFPDocumentSummary.uploadedCounts.total,
        }}
      />
    </StyledSiteRFPSection>
  )
}

interface SiteRFPSectionProps {
  siteRFPDocumentSummary: SiteRFPDocumentSummary
}

const StyledSiteRFPSection = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.75rem 1.25rem;
`

import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import moment from 'moment'
import { Button, ListGroup, Modal } from 'react-bootstrap'
import { RequestStatus, Variant } from '../../common/types'
import {
  IChangeOrder,
  useChangeOrders,
  useDismissChangeOrdersHTTPErrors,
  useLoadChangeOrders,
} from '../../state/modules/changeOrders'
import { Pager, usePager } from '../../components/Pager'
import { ErrorsAlertsDisplay } from '../../components/helpers/displayAlerts'
import { InlineLoader } from '../../components/common/Loaders'

const PAGE_SIZE = 10

interface Props {
  selectedChangeOrderId?: string
  onChangeOrderSelect: (changeOrderId: string) => void
}

export const ChangeOrderSelect: FunctionComponent<Props> = (props) => {
  const { selectedChangeOrderId, onChangeOrderSelect } = props

  const {
    payload: { records: changeOrders, total },
    httpErrors,
    status,
  } = useChangeOrders()

  const dismissErrors = useDismissChangeOrdersHTTPErrors()

  const [showListPopup, setShowListPopup] = useState(false)

  const pagerProps = usePager(PAGE_SIZE)

  const { page, pageSize } = pagerProps

  const loadChangeOrders = useLoadChangeOrders()
  useEffect(() => {
    if (showListPopup) {
      loadChangeOrders?.({ skip: pageSize * page, limit: pageSize }).catch(
        () => {
          throw new Error('loadChangeOrders failed')
        }
      )
    }
  }, [loadChangeOrders, page, pageSize, showListPopup])

  const handleSelect = useCallback(
    (_id: IChangeOrder['_id']) => () => {
      onChangeOrderSelect(_id)
      setShowListPopup(false)
    },
    [onChangeOrderSelect]
  )

  const listItems = changeOrders?.map((changeOrder) => (
    <ListGroup.Item
      key={changeOrder._id}
      action
      onClick={handleSelect(changeOrder._id)}
      active={selectedChangeOrderId === changeOrder._id}
    >
      <div>{changeOrder.title}</div>
      <small>{moment(changeOrder.timestamp).format('LLLL')}</small>
    </ListGroup.Item>
  ))

  const handleCloseModal = () => setShowListPopup(false)
  const handleToggleModal = () => setShowListPopup(!showListPopup)

  const modal = (
    <Modal onHide={handleCloseModal} show={showListPopup} centered>
      <Modal.Body>
        <h4>
          Change Orders <InlineLoader hide={status !== RequestStatus.LOADING} />
        </h4>
        <ErrorsAlertsDisplay errors={httpErrors} dismiss={dismissErrors} />
        <ListGroup>{listItems}</ListGroup>
        <div className="mx-1 mt-2">
          <Pager {...pagerProps} totalCount={total} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={Variant.PRIMARY} onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )

  const openListPopupButton = (
    <Button onClick={handleToggleModal} variant={Variant.LIGHT}>
      Open Change Order...
    </Button>
  )

  return (
    <>
      {openListPopupButton}
      {modal}
    </>
  )
}

import { stringToBoolean } from 'deep-cuts'
import { RequestStatus } from '../../../common/types'
import { createActionsRenderer, IAction } from '../../renderers/actionsRenderer'
import { phoneNumberRenderer } from '../../renderers/phoneNumberRenderer'
import createSwitchRenderer from '../../renderers/switchRenderer'
import {
  isAdminOrBBEUserOrSuperAdmin,
  isBBEUserOrSuperAdmin,
  isAdminOrSuperAdmin,
  AuthRole,
  IAuthUser,
  AuthProvider,
} from '../../../state/modules/auth'
import { IConfirmationRequest } from '../../../state/modules/confirmation'
import {
  IResendInvitationBody,
  IUser,
  UserHeaders,
  UserProps,
} from '../../../state/modules/users'
import { IStoreContextValue } from '../../../state/store'
import { ManageUsersConfirmationAction } from '../types'
import { TableName } from '../../BootstrapTable/tableTypes'
import {
  IColumnSettings,
  rendererTdArg,
} from '../../BootstrapTable/ColumnSettings'

type IUserTableSettingsArgs = {
  user?: IAuthUser
  users: IUser[]
  dataWithChanges: IUser[]
  usersStatus: RequestStatus
  sendConfirmation: (confirmationRequest: IConfirmationRequest) => string
  setConfirmationReceiptID: (value: string) => void
  resendUserInvitation: (body: IResendInvitationBody) => Promise<void>
  context: IStoreContextValue
}

export function getManageUserTableSettings({
  user,
  users,
  dataWithChanges,
  usersStatus,
  sendConfirmation,
  setConfirmationReceiptID,
  resendUserInvitation,
  context,
}: IUserTableSettingsArgs) {
  const getUser = (userId: string) => users.find((user) => user.id === userId)
  const getUserChanged = (userId: string) =>
    dataWithChanges.find((user) => user.id === userId)

  function hasAuth0Provider(userId: string) {
    return getUserChanged(userId)?.provider === AuthProvider.AUTH0
  }
  function recordHasBBERole(userId: string) {
    return (
      getUserChanged(userId)?.role === AuthRole.BBE_USER ||
      getUserChanged(userId)?.role === AuthRole.SUPER_ADMIN
    )
  }

  function recordIsAdminOrSuperAdmin(userId: string) {
    return (
      getUserChanged(userId)?.role === AuthRole.ADMIN ||
      getUserChanged(userId)?.role === AuthRole.SUPER_ADMIN
    )
  }

  function isSameUser(userId: string) {
    return getUserChanged(userId)?.email === user?.email
  }

  function isUserBlocked(userId: string) {
    const user = getUser(userId)
    return user && user.blocked
  }

  function hasPendingInvitation(userId: string) {
    const user = getUser(userId)
    return user && !user.emailVerified
  }

  function isProspectorPermissionReadonly(userId: string): boolean {
    const user = getUser(userId)
    return !!user && isBBEUserOrSuperAdmin(user)
  }

  function isNearmapPermissionReadonly(userId: string): boolean {
    const user = getUser(userId)
    return !!user && isBBEUserOrSuperAdmin(user)
  }

  const passwordRenderer = (
    cellProperties: { hashLength: number; hashSymbol: string } | null,
    userId: string
  ) => {
    if (isSameUser(userId)) {
      return ''
    }
    const hashLength = cellProperties?.hashLength || 10
    const hashSymbol = cellProperties?.hashSymbol || '*'

    return hashSymbol.repeat(hashLength)
  }

  // column settings of the table
  const columns: IColumnSettings[] = [
    {
      propName: UserProps.EMAIL,
      label: UserHeaders.EMAIL,

      readOnly: () => true,
      comment: {
        value:
          'Emails cannot be changed from the administration console. You must send a new invite for a new email.',
      },
    },

    {
      propName: UserProps.FIRST_NAME,
      label: UserHeaders.FIRST_NAME,
      type: 'text-input',
      readOnly: getReadOnly,
    },
    {
      propName: UserProps.LAST_NAME,
      label: UserHeaders.LAST_NAME,
      type: 'text-input',
      readOnly: getReadOnly,
    },
    {
      propName: UserProps.PASSWORD,
      label: UserHeaders.PASSWORD,
      type: 'password',
      // hashLength: 10,
      readOnly: getReadOnly,
      renderer: passwordRenderer,
      comment: getPasswordComment,
      columnStyle: { maxWidth: '100px' },
    },
    {
      propName: UserProps.PHONE_NUMBER,
      label: UserHeaders.PHONE_NUMBER,
      type: 'text-input',
      renderer: phoneNumberRenderer,
      readOnly: () => false,
      columnStyle: { maxWidth: '130px' },
    },
    {
      propName: UserProps.RECIEVE_RFP_EMAILS,
      label: UserHeaders.RECIEVE_RFP_EMAILS,
      type: 'checkbox',
      columnStyle: { width: '100px', maxWidth: '120px' },
    },
  ]

  const prospectorPermissionFeatureFlag = stringToBoolean(
    process.env.REACT_APP_FEATURE_FLAG_EDITABLE_PROSPECTOR_PERMISSION
  )

  const prospectorPermissionsAllowed =
    !!prospectorPermissionFeatureFlag &&
    !!user &&
    !!user.hasProspectorPermission

  if (prospectorPermissionsAllowed) {
    columns.push({
      propName: UserProps.HAS_PROSPECTOR_PERMISSION,
      label: UserHeaders.HAS_PROSPECTOR_PERMISSION,
      type: 'checkbox',
      columnStyle: { width: '100px', maxWidth: '120px' },
      readOnly: (propName: string, row: string) =>
        isProspectorPermissionReadonly(row),
    })
  }

  if (prospectorPermissionsAllowed) {
    columns.push({
      propName: UserProps.HAS_NEARMAP_PERMISSION,
      label: UserHeaders.HAS_NEARMAP_PERMISSION,
      type: 'checkbox',
      columnStyle: { width: '100px', maxWidth: '120px' },
      readOnly: (propName: string, row: string) =>
        isNearmapPermissionReadonly(row),
    })
  }

  // add 4 more columns for super admin roles
  if (user && isAdminOrBBEUserOrSuperAdmin(user)) {
    // User needs to be changed when making the post request to AuthRole.NONE
    const rolesOptions: Array<string> = ['User', AuthRole.ADMIN]

    columns.push(
      {
        propName: UserProps.ROLE,
        label: UserHeaders.ROLE,
        type: 'dropdown',
        source: rolesOptions,
        readOnly: (propName: string, userId: string) =>
          recordHasBBERole(userId) ? true : false,
        columnStyle: { maxWidth: '150px' },
      },
      {
        propName: UserProps.READ_ONLY,
        label: UserHeaders.READ_ONLY,
        type: 'checkbox',
        readOnly: (propName: string, userId: string) =>
          recordIsAdminOrSuperAdmin(userId) ? true : false,
        columnStyle: { maxWidth: '120px' },
      },
      {
        readOnly: true,
        propName: UserProps.BLOCKED,
        label: UserHeaders.STATUS,
        // type: 'string',

        // Note - we perform this user binding so that we have access to the user instance inside the renderer
        // eslint-disable-next-line
        renderer: (td: rendererTdArg, userId: string) =>
          createSwitchRenderer(context).bind(getUser(userId) as IUser)(
            UserProps.BLOCKED
          ),
        columnStyle: {
          maxWidth: '120px',
          backgroundColor: '#f0f0f0',
          color: 'black',
        },
      },
      {
        propName: UserProps.ACTIONS,
        label: UserHeaders.ACTIONS,
        disableExport: true,
        renderer: (td: rendererTdArg, userId: string) => {
          return createActionsRenderer({
            rowKey: userId,
            context,
            isBlocked: isUserBlocked(userId) || false,
            allowDeactivation: isAdminOrSuperAdmin(user),
            allowDeletion: isAdminOrSuperAdmin(user),
            allowImpersonation: isBBEUserOrSuperAdmin(user),
            allowResendInvitation: hasPendingInvitation(userId),
            onSelect: onSelectHandler,
          })
        },
      }
    )
  }

  function getReadOnly(propName: string, userId: string) {
    if (usersStatus === RequestStatus.LOADING) {
      return true
    }
    let readOnly = false
    if (propName === UserProps.PASSWORD.toString()) {
      if (!hasAuth0Provider(userId) || !isSameUser(userId)) {
        readOnly = true
      }
    }

    return readOnly
  }

  function getPasswordComment(
    propName: string,
    userId: string | number
  ): { value: string | null } {
    const comment = { value: null as string | null }
    if (propName === UserProps.PASSWORD.toString()) {
      if (!hasAuth0Provider(userId.toString())) {
        comment.value =
          'Users with third-party login (Google) cannot change their email or password in this system.'
      } else if (!isSameUser(userId.toString())) {
        comment.value =
          'Users must change their own passwords, administrators cannot do it for them.'
      }
    }
    return comment
  }

  const onSelectHandler = (userId: string, action: IAction) => {
    if (action === IAction.DELETE) {
      const userToDelete = getUser(userId) as IUser
      const receiptID = sendConfirmation({
        title: 'Delete user',
        message: `Are you sure you want to delete "${userToDelete.email}"?`,
        declineLabel: 'Cancel',
        approveLabel: 'YES, DELETE IT',
        metadata: {
          action: ManageUsersConfirmationAction.DELETE_USER,
          payload: userToDelete.id,
        },
      })
      setConfirmationReceiptID(receiptID)
    } else if (action === IAction.ACTIVE) {
      const userToActive = getUser(userId) as IUser
      const receiptID = sendConfirmation({
        title: 'Activate user',
        message: `Are you sure you want to activate "${userToActive.email}"?`,
        declineLabel: 'Cancel',
        approveLabel: 'Yes',
        metadata: {
          action: ManageUsersConfirmationAction.ACTIVE_USER,
          payload: userToActive.id,
        },
      })
      setConfirmationReceiptID(receiptID)
    } else if (action === IAction.INACTIVE) {
      const userToInActive = getUser(userId) as IUser
      const receiptID = sendConfirmation({
        title: 'Deactivate user',
        message: `Are you sure you want to deactivate "${userToInActive.email}"?`,
        declineLabel: 'Cancel',
        approveLabel: 'Yes',
        metadata: {
          action: ManageUsersConfirmationAction.INACTIVE_USER,
          payload: userToInActive.id,
        },
      })
      setConfirmationReceiptID(receiptID)
    } else if (action === IAction.IMPERSONATE) {
      const userToImpersonate = getUser(userId) as IUser
      const receiptID = sendConfirmation({
        title: 'Impersonate user',
        message: `You will be impersonating "${userToImpersonate.email}". This impersonation session will remain open until you close it. Would you like to proceed?`,
        declineLabel: 'Cancel',
        approveLabel: 'Yes',
        metadata: {
          action: ManageUsersConfirmationAction.IMPERSONATE_USER,
          payload: {
            id: userToImpersonate.id,
            email: userToImpersonate.email,
          },
        },
      })
      setConfirmationReceiptID(receiptID)
    } else if (action === IAction.RESEND_INVITATION) {
      const user = getUser(userId) as IUser
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      resendUserInvitation(user)
    }
  }
  return {
    data: dataWithChanges,
    columns,
    rowKeyPropName: UserProps.ID,
    tableName: TableName.UserTable,
  }
}

import React, { useState, useEffect } from 'react'
import { Alert, Container, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

// state
import { useAuth } from '../state/modules/auth'
import { getApiInstance } from '../state/modules/common'

// helpers
import { ScreenLoader } from '../components/common/Loaders'
import userSetupHelper from '../utils/userSetupHelper'
import { HistoryState } from '../state/modules/helpers/WithAuthenticationRequired'

function Login() {
  const history = useHistory()
  const [loginBtnClicked, setLoginBtnClicked] = useState(false)
  const {
    isLoading,
    loginWithRedirect,
    error,
    user,
    isAuthenticated,
    idToken,
  } = useAuth()

  /**
   * Authentication successful hook.
   *
   * Sometimes isAuthenticated returns true while user and idToken are undefined
   * so we want to make sure that we have all the data needed before redirecting the user.
   */
  useEffect(() => {
    // check if it's the user's first login and perform user setup
    if (!isLoading && isAuthenticated && user && idToken) {
      const api = getApiInstance(String(idToken))
      userSetupHelper(api).catch(() => {
        throw new Error('userSetupHelper failed')
      })
      if (history.location.state === HistoryState.REDIRECTED_FROM_APP) {
        history.goBack()
      } else {
        history.replace('/')
      }
    }
  }, [history, user, isAuthenticated, idToken, isLoading])

  if (error?.message === 'user is blocked') {
    error.message =
      'Login failed, Please contact Black Bear Energy about your account.'
  }

  function handleLogin() {
    setLoginBtnClicked(true)
    loginWithRedirect().catch(() => {
      throw new Error('loginWithRedirect failed')
    })
  }

  return (!isLoading && !isAuthenticated) || loginBtnClicked ? (
    <div className="fixed-top h-100 w-100 d-flex justify-content-center align-items-center">
      <Container className="row d-flex flex-column align-items-center justify-content-center flex-wrap">
        <img
          src={`${process.env.PUBLIC_URL}/logo385.png`}
          alt="logo"
          className="mb-2"
        />
        {isLoading ? (
          <ScreenLoader />
        ) : (
          <>
            {error ? (
              <Alert variant={'danger'} className="col-4 mb-2">
                {error.message || 'Unexpected error'}
              </Alert>
            ) : (
              <p className="text-dark text-center font-italic col-4">
                The partner to empower your portfolio
              </p>
            )}
            <Button className="col-4 mt-2 btn-lg" onClick={handleLogin}>
              {error ? 'Try Again' : 'Login'}
            </Button>
          </>
        )}
      </Container>
    </div>
  ) : (
    <></>
  )
}

export default Login

import { useCallback, useEffect, useMemo, useState } from 'react'
import { AxiosError } from 'axios'
import { IAPIErrorFormat, IField } from '../../common/types'
import {
  useApiWithIDToken,
  useApiWithIDTokenGranted,
  useDispatch,
} from './common'
import createSlice from './helpers/createSlice'
import { ISiteRfp } from './siteRfps'
import { useEditLevel } from './auth'

export type IChangeOrderPatch = Partial<ISiteRfp> & Pick<ISiteRfp, 'recordId'>

export interface IChangeOrderPost {
  fields: IField[]
  title: string
  reason?: string
  patches: IChangeOrderPatch[]
  patchedRecordsSnapshot: ISiteRfp[]
  patchedRecords?: ISiteRfp[]
}

export interface IChangeOrder extends IChangeOrderPost {
  _id: string
  bidSubmissionIds: number[]
  resultMetadata?: {
    [table: string]: {
      createdRecordIds: number[]
      totalNumberOfRecordsProcessed: number
      unchangedRecordIds: number[]
      updatedRecordIds: number[]
    }
  }
  timestamp: Date | string
}

export interface IChangeOrderItem {
  _id: string
  title: string
  timestamp: Date | string
}

export interface IChangeOrderQuery {
  skip?: number
  limit?: number
}

export interface IChangeOrdersSlice {
  records?: IChangeOrderItem[]
  total?: number
  query?: IChangeOrderQuery
}

// Slice Name
export const sliceName = 'changeOrder'

// Slice URL
export const url = '/change-orders'

// Abstract Slice Instance
export const changeOrderSlice = createSlice<IChangeOrdersSlice>(
  sliceName,
  url,
  {}
)

// Initial state
export const initialState = changeOrderSlice.initialState

// Slice reducers
const reducer = changeOrderSlice.reducer

// Slice Selectors Hooks
export const useChangeOrders = changeOrderSlice.useSlice

export const useLoadChangeOrders = () => {
  const dispatch = useDispatch()
  const api = useApiWithIDTokenGranted()
  return useMemo(
    () =>
      api
        ? async (query?: IChangeOrderQuery) => {
            dispatch(changeOrderSlice.requestLoading())
            try {
              const siteRfpsResponse = await api.get<IChangeOrdersSlice>(url, {
                params: { ...query },
              })
              dispatch(changeOrderSlice.requestLoaded(siteRfpsResponse.data))
            } catch (error) {
              dispatch(changeOrderSlice.requestFailed(error as IAPIErrorFormat))
            }
          }
        : undefined,
    [api, dispatch]
  )
}

export const useSubmitChangeOrder = () => {
  const dispatch = useDispatch()
  const apiWithIDToken = useApiWithIDToken()
  const [editLevel] = useEditLevel()

  return useCallback(
    async (post: IChangeOrderPost) => {
      dispatch(changeOrderSlice.requestLoading())
      try {
        const response = await apiWithIDToken.post(url, post, {
          params: { editLevel },
        })
        dispatch(changeOrderSlice.requestLoaded())
        return response.data as IChangeOrder
      } catch (error) {
        dispatch(
          changeOrderSlice.requestFailed(
            (error as AxiosError)?.response?.data || error
          )
        )
        throw error
      }
    },
    [apiWithIDToken, dispatch, editLevel]
  )
}

export const useChangeOrder = (id?: string) => {
  const api = useApiWithIDTokenGranted()

  const [changeOrder, setChangeOrder] = useState<IChangeOrder>()
  const [error, setError] = useState<IAPIErrorFormat>()

  useEffect(() => {
    if (!id) {
      return undefined
    }

    if (!api) {
      return undefined
    }

    if (error) {
      return undefined
    }

    let cancelled = false

    setChangeOrder(undefined)

    api
      .get(`${url}/${id}`)
      .then((result) => {
        if (!cancelled) {
          setChangeOrder(result.data as IChangeOrder)
        }
      })
      .catch((error) => {
        if (!cancelled) {
          setError(error as IAPIErrorFormat)
        }
      })

    return () => {
      cancelled = true
    }
  }, [id, api, error])

  const loading = !changeOrder && !error && !!api && !!id

  const dismissError = useCallback(() => setError(undefined), [])

  return {
    changeOrder,
    error,
    loading,
    dismissError,
  }
}

export const useDismissChangeOrdersHTTPErrors =
  changeOrderSlice.useDismissHTTPErrors

// Slice reducer
export default reducer

import { Dispatch, useCallback, useMemo } from 'react'
import { IField } from '../../common/types'
import {
  IReportData,
  IUserReportPatch,
  userReportToSettings,
  userSettingsToReport,
} from '../../state/modules/userReports'
import { getFieldSettings } from '../Assignment/typeRenderers'
import {
  IColumnSettings,
  useColumnSettingsFromFields,
} from '../BootstrapTable/ColumnSettings'
import { useUserSettings } from '../BootstrapTable/UserSettings'
import { defaultUserSettings } from './ChangeOrder'

const defaultSettings = {}

export const useProjectColumns = (fields: IField[]) =>
  useColumnSettingsFromFields(fields, {
    defaultSettings,
    getFieldSettings,
  })

export const useProjectTableSettings = (
  columns: readonly IColumnSettings[],
  report?: IReportData | IUserReportPatch | undefined,
  setPatch?: Dispatch<IReportData>
) => {
  const value = useMemo(
    () =>
      report ? userReportToSettings(report, columns) : defaultUserSettings,
    [report, columns]
  )

  return useUserSettings({
    value,
    onChange: useCallback(
      (newValue) => setPatch?.(userSettingsToReport(newValue, columns)),
      [setPatch, columns]
    ),
    columns,
    autoSanitize: false,
  })
}

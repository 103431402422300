// import React, { FunctionComponent } from 'react';
import Switch from '../common/Switch'
import { IUser } from '../../state/modules/users'
import { IStoreContextValue, StateProvider } from '../../state/store'

export default function createSwitchRenderer(context: IStoreContextValue) {
  return function switchRenderer(this: IUser, cellVal: unknown) {
    const pendingInvitation = !this?.emailVerified
    const blocked = this?.blocked

    // Function to cast cellVal to a boolean
    function getBoolCellValue(cellVal: unknown) {
      return cellVal === null ? false : !!cellVal
    }

    const settings: { [key: string]: unknown } = {
      pendingInvitation: pendingInvitation,
      blocked: blocked,
    }

    const SwitchCmp = (
      <StateProvider value={context}>
        <Switch
          value={getBoolCellValue(cellVal)}
          activeLabel="Inactive"
          inactiveLabel="Active"
          settings={settings}
        />
      </StateProvider>
    )

    if (blocked) {
      settings.innerText = 'Deactivated'
      settings.classList = 'pending-invitation-container'
      settings.setAttribute = { inactive: 'inactive' } // Save pendingInvitation as an attribute on tdELement
    } else if (pendingInvitation) {
      settings.innerText = 'Pending'
      settings.classList = 'pending-invitation-container'
      settings.setAttribute = { pendingInvitation: 'pending' } // Save pendingInvitation as an attribute on tdELement
    } else {
      settings.innerText = 'Active'
      settings.setAttribute = { active: 'active' } // Save pendingInvitation as an attribute on tdELement
      settings.classList = 'pending-invitation-container'
    }
    return SwitchCmp
  }
}

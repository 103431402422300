import { cloneDeep } from 'lodash'
import { ITableMessage } from 'dromo-uploader-js'
import { IRowCellBasic, IRowHookCell } from 'dromo-uploader-js/dist/interfaces'
import { getUpdatedValueString } from './getUpdatedValueString'

/**
 * Update the value of a Dromo cell and add a tooltip message that the value was updated.
 * @param cell - A Dromo cell object
 * @param oldValue - The previous value of the cell
 * @param newValue - The new value of the cell
 * @returns An updated copy of the cell
 */
export function updateCellValue<T extends IRowHookCell | IRowCellBasic>(
  cell: T,
  oldValue: string,
  newValue: string | undefined
): T {
  if (oldValue === newValue) {
    return cell
  }

  const cellClone = cloneDeep(cell)
  cellClone.value = newValue
  if (!cellClone.info) {
    cellClone.info = []
  }
  const message: ITableMessage = {
    message: getUpdatedValueString(oldValue),
    level: 'info',
  }
  cellClone.info.push(message)
  return cellClone
}

// implementation taken from https://www.geeksforgeeks.org/introduction-to-levenshtein-distance/
export default function getEditDistance(
  rawStr1: string,
  rawStr2: string
): number {
  // ignore whitespace and casing
  const str1 = rawStr1.replaceAll(/\s/g, '').toLowerCase();
  const str2 = rawStr2.replaceAll(/\s/g, '').toLowerCase();

  const m = str1.length;
  const n = str2.length;

  const matrix: number[][] = new Array(m + 1)
    .fill(null)
    .map(() => new Array(n + 1).fill(0));

  // Initialize the first row
  // and column of the matrix
  for (let i = 0; i <= m; i++) {
    matrix[i][0] = i;
  }
  for (let j = 0; j <= n; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= m; i++) {
    for (let j = 1; j <= n; j++) {
      if (str1[i - 1] === str2[j - 1]) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] =
          1 +
          Math.min(
            // Insert
            matrix[i][j - 1],
            Math.min(
              // Remove
              matrix[i - 1][j],
              // Replace
              matrix[i - 1][j - 1]
            )
          );
      }
    }
  }
  return matrix[m][n];
}

import { useEffect, useMemo, useState } from 'react'
import { Button, ButtonProps, Modal } from 'react-bootstrap'
import { isSuperAdmin, useUser } from '../../state/modules/auth'
import { useLoadSiteRfpFields } from '../../state/modules/siteRfpsFields'
import { ComponentLoader } from '../common/Loaders'
import { ErrorsAlertsDisplay } from '../helpers/displayAlerts'
import { FieldAssignmentList } from './List'
import { useFieldAssignments } from './useFieldAssignments'

const FieldAssignmentEditorButton = (props: ButtonProps) => {
  const [show, setShow] = useState(false)

  const reloadFields = useLoadSiteRfpFields()

  const {
    status,
    items,
    tables,
    load,
    create,
    remove,
    change,
    reset,
    isDirtyField,
    isDirtyRecord,
    save,
  } = useFieldAssignments()

  const [error, setError] = useState<Error>()

  const handleSave = useMemo(
    () =>
      save
        ? () => {
            save()
              .then(() => reloadFields())
              .catch((error) => {
                setError(error as Error)
              })
          }
        : undefined,
    [save, reloadFields]
  )

  const handleLoad = useMemo(
    () =>
      load
        ? async () => {
            try {
              await load()
            } catch (error) {
              setError(error as Error)
            }
          }
        : undefined,
    [load]
  )

  const list = items && tables && show && (
    <FieldAssignmentList
      items={items}
      tables={tables}
      create={create}
      remove={remove}
      change={change}
      reset={reset}
      isDirtyField={isDirtyField}
      isDirtyRecord={isDirtyRecord}
    />
  )

  const [autoLoad, setAutoLoad] = useState(true)
  useEffect(() => {
    if (autoLoad && handleLoad && show) {
      setAutoLoad(false)
      handleLoad().catch(() => {
        throw new Error('FieldAssignmentEditor handleLoad failed')
      })
    }
    if (!show) setAutoLoad(true)
  }, [autoLoad, handleLoad, show])

  const modal = show && (
    <Modal show={show} centered dialogClassName="modal-90w">
      <Modal.Header>
        <h4>Configure fields</h4>
      </Modal.Header>

      <Modal.Body>
        {error && (
          <div style={{ position: 'sticky', top: 0, zIndex: 2 }}>
            <ErrorsAlertsDisplay
              errors={[error]}
              dismiss={() => setError(undefined)}
            />
          </div>
        )}
        {status === 'loading' && <ComponentLoader className="loading" />}
        {list}
      </Modal.Body>

      <Modal.Footer
        className="justify-content-end"
        style={{ position: 'sticky', bottom: 0, backgroundColor: 'white' }}
      >
        {status === 'update' && <ComponentLoader className="loading" />}

        <Button className="px-4" onClick={handleSave} disabled={!handleSave}>
          Save
        </Button>

        <Button
          className="px-4"
          onClick={() => setShow(false)}
          disabled={false}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )

  return (
    <>
      <Button onClick={() => setShow(!show)} {...props} />
      {modal}
    </>
  )
}

export const FieldAssignmentEditorSettings = (props: ButtonProps) => {
  const user = useUser()

  if (user && isSuperAdmin(user))
    return <FieldAssignmentEditorButton {...props} />

  return null
}

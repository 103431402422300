export function deepCloneArray<T>(data: T[]) {
  const m: T[] = []
  data.forEach((element) => m.push({ ...element }))
  return m
}

export const orderBy = <k>(
  arr: k[],
  mapItem: (item: k) => string | number | bigint
) => arr.sort((e1, e2) => (mapItem(e1) > mapItem(e2) ? 1 : -1))

export function mergeArrays<T>(...args: T[][]) {
  return args.reduce((prev, cur) => [...prev, ...cur], [])
}

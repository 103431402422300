import { IField } from '../common/types'

export const separate3then2 = (seq: string, separator: string) => {
  const first3 = seq.slice(-3)
  let rest = seq.slice(0, -3)
  const groups: string[] = []
  while (true) {
    if (rest.length === 0) break
    groups.unshift(rest.slice(-2))
    rest = rest.slice(0, -2)
  }
  groups.push(first3)
  return groups.join(separator)
}

export const separate3 = (seq: string, separator: string) => {
  const groups: string[] = []
  while (true) {
    if (seq.length === 0) break
    groups.unshift(seq.slice(-3))
    seq = seq.slice(0, -3)
  }
  return groups.join(separator)
}

export const quickbaseNumberFormat = (
  value: number,
  properties: IField['properties']
) => {
  const {
    numberFormat = 0,
    decimalPlaces,
    commaStart = 3,
    currencyFormat = 'left',
    currencySymbol,
  } = properties || {}

  const isNegative = value < 0
  const absStr =
    typeof decimalPlaces === 'number'
      ? Math.abs(value).toFixed(decimalPlaces)
      : Math.abs(value).toString()

  // eslint-disable-next-line prefer-const
  let [int, fraction] = absStr.split('.')
  const decimalSeparator = [7, 6, 10].includes(numberFormat) ? ',' : '.'
  if (numberFormat !== 0 && commaStart < int.length) {
    const thousandsSeparator = [7, 6, 10].includes(numberFormat) ? '.' : ','
    if ([8, 10].includes(numberFormat)) {
      int = separate3then2(int, thousandsSeparator)
    } else {
      int = separate3(int, thousandsSeparator)
    }
  }

  return [
    currencyFormat === 'left' && currencySymbol,
    isNegative ? '-' : '',
    currencyFormat === 'middle' && currencySymbol,
    int,
    !!fraction && `${decimalSeparator}${fraction}`,
    currencyFormat === 'right' && currencySymbol,
  ]
    .filter(Boolean)
    .join('')
}

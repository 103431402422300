import styled from 'styled-components'
import { FunctionComponent } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import {
  useLoadRFPDocumentSummaries,
  useRFPDocumentSummaries,
} from '../state/modules/rfpDocumentSummaries'
import { useCallOnceOnIDTokenGranted } from '../state/modules/common'
import { Container } from 'react-bootstrap'
import { CustomerRFPList } from '../components/DocumentExchange/CustomerRFPList'
import {
  SiteRFPDetails,
  SiteRFPDetailsProps,
} from '../components/DocumentExchange/SiteRFPDetails'

export const DocumentExchange: FunctionComponent = () => {
  const loadRFPDocumentSummaries = useLoadRFPDocumentSummaries()
  const location = useLocation<SiteRFPDetailsProps | null>()
  const siteRFPDetailsProps = location.state
  useCallOnceOnIDTokenGranted(loadRFPDocumentSummaries)
  const { payload: rfpDocumentSummaries } = useRFPDocumentSummaries()

  return (
    <StyledContainer>
      <Switch>
        <Route exact path="/document-exchange">
          <CustomerRFPList rfpDocumentSummaries={rfpDocumentSummaries} />
        </Route>
        <Route path="/document-exchange/properties/:siteRFPId">
          {siteRFPDetailsProps && (
            <SiteRFPDetails
              id={siteRFPDetailsProps.id}
              name={siteRFPDetailsProps.name}
            />
          )}
        </Route>
      </Switch>
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)`
  margin-top: 2rem;
`

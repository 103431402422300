import { omitBy } from 'lodash'
import { Dispatch, HTMLProps, useEffect, useMemo, useState } from 'react'
import {
  JSONEditor,
  renderJSONSchemaEnum,
  renderValue,
  OnRenderValue,
  JSONValue,
  Content,
  JSONContent,
} from 'vanilla-jsoneditor'

interface Props {
  value?: NonNullable<unknown>
  schema?: JSONValue
  schemaDefinitions?: JSONValue
  readOnly?: boolean
  indentation?: number | string
  tabSize?: number
  mainMenuBar?: boolean
  navigationBar?: boolean
  statusBar?: boolean
  escapeControlCharacters?: boolean
  escapeUnicodeCharacters?: boolean
  flattenColumns?: true
  onValuePatch?: Dispatch<{ [key: string]: unknown }>
  onClassName?: (path: string[]) => string | undefined
  ContainerProps?: HTMLProps<HTMLDivElement>
}

interface OnChangeStatus {
  patchResult: object | null
}

export const SvelteJSONEditor = ({
  ContainerProps,
  value,
  onValuePatch,
  schema,
  schemaDefinitions,
  ...props
}: Props) => {
  const content = useMemo(
    () => ({
      json: value || {},
    }),
    [value]
  )
  const handleChange = useMemo(
    () =>
      onValuePatch
        ? (
            newJsonContent: Content,
            previousJsonContent: Content,
            { patchResult }: OnChangeStatus
          ) => {
            if (!patchResult) return undefined
            if (!newJsonContent.hasOwnProperty('json')) return undefined

            if (onValuePatch) {
              const newJson = (newJsonContent as JSONContent).json as {
                [key: string]: JSONValue
              }
              const previousJson = (previousJsonContent as JSONContent).json as
                | {
                    [key: string]: JSONValue
                  }
                | undefined
              const patch = omitBy(
                newJson,
                (value, key) => previousJson?.[key] === value
              ) as {
                [key: string]: JSONValue | undefined
              }
              const unsetKeys = Object.keys(previousJson || {}).filter(
                (key) => !(key in newJson)
              )
              for (const unsetKey of unsetKeys) patch[unsetKey] = undefined
              onValuePatch(patch)
            }
          }
        : undefined,
    [onValuePatch]
  )
  const [containerRef, setContainerRef] = useState<Element | null>(null)
  const [editor, setEditor] = useState<JSONEditor>()

  useEffect(() => {
    if (!containerRef) return undefined

    const newEditor = new JSONEditor({
      target: containerRef,
      props: {},
    })

    setEditor(newEditor)

    return () => {
      newEditor.destroy()
      setEditor(undefined)
    }
  }, [containerRef])

  const handleRenderValue = useMemo<OnRenderValue>(
    () =>
      schema && schemaDefinitions
        ? (renderProps) =>
            renderJSONSchemaEnum(renderProps, schema, schemaDefinitions) ||
            renderValue(renderProps)
        : renderValue,
    [schema, schemaDefinitions]
  )

  useEffect(() => {
    if (editor) {
      editor.updateProps({
        ...props,
        onRenderValue: handleRenderValue,
        onChange: handleChange,
      })
    }
  }, [editor, props, handleRenderValue, handleChange])

  useEffect(() => {
    if (editor) editor.updateProps({ content })
  }, [editor, content])

  const { style, ...RestContainerProps } = ContainerProps || {}

  return (
    <div
      style={{ display: 'flex', flex: 1, ...style }}
      {...RestContainerProps}
      ref={setContainerRef}
    />
  )
}

import { IConfirmation } from '../../state/modules/confirmation'
import { IImpersonatingDetails } from '../../state/modules/impersonations'

export enum ManageUsersConfirmationAction {
  SAVE_UPDATES = 'Save updates',
  DELETE_USER = 'Delete user',
  ACTIVE_USER = 'Active User',
  INACTIVE_USER = 'InActive User',
  IMPERSONATE_USER = 'Impersonate User',
}

export interface IManageUsersConfirmation extends IConfirmation {
  metadata?: {
    action: ManageUsersConfirmationAction
    payload?: IImpersonatingDetails | string
  }
}

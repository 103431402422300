import { Form } from 'react-bootstrap'
import { isDefined } from '../../utils/typeGuards'

interface IEmailInput {
  disabled: boolean
  onChange: (value: string) => void
  error?: string
}

export default function EmailInput({ disabled, onChange, error }: IEmailInput) {
  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    onChange(e.target.value)
  }

  return (
    <Form.Group>
      <Form.Label htmlFor="invite-user-modal-email" className="required">
        Email
      </Form.Label>
      <Form.Control
        type="email"
        placeholder="Enter email"
        id="invite-user-modal-email"
        name="email"
        onChange={handleEmailChange}
        className={isDefined(error) ? 'invalid' : ''}
        required
        disabled={disabled}
      />
      {isDefined(error) ? (
        <p>
          <small>{error}</small>
        </p>
      ) : (
        ''
      )}
    </Form.Group>
  )
}

import { FunctionComponent, useMemo, ChangeEvent } from 'react'
import classnames from 'classnames'
import { ICheckboxProps } from './tableProps'

export const Checkbox: FunctionComponent<ICheckboxProps> = (props) => {
  const {
    rowKey,
    propName,
    value,
    invalid,
    dirty,
    handleTableChanges,
    columnStyle,
    tdProps,
  } = props

  const cellStyle: React.CSSProperties = {}

  const handleChange = useMemo(
    () =>
      handleTableChanges
        ? (e: ChangeEvent<HTMLInputElement>) =>
            handleTableChanges?.([
              {
                rowKey,
                propName,
                oldValue: value,
                newValue: !!e.currentTarget.checked,
              },
            ])
        : undefined,
    [rowKey, propName, value, handleTableChanges]
  )

  return (
    <td
      {...tdProps}
      className={classnames(
        invalid && 'invalidCell',
        dirty && 'dirty-cell',
        tdProps?.className
      )}
      style={{
        ...columnStyle,
        ...tdProps?.style,
      }}
    >
      <label style={{ ...columnStyle, ...cellStyle }}>
        <input
          type="checkbox"
          checked={value || false}
          onChange={handleChange}
          readOnly={!handleChange}
        />
      </label>
    </td>
  )
}

import { Dropdown } from 'react-bootstrap'
import styled from 'styled-components'
import { IStyledComponentPropTypes } from '../../common/types'
import { DownIcon, PRIMARY } from '../common/BlackBearEnergy'
import { IStoreContextValue, StateProvider } from '../../state/store'

export enum IAction {
  DELETE = 'Delete',
  ACTIVE = 'Active',
  INACTIVE = 'InActive',
  IMPERSONATE = 'Impersonate',
  RESEND_INVITATION = 'ResendInviation',
}

interface IActionsDropdownPropTypes extends IStyledComponentPropTypes {
  isBlocked: boolean
  allowDeactivation?: boolean
  allowDeletion?: boolean
  allowImpersonation?: boolean
  allowResendInvitation?: boolean
  onSelect: (action: IAction) => void
}

function ActionsDropdown({
  className,
  isBlocked,
  allowDeactivation,
  allowDeletion,
  allowImpersonation,
  allowResendInvitation,
  onSelect,
}: IActionsDropdownPropTypes) {
  return (
    <Dropdown className={className}>
      <Dropdown.Toggle variant="link">
        <DownIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {allowResendInvitation && (
          <Dropdown.Item onSelect={() => onSelect(IAction.RESEND_INVITATION)}>
            Re-send Invitation
          </Dropdown.Item>
        )}
        {allowDeactivation &&
          (isBlocked === undefined || isBlocked === false ? (
            <Dropdown.Item onSelect={() => onSelect(IAction.INACTIVE)}>
              Deactivate
            </Dropdown.Item>
          ) : (
            <Dropdown.Item onSelect={() => onSelect(IAction.ACTIVE)}>
              Activate
            </Dropdown.Item>
          ))}
        {allowDeletion && (
          <Dropdown.Item onSelect={() => onSelect(IAction.DELETE)}>
            Delete
          </Dropdown.Item>
        )}
        {allowImpersonation && (
          <Dropdown.Item onSelect={() => onSelect(IAction.IMPERSONATE)}>
            Impersonate
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

const StyledActionsDropdown = styled(ActionsDropdown)`
  display: flex;
  justify-content: center;

  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-menu {
    position: absolute !important;
    bottom: 0;
  }

  .dropdown-item {
    color: ${PRIMARY};
  }

  .dropdown-item:active {
    background: transparent;
  }
`

interface ICreateActionsRendererPropTypes<T extends string | number> {
  rowKey: T
  context: IStoreContextValue
  isBlocked: boolean
  allowDeactivation?: boolean
  allowDeletion?: boolean
  allowImpersonation?: boolean
  allowResendInvitation?: boolean
  onSelect: (rowKey: T, action: IAction) => void
}

export function createActionsRenderer<T extends string | number>({
  rowKey,
  context,
  isBlocked,
  allowDeactivation,
  allowDeletion,
  allowImpersonation,
  allowResendInvitation,
  onSelect,
}: ICreateActionsRendererPropTypes<T>) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <StateProvider value={context}>
        <StyledActionsDropdown
          isBlocked={isBlocked}
          allowDeactivation={allowDeactivation}
          allowDeletion={allowDeletion}
          allowImpersonation={allowImpersonation}
          allowResendInvitation={allowResendInvitation}
          onSelect={(action) => onSelect(rowKey, action)}
        />
      </StateProvider>
    </div>
  )
}

import { useCallback, useState } from 'react'
import { stringToBoolean } from 'deep-cuts'
import { useSetObjectField } from './useSetObjectField'

const DISABLE = stringToBoolean(
  process.env[`REACT_APP_FEATURE_FLAG_LOCAL_STORAGE_JSON_DISABLE`]
)
if (DISABLE) {
  console.log(
    'LocalStorage disabled for all keys. To enable, set REACT_APP_FEATURE_FLAG_LOCAL_STORAGE_JSON_DISABLE=false'
  )
}

const makeSetter =
  (storage: Storage) =>
  <T>(key: string, value: T | null) => {
    if (DISABLE) {
      return undefined
    }
    if (value === null) {
      storage.removeItem(key)
    } else {
      storage.setItem(key, JSON.stringify(value))
    }
  }

const makeGetter =
  (storage: Storage) =>
  <T>(key: string, validate: (raw: unknown) => raw is T): T | null => {
    if (DISABLE) {
      return null
    }

    const rawJSON = storage.getItem(key)
    if (rawJSON === null) {
      return null
    }
    try {
      const raw = JSON.parse(rawJSON) as unknown
      if (!validate(raw)) {
        throw new Error(`Validation error in storage JSON ${key}`)
      }
      return raw
    } catch (error) {
      console.error(`Error while reading storage JSON ${key}`, error)
      return null
    }
  }

/**
 * To disable this hook, use env variable:
 * `REACT_APP_FEATURE_FLAG_LOCAL_STORAGE_JSON_DISABLE=true`
 */
export const makeStorageJSON =
  (storage: Storage) =>
  <T>(key: string, validate: (raw: unknown) => raw is T) => {
    const getter = makeGetter(storage)
    const setter = makeSetter(storage)

    const useStorageJSON = (defaultValue?: T) => {
      const [value, setValue] = useState<T | undefined>(() => {
        const currentValue = getter(key, validate)
        return currentValue !== null ? currentValue : defaultValue
      })

      const set = useCallback(
        (newValue: T | ((currentValue: T | undefined) => T)) => {
          if (typeof newValue === 'function')
            return setValue((currentValue) => {
              setter(key, newValue)
              return (newValue as (currentValue: T | undefined) => T)(
                currentValue
              )
            })

          setter(key, newValue)
          setValue(newValue)
        },
        []
      )

      const setField = useSetObjectField(value, set)

      const reset = useCallback(() => {
        setValue(defaultValue)
        setter(key, null)
      }, [defaultValue])

      return [value, set, setField, reset] as const
    }

    return useStorageJSON
  }

export const setLocalStorageJSON = makeSetter(localStorage)
export const getLocalStorageJSON = makeGetter(localStorage)
export const makeLocalStorageJSON = makeStorageJSON(localStorage)

export const makeSessionStorageJSON = makeStorageJSON(sessionStorage)

export const clearBrowserStorages = () => {
  localStorage.clear()
  sessionStorage.clear()
}

import { SiteUploadRecord, DuplicateCheckResult } from './UploaderHelpers'
import { AxiosInstance } from 'axios'
import {
  SiteAction,
  SiteDuplicateCheck,
  DuplicateSite,
} from '@black-bear-energy/black-bear-energy-common'
import { IRowToAdd, IRow } from 'dromo-uploader-js/dist/interfaces'
import {
  uploadMatchedRecordSiteActionOptions,
  uploadUnmatchedRecordSiteActionOptions,
  existingRecordSiteActionOptions,
} from './FieldSchema'

export const dupParentMsg = 'Duplicated by following row(s)'
export const dupChildMsg = 'Duplicate of row above'

const modifyActions = [SiteAction.Update, SiteAction.Sold]

export async function checkForDuplicates(
  sites: SiteUploadRecord[],
  clientId: number,
  api: AxiosInstance
): Promise<DuplicateCheckResult | null> {
  const proposedSites: SiteDuplicateCheck[] = sites
    .filter((site) => site.siteAction !== SiteAction.Ignore)
    .map((site) => {
      const siteAction = site.siteAction as SiteAction
      const existingSiteToModify = modifyActions.includes(siteAction)
        ? (site.existingRecordId as number)
        : undefined
      return {
        id: site.rowId as string,
        clientDesignation: site.clientDesignation as string,
        name: site.name as string,
        latitude: site.latitude as number,
        longitude: site.longitude as number,
        existingSiteToModify,
      } satisfies SiteDuplicateCheck
    })

  const data = (
    await api.post<DuplicateSite[]>('/sites/dedupe', {
      clientId,
      sites: proposedSites,
    })
  ).data

  const dupCount = data.length
  if (dupCount === 0) {
    return null
  }

  const duplicateGroups: {
    [key: string]: SiteUploadRecord[]
  } = {}

  // find the duplicate rows in the original list
  for (const site of sites) {
    // not considered in the duplicate logic
    if (site.siteAction === SiteAction.Ignore) {
      continue
    }
    const duplicateMatchInd = data.findIndex(
      (d) => d.rowId === site.rowId || d.dupId === site.rowId
    )
    // this row is not duplicated or a duplicate
    if (duplicateMatchInd === -1) {
      continue
    }
    // create a parent entry in the duplicateGroups map if one does not already exist
    // (the parent is just the record that occurs first)
    const parentId = data[duplicateMatchInd].dupId
    if (!duplicateGroups[parentId]) {
      duplicateGroups[parentId] = [] as SiteUploadRecord[]
    }

    // add this row to the list of duplicate sites
    duplicateGroups[parentId].push(site)
  }

  const rowsToAdd: IRowToAdd[] = []
  const rowIdsToRemove: string[] = []

  for (const value of Object.values(duplicateGroups)) {
    // we want to relocate all the duplicate rows
    rowIdsToRemove.push(...value.map((child) => child.rowId as string))

    // if any of the duplicates match an existing db record, all duplicate rows
    // should have that option
    const recordWithExistingId = value.find((child) => !!child.existingRecordId)
    const existingRecordId = recordWithExistingId
      ? (recordWithExistingId.existingRecordId as number)
      : undefined

    // sort the duplicate list by the amount of data provided
    const sorted = value.sort(sortSiteUploadRecords)

    // insert the duplicate rows at the top of the table,
    // with the suggested row to keep (the row with the most data)
    // as the first entry for each duplicate group
    for (let i = 0; i < sorted.length; i++) {
      const isDuplicate = i > 0
      rowsToAdd.push({
        index: 0,
        row: getIRowFromSiteUploadRecord(
          sorted[i],
          existingRecordId,
          isDuplicate
        ),
      })
    }
  }

  return {
    dupCount,
    rowIdsToRemove,
    rowsToAdd,
  }
}

function sortSiteUploadRecords(
  site1: SiteUploadRecord,
  site2: SiteUploadRecord
): number {
  // put potentially sold sites last
  if (site1.siteAction === SiteAction.Sold) {
    return 1
  }
  if (site2.siteAction === SiteAction.Sold) {
    return -1
  }
  const site1Data = Object.values(site1).filter((value) => !!value).length
  const site2Data = Object.values(site2).filter((value) => !!value).length
  return site1Data > site2Data ? -1 : 1
}

/**
 * Creates an IRow object from a SiteUploadRecord.
 * @param site - The site object to be converted
 * @param isduplicate - True if this site duplicates another site, False if this record is duplicated by another site
 */
function getIRowFromSiteUploadRecord(
  site: SiteUploadRecord,
  existingRecordId: number | undefined,
  isduplicate = false
): IRow {
  const row: IRow = Object.keys(site).reduce((acc, currKey) => {
    if (currKey === 'siteAction') {
      const message = isduplicate ? dupChildMsg : dupParentMsg
      const siteActionOptions = site.maybeSold
        ? existingRecordSiteActionOptions
        : existingRecordId
        ? uploadMatchedRecordSiteActionOptions
        : uploadUnmatchedRecordSiteActionOptions
      return {
        ...acc,
        [currKey]: {
          value: SiteAction.Review,
          info: [{ message, level: 'warning' }],
          selectOptions: siteActionOptions,
        },
      }
    }
    return {
      ...acc,
      [currKey]: { value: site[currKey] },
    }
  }, {} as IRow)
  row.existingRecordId.value = existingRecordId
  return row
}

import {
  Dispatch,
  FunctionComponent,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Button, ButtonGroup, Dropdown, Form } from 'react-bootstrap'
import { Save2, PencilSquare, XSquareFill } from 'react-bootstrap-icons'
import { isNull, omitBy, startCase } from 'lodash'
import { Variant } from '../../common/types'
import useDebounce from '../../state/modules/common'
import {
  IUserReport,
  IUserReportPatch,
  useUserReportsEdit,
  useUserReportVerifyName,
} from '../../state/modules/userReports'
import { TextButton } from '../common/TextButton'

interface Props {
  onUpdate?: Dispatch<IUserReport>
  report?: IUserReport | null
  patch?: IUserReportPatch
  onResetPatch?: (() => void) | undefined
  canChange?: boolean
}

export const useUserReportSaveUI = (props: Props) => {
  const { report, patch, onResetPatch, onUpdate, canChange } = props

  const { create, isCreating, update, isUpdating } = useUserReportsEdit()
  const inProgress = isCreating || isUpdating

  const [newName, setNewName] = useState<null | undefined | string>(
    report ? null : 'New report'
  )

  const handleCreate = useMemo(
    () =>
      newName && create
        ? async () => {
            const res = await create({
              ...omitBy(patch || {}, isNull),
              name: newName,
            })
            setNewName(null)
            onUpdate?.(res)
          }
        : undefined,
    [newName, create, patch, onUpdate]
  )

  const updateId = canChange ? report?._id : undefined

  const handleUpdate = useMemo(
    () =>
      newName !== undefined && updateId && update
        ? async () => {
            const res = await update({
              _id: updateId,
              ...patch,
              ...(newName !== null ? { name: newName } : {}),
            })
            setNewName(null)
            onUpdate?.(res)
          }
        : undefined,
    [newName, update, updateId, patch, onUpdate]
  )

  const handleSave = handleUpdate || handleCreate

  const blurNameInput = useCallback(() => {
    if (newName === report?.name && !inProgress) setNewName(null)
  }, [newName, report, inProgress])

  const nameInput = (newName !== null || !report) && (
    <NameInput
      name={newName || ''}
      setName={setNewName}
      reportId={updateId}
      onEnter={handleSave}
    />
  )

  const nameDisplay = !nameInput && (
    <span>
      <b className="UserReportCard-name">{report?.name}</b>{' '}
      <TextButton
        className="UserReportCard-clickBust"
        onClick={() => setNewName(report?.name || 'New report')}
        style={{ visibility: handleSave ? 'visible' : 'hidden' }}
        title="rename"
      >
        &nbsp;
        <PencilSquare />
      </TextButton>
    </span>
  )
  const nameControl = nameDisplay || nameInput

  const cancelRenameButton = nameInput && (
    <TextButton
      className="UserReportCard-clickBust"
      onClick={() => setNewName(null)}
    >
      <XSquareFill />
    </TextButton>
  )

  const hasChanges = patch || newName !== null
  let upsertButton: ReactNode
  if (report && hasChanges)
    upsertButton = (
      <Dropdown as={ButtonGroup}>
        <Button onClick={handleUpdate} disabled={!handleUpdate}>
          <Save2 />
          &nbsp;Save
        </Button>

        <Dropdown.Toggle split />

        <Dropdown.Menu>
          <Dropdown.Item onClick={handleCreate}>Create new</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  else if (hasChanges)
    upsertButton = (
      <Button
        onClick={handleCreate}
        disabled={!handleCreate}
        title={
          report && !canChange && handleCreate
            ? `Can't update this report, but new one can be created`
            : undefined
        }
      >
        &nbsp;Create new
      </Button>
    )

  const changedPropsHelper = useMemo(
    () =>
      patch && `Changes are: ${Object.keys(patch).map(startCase).join(', ')}`,
    [patch]
  )

  const ui = (
    <>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        {nameControl}&nbsp;
        {cancelRenameButton}
      </div>
      <div className="UserReportCard-saveBlock-controls">
        {!!patch && (
          <i style={{ color: 'darkorange' }} title={changedPropsHelper}>
            Modified&nbsp;
          </i>
        )}

        {upsertButton && (
          <div
            className="UserReportCard-clickBust"
            onBlur={(event) => {
              if (!event.currentTarget.contains(event.relatedTarget))
                blurNameInput?.()
            }}
          >
            {upsertButton}
          </div>
        )}

        {!!patch && onResetPatch && (
          <TextButton
            className="UserReportCard-clickBust"
            onClick={onResetPatch}
            variant={Variant.DANGER}
            title={changedPropsHelper}
          >
            Reset changes
          </TextButton>
        )}
      </div>
    </>
  )

  return {
    ui,
    isActive: newName !== null,
    newName,
    blurNameInput,
  }
}

const validReportNameRegEx = /^[A-Za-z][A-Za-z\s\d ]{5,36}$/

const NameInput: FunctionComponent<{
  name?: string
  setName?: Dispatch<string | undefined>
  reportId?: IUserReport['_id']
  onBlur?: () => void
  onEnter?: () => void
}> = ({ name, setName, reportId, onBlur, onEnter }) => {
  const [nameRaw, setNameRaw] = useState(name || '')

  const newName = useDebounce(nameRaw.replace(/\s{2,}/g, ' ').trim(), 200)
  const isValid = validReportNameRegEx.test(nameRaw)
  const [isVerified, setVerified] = useState<boolean>()

  const verifyName = useUserReportVerifyName()

  useEffect(() => {
    if (!newName || !verifyName) return undefined
    let cancelled = false

    setVerified(undefined)
    verifyName(newName, reportId)
      .then((result) => {
        if (!cancelled) setVerified(!!result)
      })
      .catch(() => {
        throw new Error('verifyName failed')
      })

    return () => {
      cancelled = true
    }
  }, [newName, reportId, verifyName])

  useEffect(
    () => setName?.(isValid && isVerified ? newName : undefined),
    [setName, isValid, isVerified, newName]
  )

  let desc: ReactNode = null
  if (!isValid && nameRaw.length < 6)
    desc = (
      <span className="text-danger">Should be at least 6 characters long</span>
    )
  if (!desc && !isValid && nameRaw.length >= 6)
    desc = (
      <span className="text-danger">
        Should contain only letters and numbers, and start with a letter
      </span>
    )
  if (!desc && isValid && isVerified === undefined)
    desc = <span style={{ opacity: 0.6 }}>Checking the name...</span>
  if (!desc && isValid && isVerified === false)
    desc = <span className="text-danger">This name already taken</span>

  return (
    <div style={{ position: 'relative' }}>
      <Form.Control
        type="text"
        value={nameRaw}
        onChange={(e) => setNameRaw(e.target.value)}
        isValid={isValid && isVerified}
        onBlur={onBlur}
        autoFocus={true}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') onEnter?.()
        }}
        style={{ paddingRight: 36 }}
      />
      {desc && (
        <small
          style={{
            position: 'absolute',
            top: '100%',
            width: '100%',
            left: 0,
            background: 'white',
            zIndex: 1,
          }}
        >
          {desc}
        </small>
      )}
    </div>
  )
}

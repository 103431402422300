import React, { FunctionComponent } from 'react'
import DromoUploader from 'dromo-uploader-react'
import {
  IReviewStepData,
  IRowHookInput,
  IRowHookOutputInternal,
  IBeforeFinishOutput,
} from 'dromo-uploader-js'
import {
  IPublicConnectionMethods,
  IRowToAdd,
} from 'dromo-uploader-js/dist/interfaces'
import { fieldSchema, qbRecordIdSchema } from './FieldSchema'
import {
  addColumnsIfNeeded,
  addZipCodeLeadingZeros,
  updateCellMessages,
  parseDateValues,
  convertZeroToNull,
  getUploadSummary,
  chunkSiteListUpload,
  SiteUploadRecord,
  DuplicateCheckResult,
  browserOnlyFields,
  validateNumbers,
} from './UploaderHelpers'
import { checkForDuplicates } from './Deduplication'
import { handleBulkProcessing } from './HandleBulkProcessing'
import { AuthRole, IAuthUser, useIDToken } from '../../state/modules/auth'
import { getAPI } from '../../utils/api'
import { useImpersonatingUserOrUser } from '../../state/modules/auth'
import { upsertCounts } from '../../containers/SiteUpload'
import {
  SiteAction,
  SiteListType,
  requestLimit,
  geocodingRequestsPerMinuteLimit,
} from '@black-bear-energy/black-bear-energy-common'
import { isProductionHost } from '../../utils/enviroment'

interface SiteListUploaderProps {
  clientId?: number
  siteListType: SiteListType
  isOpen: boolean
  onUploadStart: () => void
  onUploadError: () => void
  onClose: (counts?: upsertCounts) => void
}

export const SiteListUploader: FunctionComponent<SiteListUploaderProps> = (
  props
) => {
  const {
    clientId,
    siteListType,
    isOpen,
    onUploadStart,
    onUploadError,
    onClose,
  } = props
  const user = useImpersonatingUserOrUser() as IAuthUser
  const accessToken = useIDToken()
  let soldRecordRows: IRowToAdd[]

  async function beforeUpload(
    sites: SiteUploadRecord[],
    instance: IPublicConnectionMethods,
    clientId?: number,
    accessToken?: string
  ): Promise<IBeforeFinishOutput> {
    if (!clientId) {
      return { cancel: true, message: 'No client selected' }
    }

    const api = getAPI(accessToken)
    let duplicatesResult: DuplicateCheckResult | null = null
    try {
      duplicatesResult = await checkForDuplicates(sites, clientId, api)
    } catch (err) {
      return {
        cancel: true,
        message:
          'An error was thrown while deduplicating records. Please contact support.',
      }
    }

    if (duplicatesResult === null) {
      // no duplicates - show upload summary
      const siteActions = sites.map((site) => site.siteAction) as SiteAction[]
      const didConfirm = confirm(getUploadSummary(siteActions))
      if (didConfirm) {
        return
      }
      return {
        cancel: true,
        message: '',
      }
    }

    // move duplicate rows to the top of the table
    instance.removeRows(duplicatesResult.rowIdsToRemove)
    instance.addRows(duplicatesResult.rowsToAdd)

    return {
      cancel: true,
      message: `${duplicatesResult.dupCount} record${
        duplicatesResult.dupCount > 1 ? 's are duplicates' : ' is a duplicate'
      } of other records. Please review and either update the records to be unique or choose Ignore as the site action.`,
    }
  }

  async function uploadSiteListData(
    data: { [key: string]: unknown }[],
    clientId?: number,
    accessToken?: string
  ): Promise<void> {
    if (!clientId) {
      throw new Error('No client selected')
    }
    if (data.length === 0) {
      onClose()
    }

    onUploadStart()

    const api = getAPI(accessToken)
    const dataFields = Object.keys(data[0])
    const sites: SiteUploadRecord[] = data
      .filter((dataRow) => dataRow.siteAction !== SiteAction.Ignore) // don't upload ignored records
      .map((dataRow) => {
        const siteRecord = dataFields.reduce(
          (record, field) => ({
            ...record,
            [field]:
              typeof dataRow[field] === 'boolean' || dataRow[field]
                ? dataRow[field]
                : null, // change any empty strings to null
          }),
          {} as SiteUploadRecord
        )
        siteRecord.clientQBId = clientId
        // if `existingRecordId` is an empty string, we need `id` to be undefined so the db
        // will generate an auto-incremented id for new records
        siteRecord.id = siteRecord.existingRecordId ?? undefined
        for (const fieldKey of browserOnlyFields) {
          delete siteRecord[fieldKey]
        }
        return siteRecord
      })

    let counts: upsertCounts

    try {
      counts = await chunkSiteListUpload(sites, siteListType, api)
    } catch (error) {
      onUploadError()
      return
    }

    onClose(counts)
  }

  async function onBulkRowHook(
    records: IRowHookInput[],
    mode: 'init' | 'update'
  ): Promise<IRowHookOutputInternal[]> {
    if (!clientId) {
      throw new Error('No client selected')
    }

    const api = getAPI(accessToken)
    const isUpdate = mode === 'update'

    const { newRows, updatedRows } = await handleBulkProcessing({
      records,
      isUpdate,
      siteListType,
      clientId,
      api,
    })
    soldRecordRows = newRows
    return updatedRows
  }

  // super admins can map to the 'QBRecordId' value
  const fields =
    user.role === AuthRole.SUPER_ADMIN
      ? fieldSchema.concat(qbRecordIdSchema)
      : fieldSchema

  return (
    <DromoUploader
      licenseKey={process.env.REACT_APP_DROMO_FRONTEND_API_KEY!}
      fields={fields}
      settings={{
        importIdentifier: 'Site List Uploader',
        title: 'Site List Uploader',
        manualInputDisabled: true,
        developmentMode: !isProductionHost(),
        maxFileSize: requestLimit,
        maxRecords: geocodingRequestsPerMinuteLimit,
        reviewStep: {
          processingText:
            'Geocoding addresses and comparing new sites with existing sites. This may take a while.',
          helpText:
            'Only rows without errors will be uploaded. Hover over a colored cell to see more information. If some errors cannot be resolved, click the "Export" button to download "Only rows with errors" and review this list with the client. Sites that are potentially sold are pulled from the database and added to the bottom of the sheet as new rows.',
        },
      }}
      user={{
        id: user.id ?? '',
        name: user.name,
        email: user.email,
        companyId:
          user.relatedEntity?.toString() ??
          user.relatedChannelPartner?.toString() ??
          '',
      }}
      columnHooks={[
        {
          fieldName: 'zipCode',
          callback: (values) => addZipCodeLeadingZeros(values),
        },
        {
          fieldName: 'acquireDate',
          callback: (values) => parseDateValues(values, true),
        },
        {
          fieldName: 'buildingBuildYear',
          callback: (values) => parseDateValues(values, false),
        },
        {
          fieldName: 'roofInstallYear',
          callback: (values) => parseDateValues(values, false),
        },
        {
          fieldName: 'buildingArea',
          callback: (values) => convertZeroToNull(values),
        },
        {
          fieldName: 'latitude',
          callback: (values) => validateNumbers(values),
        },
        {
          fieldName: 'longitude',
          callback: (values) => validateNumbers(values),
        },
      ]}
      stepHooks={[
        {
          type: 'REVIEW_STEP',
          callback: (instance, previewData) =>
            addColumnsIfNeeded(instance, previewData as IReviewStepData),
        },
        {
          type: 'REVIEW_STEP_POST_HOOKS',
          callback: (instance) => {
            instance.addRows(soldRecordRows)
          },
        },
      ]}
      rowHooks={[(record) => updateCellMessages(record)]}
      bulkRowHooks={[(records, mode) => onBulkRowHook(records, mode)]}
      onResults={(response: { [key: string]: unknown }[]) => {
        uploadSiteListData(response, clientId, accessToken).catch(() => {
          throw new Error('uploadSiteListData failed')
        })
      }}
      beforeFinish={(records, metadata, instance) =>
        beforeUpload(records, instance, clientId, accessToken)
      }
      open={isOpen}
      onCancel={onClose}
    ></DromoUploader>
  )
}

export const isDevelopment = () => process.env.NODE_ENV === 'development'
export const isProduction = () => process.env.NODE_ENV === 'production'
export const isTest = () => process.env.NODE_ENV === 'test'

// NOTE - Not in love with this pattern, but it's a necessary evil based on the way prod is built.
export const isProductionHost = () => {
  const productionHost = 'portal.blackbear.energy'
  const { host, hostname } = window.location
  return (
    host.toLowerCase().trim() === productionHost ||
    hostname.toLowerCase().trim() === productionHost
  )
}

import { useMemo } from 'react'
import { IField } from '../../common/types'
import createSlice from './helpers/createSlice'
import { useEditLevel } from './auth'

// Slice Name
export const sliceName = 'siteRfpsFields'

// Slice URL
export const url = '/site-rfps/fields'

// Abstract Slice Instance
export const siteRfpsFieldsSlice = createSlice<IField[]>(sliceName, url)

const reducer = siteRfpsFieldsSlice.reducer

// data {payload, status}
export const useSiteRfpFields = siteRfpsFieldsSlice.useSlice

// Initial state
export const initialState = siteRfpsFieldsSlice.initialState

const useLoadSlicePayload = siteRfpsFieldsSlice.useLoadSlicePayload
export const useLoadSiteRfpFields = () => {
  const [editLevel] = useEditLevel()
  const params = useMemo(() => ({ editLevel }), [editLevel])
  return useLoadSlicePayload({ params })
}

export default reducer

import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { Variant } from '../../common/types'
import { useImpersonatingUser } from '../../state/modules/impersonations'
import { IColumnSettings } from '../BootstrapTable/ColumnSettings'
import { IFieldAssignment } from '../FieldAssignment/IFieldAssignment'
import { useFieldAssignments } from '../FieldAssignment/useFieldAssignments'

interface Props {
  displayColumns?: readonly string[]
  accessibleColumns?: readonly IColumnSettings[]
}

export const UnaccessibleColumnsAlert: FunctionComponent<Props> = (props) => {
  const { displayColumns = [], accessibleColumns = [] } = props

  const impersonatingUser = useImpersonatingUser()

  const { records: fieldAssignments, load } = useFieldAssignments()
  const [scheduleLoad, setScheduleLoad] = useState(false)

  useEffect(() => {
    if (!accessibleColumns.length) return undefined
    if (!displayColumns.length) return undefined
    if (!impersonatingUser) return undefined
    setScheduleLoad(true)
  }, [impersonatingUser, accessibleColumns, displayColumns])

  useEffect(() => {
    if (!scheduleLoad || !load) return undefined
    setScheduleLoad(false)
    load?.().catch(() => {
      throw new Error('UnaccessibleColumnsAlert load failed')
    })
  }, [scheduleLoad, load])

  const unaccessibleColumns = useMemo(
    () =>
      impersonatingUser &&
      displayColumns.length &&
      accessibleColumns.length &&
      fieldAssignments
        ? displayColumns
            .filter((propName) =>
              accessibleColumns.every((c) => c.propName !== propName)
            )
            .map((propName) =>
              fieldAssignments.find(
                ({ recordId }) => `${recordId}` === propName
              )
            )
            .filter((c): c is IFieldAssignment => !!c)
        : undefined,
    [impersonatingUser, displayColumns, accessibleColumns, fieldAssignments]
  )

  const unknownColumns = useMemo(
    () =>
      impersonatingUser && displayColumns.length && fieldAssignments
        ? displayColumns.filter((propName) =>
            fieldAssignments.every(({ recordId }) => `${recordId}` !== propName)
          )
        : undefined,
    [impersonatingUser, displayColumns, fieldAssignments]
  )

  const unaccessibleAlert = !!unaccessibleColumns &&
    unaccessibleColumns.length > 0 && (
      <Alert variant={Variant.INFO}>
        Some columns are inaccessible by the impersonated user:
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
          {unaccessibleColumns.map(({ recordId, fieldDisplayName }) => (
            <span style={{ background: 'white', padding: 2 }} key={recordId}>
              <small style={{ opacity: 0.6 }}>{recordId}:&nbsp;</small>
              {fieldDisplayName}
            </span>
          ))}
        </div>
        <small style={{ opacity: 0.6 }}>
          <i>
            Access to these columns can be granted in Fields Assignments
            configuration.
          </i>
        </small>
      </Alert>
    )

  const unknownAlert = !!unknownColumns && unknownColumns.length > 0 && (
    <Alert variant={Variant.DANGER}>
      Some columns are unknown:
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
        {unknownColumns.map((propName, index) => (
          <span style={{ background: 'white', padding: 2 }} key={index}>
            {propName}
          </span>
        ))}
      </div>
      <small style={{ opacity: 0.6 }}>
        <i>
          Most probably these columns were removed from Fields Assignments
          configuration.
        </i>
      </small>
    </Alert>
  )

  return (
    <>
      {unaccessibleAlert}
      {unknownAlert}
    </>
  )
}

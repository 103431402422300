import { useMemo } from 'react'
import { Alert } from 'react-bootstrap'
import { BootstrapTableProps, ITableValidationError } from './tableTypes'
import { IColumnSettings } from './ColumnSettings'

type TableValidationErrorsMap = ReadonlyMap<
  string | number,
  readonly ITableValidationError[]
>

const emptyErrors: readonly ITableValidationError[] = Object.freeze([])

type GetCellValidationErrors = (
  rowKey: string | number,
  propName: string | number
) => ITableValidationError[]

const MAX_ERROR_ALERTS = 3

export const useValidationErrors = (props: BootstrapTableProps) => {
  const {
    validationErrors = emptyErrors,
    hiddenColumns,
    toggleColumn,
    data,
    rowKeyPropName,
  } = props

  const validationErrorsMapByRow = useMemo<TableValidationErrorsMap>(() => {
    if (!validationErrors.length) {
      return new Map()
    }
    const map = new Map<string | number, ITableValidationError[]>()
    for (const error of validationErrors) {
      const rowKey = error[1]
      const existing = map.get(rowKey)
      if (existing) {
        existing.push(error)
      } else {
        map.set(rowKey, [error])
      }
    }
    return map
  }, [validationErrors])

  const rowsWithErrors = useMemo<readonly (string | number)[]>(
    () => Array.from(validationErrorsMapByRow.keys()),
    [validationErrorsMapByRow]
  )

  const columnsWithErrors = useMemo(() => {
    const propNames: IColumnSettings['propName'][] = []
    for (const error of validationErrors) {
      if (!propNames.includes(error[2])) {
        propNames.push(error[2])
      }
    }
    return propNames
  }, [validationErrors])

  const hiddenColumnsWithErrors = useMemo(
    () =>
      hiddenColumns?.filter((propName) => columnsWithErrors.includes(propName)),
    [hiddenColumns, columnsWithErrors]
  )

  const showColumnsWithErrors = useMemo(
    () =>
      hiddenColumnsWithErrors?.length && toggleColumn
        ? () => toggleColumn(hiddenColumnsWithErrors, true)
        : undefined,
    [hiddenColumnsWithErrors, toggleColumn]
  )

  const getCellValidationErrors = useMemo<GetCellValidationErrors | undefined>(
    () =>
      validationErrorsMapByRow.size
        ? (rowKey, propName) =>
            validationErrorsMapByRow
              .get(rowKey)
              ?.filter((error) => error[2] === propName) || []
        : undefined,
    [validationErrorsMapByRow]
  )

  const rowKeyToIndex = useMemo<
    ReadonlyMap<string | number, number | undefined>
  >(() => {
    const map = new Map<string | number, number | undefined>()
    for (let i = 0; i < data.length; i += 1) {
      map.set(
        rowKeyPropName ? (data[i][rowKeyPropName] as string | number) : i,
        i
      )
    }
    return map
  }, [data, rowKeyPropName])

  const validationErrorsSomeAlerts = validationErrors
    .slice(0, MAX_ERROR_ALERTS)
    // eslint-disable-next-line
    .map(([message, rowKey, _], index) => (
      <Alert
        key={`validation-errors-${index}`}
        variant={'danger'}
        style={{ marginBottom: 0 }}
      >
        {message}{' '}
        {rowKeyToIndex.has(rowKey) && (
          <small>
            <i>(At row {(rowKeyToIndex.get(rowKey) || 0) + 1})</i>
          </small>
        )}
      </Alert>
    ))

  const validationErrorsMoreAlert = validationErrors.length >
    MAX_ERROR_ALERTS && (
    <Alert
      key="validation-errors-more"
      variant={'danger'}
      style={{ marginBottom: 0 }}
    >
      And {validationErrors.length - MAX_ERROR_ALERTS} errors more.
    </Alert>
  )

  const validationErrorsAlerts = (
    <>
      {validationErrorsSomeAlerts}
      {validationErrorsMoreAlert}
    </>
  )

  return {
    validationErrorsMapByRow,
    rowsWithErrors,
    columnsWithErrors,
    hiddenColumnsWithErrors,
    showColumnsWithErrors,
    getCellValidationErrors,
    validationErrorsAlerts,
  }
}

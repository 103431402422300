import { Form } from 'react-bootstrap'

interface Props {
  onChange: () => void
  value: boolean
  disabled: boolean
}

// TODO - Consolidate this with the other ReceivesRfpEmailsCheckbox.
export default function hasProspectorPermissionCheckbox({
  onChange,
  value,
  disabled,
}: Props) {
  return (
    <fieldset>
      <Form.Group className="fluid">
        <Form.Check type="checkbox" name="user-readOnly">
          <Form.Check.Label>
            <Form.Check.Input
              type="checkbox"
              className="mt-1"
              name="user-with-prospector-permissions"
              checked={value}
              onChange={onChange}
              disabled={disabled}
            />
            With Prospector Permission
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>
    </fieldset>
  )
}

import { useApiWithIDToken, useDispatch } from './common'
import { IAPIErrorFormat } from '../../common/types'
import createSlice from './helpers/createSlice'

// Slice Name
export const sliceName = 'portalUsers'

// Slice URL
export const url = '/portal-users'

// Abstract Slice Instance
export const portalUsersSlice = createSlice(sliceName, url, undefined)

// Initial state
export const initialState = portalUsersSlice.initialState

// Slice reducers
const reducer = portalUsersSlice.reducer

// Slice Selectors Hooks
export const usePortalUsers = portalUsersSlice.useSlice

// Slice Actions Hooks
export function useImportUsers() {
  const dispatch = useDispatch()
  const apiWithIDToken = useApiWithIDToken()
  return () => {
    dispatch(portalUsersSlice.requestLoading())
    apiWithIDToken
      .put(`${url}/import`)
      .then(() => {
        dispatch(portalUsersSlice.requestLoaded())
      })
      .catch((error) => {
        dispatch(portalUsersSlice.requestFailed(error as IAPIErrorFormat))
      })
  }
}

export const useDismissPortalUserssHTTPErrors =
  portalUsersSlice.useDismissHTTPErrors

// Slice reducer
export default reducer

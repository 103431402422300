import { Reducer } from 'react'
import { ErrorWithInfo, IAction, IAPIErrorFormat } from '../../common/types'
import { isDefined } from '../../utils/typeGuards'
import { useApiWithIDToken, useDispatch } from './common'
import createSlice from './helpers/createSlice'

// Types & Interfaces
export enum CustomerRfpsProps {
  RFP_NAME = 'rfpId',
  ACCOUNT_MANAGER = 'accountManager',
  PRODUCT_TYPE = 'energyTechnology',
  STATUS = 'rfpStatus',
  RELEASE_DATE = 'rfpReleaseDate',
  DUE_DATE = 'rfpDueDate',
  LOCATION = 'location',
  OPPORTUNITY = 'opportunity',
  OPT_IN = 'optIn',
  OPT_OUT = 'optOut',
  RFP_TABLE = 'rfpTable',
}

export enum ChannelPartnerCustomerRfpsProps {
  STATUS = 'rfpStatusCpPortal',
}

export enum CustomerRfpsHeaders {
  RFP_NAME = 'RFP',
  ACCOUNT_MANAGER = 'Account Manager',
  PRODUCT_TYPE = 'Product Type',
  STATUS = 'Status',
  RELEASE_DATE = 'Release Date',
  DUE_DATE = 'Due Date',
  LOCATION = 'Location',
  OPPORTUNITY = 'Opportunity',
  OPT_IN = 'Opt In',
  OPT_OUT = 'Opt Out',
}

export interface ICustomerRfp {
  recordId: number
  rfpId?: string
  relatedClientDriver?: string
  combinedTextEnergyTechnology?: string[]
  rfpStatus?: string
  rfpReleaseDate?: Date
  rfpDueDate?: Date
  location?: string
  opportunity?: string
  optIn?: boolean
  optOut?: boolean
  optInId?: number
}

interface ISubmitRfpOptInBody {
  relatedRfp: number
  optIn?: boolean
  optOut?: boolean
  reasonForOptingOut?: string
}

type optInStatusSetPayload = ISubmitRfpOptInBody & {
  id: number
  optIn: boolean
  optOut: boolean
}

// Slice Name
export const sliceName = 'customerRfps'

// Slice URL
export const url = '/customer-rfps'

// Abstract Slice Instance
export const customerRfpsSlice = createSlice<ICustomerRfp[]>(sliceName, url)

// Initial state
export const initialState = customerRfpsSlice.initialState

// Actions
const OPT_IN_STATUS_SET = `${sliceName}/OPT_IN_STATUS_RESET`

// Slice reducers
const reducer: Reducer<typeof initialState, IAction> = (
  state = initialState,
  { type, payload }
): typeof initialState => {
  switch (type) {
    case OPT_IN_STATUS_SET:
      const optInStatusSetPayload = payload as optInStatusSetPayload
      return {
        ...state,
        payload: state.payload.map((rfp) =>
          rfp.recordId === optInStatusSetPayload.id
            ? { ...rfp, ...optInStatusSetPayload }
            : rfp
        ),
      }
    default:
      return customerRfpsSlice.reducer(state, {
        type,
        payload: payload as ICustomerRfp[],
      })
  }
}

// Action creators
const optInStatusSet = (body: ISubmitRfpOptInBody) => {
  const optIn = isDefined(body.optIn) ? body.optIn : !body.optOut
  const optOut = !optIn
  return {
    type: OPT_IN_STATUS_SET,
    payload: { ...body, id: body.relatedRfp, optIn, optOut },
  }
}

const optInStatusReset = (customerRfp: ICustomerRfp) => {
  return {
    type: OPT_IN_STATUS_SET,
    payload: customerRfp,
  }
}

// Slice Selectors Hooks
export const useCustomerRfps = customerRfpsSlice.useSlice

// Slice Actions Hooks
export function useLoadCustomerRfps() {
  const dispatch = useDispatch()
  const apiWithIDToken = useApiWithIDToken()
  const loadCustomerRfps = (query = {}) =>
    apiWithIDToken.get('/customer-rfps', { params: query })
  return async () => {
    dispatch(customerRfpsSlice.requestLoading())
    try {
      // Load customerRfps without OptIn info
      const customerRfpsResponse = await loadCustomerRfps()
      dispatch(
        customerRfpsSlice.requestLoaded(
          customerRfpsResponse.data as ICustomerRfp[]
        )
      )
    } catch (error) {
      dispatch(customerRfpsSlice.requestFailed(error as IAPIErrorFormat))
    }
  }
}

export function useGenerateRfpLink() {
  const dispatch = useDispatch()
  const apiWithIDToken = useApiWithIDToken()
  return async (
    optInId?: number,
    readOnly?: boolean,
    allowArchive?: boolean
  ): Promise<string | undefined> => {
    dispatch(customerRfpsSlice.requestLoading())
    try {
      if (!optInId) {
        throw new ErrorWithInfo({
          message:
            'This RFP is not ready for online bid submission. Please contact Black Bear Energy support with questions.',
        })
      } else {
        // Load customerRfps without OptIn info
        const response = await apiWithIDToken.post(
          `/rfp-opt-ins/${optInId}/tokens`,
          {
            readOnly,
            allowArchive,
          }
        )
        dispatch(customerRfpsSlice.requestLoaded())
        const data = response.data as { token: string }
        return data.token
      }
    } catch (error) {
      dispatch(customerRfpsSlice.requestFailed(error as IAPIErrorFormat))
    }
  }
}

export function useGenerateRfpLinkFromCustomerRfp() {
  const dispatch = useDispatch()
  const apiWithIDToken = useApiWithIDToken()
  return async (customerRfpId?: number): Promise<string | undefined> => {
    dispatch(customerRfpsSlice.requestLoading())
    try {
      if (!customerRfpId) {
        throw new ErrorWithInfo({
          message:
            'This RFP is not ready for online bid submission. Please contact Black Bear Energy support with questions.',
        })
      } else {
        // Load customerRfps without OptIn info
        const response = await apiWithIDToken.post(
          `/customer-rfps/${customerRfpId}/tokens`
        )
        dispatch(customerRfpsSlice.requestLoaded())
        const data = response.data as { token: string }
        return data.token
      }
    } catch (error) {
      dispatch(customerRfpsSlice.requestFailed(error as IAPIErrorFormat))
    }
  }
}

export function useSubmitRfpOptIn() {
  const dispatch = useDispatch()
  const apiWithIDToken = useApiWithIDToken()
  return async (customerRfp: ICustomerRfp, body: ISubmitRfpOptInBody) => {
    try {
      dispatch(optInStatusSet(body))
      dispatch(customerRfpsSlice.requestLoading())
      await apiWithIDToken.put('/rfp-opt-ins', body)
      dispatch(customerRfpsSlice.requestLoaded())
    } catch (error) {
      dispatch(optInStatusReset(customerRfp))
      dispatch(customerRfpsSlice.requestFailed(error as IAPIErrorFormat))
    }
  }
}

export const useDismissCustomerRfpsHTTPErrors =
  customerRfpsSlice.useDismissHTTPErrors

// Slice reducer
export default reducer

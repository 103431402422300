import { useEffect, useMemo, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import Select from 'react-select'
import { BsGear } from 'react-icons/bs'
import { IFieldAssignmentTables } from './useFieldAssignments'

interface TableSelectProps {
  tables: IFieldAssignmentTables
  value: [tableId?: string | null, fieldId?: number | null]
  onChange?: (
    newValue:
      | [tableId: null, fieldId: null]
      | [tableId: string, fieldId: number]
  ) => void
  isDirtyField?: (field: 'table' | 'field') => boolean
}
export const TableSelect = (props: TableSelectProps) => {
  const { tables, value, onChange, isDirtyField } = props

  const [lastTableId, setLastTableId] = useState<string>()

  const [tableId, fieldId] = value || []
  useEffect(() => {
    if (tableId) setLastTableId(tableId)
  }, [tableId])

  const displayTableId = tableId || lastTableId

  const tableIds = useMemo(() => Object.keys(tables), [tables])
  const tableNotFound = !!displayTableId && !tableIds.includes(displayTableId)

  const tableOptions = useMemo(
    () =>
      tableIds.map((itemTableId) => (
        <option key={itemTableId} value={itemTableId}>
          {tables[itemTableId]?.title}
        </option>
      )),
    [tables, tableIds]
  )

  const displayTable = displayTableId && tables[displayTableId]

  const { fields, quickbaseLink } = displayTable || {}

  const quickbaseTableLink = quickbaseLink && `${quickbaseLink}/?a=td`

  const tableSelect = (
    <InputGroup>
      <Form.Control
        as="select"
        value={displayTableId || ''}
        onChange={(e) => {
          const value = e.target.value
          setLastTableId(value)
          onChange?.([null, null])
        }}
        className={isDirtyField?.('table') ? 'dirty' : ''}
        style={tableNotFound ? { border: '1px solid red' } : undefined}
      >
        <option value=""></option>
        {tableOptions}
        {tableNotFound && (
          <option disabled value={displayTableId || ''}>
            UNKNOWN TABLE: {displayTableId}
          </option>
        )}
      </Form.Control>
      {quickbaseTableLink && (
        <InputGroup.Append className="pl-1 align-items-center">
          <a href={quickbaseTableLink} target="blank">
            <BsGear title="Quickbase Settings" />
          </a>
        </InputGroup.Append>
      )}
    </InputGroup>
  )

  const quickbaseFieldLink =
    quickbaseLink && fieldId && `${quickbaseLink}/?a=mf&fid=${fieldId}&chain=1`

  const [options, selectedOption, fieldNotFound] = useMemo(() => {
    if (!fields || !displayTableId) return [[], undefined, false] as const

    const fieldsOptions = fields.map(({ id, label }) => ({ value: id, label }))

    const selectedOption = fieldsOptions.find(({ value }) => value === fieldId)
    const selectedOptionUnknown =
      fields && fieldId && !selectedOption
        ? { value: fieldId, label: `UNKNOWN FIELD: ${fieldId}` }
        : undefined

    return [
      fieldsOptions,
      selectedOption || selectedOptionUnknown,
      !!selectedOptionUnknown,
    ] as const
  }, [fields, displayTableId, fieldId])

  const fieldSelect = (
    <InputGroup>
      <Select
        value={selectedOption}
        onChange={(newValueOption) => {
          if (displayTableId && newValueOption)
            onChange?.([displayTableId, newValueOption.value])
        }}
        options={options}
        styles={{
          container: (styles) => ({ ...styles, flex: 1 }),
          control: (styles) => ({
            ...styles,
            flex: 1,
            backgroundColor: fieldNotFound ? '#dc3545' : undefined,
          }),
          option: (styles) => ({ ...styles, textAlign: 'left' }),
          singleValue: (styles) => ({ ...styles, textAlign: 'left' }),
        }}
        className={isDirtyField?.('field') ? 'dirty' : ''}
      />
      {quickbaseFieldLink && (
        <InputGroup.Append className="pl-1 align-items-center">
          <a href={quickbaseFieldLink} target="blank">
            <BsGear title="Quickbase Settings" />
          </a>
        </InputGroup.Append>
      )}
    </InputGroup>
  )

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        minWidth: '20em',
      }}
    >
      {tableSelect}
      {fieldSelect}
    </div>
  )
}

import axios from 'axios'
import React, { useContext, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { isDefined } from '../../utils/typeGuards'
import { store } from '../store'
import { useIDToken, useAuth } from './auth'
import { baseURL, getAPI } from '../../utils/api'

const useRootContext = () => useContext(store)

// Delegated state ref from root context
export const useState = () => useRootContext().state

// Delegated dispatch ref from root context
export const useDispatch = () => useRootContext().dispatch

// This Hook will invoke the given cb only once component did mount
export const useCallOnce = (cb: () => void) => useEffect(cb, []) //eslint-disable-line react-hooks/exhaustive-deps

// This Hook will listen for id token and the given deps and invoke the cb only if id token is granted
export const useCallOnIDTokenGranted = (
  cb: () => Promise<void>,
  deps: unknown[] = []
) => {
  const idToken = useIDToken()
  useEffect(() => {
    if (idToken) {
      cb().catch(() => {
        throw new Error('DTokenGranted callback failed')
      })
    }
  }, [idToken, ...deps]) //eslint-disable-line react-hooks/exhaustive-deps
}

export const useApiWithIDTokenGranted = () => {
  const idToken = useIDToken()
  const apiWithIDToken = useApiWithIDToken()
  return idToken ? apiWithIDToken : undefined
}

// This Hook will listen for id token and invoke the cb once if id token is granted
export const useCallOnceOnIDTokenGranted = (cb: () => Promise<void>) => {
  useCallOnIDTokenGranted(cb)
}

// This Hook will listen for auth status and invoke the cb once if user is authenticated
export const useCallOnceOnAuthenticationVerified = (
  cb: () => Promise<void>
) => {
  const { isAuthenticated } = useAuth()
  useEffect(() => {
    if (isAuthenticated) {
      cb().catch(() => {
        throw new Error('OnAuthenticationVerified callback failed')
      })
    }
  }, [isAuthenticated]) //eslint-disable-line react-hooks/exhaustive-deps
}

// This Hook will listen for the given list and invoke the cb if list is not empty
export const useCallOnHasElements = (cb: () => void, list: unknown[]) => {
  useEffect(() => {
    if (isDefined(list) && list.length !== 0) {
      cb()
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [list])
}

// This Hook will return an axios instance with baseUrl applied
export const useApi = () => {
  return axios.create({ baseURL })
}

// This Hook will return an axios instance with Authorization header & baseUrl applied
export const useApiWithIDToken = () => {
  const accessToken = useIDToken()

  return useMemo(() => {
    return getAPI(accessToken)
  }, [accessToken])
}

export const getApiInstance = (idToken: string) => {
  return axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${idToken}` },
  })
}

export const useQuery = () => {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

interface UseSyncQueryOpts {
  exact?: boolean
}

export const useSyncQuery = <T extends string>(
  params?: Record<T, string | number | undefined | null>,
  opts: UseSyncQueryOpts = {}
) => {
  const { exact } = opts

  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const history = useHistory()

  useEffect(() => {
    if (!params) {
      return undefined
    }
    let modified = false
    for (const name in params) {
      const value = params[name]
      const newValueStr =
        value === undefined || value === null ? null : value.toString()
      const currentValue = query.get(name)
      if (currentValue !== newValueStr) {
        if (value === undefined || value === null) {
          query.delete(name)
        } else {
          query.set(name, value.toString())
        }
        modified = true
      }
    }

    if (exact) {
      const paramNames = Object.keys(params)
      for (const name of Array.from(query.keys())) {
        if (!paramNames.includes(name)) {
          query.delete(name)
          modified = true
        }
      }
    }

    if (modified) {
      history.push({
        search: `?${query.toString()}`,
      })
    }
  }, [query, history, params, exact])
}

export default function useDebounce<T>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  return debouncedValue
}

import { useCallback, useEffect, useMemo, useState } from 'react'
import { without, isUndefined, omitBy, pick } from 'lodash'
import { RequestStatus } from '../../common/types'
import { IColumnSettings } from '../../components/BootstrapTable/ColumnSettings'
import {
  getColumnsSequence,
  IBootstrapTableUserSettings,
  userSettingsKeys,
} from '../../components/BootstrapTable/UserSettings'
import { useApiWithIDToken } from './common'
import createSlice from './helpers/createSlice'

export interface IUserReport extends IBootstrapTableUserSettings {
  _id: string
  name: string
  columns?: readonly string[]
  columnsStyles?: {
    readonly [propName: string]: Partial<CSSStyleDeclaration>
  }
  filters?: { [x: string]: string }[]
  access?: ReportAccess
  ownerId?: string
}

export type IReportData = Partial<IUserReport>

export const isUserReport = (data: IReportData): data is IUserReport =>
  !!data._id && !!data.name

export const userReportToSettings = (
  report: IReportData | IUserReportPatch,
  columns: readonly IColumnSettings[]
): IBootstrapTableUserSettings =>
  omitBy(
    {
      columnsOrder: report.columns || columns.map(({ propName }) => propName),
      hiddenColumns: columns
        .map(({ propName }) => propName)
        .filter(
          (propName) => !!report.columns && !report.columns?.includes(propName)
        ),
      filter: Object.assign({}, ...(report?.filters || [])) as {
        [x: string]: string | undefined
      },
      ...pick(
        report,
        without(userSettingsKeys, 'columnsOrder', 'hiddenColumns')
      ),
    },
    isUndefined
  )

export const userReportToQuery = ({
  filters,
  sortBy,
}: IReportData | IUserReportPatch) =>
  omitBy(
    {
      filter: Object.assign({}, ...(filters || [])) as {
        [x: string]: string | undefined
      },
      sortBy,
    },
    isUndefined
  )

export const userSettingsToReport = (
  {
    filter,
    columnsOrder = [],
    hiddenColumns = [],
    ...rest
  }: IBootstrapTableUserSettings,
  columns: readonly IColumnSettings[]
): IReportData => ({
  ...pick(rest, without(userSettingsKeys, 'columnsOrder', 'hiddenColumns')),
  columns: getColumnsSequence(
    columns.map(({ propName }) => propName),
    {
      columnsOrder,
      hiddenColumns,
    }
  ),
  filters: filter
    ? ([omitBy(filter, isUndefined)] as IReportData['filters'])
    : undefined,
})

//Slice name
export const sliceName = 'userReports'

//Slice URL
export const url = '/site-rfp-reports'

//Abstract Slice Instance
export const userReportsSlice = createSlice<readonly IUserReport[]>(
  sliceName,
  url,
  []
)

//InitialState
export const initialState = userReportsSlice.initialState

export const useDismissPortalUserssHTTPErrors =
  userReportsSlice.useDismissHTTPErrors

const {
  reducer,
  useLoadSlicePayload,
  // useDismissHTTPErrors, // TODO use
  useSlice,
  useMutexAction,
} = userReportsSlice

// Slice reducer
export default reducer

export const useUserReports = () => {
  const { payload: reports, status, httpErrors: errors } = useSlice()

  const loadSlice = useLoadSlicePayload()

  const isLoading = status === RequestStatus.LOADING
  const [scheduleLoad, setScheduleLoad] = useState(false)

  const load = useCallback(() => setScheduleLoad(true), [])

  useEffect(() => {
    if (scheduleLoad && loadSlice) {
      setScheduleLoad(false)
      loadSlice().catch(() => {
        throw new Error('loadSlice failed')
      })
    }
  }, [loadSlice, scheduleLoad])

  return {
    errors,
    reports,
    isLoading,
    load,
  }
}

export type IUserReportPatch = {
  [P in keyof Omit<IUserReport, '_id'>]?: Omit<IUserReport, '_id'>[P] | null
}
export type IUserReportPatchWithId = IUserReportPatch & { _id: string }

export const useUserReportsEdit = () => {
  const { isLoading, load } = useUserReports()

  const apiWithIDToken = useApiWithIDToken()

  const [deleteById] = useMutexAction(
    useCallback(
      async (_id: IUserReport['_id']) => {
        await apiWithIDToken.delete(`${url}/${_id}`)
      },
      [apiWithIDToken]
    )
  )

  const [update, isUpdating] = useMutexAction(
    useCallback(
      async ({ _id, ...patch }: IUserReportPatchWithId) => {
        const response = await apiWithIDToken.put(`${url}/${_id}`, patch)
        const data = response.data as { report: IUserReport }
        return data.report
      },
      [apiWithIDToken]
    )
  )

  const [rename] = useMutexAction(
    useMemo(
      () =>
        update
          ? (_id: IUserReport['_id'], newName: string) =>
              update({ _id, name: newName })
          : undefined,
      [update]
    )
  )

  const [create, isCreating] = useMutexAction(
    useCallback(
      async (data: Omit<IUserReport, '_id'>) => {
        const response = await apiWithIDToken.post(url, data)
        const responseData = response.data as { report: IUserReport }
        return responseData.report
      },
      [apiWithIDToken]
    )
  )

  return {
    isLoading,
    load,
    deleteById,
    update,
    isUpdating,
    rename,
    create,
    isCreating,
  }
}

export const useUserReportVerifyName = () => {
  const apiWithIDToken = useApiWithIDToken()

  return useCallback(
    async (name: string, reportId?: IUserReport['_id']) => {
      const response = await apiWithIDToken.post(`${url}/verify`, {
        name,
        reportId,
      })
      const data = response.data as { unique: boolean } | undefined
      return data?.unique
    },
    [apiWithIDToken]
  )
}

export const useUserReportSharing = () => {
  const apiWithIDToken = useApiWithIDToken()

  const [shareGlobal] = useMutexAction(
    useCallback(
      (_id: IUserReport['_id'], unshare: boolean = false) =>
        apiWithIDToken.post(`${url}/${_id}/share-global`, { unshare }),
      [apiWithIDToken]
    )
  )

  const [shareWithOrg] = useMutexAction(
    useCallback(
      (_id: IUserReport['_id'], unshare: boolean = false) =>
        apiWithIDToken.post(`${url}/${_id}/share-with-org`, { unshare }),
      [apiWithIDToken]
    )
  )

  return {
    shareGlobal,
    shareWithOrg,
  }
}

export const REPORT_ACCESS_GLOBAL = 'Global' as const

export type ReportAccess =
  | `EntityUser:${number}`
  | `ChannelPartner:${number}`
  | `Global`

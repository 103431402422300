import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { stringToBoolean } from 'deep-cuts'
import { useCallOnceOnIDTokenGranted } from '../state/modules/common'
import { RequestStatus, IStyledComponentPropTypes } from '../common/types'
import { displayAlerts } from './helpers/displayAlerts'
import {
  ChannelPartnerCustomerRfpsProps,
  CustomerRfpsHeaders,
  CustomerRfpsProps,
  ICustomerRfp,
  useCustomerRfps,
  useGenerateRfpLink,
  useGenerateRfpLinkFromCustomerRfp,
  useLoadCustomerRfps,
  useSubmitRfpOptIn,
} from '../state/modules/customerRfps'
import {
  IAuthUser,
  isChannelPartnerUser,
  useImpersonatingUserOrUser,
} from '../state/modules/auth'
import { isDefined } from '../utils/typeGuards'
import {
  ConfirmationStatus,
  IConfirmation,
  useConfirmation,
  useSendConfirmation,
} from '../state/modules/confirmation'
import { BootstrapTable } from './BootstrapTable/BootstrapTable'
import { TableName } from './BootstrapTable/tableTypes'
import { IColumnSettings, rendererTdArg } from './BootstrapTable/ColumnSettings'
import {
  dateRenderer,
  createButtonRenderer,
  createRfpRenderer,
} from './renderers/bootstrapTableRenderer'
import { ISortFilter, SortDirection } from './helpers/query'
import {
  useUserSettings,
  useUserSettingsQuery,
} from './BootstrapTable/UserSettings'
import { isUndefined, omitBy } from 'lodash'

interface IOptOutConfirmation extends IConfirmation {
  metadata?: ICustomerRfp
}

const recordFieldsToText = (row: Record<string, unknown>) => {
  const rfpTable = row.rfpTable as { text: string } | undefined
  return {
    rfpTable: rfpTable?.text || '',
    ...row,
  } as Record<string, string>
}

function applySort(
  data: Record<string, unknown>[],
  sort: readonly ISortFilter[]
) {
  return data.sort((row1, row2) => {
    const rowText1 = recordFieldsToText(row1)
    const rowText2 = recordFieldsToText(row2)
    for (const { key, direction } of sort) {
      const result = (
        moment(rowText1[key]).isValid()
          ? moment(rowText1[key]).isAfter(rowText2[key])
          : rowText1[key] > rowText2[key]
      )
        ? 1
        : -1
      if (rowText1[key] !== rowText2[key])
        return direction === SortDirection.ASC ? result : result * -1
    }
    return 0
  })
}

function applyFilters(
  data: Record<string, unknown>[],
  filters: Record<string, string>
) {
  return data.filter((row) => {
    const rowText = recordFieldsToText(row)
    return Object.keys(filters).every((key) => {
      const f = filters[key] || ''
      const mod = f[0]
      const str = f.slice(1)
      const isIncludes = `${rowText[key]}`
        .toLocaleUpperCase()
        .includes(str.toLocaleUpperCase())
      if (mod === ':') return isIncludes
      if (mod === '-') return !isIncludes
      return true
    })
  })
}

function applySortAndFilters(
  data: Record<string, unknown>[],
  sort: readonly ISortFilter[],
  filters: Record<string, string | undefined>
) {
  return applySort(
    applyFilters(data, omitBy(filters, isUndefined) as Record<string, string>),
    sort
  )
}

function CustomerRfpsTable({ className, style }: IStyledComponentPropTypes) {
  const user = useImpersonatingUserOrUser()

  const confirmation: IOptOutConfirmation | undefined = useConfirmation()
  const sendConfirmation = useSendConfirmation()
  const [confirmationReceiptID, setConfirmationReceiptID] = useState<string>()

  const { payload: customerRfps, status, httpErrors } = useCustomerRfps()
  const [rfpOptInsLoading, setRfpOptInsLoading] = useState(true)
  const loadCustomerRfps = useLoadCustomerRfps()
  const submitRfpOptIn = useSubmitRfpOptIn()
  const generateRfpLinkFromOptIn = useGenerateRfpLink()
  const generateRfpLinkFromCustomerRfp = useGenerateRfpLinkFromCustomerRfp()

  useCallOnceOnIDTokenGranted(loadCustomerRfps)

  // Hook to listen for confirmations and proceed with the corresponding actions when approved!
  useEffect(() => {
    if (
      confirmation &&
      confirmation.id === confirmationReceiptID &&
      confirmation.status === ConfirmationStatus.APPROVED
    ) {
      const customerRfp = confirmation.metadata!
      submitRfpOptIn(customerRfp, {
        relatedRfp: customerRfp.recordId,
        optOut: true,
        reasonForOptingOut: confirmation.confirmationText,
      }).catch(() => {
        throw new Error('submitRfpOptIn failed')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmation])

  // Hook to listen for rfpOptIns and enable the opt(In/Out) buttons when optIn info is fetched!
  useEffect(() => {
    if (customerRfps.find((rfp) => isDefined(rfp.optIn))) {
      setRfpOptInsLoading(false)
    }
  }, [customerRfps])

  useEffect(() => {
    if (user && isChannelPartnerUser(user)) {
      setRfpOptInsLoading(false) // Set false as the rfps are initially embedded with the customerRFPs in case of CPs
    }
  }, [user])

  function handleOptIn(customerRfp: ICustomerRfp) {
    if (customerRfp.optIn !== true) {
      submitRfpOptIn(customerRfp, {
        relatedRfp: customerRfp.recordId,
        optIn: true,
      }).catch(() => {
        throw new Error('submitRfpOptIn failed')
      })
    }
  }

  function handleOptOut(customerRfp: ICustomerRfp) {
    if (customerRfp.optOut !== true) {
      const receiptID = sendConfirmation({
        title: 'Confirmation',
        message: 'Please provide a reason for opting out',
        metadata: customerRfp,
        approveLabel: 'Opt Out',
        withConfirmationText: true,
      })
      setConfirmationReceiptID(receiptID)
    }
  }

  function handleRfpLinkClick(customerRfp: ICustomerRfp) {
    const generateRfpLinkFunc = isChannelPartnerUser(user as IAuthUser)
      ? generateRfpLinkFromOptIn
      : generateRfpLinkFromCustomerRfp

    const id = isChannelPartnerUser(user as IAuthUser)
      ? customerRfp.optInId
      : customerRfp.recordId

    generateRfpLinkFunc(id)
      .then((link) => {
        if (link) {
          window.open(link)
        }
      })
      .catch(() => {
        throw new Error('generateRfpLink failed')
      })
  }

  function isRfpLinkDisabled(customerRfp: ICustomerRfp) {
    return (
      !!user &&
      isChannelPartnerUser(user) &&
      !customerRfp?.optIn &&
      !customerRfp?.optOut
    )
  }

  const columns: IColumnSettings[] = [
    {
      propName: CustomerRfpsProps.RFP_TABLE,
      label: CustomerRfpsHeaders.RFP_NAME,
      renderer: (
        td: rendererTdArg,
        row: string,
        value: unknown,
        { rowData }
      ) => {
        return createRfpRenderer({
          value: value as { text: string; token: string; active: boolean },
          onClick: () => handleRfpLinkClick(rowData as ICustomerRfp),
          isDisabled: () => isRfpLinkDisabled(rowData as ICustomerRfp),
        })()
      },
      columnStyle: { maxWidth: '300px' },
      filterType: 'Contains',
    },
    {
      propName: CustomerRfpsProps.ACCOUNT_MANAGER,
      label: CustomerRfpsHeaders.ACCOUNT_MANAGER,
      columnStyle: { maxWidth: '150px' },
      filterType: 'Contains',
    },
    {
      propName: CustomerRfpsProps.PRODUCT_TYPE,
      label: CustomerRfpsHeaders.PRODUCT_TYPE,
      columnStyle: { maxWidth: '100px' },
      filterType: 'Contains',
    },

    {
      propName:
        isDefined(user) && isChannelPartnerUser(user)
          ? ChannelPartnerCustomerRfpsProps.STATUS
          : CustomerRfpsProps.STATUS,
      label: CustomerRfpsHeaders.STATUS,
      columnStyle: { maxWidth: '170px' },
      filterType: 'Contains',
    },
    {
      propName: CustomerRfpsProps.RELEASE_DATE,
      label: CustomerRfpsHeaders.RELEASE_DATE,
      renderer: dateRenderer,
      filterType: 'Contains',
    },
    {
      propName: CustomerRfpsProps.DUE_DATE,
      label: CustomerRfpsHeaders.DUE_DATE,
      renderer: dateRenderer,
      filterType: 'Contains',
    },
    {
      propName: CustomerRfpsProps.LOCATION,
      label: CustomerRfpsHeaders.LOCATION,
      filterType: 'Contains',
    },

    {
      propName: CustomerRfpsProps.OPPORTUNITY,
      label: CustomerRfpsHeaders.OPPORTUNITY,
      columnStyle: { maxWidth: '170px' },
      filterType: 'Contains',
    },
  ]

  if (isDefined(user) && isChannelPartnerUser(user)) {
    const optInOutButtonsDisabled = () =>
      status === RequestStatus.LOADING || rfpOptInsLoading || user?.readOnly

    columns.push(
      {
        propName: CustomerRfpsProps.OPT_IN,
        label: CustomerRfpsHeaders.OPT_IN,
        renderer: (td: rendererTdArg, row: string, val: unknown, { rowData }) =>
          createButtonRenderer({
            width: 60,
            text: CustomerRfpsHeaders.OPT_IN,
            active: val as boolean,
            disabled: optInOutButtonsDisabled(),
            onClick: () => handleOptIn(rowData as ICustomerRfp),
          })(),
      },
      {
        propName: CustomerRfpsProps.OPT_OUT,
        label: CustomerRfpsHeaders.OPT_OUT,
        renderer: (td: rendererTdArg, row: string, val: unknown, { rowData }) =>
          createButtonRenderer({
            width: 60,
            text: CustomerRfpsHeaders.OPT_OUT,
            active: val as boolean,
            disabled: optInOutButtonsDisabled(),
            onClick: () => handleOptOut(rowData as ICustomerRfp),
          })(),
      }
    )
  }

  const customerRfpsWithOptOutUpdates = customerRfps.map((rfp) =>
    confirmation &&
    confirmation.id === confirmationReceiptID &&
    confirmation.status === ConfirmationStatus.REQUESTED &&
    confirmation.metadata?.recordId === rfp.recordId
      ? { ...rfp, optIn: false, optOut: true }
      : rfp
  )

  const tableCustomerRfpData = customerRfpsWithOptOutUpdates.map((value) => {
    return {
      ...value,
      rfpTable: { text: value.rfpId },
    }
  })

  const [userSettingsValue, setUserSettings] = useUserSettingsQuery()
  const userSettings = useUserSettings({
    columns,
    autoSanitize: true,
    value: userSettingsValue,
    onChange: setUserSettings,
  })

  const { tableProps: { sort, filter } = {} } = userSettings

  const data = useMemo(
    () => applySortAndFilters(tableCustomerRfpData, sort || [], filter || {}),
    [tableCustomerRfpData, sort, filter]
  )

  const enableFilters = stringToBoolean(
    process.env.REACT_APP_FEATURE_FLAG_ENABLE_CUSTOMER_RFP_FILTERS
  )

  return (
    <div className={`${className} customer-rfps-table`} style={style}>
      {displayAlerts(
        httpErrors && httpErrors.errors && httpErrors.errors.length !== 0
          ? httpErrors.errors // Display httpErrors
          : [httpErrors?.message] // Fall back to error.message if no errors[] provided
      )}
      <BootstrapTable
        tableName={TableName.RFPTable}
        columns={columns}
        data={data}
        loading={status === RequestStatus.LOADING}
        {...(enableFilters ? userSettings.tableProps : undefined)}
        sortMode="multi"
        enableExportToCSV={stringToBoolean(
          process.env.REACT_APP_FEATURE_FLAG_CUSTOMER_RFP_CSV_EXPORT
        )}
      />
    </div>
  )
}

export default styled(CustomerRfpsTable)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;

  .htDimmed {
    background-color: white;
  }
`

import React, { Fragment, ReactNode, useState } from 'react'
import { isEmpty } from 'lodash'
import { Alert, Button } from 'react-bootstrap'
import { AssignmentFieldsError } from './AssignmentFieldsError'

export const AssignmentFieldsErrorNote = (props: {
  assignmentFieldsError?: AssignmentFieldsError
}) => {
  const { assignmentFieldsError } = props

  const [openInfo, setOpenInfo] = useState(false)

  if (!assignmentFieldsError) return null

  const {
    problems: {
      duplicateRolePropNameAssignments = {},
      duplicateTablePropNameAssignments = {},
    } = {},
  } = assignmentFieldsError

  const displays: ReactNode[] = []
  if (!isEmpty(duplicateRolePropNameAssignments))
    displays.push(
      <>
        <h3>Assignments with similar labels</h3>
        <p>
          Some of the Assignments records, related to the table, have labels
          that look same.
        </p>
        <p>
          Changing the labels should be enough to fix this. You can add
          clarifying suffix, so the labels would be distinct.
        </p>
        {Object.keys(duplicateRolePropNameAssignments).map((propName) => (
          <div key={propName}>
            <b>{propName}:</b>
            <ul>
              {duplicateRolePropNameAssignments?.[propName].map(
                ({ recordId, label, link }) => (
                  <li key={recordId}>
                    <a href={link} target="_blank" rel="noreferrer">
                      {label}
                    </a>{' '}
                    <small>(Record#: {recordId})</small>
                  </li>
                )
              )}
            </ul>
          </div>
        ))}
      </>
    )

  if (!isEmpty(duplicateTablePropNameAssignments))
    displays.push(
      <>
        <h3>Multiple Assignments are related to same field</h3>
        <p>
          Some of the Assignments records are pointing to the same table field.
        </p>
        <p>
          Only one assignment per field is allowed - you may need to delete
          extra assignments here.
        </p>
        {Object.keys(duplicateTablePropNameAssignments).map((propName) => (
          <div key={propName}>
            <b>{propName}:</b>
            <ul>
              {duplicateTablePropNameAssignments?.[propName].map(
                ({ recordId, label, link }) => (
                  <li key={recordId}>
                    <a href={link} target="_blank" rel="noreferrer">
                      {label}
                    </a>{' '}
                    <small>(Record#: {recordId})</small>
                  </li>
                )
              )}
            </ul>
          </div>
        ))}
      </>
    )

  return (
    <Alert variant="danger">
      <Alert.Heading>Temporarily unavailable</Alert.Heading>
      <p>Table is temporary unavailable, due to configuration changes.</p>
      {!openInfo && (
        <>
          <p>
            Most probably administrators are updating the configuration, and
            soon the table will work nominal.
          </p>
          <p>
            If the problem persists for a long time, please contact
            administrators.
          </p>

          <Button onClick={() => setOpenInfo(true)}>Show details</Button>
        </>
      )}

      {openInfo && (
        <>
          <p>
            Some of the assignments, have duplicating prop names. That happens,
            when labels look same, like "Main Current mA" and "main-current
            (mA)".
          </p>

          <p>
            To fix it, check out labels in the list below: click labels to go to
            Quickbase, and make sure these assignments have labels that are
            distinct in their reading.
          </p>
          <p>
            After you fix the labels in Quickbase, wait a minute, reload the
            page and make sure this warning disappeared.
          </p>

          {displays.map((display, index) => (
            <Fragment key={index}>
              {display}
              {index < displays.length - 1 && <hr />}
            </Fragment>
          ))}
        </>
      )}
    </Alert>
  )
}

import createSlice from './helpers/createSlice'

// Types & Interfaces
export enum BidHistoryProps {
  RFP_NAME = 'rfpOptInRfpName',
  SUBMISSION_DATE = 'dateCreated',
}

export enum BidHistoryHeaders {
  RFP_NAME = 'RFP Name',
  SUBMISSION_DATE = 'Submission Date',
}

export interface IBidHistoryRecord {
  rfpOptInRfpName: string
  relatedRfpOptIn: number
  dateCreated: Date
}

// Slice Name
export const sliceName = 'bidHistory'

// Slice URL
export const url = '/bid-history'

// Abstract Slice Instance
export const bidHistorySlice = createSlice<IBidHistoryRecord[]>(sliceName, url)

// Initial state
export const initialState = bidHistorySlice.initialState

// Slice reducers
const reducer = bidHistorySlice.reducer

// Slice Selectors Hooks
export const useBidHistory = bidHistorySlice.useSlice

// Slice Actions Hooks
export const useLoadBidHistory = bidHistorySlice.useLoadSlicePayload

export const useDismissBidHistoryHTTPErrors =
  bidHistorySlice.useDismissHTTPErrors

// Slice reducer
export default reducer

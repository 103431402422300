import createSlice from './helpers/createSlice'

// Types & Interfaces
export interface IEntity {
  recordId: number
  entityName: string
  emailDomain: string
  enableDashboardPage: boolean
  enableProjectPage: boolean
}

// Slice Name
export const sliceName = 'entities'

// Abstract Slice Instance
const entitiesSlice = createSlice<IEntity[]>(sliceName)

// Initial state
export const initialState = entitiesSlice.initialState

// Slice Selectors Hooks
export const useEntities = entitiesSlice.useSlice

// Slice Actions Hooks
export const useLoadEntities = entitiesSlice.useLoadSlicePayload

export const useDismissEntitiesHTTPErrors = entitiesSlice.useDismissHTTPErrors

// Slice reducer
export default entitiesSlice.reducer

import React, { FunctionComponent } from 'react'
import { Container, Row } from 'react-bootstrap'
import styled, { CSSProperties } from 'styled-components'
import { Spinner } from './BlackBearEnergy'

interface Props {
  className?: string
  style?: CSSProperties
  hide?: boolean
}

export const ComponentLoader: FunctionComponent<Props> = ({
  className,
  style,
  hide,
}) => (
  <Row
    className={`d-flex justify-content-center ${className}`}
    style={{ visibility: hide ? 'hidden' : undefined, ...style }}
  >
    <Spinner animation="grow"></Spinner>
    <Spinner animation="grow" className="ml-1"></Spinner>
    <Spinner animation="grow" className="ml-1"></Spinner>
  </Row>
)

const InlineWrapper = styled('span')`
  display: inline-block;
  padding: 0 0.25rem;
`

export const InlineLoader: FunctionComponent<Props> = ({
  className,
  style,
  hide,
}) => (
  <InlineWrapper
    className={className}
    style={{ visibility: hide ? 'hidden' : undefined, ...style }}
  >
    <Spinner animation="grow"></Spinner>
    <Spinner animation="grow" className="ml-1"></Spinner>
    <Spinner animation="grow" className="ml-1"></Spinner>
  </InlineWrapper>
)

export function ScreenLoader() {
  return (
    <Container className="my-2">
      <ComponentLoader />
    </Container>
  )
}

import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import { useCallOnceOnIDTokenGranted } from '../state/modules/common'
import { IStyledComponentPropTypes, RequestStatus } from '../common/types'
import { displayAlerts } from './helpers/displayAlerts'
import {
  EntityPortalAttachmentsHeaders,
  EntityPortalAttachmentsProps,
  IEntityPortalAttachment,
  useEntityPortalAttachments,
  useLoadEntityPortalAttachments,
} from '../state/modules/entityPortalAttachments'
import dateRenderer from '../components/renderers/dateRenderer'
import uploadAttachmentRenderer from './renderers/uploadAttachmentRenderer'
import downloadAttachmentRenderer from './renderers/downloadAttachmentRenderer'
import { IStoreContextValue, store } from '../state/store'
import { stringEllipsis } from '../utils/string'
import { IAuthUser, useImpersonatingUserOrUser } from '../state/modules/auth'
import { BootstrapTable } from './BootstrapTable/BootstrapTable'
import { TableName } from './BootstrapTable/tableTypes'
import { IColumnSettings, rendererTdArg } from './BootstrapTable/ColumnSettings'

interface IUseSettingsParams {
  context: IStoreContextValue
  user: IAuthUser | undefined
}
const useColumns = ({ context, user }: IUseSettingsParams) =>
  useMemo(() => {
    const baseColumns: IColumnSettings[] = [
      {
        propName: EntityPortalAttachmentsProps.NAME,
        label: EntityPortalAttachmentsHeaders.NAME,
        renderer: (td: rendererTdArg, row: string, val: unknown) =>
          stringEllipsis(String(val) || '', 30),
        columnStyle: { textAlign: 'left' },
      },
      {
        propName: EntityPortalAttachmentsProps.DATE_MODIFIED,
        label: EntityPortalAttachmentsHeaders.DATE_MODIFIED,

        renderer: dateRenderer,
        columnStyle: { width: '200px' },
      },
      {
        propName: EntityPortalAttachmentsProps.UPLOAD_DOWNLOAD,
        label: EntityPortalAttachmentsHeaders.UPLOAD_DOWNLOAD,
        renderer: (td: unknown, row: unknown, val: unknown, { rowData }) =>
          downloadAttachmentRenderer(
            context,
            rowData as IEntityPortalAttachment
          ),
        columnStyle: { width: '480px' },
      },
    ]

    if (user && !user.readOnly) {
      baseColumns.push({
        propName: EntityPortalAttachmentsProps.ATTACHMENT,
        label: EntityPortalAttachmentsHeaders.ATTACHMENT_NAME,
        renderer: (td: unknown, row: unknown, val: unknown, { rowData }) =>
          uploadAttachmentRenderer(context, rowData as IEntityPortalAttachment),
        columnStyle: { width: '400px' },
      })
    }

    return baseColumns
  }, [context, user])

function DocumentsTable({ className, style }: IStyledComponentPropTypes) {
  const context = useContext(store)
  const user = useImpersonatingUserOrUser()

  const {
    payload: entityPortalAttachments,
    status,
    httpErrors,
  } = useEntityPortalAttachments()

  const loadEntityPortalAttachments = useLoadEntityPortalAttachments()
  useCallOnceOnIDTokenGranted(loadEntityPortalAttachments)

  const columns = useColumns({
    context,
    user,
  })

  const entityPortalAttachmentObjs = entityPortalAttachments.map(
    (entityPortalAttachment) =>
      Object.keys(entityPortalAttachment).reduce(
        (entityPortalAttachmentObj, key) => ({
          ...entityPortalAttachmentObj,
          [key]: entityPortalAttachment[key as keyof IEntityPortalAttachment],
        }),
        {} as { [key: string]: unknown }
      )
  )

  return (
    <div className={`${className}`} style={style}>
      {displayAlerts(
        httpErrors && httpErrors.errors && httpErrors.errors.length !== 0
          ? httpErrors.errors // Display httpErrors
          : [httpErrors?.message] // Fall back to error.message if no errors[] provided
      )}
      <BootstrapTable
        tableName={TableName.DocumentTable}
        columns={columns}
        data={entityPortalAttachmentObjs}
        loading={status === RequestStatus.LOADING}
      />
    </div>
  )
}

export default styled(DocumentsTable)`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .htDimmed {
    background-color: white;
  }
  td:first-child {
    text-align: left;
  }
`

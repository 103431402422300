export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = '',
}

export interface ISortFilter {
  key: string
  direction: SortDirection
}

export function decodeSortQuery(query: string) {
  return decodeURIComponent(query)
    .split(',')
    .filter(Boolean)
    .map((sortItemStr) => sortItemStr.split(':'))
    .map(
      ([key, direction]): ISortFilter => ({
        key,
        direction: direction as ISortFilter['direction'],
      })
    )
}

export function encodeSortObject(sortItems: ISortFilter[]) {
  return encodeURIComponent(
    sortItems.map(({ key, direction }) => `${key}:${direction}`).join(',')
  )
}

export function getQueryParmas(query: URLSearchParams, exclude: string[] = []) {
  const result: { [key: string]: string | undefined } = {}
  for (const key of query.keys()) {
    if (exclude.includes(key)) {
      continue
    }
    const value = query.get(key)
    if (!!value) {
      result[key] = decodeURIComponent(value)
    }
  }
  return result
}

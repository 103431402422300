import { IUser, UserProps, IUserUpdate } from '../../../state/modules/users'
import { deepCloneArray } from '../../../utils/array'
import {
  phoneNumber,
  firstName,
  lastName,
  password,
  required,
} from '@black-bear-energy/black-bear-energy-common/dist/validations'
import { AuthRole } from '../../../state/modules/auth'
import {
  ITableCellChange,
  ITableValidationError,
} from '../../BootstrapTable/tableTypes'

export function mapToUsersUpdates(changes: ITableCellChange[]) {
  const usersUpdates: IUserUpdate[] = []
  changes.forEach(({ rowKey, propName, newValue }) => {
    const matchInd = usersUpdates.findIndex(
      (userUpdate) => userUpdate.id === rowKey
    )
    if (matchInd > -1) {
      const updated = {
        ...usersUpdates[matchInd],
        [propName]: newValue,
      }
      usersUpdates.splice(matchInd, 1, updated)
    } else {
      if (propName === UserProps.ROLE.toString() && newValue === 'User') {
        usersUpdates.push({ id: rowKey as string, [propName]: AuthRole.NONE })
      } else {
        usersUpdates.push({ id: rowKey as string, [propName]: newValue })
      }
    }
  })
  return usersUpdates
}

export function mergeAndFilterChanges(
  oldChanges: ITableCellChange[],
  snapshotChanges: readonly ITableCellChange[],
  lookupArray: IUser[]
) {
  const newChanges = [...oldChanges] // Load oldChanges changes
  // Loop to add new changes
  snapshotChanges.forEach(({ rowKey, propName, oldValue, newValue }) => {
    const match = newChanges.findIndex(
      (newChange) =>
        newChange.rowKey === rowKey && newChange.propName === propName
    )
    if (~match) {
      const prevPrev = newChanges[match].oldValue // Use prev value from first change logged
      newChanges[match] = { rowKey, propName, oldValue: prevPrev, newValue }
    } else {
      newChanges.push({ rowKey, propName, oldValue, newValue })
    }
  })
  // Remove obsolete changes
  return newChanges.filter(({ rowKey, propName, oldValue, newValue }) => {
    // Filter out falsy update if prevVal is a falsy value
    if (!newValue && !oldValue) {
      return false
    }
    const user = lookupArray.find((user) => user.id === rowKey)
    return !user || newValue !== user[propName as keyof IUser]
  })
}

export function validateChanges(newChanges: ITableCellChange[]) {
  const validationErrors: ITableValidationError[] = []
  newChanges.forEach(({ rowKey, propName, newValue }) => {
    const userPropName = propName as UserProps
    let error
    if (userPropName === UserProps.PHONE_NUMBER) {
      error = phoneNumber(String(newValue))
    } else if (userPropName === UserProps.FIRST_NAME) {
      error = firstName(String(newValue))
    } else if (userPropName === UserProps.LAST_NAME) {
      error = lastName(String(newValue))
    } else if (userPropName === UserProps.PASSWORD) {
      error = password(String(newValue))
    }
    if (error) {
      validationErrors.push([error, rowKey, propName])
    }

    // Allow Role property to be empty string
    if (userPropName !== UserProps.ROLE && required(propName)(newValue)) {
      validationErrors.push([`${propName} is required`, rowKey, propName])
    }
  })
  return validationErrors
}

export function applyChanges(users: IUser[], changes: ITableCellChange[]) {
  const data = deepCloneArray(users) // Make sure to clone the array as handsontable mutates that array
  changes.forEach(({ rowKey, propName, newValue }: ITableCellChange) => {
    // Apply changes
    const userInd = data.findIndex((user) => user.id === rowKey)
    if (userInd >= 0) {
      const changedUser = {
        ...data[userInd],
        [propName]: newValue,
      }
      data.splice(userInd, 1, changedUser)
    }
  })
  return data
}

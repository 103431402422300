import createSlice from './helpers/createSlice'
import { useApiWithIDToken, useDispatch } from './common'
import { IAuthUser } from './auth'
import { isDefined } from '../../utils/typeGuards'
import { IAPIErrorFormat } from '../../common/types'
import { IChannelPartner } from './channelPartners'
import { IEntity } from './entities'
import { clearBrowserStorages } from '../../utils/useLocalStorageJSON'

// Types & Interfaces
export interface ISimpleUser {
  id: string
  email: string
}

export interface ISimpleBBEUser {
  isBBEUserImpersonation: true
}

export interface ISimpleChannelPartner {
  relatedChannelPartner: number
  channelPartnerName?: string
}

export interface ISimpleEntity {
  relatedEntity: number
  entityName?: string
}

export type IImpersonatingDetails =
  | ISimpleUser
  | ISimpleBBEUser
  | ISimpleChannelPartner
  | ISimpleEntity

interface IImpersonation {
  impersonatingUser?: IAuthUser
  entity?: Partial<IEntity>
  channelPartner?: Partial<IChannelPartner>
}

export function isSimpleUser(
  impersonating: IImpersonatingDetails
): impersonating is ISimpleUser {
  return 'id' in impersonating && isDefined(impersonating.id)
}

export function isSimpleBBEUser(
  impersonating: IImpersonatingDetails
): impersonating is ISimpleBBEUser {
  return (
    'isBBEUserImpersonation' in impersonating &&
    isDefined(impersonating.isBBEUserImpersonation)
  )
}

export function isSimpleChannelPartner(
  impersonating: IImpersonatingDetails
): impersonating is ISimpleChannelPartner {
  return (
    'relatedChannelPartner' in impersonating &&
    isDefined(impersonating.relatedChannelPartner)
  )
}

export function isSimpleEntity(
  impersonating: IImpersonatingDetails
): impersonating is ISimpleEntity {
  return (
    'relatedEntity' in impersonating && isDefined(impersonating.relatedEntity)
  )
}

// Slice Name
export const sliceName = 'impersonations'

// Slice URL
const url = `/${sliceName}`

// Abstract Slice Instance
const impersonationsSlice = createSlice<IImpersonation>(sliceName, url, {})

// Initial state
export const initialState = impersonationsSlice.initialState

// Slice Selectors Hooks
export const useImpersonations = impersonationsSlice.useSlice

export const useImpersonatingUser = () =>
  useImpersonations().payload.impersonatingUser

// Slice Actions Hooks
export const useLoadImpersonations = impersonationsSlice.useLoadSlicePayload

export function useCreateImpersonation() {
  const dispatch = useDispatch()
  const apiWithIDToken = useApiWithIDToken()
  return async (body: IImpersonatingDetails) => {
    try {
      dispatch(impersonationsSlice.requestLoading())
      clearBrowserStorages()
      await apiWithIDToken.post(url, body)
      dispatch(impersonationsSlice.requestLoaded())
      window.location.reload() //Reload the window to apply the impersonation session
    } catch (error) {
      dispatch(impersonationsSlice.requestFailed(error as IAPIErrorFormat))
    }
  }
}

export function useDeleteImpersonation() {
  const dispatch = useDispatch()
  const apiWithIDToken = useApiWithIDToken()
  return () => {
    dispatch(impersonationsSlice.requestLoading())
    clearBrowserStorages()
    apiWithIDToken
      .delete(url)
      .then(() => {
        dispatch(impersonationsSlice.requestLoaded())
        window.location.reload() //Reload the window to reset session
      })
      .catch((error) => {
        dispatch(impersonationsSlice.requestFailed(error as IAPIErrorFormat))
      })
  }
}

export const useDismissImpersonationsHTTPErrors =
  impersonationsSlice.useDismissHTTPErrors

// Slice reducer
export default impersonationsSlice.reducer

import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { isDefined } from '../../utils/typeGuards'
import {
  formatPhoneNumber,
  validatePhoneNumber,
} from '../../utils/validatePhoneNumber'

const PhoneInput = ({
  onPhoneChange,
  phoneNumber,
}: {
  onPhoneChange: (value: string) => void
  phoneNumber: string
}) => {
  const [error, setError] = useState('')

  function handlePhoneChange(value: string) {
    const formattedNumber = formatPhoneNumber(value)

    if (isDefined(error)) {
      setError('')
    }

    onPhoneChange(formattedNumber)
  }

  function handleValidateNumber() {
    try {
      validatePhoneNumber(phoneNumber)
    } catch (err) {
      setError((err as Error).message)
    }
  }

  return (
    <fieldset>
      <Form.Group>
        <Form.Label htmlFor="invite-user-phone-input">Phone Number</Form.Label>
        <Form.Control
          type="text"
          placeholder="XXX-XXX-XXXX"
          id="invite-user-phone-input"
          name="phoneNumber"
          value={phoneNumber}
          onBlur={() => handleValidateNumber()}
          onChange={(e) => handlePhoneChange(e.target.value)}
          isInvalid={!!error.length}
        />
      </Form.Group>
      {isDefined(error) && (
        <p>
          <small>{error}</small>
        </p>
      )}
    </fieldset>
  )
}

export default PhoneInput

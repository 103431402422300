import { flatten, keyBy, uniq } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useApiWithIDTokenGranted } from '../../state/modules/common'
import { IFieldAssignment } from './IFieldAssignment'

const url = '/field-assignment'

export interface IFieldAssignmentChange
  extends Partial<Omit<IFieldAssignment, 'recordId'>> {
  recordId: number | string
  _isRemoved?: boolean
  _isNew?: boolean
}

export interface IFieldAssignmentTables {
  [tableId: string]: {
    title: string
    fields: {
      id: number
      label: string
      fieldType?: string
      mode?: string
      properties?: object
    }[]
    quickbaseLink?: string
  }
}

interface State {
  records: IFieldAssignment[]
  tables: IFieldAssignmentTables
}

const replaceOrAppend =
  (changes: readonly IFieldAssignmentChange[]) =>
  ({ recordId, ...patch }: IFieldAssignmentChange) => {
    const index = changes.findIndex((item) => item.recordId === recordId)
    if (index === -1) return [...changes, { recordId, ...patch }]
    else
      return [
        ...changes.slice(0, index),
        {
          ...changes[index],
          ...patch,
        },
        ...changes.slice(index + 1),
      ]
  }

export const useFieldAssignments = () => {
  const [status, setStatus] = useState<'loading' | 'update'>()
  const [state, setState] = useState<State>()
  const [changes, setChanges] = useState<IFieldAssignmentChange[]>([])

  const api = useApiWithIDTokenGranted()

  const { records, tables } = state || {}

  const load = useMemo(
    () =>
      api && !status
        ? async () => {
            try {
              setStatus('loading')
              setState((await api.get<State>(url)).data)
            } finally {
              setStatus(undefined)
            }
          }
        : undefined,
    [api, status]
  )

  const recordsMap = useMemo(
    () => (records ? keyBy(records, 'recordId') : undefined),
    [records]
  )

  const changesMap = useMemo(
    () => (changes ? keyBy(changes, 'recordId') : undefined),
    [changes]
  )

  const allRecordIds = useMemo(
    () =>
      uniq([
        ...Object.keys(recordsMap || {}),
        ...Object.keys(changesMap || {}),
      ]),
    [recordsMap, changesMap]
  )

  const items = useMemo(
    () =>
      allRecordIds.map((recordId) => ({
        recordId,
        ...recordsMap?.[recordId],
        ...changesMap?.[recordId],
      })),
    [allRecordIds, recordsMap, changesMap]
  )

  const isDirtyRecord = useCallback(
    (recordId: IFieldAssignmentChange['recordId']) => !!changesMap?.[recordId],
    [changesMap]
  )

  const dirtyFields = useMemo(
    () =>
      flatten(
        Object.values(changesMap || {}).map(({ recordId, ...itemChanges }) =>
          Object.keys(itemChanges).map((field) => `${recordId}/${field}`)
        )
      ),
    [changesMap]
  )

  const isDirtyField = useCallback(
    (recordId: IFieldAssignmentChange['recordId']) =>
      (field: keyof IFieldAssignment) =>
        !!dirtyFields?.includes(`${recordId}/${field}`),
    [dirtyFields]
  )

  const create = useCallback(
    (init: IFieldAssignmentChange) => {
      const newItem: IFieldAssignmentChange = {
        ...init,
        _isNew: true,
      }
      setChanges([...changes, newItem])
      return newItem
    },
    [changes]
  )

  const remove = useCallback(
    (recordId: IFieldAssignmentChange['recordId']) =>
      setChanges(
        replaceOrAppend(changes)({
          recordId,
          _isRemoved: true,
        }).filter(({ _isNew, _isRemoved }) => !(_isNew && _isRemoved))
      ),
    [changes]
  )

  const change = useCallback(
    (newChange: IFieldAssignmentChange) =>
      setChanges(
        replaceOrAppend(changes)({
          ...newChange,
          _isRemoved: false,
        })
      ),
    [changes]
  )

  const reset = useCallback(
    (recordId: IFieldAssignmentChange['recordId']) =>
      setChanges(changes.filter((change) => change.recordId !== recordId)),
    [changes]
  )

  const save = useMemo(
    () =>
      api && !status && changes.length > 0
        ? async () => {
            try {
              setStatus('update')
              await api.post(url, {
                changes,
              })
              setStatus('loading')
              setState((await api.get<State>(url)).data)
              setChanges([])
            } finally {
              setStatus(undefined)
            }
          }
        : undefined,
    [api, status, changes]
  )

  return {
    records,
    items,
    tables,
    dirtyFields,
    isDirtyRecord,
    isDirtyField,
    load,
    status,
    create,
    remove,
    change,
    reset,
    save,
  }
}

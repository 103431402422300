import { IAPIErrorFormat } from '../../common/types'
import { useApiWithIDToken, useDispatch } from './common'
import createSlice from './helpers/createSlice'

// Types & Interfaces
export interface ITermsOfServiceDetails {
  tosText: string
  dateModified: Date
}
export interface ITermsOfServiceInfo {
  confirmed: boolean
  details: ITermsOfServiceDetails
  confirmation?: ISendTOSConfirmationBody
}

export interface ISendTOSConfirmationBody {
  tosConfirmed?: boolean
  tosText?: string
}

// Slice Name
export const sliceName = 'termsOfService'

// Slice URL
export const url = '/terms-of-service'

// Abstract Slice Instance
export const termsOfServiceSlice = createSlice<ITermsOfServiceInfo>(
  sliceName,
  url,
  undefined
)

// Initial state
export const initialState = termsOfServiceSlice.initialState

// Slice reducers
const reducer = termsOfServiceSlice.reducer

// Slice Selectors Hooks
export const useTermsOfService = termsOfServiceSlice.useSlice

// Slice Actions Hooks
export const useLoadTermsOfService = termsOfServiceSlice.useLoadSlicePayload

export function useSendTOSConfirmation() {
  const dispatch = useDispatch()
  const apiWithIDToken = useApiWithIDToken()
  const { payload: tosInfo } = useTermsOfService()
  return async (body: ISendTOSConfirmationBody) => {
    try {
      dispatch(termsOfServiceSlice.requestLoading())
      await apiWithIDToken.post(`${url}/confirmations`, body)
      dispatch(
        termsOfServiceSlice.requestLoaded({
          ...tosInfo,
          confirmed: true,
          confirmation: body,
        })
      )
    } catch (error) {
      dispatch(termsOfServiceSlice.requestFailed(error as IAPIErrorFormat))
    }
  }
}

export const useDismissTermsOfServicesHTTPErrors =
  termsOfServiceSlice.useDismissHTTPErrors

// Slice reducer
export default reducer

import React, { FunctionComponent } from 'react'
import { Badge, Button, Card, ListGroup, Modal } from 'react-bootstrap'
import { AuthRole, IAuthUser } from '../../state/modules/auth'
import { IChannelPartner } from '../../state/modules/channelPartners'
import { IEntity } from '../../state/modules/entities'
import { UserTypes } from '../../state/modules/users'

interface Props {
  user: IAuthUser
  email: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  role?: AuthRole.ADMIN | undefined
  userInviteText?: string
  userType?: UserTypes
  relatedChannelPartner?: IChannelPartner
  relatedEntity?: IEntity
  readOnly: boolean
}

export const InviteUserSummary: FunctionComponent<Props> = (props) => {
  const {
    user,
    email,
    firstName,
    lastName,
    phoneNumber,
    role,
    userInviteText,
    userType,
    relatedChannelPartner,
    relatedEntity,
    readOnly,
  } = props

  const channelPartnerDisplay = relatedChannelPartner && (
    <strong>{relatedChannelPartner.channelPartnerName}</strong>
  )

  const channelPartnerBlock = userType === UserTypes.CHANNEL_PARTNER &&
    (user.role === AuthRole.BBE_USER || user.role === AuthRole.SUPER_ADMIN) && (
      <Card className="mt-3">
        <Card.Header>Channel Partner</Card.Header>
        <Card.Body>
          <Card.Title>
            {channelPartnerDisplay || (
              <span className="text-danger">Not selected</span>
            )}
          </Card.Title>
        </Card.Body>
      </Card>
    )

  const entityDisplay = relatedEntity && (
    <strong>{relatedEntity.entityName}</strong>
  )
  const entityBlock = userType === UserTypes.ENTITY &&
    (user.role === AuthRole.BBE_USER || user.role === AuthRole.SUPER_ADMIN) && (
      <Card className="mt-3">
        <Card.Header>Client</Card.Header>
        <Card.Body>
          <Card.Title>
            {entityDisplay || <span className="text-danger">Not selected</span>}
          </Card.Title>
        </Card.Body>
      </Card>
    )

  const bbe_user_block = userType === UserTypes.BBE_USER && (
    <Card className="mt-3">
      <Card.Body>
        <Card.Title className="text-info font-weight-bold">BBE User</Card.Title>
        <Card.Subtitle className="text-muted">
          This user will be able to view all clients and channel partners.
        </Card.Subtitle>
      </Card.Body>
    </Card>
  )

  const super_admin_block = userType === UserTypes.SUPER_ADMIN && (
    <Card className="mt-3">
      <Card.Body>
        <Card.Title className="text-info font-weight-bold">
          Super Admin
        </Card.Title>
        <Card.Subtitle className="text-muted">
          This user will have a lot of privileges
        </Card.Subtitle>
      </Card.Body>
    </Card>
  )

  const unknown_type_block = !bbe_user_block &&
    !super_admin_block &&
    !entityBlock &&
    !channelPartnerBlock &&
    (user.role === AuthRole.BBE_USER || user.role === AuthRole.SUPER_ADMIN) && (
      <Card className="mt-3" bg="danger">
        <Card.Body>
          <Card.Title>Unknown user type</Card.Title>
        </Card.Body>
      </Card>
    )

  const read_only_badge = readOnly && (
    <Badge variant="secondary">Read Only</Badge>
  )

  const inviteTextDisplay = !!userInviteText?.trim() && (
    <Card className="mt-3">
      <Card.Header>Invite text</Card.Header>
      <Card.Body>
        <Card.Text>{userInviteText}</Card.Text>
      </Card.Body>
    </Card>
  )

  return (
    <>
      <ListGroup>
        <ListGroup.Item>
          Email: <strong>{email}</strong>
        </ListGroup.Item>
        {(firstName || lastName) && (
          <ListGroup.Item>
            Name: {firstName} {lastName}
          </ListGroup.Item>
        )}
        <ListGroup.Item>
          Phone: {phoneNumber || <em>Not specified</em>}
        </ListGroup.Item>
        {(userType === UserTypes.ENTITY ||
          userType === UserTypes.CHANNEL_PARTNER) && (
          <ListGroup.Item>
            Role:{' '}
            {role === AuthRole.ADMIN ? (
              <span className="text-info fw-bold">Admin</span>
            ) : (
              'User'
            )}
            &nbsp;
            {read_only_badge}
            &nbsp;
            {role === AuthRole.ADMIN && (
              <small className="text-muted">Extended privileges</small>
            )}
          </ListGroup.Item>
        )}
      </ListGroup>

      {channelPartnerBlock}
      {entityBlock}
      {bbe_user_block}
      {super_admin_block}
      {unknown_type_block}
      {inviteTextDisplay}
    </>
  )
}

interface ModalContentProps extends Props {
  onConfirm?: () => void
  onCancel?: () => void
}

export const InviteUserSummaryModalContent: FunctionComponent<
  ModalContentProps
> = ({ onConfirm, onCancel, ...passThroughProps }) => (
  <>
    <Modal.Body>
      <p className="lead">Please review the invitation to continue:</p>
      <InviteUserSummary {...passThroughProps} />
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={onCancel}>
        Back
      </Button>
      <Button variant="primary" onClick={onConfirm}>
        Invite
      </Button>
    </Modal.Footer>
  </>
)

import { isDevelopment } from './enviroment'
import axios, { AxiosError, AxiosInstance } from 'axios'
import promiseRetry from 'promise-retry'
import { OperationOptions as RetryOptions } from 'retry'
import { toLower } from 'lodash'

export const baseURL = '/api/v1'
export const host = isDevelopment() ? 'http://localhost:8000' : ''

export function getAPI(accessToken?: string): AxiosInstance {
  const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  const retryOptions: Partial<RetryOptions> = {
    retries: 3,
    factor: 5,
    minTimeout: 100,
  }
  const backOffProxyHandler = {
    get: function (target: AxiosInstance, prop: keyof AxiosInstance) {
      return (...args: unknown[]) =>
        promiseRetry(async (retry) => {
          try {
            const targetFunc = target[prop] as (
              ...args: unknown[]
            ) => Promise<unknown>
            return await targetFunc(...args)
          } catch (error) {
            // Do not retry failed POST requests
            if (toLower(prop) === 'post') throw error

            if (error instanceof AxiosError) {
              const status = error.response?.status
              if (status) {
                // 501 Not Implemented
                if (status === 501) throw error
                // 503 Service Unavailable
                if (status === 503) throw error

                // Non 5xx codes
                if (status < 500 && status > 599) throw error
              }
            }
            retry(error)
          }
        }, retryOptions)
    },
  }
  return new Proxy(axiosInstance, backOffProxyHandler)
}

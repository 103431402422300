import { FunctionComponent, useMemo } from 'react'
import { stringToBoolean } from '../../../utils/string'
import { TextButton } from '../../common/TextButton'
import {
  FilterProps,
  isFilterPropsType,
  ParseType,
  StringifyType,
} from './Props'

export const filterType = 'Boolean' as const

export type Value = boolean

export const parseBoolean: ParseType<Value> = (valueRes) =>
  valueRes !== undefined ? stringToBoolean(valueRes) : undefined

export const stringifyBoolean: StringifyType<Value> = (value) =>
  value ? '1' : '0'
export type FilterPropsBoolean = FilterProps<typeof filterType, Value>

export const isFilterPropsBoolean =
  isFilterPropsType<FilterPropsBoolean>(filterType)

export const FilterBoolean: FunctionComponent<FilterPropsBoolean> = (props) => {
  const { value, onChange, onReset } = props

  const handleToggle = useMemo(
    () =>
      onChange
        ? () => {
            if (value === false && onReset) onReset()
            else onChange(!value)
          }
        : undefined,
    [value, onChange, onReset]
  )

  let color: string | undefined = 'darkgrey'
  if (value === false) color = 'LightSlateGray'
  if (value) color = 'MediumSeaGreen'

  let label = 'Any'
  if (value === false) label = 'No'
  if (value) label = 'Yes'

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <TextButton
        onClick={handleToggle}
        style={{ opacity: value === undefined ? 0.4 : 1 }}
      >
        {value !== undefined && <>Only</>}&nbsp;
        <span style={{ color }}>{label}</span>
      </TextButton>
    </div>
  )
}

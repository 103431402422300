import { FunctionComponent } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { BootstrapTableProps } from './BootstrapTable/tableTypes'

type Props = Pick<BootstrapTableProps, 'columns' | 'data' | 'tableName'>

const ExportToCSVButton: FunctionComponent<Props> = (props) => {
  function getRecordValue(
    record: { [key: string]: unknown },
    key: string
  ): string {
    const value = record[key]
    if (value === undefined || value === null) {
      return ''
    }
    switch (typeof value) {
      case 'object':
        const valObj = value as { [key: string]: unknown }
        return valObj.text ? String(valObj.text) : ''
      default:
        return String(value)
    }
  }
  function exportCSV() {
    const { columns, data, tableName } = props
    const filteredColumns = columns.filter((column) => !column.disableExport)
    const csvContent = [
      filteredColumns.map((column) => column.label).join(','),
      ...data.map((record) =>
        filteredColumns
          .map((column) => {
            return getRecordValue(record, column.propName)
          })
          .join(',')
      ),
    ].join('\n')
    const downloadUrl = window.URL.createObjectURL(new Blob([csvContent]))
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', `${tableName}.csv`)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
  return (
    <Row className="mb-2">
      <Col xs={{ span: 2, offset: 10 }}>
        <Button onClick={exportCSV} block>
          Export To CSV
        </Button>
      </Col>
    </Row>
  )
}

export default ExportToCSVButton

import { Dispatch, FunctionComponent } from 'react'
import { Dropdown } from 'react-bootstrap'
import { IAuthUser, IAuthUserWithId, useUser } from '../../state/modules/auth'
import { useImpersonatingUser } from '../../state/modules/impersonations'
import { TextButton } from '../common/TextButton'
import { Access, UserReportAccessInfo } from './Access'

interface Props
  extends Pick<
    UserReportAccessInfo,
    'availableAccesses' | 'reportAccess' | 'canChange'
  > {
  onChange?: Dispatch<Access>
  className?: string
}

export const UserReportAccessInput: FunctionComponent<Props> = ({
  availableAccesses,
  canChange,
  reportAccess,
  onChange,
  className,
}) => {
  const user = useUser()
  const impersonatingUser = useImpersonatingUser()

  return availableAccesses.length && canChange && onChange ? (
    <Dropdown
      onSelect={(access) => {
        if (access) onChange?.(access as Access)
      }}
      className={className}
    >
      <Dropdown.Toggle
        as={TextButton}
        title={
          reportAccess === 'My own (impersonated)'
            ? 'Owned by the user you are impersonating'
            : undefined
        }
      >
        {getAccessLabel(reportAccess, user, impersonatingUser)}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {availableAccesses.map((access) => (
          <Dropdown.Item key={access} eventKey={access}>
            {getAccessLabel(access, user, impersonatingUser)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    <span>{reportAccess}</span>
  )
}

const getAccessLabel = (
  reportAccess?: string,
  user?: IAuthUserWithId,
  impersonatingUser?: IAuthUser
) => {
  if (!user) return null
  if (reportAccess === undefined) return null

  if (
    reportAccess === 'My own (impersonated)' &&
    impersonatingUser &&
    !!impersonatingUser.email
  )
    return (
      <div
        style={{
          textAlign: 'left',
          display: 'inline-block',
          verticalAlign: 'middle',
        }}
      >
        My own
        <div style={{ fontSize: 10 }}>{impersonatingUser.email}</div>
      </div>
    )

  if (reportAccess === 'My own' && impersonatingUser && !!user.email)
    return (
      <div
        style={{
          textAlign: 'left',
          display: 'inline-block',
          verticalAlign: 'middle',
        }}
      >
        My own
        <div style={{ fontSize: 10 }}>{user.email}</div>
      </div>
    )

  return reportAccess
}

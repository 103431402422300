import { CSSProperties, ReactNode } from 'react'
import { IStyledComponentPropTypes } from '../../common/types'
import { PagerProps } from '../Pager'
import { IColumnSettings } from './ColumnSettings'
import { BootstrapTableUserSettingsProps } from './UserSettings'

export enum TableName {
  DocumentTable = 'Documents',
  UserTable = 'Manage Users',
  RFPTable = 'RFPs',
  BidHistoryTable = 'Bid History',
  PROJECTS = 'Projects',
}

export interface BootstrapTableProps
  extends IStyledComponentPropTypes,
    Partial<BootstrapTableUserSettingsProps> {
  pagerProps?: PagerProps
  columns: readonly IColumnSettings[]
  loading?: boolean
  data: readonly { [key: string]: unknown }[]
  changesMap?: ITableChangesMap
  handleTableChanges?: (changes: readonly ITableCellChange[]) => void
  rowKeyPropName?: string
  searchBar?: boolean
  sortMode?: 'single' | 'multi'
  enableExportToCSV?: boolean
  topRightMenu?: (children: ReactNode) => ReactNode
  columnSelection?: boolean
  horizontalMargins?: string
  width?: CSSProperties['width']
  validationErrors?: readonly ITableValidationError[]
  headerChildren?: ReactNode
  readOnly?: boolean
  scheduleLoad?: () => void
}

export type ITableCellChange = {
  rowKey: number | string
  propName: string
  oldValue: unknown
  newValue: unknown
}

/** Row patches by row keys */
export type ITableChangesMap = ReadonlyMap<
  string | number,
  { [key: string]: unknown }
>

export type ITableValidationError = [
  message: string,
  rowKey: string | number,
  propName: string
]

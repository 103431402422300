import { FunctionComponent, ReactNode, useEffect } from 'react'
import { map, startCase } from 'lodash'
import { Button } from 'react-bootstrap'
import moment from 'moment'
import styled from 'styled-components'
import { ArrowLeft } from 'react-bootstrap-icons'
import { ISiteRfp } from '../../state/modules/siteRfps'
import { BootstrapTable } from '../BootstrapTable/BootstrapTable'
import { TableName } from '../BootstrapTable/tableTypes'
import { useProjectColumns } from './columns'
import {
  IBootstrapTableUserSettings,
  makeBootstrapTableUserSettings,
} from '../BootstrapTable/UserSettings'
import { makeTableRecordChangesHook } from '../BootstrapTable/RecordChangesHook'
import { useChangeOrder } from '../../state/modules/changeOrders'
import { FieldType, IField } from '../../common/types'
import { ComponentLoader } from '../common/Loaders'
import { ErrorsAlertsDisplay } from '../helpers/displayAlerts'

const useUserSettings = makeBootstrapTableUserSettings(TableName.PROJECTS)

const useProjectsChanges = makeTableRecordChangesHook<ISiteRfp, 'recordId'>(
  'recordId'
)

export const defaultUserSettings: IBootstrapTableUserSettings = {
  stickyHeaders: true,
}
interface Props {
  changeOrderId: string
  onClose: () => void
  actionButtons?: ReactNode
}

const Header = styled('h4')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-top: 1rem;
`

const Title = styled('span')`
  flex: 1;
`

const HeaderActionButtons = styled('span')`
  justify-self: flex-end;
`

const OLD_FIELDS: IField[] = map(
  [
    {
      propName: 'rfpName',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'currentSystemSizeMw',
      fieldType: FieldType.NUMERIC,
    },
    {
      propName: 'currentSystemSizeMwh',
      fieldType: FieldType.NUMERIC,
    },
    {
      propName: 'propertyName',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'propertyNameObsf',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'kanbanStatusSiteRfp',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'awardedChannelPartnerName',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'manualProspectorLink',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'publishToPortal',
      fieldType: FieldType.CHECKBOX,
    },
    {
      propName: 'rfpRelatedClient',
      fieldType: FieldType.NUMERIC,
    },
    {
      propName: 'solarArrayLocation',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'constructionNotes',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'address',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'city',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'state',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'roofAgeBuiltYearInstallReplace',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'rfpClientName',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'arrayArea',
      fieldType: FieldType.NUMERIC,
    },
    {
      propName: 'constructionStatus',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'siteNotes',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'clientNextActions',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'jointVenture',
      fieldType: FieldType.CHECKBOX,
    },
    {
      propName: 'roofRightsTenantConsentStatus',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'roofInstallYear',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'roofStatus',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'lenderConsent',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'lenderName',
      fieldType: FieldType.TEXT,
    },
    {
      propName: 'ntpDateEstimate',
      fieldType: FieldType.DATE,
    },
  ],
  (f) => ({ ...f, label: startCase(f.propName) })
)

export const ChangeOrderTable: FunctionComponent<Props> = (props) => {
  const { changeOrderId, onClose, actionButtons } = props

  const { changeOrder, loading, error, dismissError } =
    useChangeOrder(changeOrderId)

  const {
    fields: changeOrderFields,
    patches,
    patchedRecordsSnapshot,
  } = changeOrder || {}

  const fields =
    changeOrderFields && changeOrderFields.length > 0
      ? changeOrderFields
      : OLD_FIELDS

  const columns = useProjectColumns(fields)

  const changesProps = useProjectsChanges({
    readOnly: true,
  })

  const { setChanges, resetChanges } = changesProps

  useEffect(() => {
    if (patches) {
      setChanges(patches)
    } else {
      resetChanges()
    }
  }, [setChanges, resetChanges, patches])

  const userSettings = useUserSettings(
    { columns, autoSanitize: false },
    defaultUserSettings
  )

  if (error) {
    return <ErrorsAlertsDisplay errors={error} dismiss={dismissError} />
  }

  if (!changeOrder) {
    return <ComponentLoader />
  }

  const siteRfpObjs = patchedRecordsSnapshot
    ? patchedRecordsSnapshot.map((siteRfp) =>
        Object.keys(siteRfp).reduce(
          (siteRfpObj, key) => ({
            ...siteRfpObj,
            [key]: siteRfp[key as keyof ISiteRfp],
          }),
          {} as { [key: string]: unknown }
        )
      )
    : []

  return (
    <BootstrapTable
      data={siteRfpObjs}
      {...userSettings}
      {...changesProps}
      columnSelection={true}
      horizontalMargins={'8.33333%'}
      loading={loading}
      headerChildren={
        <Header>
          <Button onClick={onClose}>
            <ArrowLeft /> Back to Projects
          </Button>
          <Title>
            <span>Change Order «{changeOrder.title}» </span>

            <small title={moment(changeOrder.timestamp).toISOString()}>
              Created: {moment(changeOrder.timestamp).format('LLLL')}
            </small>
          </Title>
          <HeaderActionButtons>{actionButtons}</HeaderActionButtons>
        </Header>
      }
    />
  )
}

import {
  AuthRole,
  IAuthUser,
  isBBEUserOrSuperAdmin,
} from '../../state/modules/auth'
import { IInviteUserBody, UserTypes } from '../../state/modules/users'
import { IEntity } from '../../state/modules/entities'

export function makeInviteBody(
  email: string,
  userInviteText: string | undefined,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  recieveRFPEmails: boolean,
  role: AuthRole | undefined,
  userType: UserTypes,
  user: IAuthUser | undefined,
  readOnly: boolean,
  relatedChannelPartnerId: number | undefined,
  relatedEntity: IEntity | undefined,
  relatedEntityId: number | undefined,
  prospectorPermissionsAllowed: boolean,
  hasProspectorPermission: boolean,
  hasNearmapPermission: boolean
) {
  const body: IInviteUserBody = {
    email,
    text: userInviteText,
    firstName,
    lastName,
    phoneNumber,
    recieveRFPEmails,
  }

  if (
    role === AuthRole.ADMIN &&
    userType !== UserTypes.BBE_USER &&
    userType !== UserTypes.SUPER_ADMIN
  ) {
    body.role = AuthRole.ADMIN
  } else if (
    isBBEUserOrSuperAdmin(user!) &&
    (userType === UserTypes.BBE_USER || userType === UserTypes.SUPER_ADMIN)
  ) {
    body.role =
      userType === UserTypes.BBE_USER ? AuthRole.BBE_USER : AuthRole.SUPER_ADMIN
  } else {
    body.role = AuthRole.NONE
  }

  if (isBBEUserOrSuperAdmin(user!)) {
    body.readOnly = readOnly
  }
  // NOTE - We only want to set this value for the correct user types.
  if (relatedChannelPartnerId && userType === UserTypes.CHANNEL_PARTNER) {
    body.channelPartnerID = relatedChannelPartnerId
  } else if (relatedEntity && userType === UserTypes.ENTITY) {
    body.entityID = relatedEntityId
  }

  if (prospectorPermissionsAllowed) {
    body.hasProspectorPermission = hasProspectorPermission
    body.hasNearmapPermission = hasNearmapPermission
  }

  return body
}

import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'

import { NewLineParagraph } from './common/Layout'
import {
  useConfirmation,
  useApproveConfirmation,
  useDeclineConfirmation,
  ConfirmationStatus,
} from '../state/modules/confirmation'
import { Variant } from '../common/types'

type IFooterButtonPropTypes = {
  onApproval: () => void
  onDecline: () => void
  variant?: Variant
  approveLabel?: string
  decelineLabel?: string
}

function FooterButtons({
  onApproval,
  onDecline,
  variant = Variant.PRIMARY,
  approveLabel = 'Ok',
  decelineLabel = 'Cancel',
}: IFooterButtonPropTypes) {
  return (
    <>
      <Button variant={variant} onClick={onApproval}>
        {approveLabel}
      </Button>
      <Button variant={Variant.LIGHT} onClick={onDecline}>
        {decelineLabel}
      </Button>
    </>
  )
}

export function ConfirmationModal() {
  const confirmation = useConfirmation()
  const approveConfirmation = useApproveConfirmation()
  const declineConfirmation = useDeclineConfirmation()

  const [confirmationText, setConfirmationText] = useState('')

  function handleConfirmationTextChange(
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) {
    setConfirmationText(e.target.value)
  }

  if (confirmation && confirmation.status === ConfirmationStatus.REQUESTED) {
    return (
      <Modal
        onHide={declineConfirmation}
        animation={false}
        show={true}
        centered
      >
        <Modal.Body>
          <h4>{confirmation.title}</h4>
          <NewLineParagraph className="text-black" />
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>{confirmation.message}</Form.Label>
            {confirmation.withConfirmationText ? (
              <Form.Control
                as="textarea"
                onChange={handleConfirmationTextChange}
                rows={3}
              />
            ) : (
              ''
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <FooterButtons
            variant={confirmation.variant}
            approveLabel={confirmation.approveLabel}
            decelineLabel={confirmation.declineLabel}
            onApproval={() => approveConfirmation(confirmationText)}
            onDecline={declineConfirmation}
          />
        </Modal.Footer>
      </Modal>
    )
  } else {
    return <></>
  }
}

export default ConfirmationModal

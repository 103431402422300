import { Dictionary } from 'lodash'

interface AssignmentProblem {
  recordId: number
  label: string
  link?: string
}

interface Problems {
  duplicateRolePropNameAssignments?: Dictionary<AssignmentProblem[]>
  duplicateTablePropNameAssignments?: Dictionary<AssignmentProblem[]>
}

export interface AssignmentFieldsError {
  type: 'AssignmentFieldsError'
  message: string
  problems: Problems
}

export const isAssignmentFieldsError = (
  e: unknown
): e is AssignmentFieldsError => {
  const error = e as { type?: string }
  return error.type === 'AssignmentFieldsError'
}

export default [
  { short: 'AB', full: 'Alberta' },
  { short: 'AK', full: 'Alaska' },
  { short: 'AL', full: 'Alabama' },
  { short: 'AR', full: 'Arkansas' },
  { short: 'AS', full: 'American Samoa' },
  { short: 'AZ', full: 'Arizona' },
  { short: 'BC', full: 'British Columbia' },
  { short: 'CA', full: 'California' },
  { short: 'CO', full: 'Colorado' },
  { short: 'CT', full: 'Connecticut' },
  { short: 'DC', full: 'District of Columbia' },
  { short: 'DE', full: 'Delaware' },
  { short: 'FL', full: 'Florida' },
  { short: 'GA', full: 'Georgia' },
  { short: 'GU', full: 'Guam' },
  { short: 'HI', full: 'Hawaii' },
  { short: 'IA', full: 'Iowa' },
  { short: 'ID', full: 'Idaho' },
  { short: 'IL', full: 'Illinois' },
  { short: 'IN', full: 'Indiana' },
  { short: 'KS', full: 'Kansas' },
  { short: 'KY', full: 'Kentucky' },
  { short: 'LA', full: 'Louisiana' },
  { short: 'MA', full: 'Massachusetts' },
  { short: 'MB', full: 'Manitoba' },
  { short: 'MD', full: 'Maryland' },
  { short: 'ME', full: 'Maine' },
  { short: 'MI', full: 'Michigan' },
  { short: 'MN', full: 'Minnesota' },
  { short: 'MO', full: 'Missouri' },
  { short: 'MS', full: 'Mississippi' },
  { short: 'MT', full: 'Montana' },
  { short: 'NB', full: 'New Brunswick' },
  { short: 'NC', full: 'North Carolina' },
  { short: 'ND', full: 'North Dakota' },
  { short: 'NE', full: 'Nebraska' },
  { short: 'NH', full: 'New Hampshire' },
  { short: 'NJ', full: 'New Jersey' },
  { short: 'NL', full: 'Newfoundland and Labrador' },
  { short: 'NM', full: 'New Mexico' },
  { short: 'NS', full: 'Nova Scotia' },
  { short: 'NT', full: 'Northwest Territories' },
  { short: 'NU', full: 'Nunavut' },
  { short: 'NV', full: 'Nevada' },
  { short: 'NY', full: 'New York' },
  { short: 'OH', full: 'Ohio' },
  { short: 'OK', full: 'Oklahoma' },
  { short: 'ON', full: 'Ontario' },
  { short: 'OR', full: 'Oregon' },
  { short: 'PA', full: 'Pennsylvania' },
  { short: 'PE', full: 'Prince Edward Island' },
  { short: 'PR', full: 'Puerto Rico' },
  { short: 'QC', full: 'Quebec' },
  { short: 'RI', full: 'Rhode Island' },
  { short: 'SC', full: 'South Carolina' },
  { short: 'SD', full: 'South Dakota' },
  { short: 'SK', full: 'Saskatchewan' },
  { short: 'TN', full: 'Tennessee' },
  { short: 'TX', full: 'Texas' },
  { short: 'UT', full: 'Utah' },
  { short: 'VA', full: 'Virginia' },
  { short: 'VI', full: 'Virgin Islands' },
  { short: 'VT', full: 'Vermont' },
  { short: 'WA', full: 'Washington' },
  { short: 'WI', full: 'Wisconsin' },
  { short: 'WV', full: 'West Virginia' },
  { short: 'WY', full: 'Wyoming' },
  { short: 'YT', full: 'Yukon' },
];

import { CSSProperties, forwardRef, PropsWithChildren } from 'react'
import { Variant } from '../../common/types'
import './TextButton.css'

interface Props {
  onClick?: () => void
  disabled?: boolean
  style?: CSSProperties
  className?: string
  title?: string
  variant?: Variant
  gentle?: boolean
}

//eslint-disable-next-line
export const TextButton = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<Props>
>(
  (
    { children, disabled, onClick, style, className, title, variant, gentle },
    ref
  ) => (
    <button
      ref={ref}
      className={[
        'TextButton',
        gentle && `TextButton-gentle`,
        !variant && `TextButton-pseudo`,
        !variant && !disabled && 'TextButton-pseudo-enabled',
        !variant && disabled && 'TextButton-pseudo-disabled',
        variant && `btn-outline-${variant}`,
        className,
      ]
        .filter(Boolean)
        .join(' ')}
      onClick={disabled ? undefined : onClick}
      title={title}
      style={style}
      disabled={disabled}
      type="button"
    >
      {children}
    </button>
  )
)

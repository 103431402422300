import createSlice from './helpers/createSlice'
import { useApiWithIDToken, useDispatch } from './common'
import { IAPIErrorFormat, RequestStatus } from '../../common/types'
import { useCallback, useEffect } from 'react'
import { IUserReport } from '../../state/modules/userReports'

type UserAccessLevel = 'Global' | 'Org' | 'UserId'

interface State {
  settings: {
    [name: string]: object | null | undefined
  }
}

export interface Settings {
  defaultReportId?: IUserReport['_id']
}

export const sliceName = 'personal-settings'

const url = `/${sliceName}`

const slice = createSlice<State>(sliceName, url, { settings: {} })

export const initialState = slice.initialState

export const usePersonalSettingsSlice = slice.useSlice
export const useLoadPersonalSettings = slice.useLoadSlicePayload

export function usePersonalSettings({
  disableAutoLoad,
}: { disableAutoLoad?: false } = {}) {
  const {
    status,
    payload: { settings },
  } = usePersonalSettingsSlice()

  const load = useLoadPersonalSettings()

  useEffect(() => {
    if (!disableAutoLoad && status === RequestStatus.IDLE) {
      load().catch(() => {
        throw new Error('load failed')
      })
    }
  }, [disableAutoLoad, status, load])

  const dispatch = useDispatch()
  const apiWithIDToken = useApiWithIDToken()

  const patchSettings = useCallback(
    async (
      name: string,
      value: Settings | string | null | undefined,
      level?: UserAccessLevel
    ) => {
      try {
        dispatch(slice.requestLoading())
        const patch = { level, undefine: value === undefined, name, value }
        const response = await apiWithIDToken.patch(url, patch)
        dispatch(slice.requestLoaded(response.data as State))
      } catch (error) {
        dispatch(slice.requestFailed(error as IAPIErrorFormat))
      }
    },
    [dispatch, apiWithIDToken]
  )

  return {
    settings,
    status,
    isLoading: status === RequestStatus.LOADING,
    patchSettings,
  }
}

export const makePersonalSettingsHook =
  (name: string) => (defaultValue?: Settings) => {
    const { settings, patchSettings } = usePersonalSettings()

    const currentValue: Settings | null | undefined =
      ((settings || {})[name] as Settings | null) || defaultValue

    const set = useCallback(
      async (newValue: Settings | null) => {
        await patchSettings(name, newValue)
      },
      [patchSettings]
    )

    const setField = useCallback(
      <F extends keyof Settings>(fieldName: F | string) =>
        async (
          value: F extends keyof Settings
            ? Settings[F]
            : Settings | string | null | undefined
        ) => {
          await patchSettings(`${name}.${fieldName}`, value)
        },
      [patchSettings]
    )

    return [currentValue, setField, { set }] as const
  }

export const useDismissPersonalSettingsHTTPErrors = slice.useDismissHTTPErrors

export default slice.reducer

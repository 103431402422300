import { deepCloneArray } from '../../../utils/array'
import { IUser, IUserUpdate } from '../users'

export function applyUpdates(users: IUser[], usersUpdates: IUserUpdate[]) {
  const updatedUsers = deepCloneArray(users)
  usersUpdates.forEach((usersUpdate) => {
    const userInd = updatedUsers.findIndex(
      (updatedUser) => updatedUser.id === usersUpdate.id
    )
    if (userInd < 0) {
      throw new Error(`Could not find user with id ${usersUpdate.id}`)
    }
    const keys = Object.keys(usersUpdate) as Array<keyof IUser>
    const updated = keys.reduce(
      (user, prop) => ({
        ...user,
        [prop]: usersUpdate[prop],
      }),
      updatedUsers[userInd]
    )
    // replace the user obj with the updated one
    updatedUsers.splice(userInd, 1, updated)
  })
  return updatedUsers
}

import { useEffect, useState } from 'react'
import {
  IManageUsersConfirmation,
  ManageUsersConfirmationAction,
} from '../types'
import {
  ConfirmationStatus,
  IConfirmationRequest,
  useConfirmation,
  useSendConfirmation,
} from '../../../state/modules/confirmation'
import {
  IUserUpdate,
  useDeleteUser,
  useSaveUsersUpdates,
} from '../../../state/modules/users'
import { mapToUsersUpdates } from './usersChanges'
import {
  IImpersonatingDetails,
  useCreateImpersonation,
} from '../../../state/modules/impersonations'
import { ITableCellChange } from '../../BootstrapTable/tableTypes'

interface IActionConfirmationResponse {
  confirmation: IManageUsersConfirmation | undefined
  sendConfirmation: (confirmationRequest: IConfirmationRequest) => string
  confirmationReceiptID: string | undefined
  setConfirmationReceiptID: (value: string) => void
}

export function useActionConfirmationResponse({
  changes,
}: {
  changes: ITableCellChange[]
}): IActionConfirmationResponse {
  const confirmation: IManageUsersConfirmation | undefined = useConfirmation()
  const sendConfirmation = useSendConfirmation()
  const [confirmationReceiptID, setConfirmationReceiptID] = useState<string>()
  const saveUsersUpdates = useSaveUsersUpdates()
  const deleteUser = useDeleteUser()
  const createImpersonation = useCreateImpersonation()

  const saveUpdates = async () => {
    const usersUpdates = mapToUsersUpdates(changes)
    await saveUsersUpdates(usersUpdates)
  }

  // NOTE - Hook to listen for confirmations and proceed with the corresponding actions when approved!
  useEffect(() => {
    if (
      confirmation &&
      confirmation.metadata &&
      confirmation.id === confirmationReceiptID &&
      confirmation.status === ConfirmationStatus.APPROVED
    ) {
      if (
        confirmation.metadata.action ===
        ManageUsersConfirmationAction.SAVE_UPDATES
      ) {
        saveUpdates().catch(() => {
          throw new Error('saveUpdates failed')
        })
      } else if (
        confirmation.metadata.action ===
        ManageUsersConfirmationAction.DELETE_USER
      ) {
        deleteUser(confirmation.metadata.payload as string).catch(() => {
          throw new Error('deleteUser failed')
        })
      } else if (
        confirmation.metadata.action ===
        ManageUsersConfirmationAction.ACTIVE_USER
      ) {
        const usersUpdates: IUserUpdate[] = [
          {
            id: confirmation.metadata.payload as string,
            blocked: false,
          },
        ]
        saveUsersUpdates(usersUpdates).catch(() => {
          throw new Error('saveUsersUpdates failed')
        })
      } else if (
        confirmation.metadata.action ===
        ManageUsersConfirmationAction.INACTIVE_USER
      ) {
        const usersUpdates: IUserUpdate[] = [
          {
            id: confirmation.metadata.payload as string,
            blocked: true,
          },
        ]
        saveUsersUpdates(usersUpdates).catch(() => {
          throw new Error('saveUsersUpdates failed')
        })
      } else if (
        confirmation.metadata.action ===
        ManageUsersConfirmationAction.IMPERSONATE_USER
      ) {
        createImpersonation(
          confirmation.metadata.payload as IImpersonatingDetails
        ).catch(() => {
          throw new Error('createImpersonation failed')
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmation])

  return {
    confirmation,
    sendConfirmation,
    confirmationReceiptID,
    setConfirmationReceiptID,
  }
}

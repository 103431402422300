import React, {
  ChangeEventHandler,
  FunctionComponent,
  useEffect,
  useMemo,
} from 'react'
import { Form } from 'react-bootstrap'
import { RequestStatus } from '../../common/types'
import { useCallOnceOnIDTokenGranted } from '../../state/modules/common'
import {
  IEntity,
  useEntities,
  useLoadEntities,
} from '../../state/modules/entities'
import { ComponentLoader } from '../common/Loaders'

interface Props {
  entity?: IEntity['recordId'] | IEntity
  onEntityChange: (entity?: IEntity) => void
  autoSelectEntityId?: number
  disabled: boolean
  showLabel?: boolean
}

export const RelatedEntitySelect: FunctionComponent<Props> = (props) => {
  const {
    entity,
    onEntityChange,
    autoSelectEntityId,
    disabled,
    showLabel = true,
  } = props

  const {
    payload: entities,
    status: entitiesStatus,
    httpErrors: entitiesHTTPErrors,
  } = useEntities()
  const loadEntities = useLoadEntities()

  const entityId = typeof entity === 'number' ? entity : entity?.recordId

  useEffect(() => {
    if (!autoSelectEntityId) return undefined
    if (!entities) return undefined
    if (entity) return undefined

    onEntityChange?.(
      entities.find(({ recordId }) => recordId === autoSelectEntityId)
    )
  }, [autoSelectEntityId, entities, entity, onEntityChange])

  /**
   * Hook to load entities.
   *
   * Note - useCallOnceOnIDTokenGranted, so that it only calls the api when the idToken is fetched!
   */
  useCallOnceOnIDTokenGranted(async () => {
    if (entitiesStatus === RequestStatus.IDLE) {
      await loadEntities()
    }
  })

  const handleChange = useMemo<
    ChangeEventHandler<HTMLSelectElement> | undefined
  >(
    () =>
      onEntityChange
        ? (e) => {
            const idStr = e.currentTarget.value
            const newId = parseInt(idStr, 10)
            if (isNaN(newId)) throw new Error(`Wrong client id: ${idStr}`)

            const new_entity = entities.find(
              ({ recordId }) => recordId === newId
            )
            onEntityChange(new_entity)
          }
        : undefined,
    [entities, onEntityChange]
  )

  return (
    <fieldset>
      {showLabel && <legend className="required">Related Client</legend>}
      <Form.Group>
        {entitiesStatus === RequestStatus.LOADING ? (
          <ComponentLoader />
        ) : (
          <Form.Control
            name="entity-select"
            as="select"
            value={entityId === undefined ? '' : entityId.toString()}
            onChange={handleChange}
            disabled={disabled}
          >
            <option disabled hidden style={{ display: 'none' }} value="" />
            {entities
              .map(({ recordId, entityName }) =>
                entityName === '' || entityName === undefined ? null : (
                  <option key={recordId} value={recordId}>
                    {entityName}
                  </option>
                )
              )
              .filter((val) => !!val)}
          </Form.Control>
        )}
        {entitiesStatus === RequestStatus.FAILED ? (
          <p>
            <small>{entitiesHTTPErrors?.message}</small>
          </p>
        ) : (
          ''
        )}
      </Form.Group>
    </fieldset>
  )
}

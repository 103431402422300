import React from 'react'
import styled from 'styled-components'
import { useCallOnceOnIDTokenGranted } from '../state/modules/common'
import { IStyledComponentPropTypes, RequestStatus } from '../common/types'
import { displayAlerts } from './helpers/displayAlerts'
import {
  BidHistoryHeaders,
  BidHistoryProps,
  IBidHistoryRecord,
  useBidHistory,
  useLoadBidHistory,
} from '../state/modules/bidHistory'
import { BootstrapTable } from './BootstrapTable/BootstrapTable'
import { TableName } from './BootstrapTable/tableTypes'
import { IColumnSettings, rendererTdArg } from './BootstrapTable/ColumnSettings'
import {
  createRfpRenderer,
  dateRenderer,
} from './renderers/bootstrapTableRenderer'
import { useGenerateRfpLink } from '../state/modules/customerRfps'

function BidHistoryTable({ className, style }: IStyledComponentPropTypes) {
  const { payload: bidHistoryRecords, status, httpErrors } = useBidHistory()
  const loadBidHistory = useLoadBidHistory()
  const generateRfpLink = useGenerateRfpLink()

  useCallOnceOnIDTokenGranted(loadBidHistory)

  function handleRfpLinkClick(bidHistoryRecord: IBidHistoryRecord) {
    generateRfpLink(bidHistoryRecord.relatedRfpOptIn, true, true)
      .then((link) => {
        // Set read only & allow archive to true
        if (link) {
          window.open(link)
        }
      })
      .catch(() => {
        throw new Error('RFP link generation failed')
      })
  }

  const columns: IColumnSettings[] = [
    {
      propName: BidHistoryProps.RFP_NAME,
      label: BidHistoryHeaders.RFP_NAME,
      renderer: (
        td: rendererTdArg,
        row: string,
        value: unknown,
        { rowData }
      ) => {
        return createRfpRenderer({
          value: value as { text: string; token: string; active: boolean },
          onClick: () => handleRfpLinkClick(rowData as IBidHistoryRecord),
        })()
      },
      columnStyle: { width: '300px', textAlign: 'left' },
    },
    {
      propName: BidHistoryProps.SUBMISSION_DATE,
      label: BidHistoryHeaders.SUBMISSION_DATE,
      columnStyle: { maxWidth: '150px' },
      renderer: dateRenderer,
      readOnly: true,
    },
  ]

  const tableBidHistoryData = bidHistoryRecords.map((value) => {
    return {
      ...value,
      [BidHistoryProps.RFP_NAME]: { text: value[BidHistoryProps.RFP_NAME] },
    }
  })

  return (
    <div className={`${className}`} style={style}>
      {displayAlerts(
        httpErrors && httpErrors.errors && httpErrors.errors.length !== 0
          ? httpErrors.errors // Display httpErrors
          : [httpErrors?.message] // Fall back to error.message if no errors[] provided
      )}
      <BootstrapTable
        columns={columns}
        data={tableBidHistoryData}
        tableName={TableName.BidHistoryTable}
        loading={status === RequestStatus.LOADING}
        enableExportToCSV={true}
      />
    </div>
  )
}

export default styled(BidHistoryTable)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;

  .htDimmed {
    background-color: white;
  }
`

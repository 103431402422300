enum MarketSegment {
  Land = 'Land',
  Retail = 'Retail',
  Other = 'Other',
  Hotel = 'Hotel',
  DataCenter = 'Data Center',
  Industrial = 'Industrial',
  Office = 'Office',
  LifeScience = 'Life Science',
  HealthcareFacility = 'Healthcare Facility',
  ParkingGarage = 'Parking Garage',
  Residential = 'Residential',
  SelfStorage = 'Self Storage',
  MobileHomePark = 'Mobile Home Park',
  ColdStorageFacility = 'Cold Storage Facility',
  Multifamily = 'Multifamily',
  RVPark = 'RV Park',
}

export default MarketSegment;

import { useMemo } from 'react'
import {
  IFieldAssignment,
  IFieldAssignmentRole,
  roles,
} from './IFieldAssignment'
import {
  IFieldAssignmentChange,
  IFieldAssignmentTables,
} from './useFieldAssignments'
import './ListStyle.css'
import { Row } from './Row'

interface Props {
  tables: IFieldAssignmentTables
  items: readonly IFieldAssignmentChange[]
  create?: (init: IFieldAssignmentChange) => IFieldAssignmentChange
  remove?: (removeRecordId: IFieldAssignmentChange['recordId']) => void
  change?: (patch: IFieldAssignmentChange) => void
  reset?: (recordId: IFieldAssignmentChange['recordId']) => void
  isDirtyField?: (
    recordId: IFieldAssignmentChange['recordId']
  ) => (field: keyof IFieldAssignment) => boolean
  isDirtyRecord?: (recordId: IFieldAssignmentChange['recordId']) => boolean
}

export const getRoleAccessLevel =
  (assignment: IFieldAssignmentChange) => (role: IFieldAssignmentRole) => {
    if (assignment.whoEdits?.includes(role)) return 'read-write'
    if (role === 'Admin') return 'read-write'

    const mapped = !!(assignment.mappedTableId && assignment.mappedFieldId)

    if (assignment.whoViews?.includes(role) || role === 'BBE')
      return mapped ? 'read-write-mapped' : 'read-only'

    return 'no-access'
  }

export const accessLevelTitle = {
  'read-write': (
    <div style={{ display: 'inline-block' }}>
      Read
      <br />
      Write
    </div>
  ),
  'read-write-mapped': (
    <div style={{ display: 'inline-block' }}>
      Read
      <br />
      Write to mapped
    </div>
  ),
  'read-only': (
    <div style={{ display: 'inline-block' }}>
      Read-Only
      <br />
      &nbsp;
    </div>
  ),
  'no-access': (
    <div style={{ display: 'inline-block' }}>
      No Access
      <br />
      &nbsp;
    </div>
  ),
}

export const fieldTypes = ['Text', 'Numeric', 'Date', 'Boolean'] as const
export type AssignmentFieldType = (typeof fieldTypes)[number]
export const detectFieldType = (
  fieldType: string
): AssignmentFieldType | undefined => {
  const fieldTypeLc = fieldType.toLowerCase()
  if (fieldTypeLc.startsWith('numeric')) return 'Numeric'
  if (fieldTypeLc.startsWith('currency')) return 'Numeric'

  if (fieldTypeLc.startsWith('text')) return 'Text'
  if (fieldTypeLc.startsWith('url')) return 'Text'

  if (fieldTypeLc === 'date') return 'Date'

  if (fieldTypeLc === 'checkbox') return 'Boolean'
  if (fieldTypeLc === 'boolean') return 'Boolean'

  return undefined
}

export const filterTypes = [
  'Contains',
  'List',
  'Numeric Range',
  'Date Range',
  'Boolean',
]

const showRoles = roles.filter((r) => r !== 'Admin')

export const FieldAssignmentList = (props: Props) => {
  const {
    tables,
    items,
    create,
    remove,
    change,
    reset,
    isDirtyField,
    isDirtyRecord,
  } = props

  const header = (
    <tr>
      <th style={{ width: '30%' }}>Label (units) / Settings</th>

      <th style={{ width: '12px' }} />

      <th style={{ width: '16%' }} className="source">
        Source
      </th>

      <th />

      <th className="mapping">Mapping</th>

      <th style={{ width: '12px' }} />

      {showRoles.map((role) => (
        <th key={role} style={{ width: '12%' }}>
          {role}
        </th>
      ))}

      <th>&nbsp;</th>
    </tr>
  )

  const rows = items.map((assignment) => (
    <Row
      key={assignment.recordId}
      tables={tables}
      assignment={assignment}
      showRoles={showRoles}
      remove={remove}
      change={change}
      isDirty={isDirtyRecord?.(assignment.recordId)}
      reset={reset && (() => reset(assignment.recordId))}
      isDirtyField={isDirtyField?.(assignment.recordId)}
    />
  ))

  const newAssignment = useMemo(
    () => ({
      recordId: `new:${items.length}/${+new Date()}`,
    }),
    [items]
  )

  rows.push(
    <Row
      key={newAssignment.recordId}
      tables={tables}
      assignment={newAssignment}
      showRoles={showRoles}
      change={create}
      transparent={true}
      isDirtyField={isDirtyField?.(newAssignment.recordId)}
    />
  )

  return (
    <table className="FieldAssignmentListTable">
      <thead
        style={{ position: 'sticky', top: 0, zIndex: 1, background: 'white' }}
      >
        {header}
      </thead>
      <tbody>{rows}</tbody>
    </table>
  )
}

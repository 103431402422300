import { ChangeEvent, FunctionComponent, useMemo } from 'react'
import { FormCheck, FormControl } from 'react-bootstrap'
import {
  FilterProps,
  isFilterPropsType,
  ParseType,
  StringifyType,
} from './Props'

export const filterType = 'Contains' as const

/**
 * - ':': contains,
 * - '-': does not contain,
 */
type SubOperator = ':' | '-'
export type Value = [subOperator: ':' | '-', value: string]

export const parseContains: ParseType<Value> = (valueRes) => {
  if (!valueRes) return undefined
  if (Array.isArray(valueRes)) return valueRes as Value

  if (typeof valueRes !== 'string') return undefined

  let subOperator = valueRes.slice(0, 1)

  let value = valueRes.slice(1)

  if (subOperator !== ':' && subOperator !== '-') {
    value = valueRes
    subOperator = ':'
  }

  return [subOperator as SubOperator, value]
}

export const stringifyContains: StringifyType<Value> = ([subOperator, value]) =>
  `${subOperator}${value}`

export type FilterPropsContains = FilterProps<typeof filterType, Value>

export const isFilterPropsContains =
  isFilterPropsType<FilterPropsContains>(filterType)

export const FilterContains: FunctionComponent<FilterPropsContains> = (
  props
) => {
  const { value: [subOperator, value] = [':', ''], onChange, onReset } = props

  const handleChange = useMemo(
    () =>
      onChange
        ? (event: ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value
            if (!newValue && onReset) onReset()
            else onChange([subOperator, newValue])
          }
        : undefined,
    [subOperator, onChange, onReset]
  )

  const input = (
    <FormControl
      value={value || ''}
      onChange={handleChange}
      style={{ paddingRight: '60px' }}
    />
  )

  const handleDoesNotContainToggle = useMemo(
    () =>
      onChange
        ? (event: ChangeEvent<HTMLInputElement>) => {
            const doesNotContain = !!event.target.checked
            const subOperator = doesNotContain ? '-' : ':'
            onChange([subOperator, value])
          }
        : undefined,
    [onChange, value]
  )

  const doesNotContainSwitch = (
    <FormCheck
      type="checkbox"
      label="Not"
      checked={subOperator === '-'}
      onChange={handleDoesNotContainToggle}
    />
  )

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      {input}
      <div
        style={{
          position: 'absolute',
          right: '4px',
          top: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {doesNotContainSwitch}
      </div>
    </div>
  )
}

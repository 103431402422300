import React, { FunctionComponent, ReactNode, useState } from 'react'
import { RequestStatus } from '../common/types'
import { ComponentLoader } from '../components/common/Loaders'
import { FieldAssignmentEditorSettings } from '../components/FieldAssignment/Editor'
import { ErrorsAlertsDisplay } from '../components/helpers/displayAlerts'
import { ChangeOrderTable } from '../components/ProjectTable/ChangeOrder'
import ProjectTable from '../components/ProjectTable/ProjectTable'
import { IChangeOrder } from '../state/modules/changeOrders'
import {
  useCallOnIDTokenGranted,
  useQuery,
  useSyncQuery,
} from '../state/modules/common'
import {
  useLoadSiteRfpFields,
  useSiteRfpFields,
} from '../state/modules/siteRfpsFields'
import { stringToBoolean } from '../utils/string'
import { ChangeOrderSelect } from './Project/ChangeOrderSelect'
import { useEditLevelUser, useOnEditLevelChanged } from '../state/modules/auth'

const forceReadOnly = stringToBoolean(
  process.env.REACT_APP_PROJECTS_READONLY || 'false'
)

export const Projects: FunctionComponent = () => {
  const query = useQuery()

  const {
    payload: fields,
    status: fieldsStatus,
    httpErrors: fieldsHTTPErrors,
  } = useSiteRfpFields()

  const loadFields = useLoadSiteRfpFields()

  const user = useEditLevelUser()

  useOnEditLevelChanged(loadFields)

  useCallOnIDTokenGranted(async () => {
    if (fieldsStatus === RequestStatus.IDLE) {
      await loadFields()
    }
  }, [fieldsStatus])

  const [changeOrderId, setChangeOrderId] = useState<
    IChangeOrder['_id'] | null
  >(() => query.get('changeOrder'))

  useSyncQuery(
    {
      changeOrder: changeOrderId,
    },
    {
      exact: !!changeOrderId,
    }
  )

  const handleCloseChangeOrder = () => setChangeOrderId(null)

  const loadingDisplay = fieldsStatus === RequestStatus.LOADING && (
    <ComponentLoader />
  )

  let content: ReactNode

  const changeOrderSelect = (
    <ChangeOrderSelect
      selectedChangeOrderId={changeOrderId || undefined}
      onChangeOrderSelect={setChangeOrderId}
    />
  )

  if (
    !loadingDisplay &&
    fieldsStatus !== RequestStatus.LOADING &&
    fields?.length
  ) {
    if (changeOrderId) {
      content = (
        <ChangeOrderTable
          changeOrderId={changeOrderId}
          onClose={handleCloseChangeOrder}
          actionButtons={changeOrderSelect}
        />
      )
    } else {
      content = (
        <ProjectTable
          fields={fields}
          user={user}
          actionButtons={
            <>
              {changeOrderSelect}
              <FieldAssignmentEditorSettings>
                Configure
              </FieldAssignmentEditorSettings>
            </>
          }
          onOpenChangeOrder={setChangeOrderId}
          readOnly={forceReadOnly}
        />
      )
    }
  }

  return (
    <>
      <div className="py-2" style={{ margin: '0 8.33333%' }}>
        <ErrorsAlertsDisplay errors={fieldsHTTPErrors} />
        {loadingDisplay}
      </div>
      {content}
    </>
  )
}

import React, { CSSProperties } from 'react'

export interface IReactParentPropTypes {
  children?: React.ReactNode
}

export interface IAction {
  type: string
  payload?: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

export enum RequestStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum Variant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  INFO = 'info',
  LIGHT = 'light',
  DARK = 'dark',
  OUTLINE = 'outline',
}

export interface IStyledComponentPropTypes {
  className?: string
  style?: CSSProperties
}

export interface IAPIErrorFormat {
  message: string
  errors: []
  status: number
}

export interface IFile extends File {
  fileName: string
  path: string
}

export const nameof = <T>(name: keyof T) => name

export class ErrorWithInfo extends Error {
  status?: number
  constructor({ message, status }: { message?: string; status?: number }) {
    super(message)
    this.status = status || 400
  }
}

export enum FieldType {
  CURRENCY = 'currency',
  TIME_STAMP = 'timestamp',
  PERCENT = 'percent',
  TEXT = 'text',
  NUMBER = 'number',
  CHECKBOX = 'checkbox',
  NUMERIC = 'numeric',
  MULTCHOICE = 'text-multiple-choice',
  DATE = 'date',
  USER = 'user',
  BOOLEAN = 'boolean',
}

export interface IField {
  label: string
  propName: string
  fieldType: FieldType
  filterType?: string
  readOnly?: boolean

  meta?: {
    isMappedEdit?: boolean
  }

  properties?: IFieldProps
}

interface IFieldProps {
  choices?: string[]
  units?: string
  decimalPlaces?: number
  currencyFormat?: 'left' | 'middle' | 'right'
  currencySymbol?: string
  commaStart?: 3 | 4
  zeroMeansEmpty?: boolean
  numberFormat?: number
  multiline?: boolean
}

export interface PaginatedQuery {
  page?: number
  pageSize?: number
}

export type PaginatedRecords<T, Q extends PaginatedQuery> = {
  hasNext: boolean
  records: T[]
  totalRecord: number
  query: Q
}

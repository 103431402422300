import numeral from 'numeral'
import { FieldType } from '../common/types'
import { ITableCellProps } from './BootstrapTable/tableProps'
import { isNil } from 'deep-cuts'

export function applyCellFormat(
  props: ITableCellProps,
  type?: string
): ITableCellProps {
  const value = props.value as string | number | Date
  switch (type) {
    case FieldType.CURRENCY:
      return {
        ...props,
        value: numeral(value).format('$0,00'),
      }
    case FieldType.PERCENT:
      return {
        ...props,
        value: numeral(value).format('0%'),
      }
    case FieldType.TIME_STAMP:
      return {
        ...props,
        value: new Date(value).toLocaleDateString(),
      }
    default:
      return { ...props }
  }
}

export function joinArrayValues<T>(value: T | T[]): string | T {
  return Array.isArray(value) ? value.join(', ') : value
}

export function displayBlankForNil<T>(value: T): string | T {
  return isNil(value) || ['null', 'undefined'].includes(String(value))
    ? ''
    : value
}

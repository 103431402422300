import { Reducer, useEffect } from 'react'

import { IAction, Variant } from '../../common/types'
import { useDispatch, useState } from './common'

// Slice_name
export const sliceName = 'notifications'

// Types & Interfaces
export interface INotification {
  id: string
  title: string
  message: string
  variant?: Variant
  metadata?: unknown
  timeoutLimit?: number
}

export type INotifications = INotification[]

// Initial state
// eslint-disable-next-line no-var
export var initialState: INotifications = []

// Actions
const NOTIFICATION_DISMISSED = `${sliceName}/NOTIFICATION_DISMISSED`
const NOTIFICATION_ISSUED = `${sliceName}/NOTIFICATION_ISSUED`

// Slice reducers
const reducer: Reducer<INotifications, IAction> = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case NOTIFICATION_ISSUED:
      return [...state, payload as INotification]
    case NOTIFICATION_DISMISSED:
      return state.filter(({ id }) => id !== payload)
    default:
      return state
  }
}

// Action creators
const notificationDismissed = (id: string) => {
  return { type: NOTIFICATION_DISMISSED, payload: id }
}

const notificationIssued = (notification: INotification) => {
  return { type: NOTIFICATION_ISSUED, payload: notification }
}

// API Selectors Hooks
const generateID = () => new Date().toISOString()
interface INotificationRequest extends Partial<INotification> {
  title: string
  message: string
}

export const useNotifications = () => {
  const dispatch = useDispatch()

  function dismiss(id: string) {
    dispatch(notificationDismissed(id))
  }

  function send(notificationRequest: INotificationRequest) {
    const id = generateID()
    dispatch(
      notificationIssued({
        id,
        ...notificationRequest,
      })
    )
  }

  return {
    dismiss,
    send,
    data: useState()[sliceName],
  }
}

export const useTimedNotifications = (defaultTimeoutlimit = 9000): void => {
  const { data: notifications, dismiss: dismissNotification } =
    useNotifications()

  useEffect(() => {
    if (notifications) {
      notifications.forEach(({ id, timeoutLimit }) => {
        setTimeout(() => {
          dismissNotification(id)
        }, timeoutLimit || defaultTimeoutlimit)
      })
    }
  }, [notifications]) // eslint-disable-line react-hooks/exhaustive-deps
}

export default reducer

import { FunctionComponent } from 'react'
import {
  FileEarmark,
  FileEarmarkCheckFill,
  ExclamationCircle,
  CheckCircle,
  Upload,
  Icon as BootstrapIcon,
  IconProps as BootstrapIconProps,
} from 'react-bootstrap-icons'

export const Icon: FunctionComponent<IconProps> = ({
  iconName,
  ...props
}: IconProps) => {
  const NamedIcon = icons[iconName]
  return <NamedIcon {...props} />
}

interface IconProps extends BootstrapIconProps {
  iconName: string
}

const icons: { [key: string]: BootstrapIcon } = {
  FileEarmark,
  FileEarmarkCheckFill,
  ExclamationCircle,
  CheckCircle,
  Upload,
}

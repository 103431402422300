/* eslint-disable max-lines */
import { useCallback, useMemo, useState } from 'react'
import {
  has,
  isObject,
  isString,
  isUndefined,
  omitBy,
  pick,
  uniq,
} from 'lodash'
import { Button, Dropdown, Form } from 'react-bootstrap'
import { ChevronUp, ChevronDown } from 'react-bootstrap-icons'
import { IFieldAssignment, IFieldAssignmentRole } from './IFieldAssignment'
import {
  IFieldAssignmentChange,
  IFieldAssignmentTables,
} from './useFieldAssignments'
import { TableSelect } from './TableSelect'
import { IField } from '../../common/types'
import { SvelteJSONEditor } from '../../utils/SvelteJsonEditor'
import {
  filterTypes,
  fieldTypes,
  getRoleAccessLevel,
  accessLevelTitle,
  detectFieldType,
  AssignmentFieldType,
} from './List'
import { FieldAssignmentHelp } from './Help'
import { TextButton } from '../common/TextButton'
import {
  inheritProperties,
  propertiesTemplateByType,
  propertiesTemplateCommon,
  normalize,
  humanize,
  schema,
  schemaDefinitions,
} from './properties'

interface RowProps {
  assignment: IFieldAssignmentChange
  showRoles: readonly IFieldAssignmentRole[]
  tables: IFieldAssignmentTables
  remove?: (removeRecordId: IFieldAssignmentChange['recordId']) => void
  change?: (patch: IFieldAssignmentChange) => void
  reset?: () => void
  transparent?: boolean
  isDirty?: boolean
  isDirtyField?: (field: keyof IFieldAssignment) => boolean
}

export const Row = (props: RowProps) => {
  const {
    assignment,
    showRoles,
    tables,
    remove,
    change,
    reset,
    transparent,
    isDirty,
    isDirtyField,
  } = props

  const {
    recordId,
    fieldDisplayName,
    filterType,
    fieldType: fieldTypeAssigned,
    turnOff,
    tableId,
    fieldId,
    mappedTableId,
    mappedFieldId,
    _isRemoved,
    _isNew,
    properties: propertiesRaw,
  } = assignment

  const table = tableId ? tables[tableId] : undefined
  const { fields, quickbaseLink } = table || {}

  const field = useMemo(
    () => (fields ? fields?.find(({ id }) => id === fieldId) : undefined),
    [fields, fieldId]
  )

  const sourceFieldTypeRaw = field?.fieldType
  const sourceFieldType =
    sourceFieldTypeRaw && detectFieldType(sourceFieldTypeRaw)
  const fieldType = fieldTypeAssigned || sourceFieldType

  const { properties: fieldProperties } = field || {}

  const properties = useMemo(() => {
    if (!propertiesRaw) return undefined
    if (isObject(propertiesRaw)) return propertiesRaw
    if (isString(propertiesRaw)) {
      try {
        return JSON.parse(propertiesRaw) as IField['properties']
      } catch (error) {
        console.error(error)
        return undefined
      }
    }
  }, [propertiesRaw])

  const fieldPropertiesTransformed = useMemo(
    () => ({
      multiline: sourceFieldTypeRaw === 'text-multi-line' ? true : undefined,
    }),
    [sourceFieldTypeRaw]
  )

  const fieldPropertiesInherited = useMemo(
    () => ({
      ...omitBy(fieldPropertiesTransformed, isUndefined),
      ...pick(fieldProperties, inheritProperties),
    }),
    [fieldPropertiesTransformed, fieldProperties]
  )

  const propertiesTemplate = useMemo(
    () =>
      propertiesTemplateByType[
        (
          fieldType || 'default'
        ).toLowerCase() as keyof typeof propertiesTemplateByType
      ] || propertiesTemplateCommon,
    [fieldType]
  )

  const propertiesMerged = useMemo(
    () => ({
      ...propertiesTemplate,
      ...fieldPropertiesInherited,
      ...properties,
    }),
    [propertiesTemplate, fieldPropertiesInherited, properties]
  )

  const [expand, setExpand] = useState(false)
  const [showHelp, setShowHelp] = useState(false)

  const expandToggleButton = (
    <Button onClick={() => setExpand(!expand)}>
      {expand ? <ChevronUp /> : <ChevronDown />}
      Options
    </Button>
  )

  const propertiesPatchHandle = useMemo(
    () =>
      change
        ? (propertiesPatch: { [key: string]: unknown }) => {
            return change({
              recordId,
              properties: JSON.stringify(
                { ...properties, ...normalize(propertiesPatch) },
                undefined,
                4
              ),
            })
          }
        : undefined,
    [change, recordId, properties]
  )

  const getClassName = useCallback(
    (path: string[]) => {
      if (path.length !== 1) return undefined
      if (has(properties, path)) return 'JSONEditor-prop-custom'
      if (has(fieldPropertiesInherited, path))
        return 'JSONEditor-prop-inherited'
      return 'JSONEditor-prop-template'
    },
    [properties, fieldPropertiesInherited]
  )

  const propertiesHumanized = useMemo(
    () => humanize(propertiesMerged),
    [propertiesMerged]
  )

  const hasInheritProperties =
    Object.keys(fieldPropertiesInherited || {}).length > 0

  const removeButton = remove && !_isRemoved && (
    <Button onClick={() => remove(recordId)}>╳ Remove field</Button>
  )

  const resetButton = isDirty && (
    <Button onClick={reset} title="Reset" className="dirty">
      ↶ Reset changes
    </Button>
  )

  const quickbaseFieldLink =
    quickbaseLink && fieldId && `${quickbaseLink}/?a=mf&fid=${fieldId}&chain=1`

  const expandContent = expand && (
    <tr>
      <td colSpan={10}>
        <div style={{ maxWidth: 700 }}>
          <SvelteJSONEditor
            value={propertiesHumanized}
            schema={schema}
            schemaDefinitions={schemaDefinitions}
            onValuePatch={propertiesPatchHandle}
            onClassName={getClassName}
            mainMenuBar={false}
            navigationBar={false}
          />
          <div style={{ textAlign: 'right', fontSize: 'small' }}>
            {hasInheritProperties && quickbaseFieldLink && (
              <>
                <span className="JSONEditor-prop-inherited-text">
                  These properties
                </span>{' '}
                are inherited from{' '}
                <a href={quickbaseFieldLink} target="_blank" rel="noreferrer">
                  Quickbase
                </a>
                ,
                <span className="JSONEditor-prop-custom-text mx-1">
                  these are custom
                </span>
              </>
            )}
            &nbsp; | &nbsp;
            <TextButton onClick={() => setShowHelp(!showHelp)}>
              {showHelp ? 'Hide Help' : 'Help'}
            </TextButton>
          </div>
          {showHelp && (
            <div style={{ textAlign: 'left' }} className="text-left m-2">
              <FieldAssignmentHelp />
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 10,
          }}
        >
          {resetButton}
          {removeButton}
        </div>
      </td>
    </tr>
  )

  const onChangeAccessLevel = useCallback(
    (role: IFieldAssignmentRole) => (newLevel: string | null) => {
      if (!newLevel) return undefined

      const { recordId, whoEdits = [], whoViews = [] } = assignment

      if (newLevel === 'no-access')
        change?.({
          recordId,
          whoViews: whoViews.filter((r) => r !== role),
          whoEdits: whoEdits.filter((r) => r !== role),
        })
      else if (newLevel === 'no-write')
        change?.({
          recordId,
          whoViews: uniq([...whoViews, role]),
          whoEdits: whoEdits.filter((r) => r !== role),
        })
      else if (newLevel === 'write')
        change?.({
          recordId,
          whoViews: uniq([...whoViews, role]),
          whoEdits: uniq([...whoEdits, role]),
        })
      else throw new Error(`Unknown level ${newLevel}`)
    },
    [assignment, change]
  )

  const withMapping = !!(mappedFieldId && mappedTableId)

  const turnOnCheckbox = (
    <input
      type="checkbox"
      checked={!turnOff}
      onChange={() => change?.({ recordId, turnOff: !turnOff })}
      title="Enabled"
      style={{ width: 20, height: 20 }}
      className={isDirtyField?.('turnOff') ? 'dirty' : ''}
    />
  )

  return (
    <>
      <tr
        style={{ padding: 6, opacity: transparent ? 0.6 : 1 }}
        className={[
          _isRemoved && '_isRemoved',
          _isNew && '_isNew',
          turnOff && 'turnOff',
          expand && 'expand',
        ]
          .filter(Boolean)
          .join(' ')}
      >
        <td>
          <div
            style={{
              display: 'flex',
              gap: 6,
              alignItems: 'flex-start',
              position: 'relative',
            }}
          >
            <Form.Control
              value={fieldDisplayName || ''}
              onChange={(e) =>
                change?.({
                  recordId,
                  fieldDisplayName: e.target?.value || '',
                })
              }
              style={{ fontWeight: 'bold' }}
              className={isDirtyField?.('fieldDisplayName') ? 'dirty' : ''}
            />
            {_isNew && (
              <div
                style={{
                  backgroundColor: 'limegreen',
                  color: 'white',
                  fontSize: 'small',
                  fontWeight: 'bold',
                  padding: 2,
                }}
              >
                NEW
              </div>
            )}
          </div>
          <div style={{ display: 'flex', gap: 6 }}>
            <Form.Control
              as="select"
              value={filterType || ''}
              title="Filter type"
              onChange={(e) => {
                const filterType = e.target.value
                change?.({
                  recordId,
                  filterType,
                })
              }}
              className={isDirtyField?.('filterType') ? 'dirty' : ''}
              style={
                !!filterType && !filterTypes.includes(filterType)
                  ? { border: '1px solid darkred' }
                  : {}
              }
            >
              <option value="" disabled></option>
              {filterTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
              {!!filterType && !filterTypes.includes(filterType) && (
                <option key={filterType} value={filterType} disabled>
                  UNKNOWN: {filterType}
                </option>
              )}
            </Form.Control>

            <Form.Control
              as="select"
              value={fieldTypeAssigned || ''}
              title="Field type"
              onChange={(e) =>
                change?.({
                  recordId,
                  fieldType: e.target?.value || '',
                })
              }
              className={isDirtyField?.('fieldType') ? 'dirty' : ''}
              style={
                !!fieldTypeAssigned &&
                !fieldTypes.includes(fieldTypeAssigned as AssignmentFieldType)
                  ? { border: '1px solid darkred' }
                  : {}
              }
            >
              <option value="" style={{ fontStyle: 'italic' }}>
                Auto {sourceFieldType && `(${sourceFieldType})`}
              </option>
              {fieldTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
              {!!fieldTypeAssigned &&
                !fieldTypes.includes(
                  fieldTypeAssigned as AssignmentFieldType
                ) && (
                  <option
                    key={fieldTypeAssigned}
                    value={fieldTypeAssigned}
                    disabled
                  >
                    UNKNOWN: {fieldTypeAssigned}
                  </option>
                )}
            </Form.Control>
          </div>
        </td>

        <td style={{ width: '12px' }} />

        <td>
          <TableSelect
            tables={tables}
            value={[tableId, fieldId]}
            onChange={([tableId, fieldId]) =>
              change?.({
                recordId,
                tableId,
                fieldId,
              })
            }
            isDirtyField={(field) =>
              !!isDirtyField?.(field === 'table' ? 'tableId' : 'fieldId')
            }
          />
        </td>

        <td style={{ width: '12px' }} />

        <td>
          <TableSelect
            tables={tables}
            value={[mappedTableId, mappedFieldId]}
            onChange={([mappedTableId, mappedFieldId]) =>
              change?.({
                recordId,
                mappedTableId,
                mappedFieldId,
              })
            }
            isDirtyField={(field) =>
              !!isDirtyField?.(
                field === 'table' ? 'mappedTableId' : 'mappedFieldId'
              )
            }
          />
        </td>

        <td style={{ width: '12px' }} />

        {showRoles.map((role) => {
          const accessLevel = getRoleAccessLevel(assignment)(role)

          return (
            <td
              key={role}
              className={[
                accessLevel,
                (isDirtyField?.('whoEdits') || isDirtyField?.('whoViews')) &&
                  'dirty',
              ]
                .filter(Boolean)
                .join(' ')}
              style={{ position: 'relative' }}
            >
              <Dropdown
                onSelect={onChangeAccessLevel(role)}
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'transparent',
                  borderTop: 0,
                  borderBottom: 0,
                  borderRight: 0,
                }}
              >
                <Dropdown.Toggle
                  variant="default"
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'transparent',
                    borderTop: 0,
                    borderBottom: 0,
                    borderRight: 0,
                  }}
                >
                  {accessLevelTitle[accessLevel]}
                  {field?.mode === 'formula' &&
                    accessLevel === 'read-write' && (
                      <div
                        className="alert-danger"
                        style={{ fontSize: 'small', whiteSpace: 'normal' }}
                        title="Field is read-only because it uses formula"
                      >
                        Read-only (formula)
                      </div>
                    )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {!['BBE', 'Admin'].includes(role) && (
                    <Dropdown.Item eventKey="no-access">
                      {accessLevelTitle['no-access']}
                    </Dropdown.Item>
                  )}
                  {!['Admin'].includes(role) && (
                    <Dropdown.Item eventKey="no-write">
                      {
                        accessLevelTitle[
                          withMapping ? 'read-write-mapped' : 'read-only'
                        ]
                      }
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item eventKey="write">
                    {accessLevelTitle['read-write']}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          )
        })}

        <td style={{ paddingTop: 0, paddingBottom: 0 }}>
          <div
            style={{
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center',
              gap: 6,
            }}
          >
            {turnOnCheckbox}
            {expandToggleButton}
          </div>
        </td>
      </tr>
      {expandContent}
    </>
  )
}

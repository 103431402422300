import React, { useContext, useEffect, useState } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import {
  IUser,
  useLoadUsers,
  useDismissUsersHTTPErrors,
  useUsers,
  useResendUserInvitation,
} from '../../state/modules/users'
import { useCallOnceOnIDTokenGranted } from '../../state/modules/common'
import { RequestStatus, IStyledComponentPropTypes } from '../../common/types'
import {
  mergeAndFilterChanges,
  validateChanges,
  applyChanges,
} from './helpers/usersChanges'
import { displayAlerts } from '../helpers/displayAlerts'
import InviteUserModal from '../invite-user/InviteUserModal'
import { store } from '../../state/store'
import { ManageUsersConfirmationAction } from './types'
import {
  useImpersonatingUserOrUser,
  isAdminOrBBEUserOrSuperAdmin,
  AuthRole,
  isBBEUserOrSuperAdmin,
} from '../../state/modules/auth'
import { useActionConfirmationResponse } from './helpers/manageActions'
import Buttons from './Buttons'
import { ImpersonationInputs } from './ImpersonationInputs'
import { BootstrapTable } from '../BootstrapTable/BootstrapTable'
import { getManageUserTableSettings } from './helpers/manageUserTableSettings'
import {
  ITableCellChange,
  ITableValidationError,
} from '../BootstrapTable/tableTypes'

function UsersTableCmp({ className, style }: IStyledComponentPropTypes) {
  const context = useContext(store)

  const { path } = useRouteMatch()

  const user = useImpersonatingUserOrUser()

  const resendUserInvitation = useResendUserInvitation()

  const { payload: users, status: usersStatus, httpErrors } = useUsers()
  const dismissHTTPErrors = useDismissUsersHTTPErrors()
  const loadUsers = useLoadUsers()
  useCallOnceOnIDTokenGranted(loadUsers)

  const [changes, setChanges] = useState<ITableCellChange[]>([])
  const [dataWithChanges, setDataWithChanges] = useState<IUser[]>([])
  const [validationErrors, setValidationErrors] = useState<
    ITableValidationError[]
  >([])
  // this state is used to trigger reset on child component
  const [resetCount, setResetCount] = useState<number>(0)

  const [saveBtnActive, setSaveBtnActive] = useState(false)
  const [resetBtnActive, setResetBtnActive] = useState(false)

  // Hook to listen for global users state changes
  useEffect(() => {
    setChanges([]) // Reset changes
    setValidationErrors([]) // Reset validation errors
  }, [users])

  // Hook to listen for and apply changes
  useEffect(() => {
    const updatedUsers = users.map((user: IUser) => {
      if (!user.role) {
        return { ...user, role: AuthRole.BBE_USER } as IUser
      } else {
        return user
      }
    })

    const data = applyChanges(updatedUsers, changes)
    setDataWithChanges(data)
  }, [users, changes])

  function handleTableChanges(
    snapshotChanges: readonly ITableCellChange[] // [row, col, oldValue, newValue],
  ) {
    if (snapshotChanges) {
      const newChanges = mergeAndFilterChanges(changes, snapshotChanges, users) // Load registered changes
      const currentValidationErrors = validateChanges(newChanges) // Validate and report validation

      setValidationErrors(currentValidationErrors) // Update validation errors
      setChanges(newChanges) // Save changes
      // Disimss users https errors

      if (httpErrors) {
        dismissHTTPErrors()
      }
    }
  }

  // Hook to update buttons state
  useEffect(() => {
    // Disable when loading or when no changes found
    if (changes.length > 0 && usersStatus !== RequestStatus.LOADING) {
      setResetBtnActive(true)
      // Disable save button when data is not valid
      if (validationErrors.length === 0) {
        setSaveBtnActive(true)
      } else {
        setSaveBtnActive(false)
      }
    } else {
      setSaveBtnActive(false)
      setResetBtnActive(false)
    }
  }, [changes, usersStatus, validationErrors])

  const { sendConfirmation, setConfirmationReceiptID } =
    useActionConfirmationResponse({ changes })

  function handleTableReset() {
    setChanges([]) // Reset changes if global users state changed
    setValidationErrors([]) // Reset validation errors
    dismissHTTPErrors() // Dismiss http errors
    setResetCount(resetCount + 1)
  }

  function handleTableSave() {
    // Send a confirmation request when user attempts to save updates
    const receiptID = sendConfirmation({
      title: 'Confirmation',
      message: 'Are you sure you want to save the changes?',
      metadata: {
        action: ManageUsersConfirmationAction.SAVE_UPDATES,
      },
    })
    setConfirmationReceiptID(receiptID)
  }

  const { tableName, columns, data, rowKeyPropName } =
    getManageUserTableSettings({
      user,
      users,
      dataWithChanges,
      usersStatus,
      sendConfirmation,
      setConfirmationReceiptID,
      resendUserInvitation,
      context,
    })

  const userObjs = data.map((user) =>
    Object.keys(user).reduce(
      (userObj, key) => ({
        ...userObj,
        [key]: user[key as keyof IUser],
      }),
      {} as { [key: string]: unknown }
    )
  )

  return (
    <div className={`${className}`} style={style}>
      {displayAlerts(
        httpErrors && httpErrors.errors && httpErrors.errors.length !== 0
          ? httpErrors.errors // Display httpErrors
          : [httpErrors?.message] // Fall back to error.message if no errors[] provided
      )}
      {user && isBBEUserOrSuperAdmin(user) ? (
        <div className="mb-1 ml-auto border-dotted-primary">
          <ImpersonationInputs />
        </div>
      ) : (
        ''
      )}

      <BootstrapTable
        key={resetCount}
        rowKeyPropName={rowKeyPropName}
        tableName={tableName}
        columns={columns}
        data={userObjs}
        handleTableChanges={
          usersStatus !== RequestStatus.LOADING ? handleTableChanges : undefined
        }
        loading={usersStatus === RequestStatus.LOADING}
        validationErrors={validationErrors}
      />
      <div className="btn-panel pt-1 rounded shadow-sm">
        <Buttons
          saveActive={saveBtnActive}
          resetActive={resetBtnActive}
          onTableSave={handleTableSave}
          onTableReset={handleTableReset}
        />
      </div>

      {user && isAdminOrBBEUserOrSuperAdmin(user) ? (
        <Route path={`${path}/invite`}>
          <InviteUserModal />
        </Route>
      ) : (
        ''
      )}
    </div>
  )
}

export const UsersTable = styled(UsersTableCmp)`
  position: relative;
  display: flex;
  flex-direction: column;

  .btn-panel {
    display: inline-flex;
    align-items: center;
    position: sticky;
    top: 0.25rem;
    right: 0.25rem;
    background: white;
    align-self: flex-end;
  }

  .btn-group {
    display: flex;
  }

  .btn-group :nth-child(1) {
    flex: 0 1 66%;
    margin-right: 1%;
  }

  .btn-group :nth-child(2) {
    flex: 0 1 33%;
  }

  .btn-group :nth-child(3) {
    flex: 0 1 15%;
    margin-left: 1%;
  }
`

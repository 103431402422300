import { useLoadEntities } from './entities'
import { useCallOnceOnIDTokenGranted } from './common'
import { isBBEUserOrSuperAdmin, useAuth } from './auth'
import { useLoadImpersonations } from './impersonations'
import { isDefined } from '../../utils/typeGuards'
import { useLoadChannelPartners } from './channelPartners'

export const usePreloadForBBEUsersOrSuperAdmins = () => {
  const { user } = useAuth()
  const loadChannelPartners = useLoadChannelPartners()
  const loadEntities = useLoadEntities()
  const loadImpersonations = useLoadImpersonations()
  useCallOnceOnIDTokenGranted(async () => {
    if (isDefined(user) && isBBEUserOrSuperAdmin(user)) {
      await loadImpersonations()
      await loadChannelPartners()
      await loadEntities()
    }
  })
}

import { Form } from 'react-bootstrap'

interface ITextAreaInputPropTypes {
  disabled: boolean
  defaultValue?: string
  onChange: (value: string) => void
}

export default function TextAreaInput({
  disabled,
  defaultValue,
  onChange,
}: ITextAreaInputPropTypes) {
  function handleTextAreaChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    onChange(e.target.value)
  }

  return (
    <Form.Group>
      <Form.Label htmlFor="invite-user-modal-text">Text</Form.Label>
      <Form.Control
        as="textarea"
        id="invite-user-modal-text"
        name="invitation text"
        rows={3}
        onChange={handleTextAreaChange}
        defaultValue={defaultValue}
        disabled={disabled}
      />
    </Form.Group>
  )
}

import styled from 'styled-components'
import { FunctionComponent } from 'react'

export const MetricsContainer: FunctionComponent<MetricsContainerProps> = ({
  required,
  reviewed,
}: MetricsContainerProps) => {
  return (
    <StyledMetricsContainer>
      <div>
        {required.numerator} out of {required.denominator} required
      </div>
      <div>
        {reviewed.numerator} out of {reviewed.denominator} reviewed
      </div>
    </StyledMetricsContainer>
  )
}

export interface MetricsContainerProps {
  required: {
    numerator: number
    denominator: number
  }
  reviewed: {
    numerator: number
    denominator: number
  }
}

const StyledMetricsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  text-align: right;
`

import { DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap'
import { FunctionComponent, useMemo } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import { IDropdownProps } from './tableProps'

const NoMarginLabel = styled.label`
  width: 100%;
  margin-bottom: 0;
`

export const DropdownComponent: FunctionComponent<IDropdownProps> = (props) => {
  const {
    rowKey,
    propName,
    value,
    invalid,
    dirty,
    handleTableChanges,
    columnStyle,
    tdProps,
    options,
  } = props

  const handleSelect = useMemo(
    () =>
      handleTableChanges
        ? (newValue: typeof value) =>
            handleTableChanges([
              { rowKey, propName, oldValue: value, newValue },
            ])
        : undefined,
    [rowKey, propName, value, handleTableChanges]
  )

  const optionsEls = options.map((option: string, index: number) => (
    <Dropdown.Item
      style={columnStyle}
      eventKey={option}
      key={index}
      disabled={option === value}
    >
      {option}
    </Dropdown.Item>
  ))

  return (
    <td
      {...tdProps}
      className={classnames(
        invalid && 'invalidCell',
        dirty && 'dirty-cell',
        tdProps?.className
      )}
      style={{
        ...columnStyle,
        ...tdProps?.style,
      }}
    >
      <NoMarginLabel>
        <DropdownButton
          as={ButtonGroup}
          value={value}
          onSelect={handleSelect}
          title={value}
          readOnly={!handleSelect}
          className={`w-100`}
        >
          {optionsEls}
        </DropdownButton>
      </NoMarginLabel>
    </td>
  )
}

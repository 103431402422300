import styled from 'styled-components'
import { FunctionComponent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Accordion } from 'react-bootstrap'
import { Checklist } from '@black-bear-energy/black-bear-energy-common'
import { useApiWithIDTokenGranted } from '../../state/modules/common'
import { ChecklistCard } from './ChecklistCard'

export const SiteRFPDetails: FunctionComponent<SiteRFPDetailsProps> = ({
  id,
  name,
}: SiteRFPDetailsProps) => {
  const [checklists, setChecklists] = useState<Checklist[]>([])
  const api = useApiWithIDTokenGranted()

  useEffect(() => {
    const fetchChecklist = async () => {
      if (!api) {
        return
      }

      const response = await api.get<Checklist[]>(`/site-rfps/${id}/checklists`)
      setChecklists(response.data)
    }

    void fetchChecklist()
  }, [id, api])

  return (
    <div>
      <StyledTitleRow>
        <div>{name}</div>
        <Link to="/document-exchange">Back</Link>
      </StyledTitleRow>
      <Accordion>
        {checklists.map((checklist) => (
          <ChecklistCard key={checklist.order} checklist={checklist} />
        ))}
      </Accordion>
    </div>
  )
}

export interface SiteRFPDetailsProps {
  id: number
  name: string
}

const StyledTitleRow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

import { FunctionComponent, useCallback } from 'react'
import { InputNumber } from '../../Input/Number'
import {
  FilterProps,
  isFilterPropsType,
  ParseType,
  StringifyType,
} from './Props'

export const filterType = 'Numeric Range' as const

export type Value = readonly [from: number | undefined, to: number | undefined]

export const parseNumericRange: ParseType<Value> = (valueRes) => {
  if (!valueRes) return undefined
  if (Array.isArray(valueRes)) return valueRes as Value

  if (typeof valueRes !== 'string') return undefined
  if (!/^(-?\d+(\.\d+)?)?,(-?\d+(\.\d+))?$/.test(valueRes)) return undefined

  return valueRes
    .split(',')
    .map((s) =>
      s !== '' && s !== '' ? parseFloat(s) : undefined
    ) as unknown as Value
}

export const stringifyNumericRange: StringifyType<Value> = (value) =>
  value.map((v) => (v === undefined ? '' : v.toString())).join(',')

export type FilterPropsNumericRange = FilterProps<typeof filterType, Value>

export const isFilterPropsNumericRange =
  isFilterPropsType<FilterPropsNumericRange>(filterType)

export const FilterNumericRange: FunctionComponent<FilterPropsNumericRange> = (
  props
) => {
  const {
    value: [from, to] = [],
    properties: { decimalPlaces } = {},
    onChange,
    onReset,
  } = props

  const handleChangeFrom = useCallback(
    (newFrom: number) => onChange?.([newFrom, to]),
    [onChange, to]
  )

  const handleResetFrom = useCallback(() => {
    if (to === undefined && onReset) onReset()
    else onChange?.([undefined, to])
  }, [onReset, onChange, to])

  const fromSelect = (
    <InputNumber
      value={from}
      decimalPlaces={decimalPlaces}
      onChange={handleChangeFrom}
      onReset={handleResetFrom}
      placeholder="from"
    />
  )

  const handleChangeTo = useCallback(
    (newTo: number) => onChange?.([from, newTo]),
    [onChange, from]
  )

  const handleResetTo = useCallback(() => {
    if (from === undefined && onReset) onReset()
    else onChange?.([from, undefined])
  }, [onReset, onChange, from])

  const toSelect = (
    <InputNumber
      value={to}
      decimalPlaces={decimalPlaces}
      onChange={handleChangeTo}
      onReset={handleResetTo}
      placeholder="to"
    />
  )

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
      }}
    >
      {fromSelect}&mdash;{toSelect}
    </div>
  )
}

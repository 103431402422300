import React, {
  FunctionComponent,
  useMemo,
  useState,
  KeyboardEvent,
} from 'react'
import { AxiosError } from 'axios'
import moment from 'moment'
import { Button, Form, Modal } from 'react-bootstrap'
import { Variant, RequestStatus, IField } from '../../common/types'
import {
  IChangeOrder,
  useChangeOrders,
  useSubmitChangeOrder,
} from '../../state/modules/changeOrders'
import { useNotifications } from '../../state/modules/notifications'
import { ISiteRfp, useSiteRfps } from '../../state/modules/siteRfps'
import { BootstrapTableChangesMap } from '../BootstrapTable/RecordChangesHook'
import { ComponentLoader } from '../common/Loaders'

interface Props {
  fields: IField[]
  changesMap?: BootstrapTableChangesMap<ISiteRfp, 'recordId'>
  changedRecordsSnapshot: readonly ISiteRfp[]
  resetChanges?: () => void
  disallowSubmit?: boolean
  onOpen?: (_id: string) => void
  reload?: () => void
}

export const ProjectChangesControlPanel: FunctionComponent<Props> = (props) => {
  const {
    fields,
    changesMap,
    changedRecordsSnapshot,
    resetChanges,
    disallowSubmit,
    onOpen,
    reload,
  } = props

  const { status: siteRfpsStatus } = useSiteRfps()

  const { status: changesOrdersStatus } = useChangeOrders()

  const submitChangeOrder = useSubmitChangeOrder()

  const [submitting, setSubmitting] = useState(false)
  const [results, setResults] = useState<IChangeOrder>()
  const { send: sendNotification } = useNotifications()
  const [showResetModal, setShowResetModal] = useState(false)
  const [reason, setReason] = useState<IChangeOrder['reason']>()

  const mappedEditPropNames = useMemo(
    () =>
      fields
        .filter(({ meta: { isMappedEdit } = {} }) => !!isMappedEdit)
        .map(({ propName }) => propName),
    [fields]
  )
  const includesMappedEditChanges = useMemo(() => {
    if (!changesMap) return false
    if (mappedEditPropNames.length === 0) return false
    return Array.from(changesMap.values()).some((obj) =>
      Object.keys(obj).some((propName) =>
        mappedEditPropNames.includes(propName)
      )
    )
  }, [changesMap, mappedEditPropNames])

  const changesCount = changesMap?.size || 0
  const hasNoChanges = changesCount === 0

  const title = useMemo(
    () => moment().format('LLLL'),
    // Updating title when changes update
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changesMap]
  )

  const resetModal = (
    <Modal
      onHide={() => setShowResetModal(false)}
      show={showResetModal && !hasNoChanges}
      centered
    >
      <Modal.Header>
        <h4>Reset changes</h4>
      </Modal.Header>
      <Modal.Body>You're about to reset changes. Are you sure?</Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button
          className="px-4"
          variant={Variant.LIGHT}
          onClick={() => {
            resetChanges?.()
            setShowResetModal(false)
          }}
        >
          Yes, reset!
        </Button>
        <Button onClick={() => setShowResetModal(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  )

  const readyToSubmit = !includesMappedEditChanges || !!reason

  const handleSubmit = useMemo(
    () =>
      changesMap && changesMap.size > 0 && readyToSubmit && !submitting
        ? async () => {
            setSubmitting(true)
            const snapshotRecordIds = changedRecordsSnapshot.map(
              ({ recordId }) => recordId
            )

            const snapshotsMap = new Map<ISiteRfp['recordId'], ISiteRfp>()
            for (const record of changedRecordsSnapshot) {
              snapshotsMap.set(record.recordId, record)
            }

            const patches = Array.from(changesMap.values())
            const patchedRecordsSnapshot = [...changedRecordsSnapshot]

            for (const { recordId } of patches) {
              if (!snapshotRecordIds.includes(recordId)) {
                throw new Error(
                  `Record snapshot not found for recordId ${recordId}`
                )
              }
            }

            try {
              setResults(
                await submitChangeOrder({
                  fields,
                  title,
                  reason,
                  patches,
                  patchedRecordsSnapshot,
                })
              )
              setReason(undefined)
              resetChanges?.()
            } catch (e) {
              const error = e as AxiosError | undefined
              const errorData = error?.response?.data as
                | { message: string }
                | undefined
              const axiosErrorMessage = errorData?.message
              sendNotification({
                title: 'Failed to create Change Order',
                message: [error?.message, axiosErrorMessage]
                  .filter(Boolean)
                  .join(' - '),
                variant: Variant.DANGER,
                timeoutLimit: 99999999,
              })
            }

            setSubmitting(false)
          }
        : undefined,
    [
      readyToSubmit,
      submitting,
      title,
      reason,
      changesMap,
      changedRecordsSnapshot,
      fields,
      submitChangeOrder,
      resetChanges,
      sendNotification,
    ]
  )

  const handleKeyDown = useMemo(
    () =>
      handleSubmit
        ? async (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
              event.preventDefault()
              await handleSubmit()
            }
          }
        : undefined,
    [handleSubmit]
  )

  const openResults = () => {
    onOpen?.(results!._id)
    setResults(undefined)
  }

  const closeResults = () => {
    reload?.()
    setResults(undefined)
  }

  const resultsModal = results && (
    <Modal onHide={closeResults} show={!!results} centered>
      <Modal.Header>
        <h4>Your Change Order has been submitted!</h4>
      </Modal.Header>
      <Modal.Body>
        <p>
          <b>{results.title}</b>
        </p>
        <p>Patches: {results.patches.length}</p>
        {!!onOpen && (
          <Button className="px-4" onClick={openResults}>
            Open the Change Order
          </Button>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button onClick={closeResults} variant={Variant.LIGHT}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )

  const submitModal = (
    <Modal
      onHide={() => setReason(undefined)}
      show={reason !== undefined && !hasNoChanges}
      centered
    >
      <Modal.Header>
        <h4>Submit Change Order</h4>
      </Modal.Header>
      <Modal.Body>
        <b>{title}</b>
        <p>Submission contains changes in {changesMap?.size || 0} records.</p>
        {includesMappedEditChanges && (
          <>
            <p>Some of your changes require a reason for submission.</p>
            <Form.Label htmlFor="submitChangeReportReason">Reason:</Form.Label>
            <Form.Control
              type="text"
              id="submitChangeReportReason"
              aria-describedby="reportNameDesc"
              value={reason}
              onChange={(e) => setReason(e.currentTarget.value)}
              onKeyDown={handleKeyDown}
              isValid={readyToSubmit}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        {submitting && <ComponentLoader className="loading" />}
        <Button onClick={() => setReason(undefined)} variant={Variant.LIGHT}>
          Cancel
        </Button>
        <Button
          className="px-4"
          onClick={handleSubmit}
          disabled={!readyToSubmit || submitting}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )

  return (
    <>
      <Button
        variant={Variant.PRIMARY}
        onClick={() => setReason('')}
        disabled={
          siteRfpsStatus === RequestStatus.LOADING ||
          changesOrdersStatus === RequestStatus.LOADING ||
          hasNoChanges ||
          disallowSubmit
        }
      >
        {includesMappedEditChanges ? 'Submit Change Order' : 'Save Changes'}
      </Button>
      &nbsp;
      <Button
        variant={Variant.LIGHT}
        onClick={() => setShowResetModal(true)}
        disabled={
          siteRfpsStatus === RequestStatus.LOADING ||
          changesOrdersStatus === RequestStatus.LOADING ||
          hasNoChanges
        }
      >
        Reset changes
        {changesCount > 0 && <> ({changesCount})</>}
      </Button>
      {resultsModal}
      {submitModal}
      {resetModal}
    </>
  )
}

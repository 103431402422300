import {
  Reducer,
  useEffect,
  useCallback,
  useState as useStateReact,
} from 'react'
import { useAuth0, Auth0Provider } from '@auth0/auth0-react'
import { IAction, RequestStatus, Variant } from '../../common/types'
import { useCallOnceOnIDTokenGranted, useDispatch, useState } from './common'
import { useNotifications } from './notifications'
import { isDefined } from '../../utils/typeGuards'
import {
  IImpersonatingDetails,
  useImpersonatingUser,
  useImpersonations,
  useLoadImpersonations,
} from './impersonations'
import { IUser } from './users'
import { clearBrowserStorages } from '../../utils/useLocalStorageJSON'

// Slice_name
export const sliceName = 'auth'

// Types & Interfaces
export enum AuthProvider {
  'AUTH0' = 'auth0',
  'GOOGLE_OAUTH2' = 'google-oauth2',
}

export enum AuthRole {
  NONE = '',
  ADMIN = 'Admin',
  BBE_USER = 'BBE User',
  SUPER_ADMIN = 'Super Admin',
}

export interface IAuthUser {
  id?: string
  role: AuthRole
  picture: string
  given_name?: string
  family_name?: string
  name: string
  nickname: string
  email: string
  readOnly?: boolean
  relatedChannelPartner?: number
  relatedEntity?: number
  portalUserId?: number
  impersonatingDetails?: IImpersonatingDetails
  hasProspectorPermission?: boolean
  hasNearmapPermission?: boolean
}

export interface IAuthUserWithId extends IAuthUser {
  id: string
}

export type IAuthEditLevel = 'impersonating' | 'own'

export interface IAuth {
  accessToken?: string
  idToken?: string
  user?: IAuthUserWithId
  editLevel: IAuthEditLevel
}

// Initial state
// eslint-disable-next-line no-var
export var initialState: IAuth = {
  accessToken: undefined,
  idToken: undefined,
  user: undefined,
  editLevel: 'impersonating',
}

// Actions
const ACCESS_TOKEN_FETCHED = `${sliceName}/ACCESS_TOKEN_FETCHED`
const ID_TOKEN_FETCHED = `${sliceName}/ID_TOKEN_FETCHED`
const USER_FETCHED = `${sliceName}/USER_FETCHED`
const SET_EDIT_LEVEL = `${sliceName}/SET_EDIT_LEVEL`

// Slice reducers
const reducer: Reducer<IAuth, IAction> = (
  state = initialState,
  { type, payload }
): IAuth => {
  switch (type) {
    case ACCESS_TOKEN_FETCHED:
      return {
        ...state,
        accessToken: payload as string | undefined,
      }
    case ID_TOKEN_FETCHED:
      return {
        ...state,
        idToken: payload as string | undefined,
      }
    case USER_FETCHED:
      return {
        ...state,
        user: payload as IAuthUserWithId | undefined,
      }
    case SET_EDIT_LEVEL:
      return {
        ...state,
        editLevel: payload as IAuthEditLevel,
      }
    default:
      return state
  }
}

// IAction creators
export const accessTokenFetched = (idToken: string) => {
  return { type: ACCESS_TOKEN_FETCHED, payload: idToken }
}

export const idTokenFetched = (idToken: string) => {
  return { type: ID_TOKEN_FETCHED, payload: idToken }
}

export const userFetched = (user: IAuthUser) => {
  return { type: USER_FETCHED, payload: user }
}

// API Selectors Hooks
export const useAuth = () => {
  const dispatch = useDispatch()
  const { user, logout: logoutAuth0, ...auth0state } = useAuth0() // Exclude auth0 user from the public API

  const { isAuthenticated, isLoading } = auth0state

  useEffect(() => {
    if (!isLoading && !isAuthenticated) clearBrowserStorages()
  }, [isLoading, isAuthenticated])

  // Hook to listen for auth0 user changes / update context state with data from auth0
  useEffect(() => {
    if (user) {
      const userData: Record<string, unknown> = {
        id: user.sub,
        ...user,
        role: user[process.env.REACT_APP_AUTH0_NAMESPACE + '/role'], // Attach role prop to user
        readOnly: user[process.env.REACT_APP_AUTH0_NAMESPACE + '/read-only'], // Attach readOnly prop to user
        relatedChannelPartner:
          user[
            process.env.REACT_APP_AUTH0_NAMESPACE + '/related-channel-partner'
          ], // Attach relate channel partner if found
        relatedEntity:
          user[process.env.REACT_APP_AUTH0_NAMESPACE + '/related-entity'], // Attach relate entity if found
        portalUserId:
          user[process.env.REACT_APP_AUTH0_NAMESPACE + '/portal-users'],
        hasProspectorPermission:
          user[
            process.env.REACT_APP_AUTH0_NAMESPACE + '/has-prospector-permission'
          ],
        hasNearmapPermission:
          user[
            process.env.REACT_APP_AUTH0_NAMESPACE + '/has-nearmap-permission'
          ],
      }

      // Remove namespace fields from userData;
      delete userData[process.env.REACT_APP_AUTH0_NAMESPACE + '/role']
      delete userData[process.env.REACT_APP_AUTH0_NAMESPACE + '/read-only']
      delete userData[
        process.env.REACT_APP_AUTH0_NAMESPACE + '/related-channel-partner'
      ]
      delete userData[process.env.REACT_APP_AUTH0_NAMESPACE + '/related-entity']

      // @ts-expect-error the 'delete' operations give us the correct keys for an IAuthUser
      dispatch(userFetched(userData))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const logout: typeof logoutAuth0 = useCallback(
    (logoutOptions) => {
      logoutAuth0(logoutOptions)
      clearBrowserStorages()
    },
    [logoutAuth0]
  )

  return {
    ...auth0state,
    logout,
    ...useState()[sliceName],
  }
}

export const useIDToken = () => useAuth().idToken
export const useAccessToken = () => useAuth().accessToken
export const useUser = () => useAuth().user
export const useImpersonatingUserOrUser = () => {
  const user = useUser()
  const impersonatingUser = useImpersonatingUser()
  return impersonatingUser ?? user
}

export const useEditLevel = () => {
  const dispatch = useDispatch()
  const editLevel = useState()[sliceName]?.editLevel
  const setEditLevel = useCallback(
    (newEditLevel?: IAuthEditLevel) =>
      dispatch({
        type: SET_EDIT_LEVEL,
        payload: newEditLevel,
      }),
    [dispatch]
  )
  return [editLevel, setEditLevel] as const
}

export const useEditLevelUser = () => {
  const user = useUser()
  const impersonatingUser = useImpersonatingUser()
  const [editLevel] = useEditLevel()

  if (editLevel === 'impersonating') return impersonatingUser ?? user
  if (editLevel === 'own') return user

  throw new Error(`Edit level ${String(editLevel)} unknown`)
}

export const useOnEditLevelChanged = (cb: () => Promise<void>) => {
  const userLoaded = !!useImpersonatingUserOrUser()
  const [editLevel] = useEditLevel()

  const [lastEditLevel, setLastEditLevel] = useStateReact(editLevel)

  useEffect(() => {
    if (cb && editLevel && lastEditLevel !== editLevel && userLoaded) {
      setLastEditLevel(editLevel)
      cb().catch(() => {
        throw new Error('editLevel callback failed')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cb, editLevel, lastEditLevel, userLoaded])
}

// API Actions Hooks
export function useGetIDToken() {
  const dispatch = useDispatch()
  const { getIdTokenClaims, getAccessTokenSilently } = useAuth0()
  const { send: sendNotification } = useNotifications()
  return async () => {
    try {
      const accessToken = await getAccessTokenSilently()
      const claims = await getIdTokenClaims()
      if (!claims) {
        throw new Error()
      }
      const idToken = claims.__raw
      dispatch(idTokenFetched(idToken))
      dispatch(accessTokenFetched(accessToken))
    } catch (error) {
      sendNotification({
        title: 'Authentication failed',
        message: 'Failed to fetch idToken!',
        variant: Variant.DANGER,
        metadata: error,
      })
    }
  }
}

// API Components Helper Hooks

// TODO -> unused
export function useCallOnIsAuthenticated(cb: () => void, deps: unknown[] = []) {
  const { isAuthenticated, isLoading } = useAuth0()
  useEffect((): void => {
    if (!isLoading && isAuthenticated) {
      cb()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated, ...deps])
}

export function useCallOnNotAuthenticated(
  cb: () => void,
  deps: unknown[] = []
) {
  const { isAuthenticated, isLoading } = useAuth0()
  useEffect((): void => {
    if (!isLoading && !isAuthenticated) {
      cb()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated, ...deps])
}

export const useAuthInfo = () => {
  const user = useUser()
  const impersonatingUser = useImpersonatingUser()

  const {
    status: impersonationStatus,
    payload: { entity, channelPartner },
  } = useImpersonations()

  const loadImpersonations = useLoadImpersonations()
  useCallOnceOnIDTokenGranted(loadImpersonations)

  const isLoading =
    impersonationStatus === RequestStatus.LOADING ||
    impersonationStatus === RequestStatus.IDLE

  const isBBEUserOrSuperAdminSelf =
    !!user && isBBEUserOrSuperAdmin(user) && !isLoading && !impersonatingUser

  const isBBEUserOrSuperAdminImpersonated =
    !!impersonatingUser &&
    isBBEUserOrSuperAdmin(impersonatingUser) &&
    !isLoading

  return {
    isLoading,
    entity,
    channelPartner,
    settings: {
      enableDashboard:
        entity?.enableDashboardPage ||
        isBBEUserOrSuperAdminSelf ||
        isBBEUserOrSuperAdminImpersonated,
      enableProjectsPage:
        entity?.enableProjectPage ||
        channelPartner?.enableProjectsPageCp ||
        isBBEUserOrSuperAdminSelf,
    },
  }
}

// API HOCs
export const AuthContextProvider = Auth0Provider

// API Methods
export const isAdmin = (user: IAuthUser | IUser) => user.role === AuthRole.ADMIN

export const isBBEUser = (user: IAuthUser | IUser) =>
  user.role === AuthRole.BBE_USER

export const isSuperAdmin = (user: IAuthUser | IUser) =>
  user.role === AuthRole.SUPER_ADMIN

export const isBBEUserOrSuperAdmin = (user: IAuthUser | IUser) =>
  isBBEUser(user) || isSuperAdmin(user)

export const isAdminOrSuperAdmin = (user: IAuthUser) =>
  isAdmin(user) || isSuperAdmin(user)

export const isAdminOrBBEUserOrSuperAdmin = (user: IAuthUser) =>
  isAdmin(user) || isBBEUser(user) || isSuperAdmin(user)

export const isChannelPartnerUser = (user: IAuthUser) =>
  isDefined(user.relatedChannelPartner)

export const isEntityUser = (user: IAuthUser | IUser) =>
  isDefined(user.relatedEntity)

export default reducer

import { IRowHookInput } from 'dromo-uploader-react'
import { ITableMessage } from 'dromo-uploader-js'
import {
  GeocodeRequestAddress,
  GeocodedAddress,
  ValidGeocodedAddress,
  GeocodeResponse,
} from '@black-bear-energy/black-bear-energy-common'
import { AxiosInstance } from 'axios'
import { getUpdatedValueString } from '../helpers/getUpdatedValueString'
import { updateCellValue } from '../helpers/updateCellValue'
import { IRowHookCell } from 'dromo-uploader-js/dist/interfaces'

export const badGeocodeMsg = 'Address could not be accurately geocoded'
export const failedGeocodeMsg =
  'Geocoding process failed. Please contact support'

export async function geocodeAddresses(
  records: IRowHookInput[],
  isUpdate: boolean,
  api: AxiosInstance
): Promise<IRowHookInput[]> {
  const addresses: GeocodeRequestAddress[] = records.map((record) => {
    const geocodeRequestAddress: GeocodeRequestAddress = {
      id: record.rowId,
      address: record.row.address.value as string,
    }
    const city = record.row.city?.value as string | undefined
    if (city) {
      geocodeRequestAddress.city = city
    }
    const state = record.row.state?.value as string | undefined
    if (state) {
      geocodeRequestAddress.state = state
    }
    const zipCode = record.row.zipCode?.value as string | undefined
    if (zipCode) {
      geocodeRequestAddress.zipCode = zipCode
    }
    return geocodeRequestAddress
  })

  let geocodeResponse: GeocodeResponse

  try {
    geocodeResponse = (
      await api.post<GeocodeResponse>('/internal/geocode', {
        addresses,
      })
    ).data
  } catch (err) {
    return records.map((record) => {
      const recordClone = { ...record }
      recordClone.row.address.info = [
        { message: failedGeocodeMsg, level: 'error' },
      ]
      return recordClone
    })
  }

  return records.map((record, index) =>
    formatRawAddress(isUpdate, record, geocodeResponse.addresses[index])
  )
}

function formatRawAddress(
  isUpdate: boolean,
  record: IRowHookInput,
  geocode: GeocodedAddress
): IRowHookInput {
  // create a clone that we can safely mutate
  const recordClone = { ...record }

  // set the record's unique id
  recordClone.row.rowId.value = record.rowId

  if (!isUpdate) {
    recordClone.row = ensureDefaultSiteName(recordClone.row)
  }

  // if we previously added an error message, clear it out (user may have updated)
  recordClone.row.address.info =
    recordClone.row.address.info?.filter(
      (i) => i.message !== badGeocodeMsg && i.message !== failedGeocodeMsg
    ) ?? []

  const rawLat = recordClone.row.latitude.value as string
  const rawLong = recordClone.row.longitude.value as string

  // Record whether the geocode is valid in a hidden field.
  recordClone.row.isGeocodeValid.value = geocode.isValid

  // check geocode validity
  if (!geocode.isValid) {
    // if the user provided lat/long values, show a warning; else error
    const level = rawLat && rawLong ? 'warning' : 'error'
    recordClone.row.address.info.push({
      message: badGeocodeMsg,
      level,
    })
    return recordClone
  }

  // set the lat/long values
  recordClone.row.latitude.value = geocode.lat
  recordClone.row.longitude.value = geocode.long

  recordClone.row = addStandardizedSuggestions(recordClone.row, geocode)
  if (isUpdate) {
    recordClone.row = updateInfoMessages(recordClone.row)
  }

  return recordClone
}

function ensureDefaultSiteName(
  row: IRowHookInput['row']
): IRowHookInput['row'] {
  if (row.name.value) {
    // has a value already
    return row
  }

  const rowClone = { ...row }
  // set the street address as the default site name
  rowClone.name.value = rowClone.address.value as string
  return rowClone
}

/**
 * Called when the user has manually updated values in this row.
 * Check whether the user reverted any of the values that we auto-formatted.
 * If yes, remove the info message saying that it was updated.
 * @param row - The manually updated record
 * @returns The same record with updated info messages
 */
function updateInfoMessages(row: IRowHookInput['row']): IRowHookInput['row'] {
  const rowClone = { ...row }
  // street address
  const currAddress = rowClone.address.value as string
  rowClone.address.info = getUpdatedInfoMessageList(
    currAddress,
    rowClone.address.info
  )
  // city
  const currCity = rowClone.city.value as string
  rowClone.city.info = getUpdatedInfoMessageList(currCity, rowClone.city.info)
  // state
  const currState = rowClone.state.value as string
  rowClone.state.info = getUpdatedInfoMessageList(
    currState,
    rowClone.state.info
  )
  // zip code
  const currZip = rowClone.zipCode.value as string
  rowClone.zipCode.info = getUpdatedInfoMessageList(
    currZip,
    rowClone.zipCode.info
  )
  return rowClone
}

/**
 * If the user updated the value to be the original value, remove the 'updated' message.
 * @param currValue - The current value of a cell.
 * @param infoList - The current list of info messages for the cell.
 * @returns The updated list of info messages for the cell.
 */
function getUpdatedInfoMessageList(
  currValue: string,
  infoList?: ITableMessage[]
): ITableMessage[] | undefined {
  if (!infoList?.length) {
    return infoList
  }
  const infoMsgIdx = infoList.findIndex(
    (i) => getUpdatedValueString(currValue) === i.message
  )
  // leave the message as is
  if (infoMsgIdx === -1) {
    return infoList
  }
  return infoList.toSpliced(infoMsgIdx, 1)
}

/**
 * Replace a row's address, city, state and zip code values with geocoded values.
 * @param row - The row object.
 * @param geocode - Components of the valid geocoded address.
 * @returns The updated row object.
 */
function addStandardizedSuggestions(
  row: IRowHookInput['row'],
  geocode: ValidGeocodedAddress
): IRowHookInput['row'] {
  const rowClone = { ...row }
  // street address
  rowClone.address = processAddressComponentUpdate(
    rowClone.address,
    geocode.streetAddress
  )
  // city
  rowClone.city = processAddressComponentUpdate(rowClone.city, geocode.city)
  // state
  rowClone.state = processAddressComponentUpdate(rowClone.state, geocode.state)
  // zip code
  rowClone.zipCode = processAddressComponentUpdate(
    rowClone.zipCode,
    geocode.zipCode
  )
  return rowClone
}

function processAddressComponentUpdate(
  cell: IRowHookCell,
  geocodedValue?: string
): IRowHookCell {
  const rawValue = cell.value as string
  // no change
  if (rawValue === geocodedValue) {
    return cell
  }

  // update the value and mark as modified
  const cellClone = updateCellValue(cell, rawValue, geocodedValue)
  return cellClone
}

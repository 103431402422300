import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { Row, Col } from 'react-bootstrap'
import { IFile } from '../../common/types'

const WideInput = styled.input`
  padding: 0;
  margin: 0;
  line-height: 0.9em;
  width: 100%;
`

const WideDiv = styled.div`
  margin: 0;
  padding: 0px;
  border-radius: 0;
  width: 100%;
`

const LightPadding = styled.div`
  padding: 0px;
`

const TruncatedText = styled(WideDiv)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

interface IFileDropPropTypes {
  onDrop: (files: IFile[]) => void
  disabled?: boolean
  maxFiles?: number
}

const FileDrop = ({
  onDrop,
  disabled = false,
  maxFiles,
}: IFileDropPropTypes) => {
  const [files, setFiles] = useState<IFile[]>([])

  const onFilesDropped = useCallback(
    (files: IFile[]) => {
      if (!disabled) {
        setFiles(files)
        onDrop(files)
      }
    },
    // eslint-disable-next-line  react-hooks/exhaustive-deps
    [onDrop]
  ) as <T extends File>(files: T[]) => void

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles,
    onDrop: onFilesDropped,
  })

  const firstFileName = files.length !== 0 && files[0].name

  return (
    <>
      <LightPadding>
        <Row>
          <Col>
            <div {...getRootProps()}>
              <WideInput {...getInputProps()} disabled={disabled} />
              {isDragActive && !disabled ? (
                <WideDiv className="btn btn-sm btn-outline-secondary">
                  {firstFileName || 'Drop'}
                </WideDiv>
              ) : (
                <WideDiv
                  className={`btn btn-sm btn-secondary ${
                    disabled ? 'disabled' : ''
                  }`}
                >
                  <TruncatedText>{firstFileName || 'Attach'}</TruncatedText>
                </WideDiv>
              )}
            </div>
          </Col>
        </Row>
      </LightPadding>
    </>
  )
}

export default FileDrop

import { Form } from 'react-bootstrap'
import { AuthRole } from '../../state/modules/auth'

interface IRoleSelectPropTypes {
  onChange: (value: AuthRole) => void
  value?: AuthRole
  disabled: boolean
}

export default function RoleSelect({
  onChange,
  value,
  disabled,
}: IRoleSelectPropTypes) {
  function handleRoleChange(e: React.ChangeEvent<HTMLInputElement>) {
    onChange(e.target.value as AuthRole)
  }

  return (
    <fieldset>
      <legend className="required">User Role</legend>
      <Form.Group className="fluid">
        <Form.Check type="radio" name="user-role">
          <Form.Check.Label>
            <Form.Check.Input
              type="radio"
              name="user-role"
              value={AuthRole.ADMIN}
              checked={value === AuthRole.ADMIN}
              onChange={handleRoleChange}
              disabled={disabled}
            />
            {AuthRole.ADMIN}
          </Form.Check.Label>
        </Form.Check>
        <Form.Check type="radio">
          <Form.Check.Label>
            <Form.Check.Input
              type="radio"
              name="user-role"
              value={undefined}
              checked={value === undefined}
              onChange={handleRoleChange}
              disabled={disabled}
            />
            User
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>
    </fieldset>
  )
}

import React, {
  Dispatch,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Button, Modal, Form, ListGroup } from 'react-bootstrap'
import FlipMove from 'react-flip-move'
import { sortBy } from 'lodash'
import { XLg } from 'react-bootstrap-icons'
import { arrayMoveImmutable } from 'array-move'
import { useDrag, useDrop } from 'react-dnd'
import { Variant } from '../common/types'
import { ISortFilter, SortDirection } from './helpers/query'
import { IColumnSettings } from './BootstrapTable/ColumnSettings'
import { AppModal } from './common/Modal'

interface IMultiColSortModalProps {
  show: boolean
  columns: readonly IColumnSettings[]
  sort?: readonly ISortFilter[]
  onSort?: Dispatch<ISortFilter[]>
  onHide?: () => void
}

const emptySort: readonly ISortFilter[] = []

const dragDropType = 'MultiColSortModal'

export default function MultiColSortModal({
  show,
  columns,
  sort,
  onSort,
  onHide,
}: IMultiColSortModalProps) {
  const [newSort, setNewSort] =
    useState<(ISortFilter | (Partial<ISortFilter> & { id: string }))[]>()

  useEffect(() => {
    if (!show) setNewSort(undefined)
  }, [show])

  const currentSort = useMemo(
    () => newSort || [...(sort || emptySort)],
    [newSort, sort]
  )

  const handleChangeItem = useCallback(
    (index: number) =>
      <F extends keyof ISortFilter>(field: F, value: ISortFilter[F]) =>
        setNewSort([
          ...currentSort.slice(0, index),
          {
            ...(currentSort[index] || { direction: SortDirection.ASC }),
            [field]: value,
          },
          ...currentSort.slice(index + 1),
        ]),
    [currentSort]
  )

  const handleRemove = useCallback(
    (index: number) => () =>
      setNewSort([
        ...currentSort.slice(0, index),
        ...currentSort.slice(index + 1),
      ]),
    [currentSort]
  )

  const handleMove = useCallback(
    (fromIndex: number, toIndex: number) =>
      setNewSort(arrayMoveImmutable(currentSort, fromIndex, toIndex)),
    [currentSort]
  )

  const fieldsOptions = useMemo(
    () =>
      sortBy(
        columns.map(
          (column) =>
            [
              column.propName,
              currentSort.some(({ key }) => key === column.propName),
              <option key={column.propName} value={column.propName}>
                {column.label}
              </option>,
            ] as [key: string, used: boolean, option: JSX.Element]
        ),
        ([key]) => key
      ),
    [currentSort, columns]
  )

  const validSort = useMemo(
    () =>
      currentSort.every(
        (item): item is ISortFilter =>
          !!item.key &&
          (SortDirection.ASC === item.direction ||
            SortDirection.DESC === item.direction)
      )
        ? currentSort
        : undefined,
    [currentSort]
  )

  return (
    <AppModal show={show} onHide={onHide} header="Sort">
      <Modal.Body>
        <ListGroup>
          <FlipMove duration={1000} typeName={null}>
            {currentSort.map((item, index) => (
              <div key={item.key}>
                <Row
                  fieldsOptions={fieldsOptions}
                  item={item}
                  handleChangeField={handleChangeItem(index)}
                  index={index}
                  onDelete={handleRemove(index)}
                  onMove={handleMove}
                />
              </div>
            ))}

            <div key={`__new`}>
              <Row
                key={`__new`}
                fieldsOptions={fieldsOptions}
                item={{}}
                handleChangeField={handleChangeItem(currentSort.length)}
                index={currentSort.length}
                ghost={true}
                onMove={handleMove}
              />
            </div>
          </FlipMove>
        </ListGroup>
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button
          disabled={!onSort || !validSort}
          onClick={onSort && validSort && (() => onSort(validSort))}
        >
          Sort
        </Button>
      </Modal.Footer>
    </AppModal>
  )
}

interface RowProps {
  item?: Partial<ISortFilter>
  handleChangeField: <F extends keyof ISortFilter>(
    field: F,
    value: ISortFilter[F]
  ) => void
  onDelete?: () => void
  onMove?: (fromIndex: number, toIndex: number) => void
  fieldsOptions: [key: string, used: boolean, option: JSX.Element][]
  index: number
  ghost?: boolean
}

interface CollectedProps {
  isDragging?: boolean
  isOver?: boolean
  dragIndex?: number
}

interface DragObject {
  index: number
}

interface DropResult {
  index: number
}

const Row: FunctionComponent<RowProps> = ({
  item: { key, direction } = {},
  handleChangeField,
  onDelete,
  onMove,
  fieldsOptions,
  index,
  ghost,
}) => {
  const draggableRef = useRef<HTMLDivElement | null>(null)

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: dragDropType,
      item: () => (ghost ? undefined : { index }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [index]
  )

  const [{ isOver, dragIndex }, drop] = useDrop<
    DragObject,
    DropResult,
    CollectedProps
  >(
    () => ({
      accept: dragDropType,
      canDrop: () => !ghost,
      drop: (dragObject) => {
        const fromIndex = dragObject.index
        const toIndex = index

        if (
          typeof fromIndex === 'number' &&
          typeof toIndex === 'number' &&
          toIndex !== fromIndex
        )
          onMove?.(fromIndex, toIndex)

        return undefined
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        dragIndex: monitor.getItem<{ index: number } | undefined>()?.index,
      }),
    }),
    [onMove, index]
  )

  drag(drop(draggableRef))

  const dragTop = (dragIndex || 0) > index

  return (
    <div
      ref={draggableRef}
      style={{
        position: 'relative',
        marginTop: index === 0 ? 0 : -1,
        paddingTop: !isDragging && isOver && !ghost && dragTop ? 20 : 0,
        paddingBottom: !isDragging && isOver && !ghost && !dragTop ? 20 : 0,
        transition: 'all 1s',
        opacity: isDragging ? 0.2 : 1,
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        gap: '0.5rem',
      }}
    >
      <ListGroup.Item
        style={{
          opacity: ghost ? 0.4 : 1,
          display: 'flex',
          flexFlow: 'row',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <div style={{ whiteSpace: 'nowrap', cursor: 'ns-resize' }}>
          {index === 0 ? 'Sort by' : 'Then by'}
        </div>
        <Form.Control
          as="select"
          value={key || ''}
          onChange={(e) => handleChangeField('key', e.target.value)}
          style={{ flex: 1 }}
        >
          <option disabled hidden style={{ display: 'none' }} value="" />
          {fieldsOptions
            ?.filter(([optionKey, used]) => !used || optionKey === key)
            .map(([, , option]) => option)}
        </Form.Control>
        <Form.Control
          as="select"
          value={direction}
          onChange={(e) =>
            handleChangeField('direction', e.target.value as SortDirection)
          }
          style={{ width: 100, visibility: ghost ? 'hidden' : 'visible' }}
        >
          <option disabled hidden style={{ display: 'none' }} value="" />
          <option value={SortDirection.ASC}>{SortDirection.ASC}</option>
          <option value={SortDirection.DESC}>{SortDirection.DESC}</option>
        </Form.Control>
        <Button
          onClick={onDelete}
          style={{ visibility: ghost ? 'hidden' : 'visible', border: 0 }}
          variant={Variant.LIGHT}
        >
          <XLg />
        </Button>
      </ListGroup.Item>
    </div>
  )
}

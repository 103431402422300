import { FieldType, IField } from '../../common/types'
import { IColumnSettings } from '../BootstrapTable/ColumnSettings'
import { TextButton } from '../common/TextButton'

export interface UserType {
  id: string
  userName: string
  name: string
  email: string
}

export const getFieldSettings = (
  field: IField
): Partial<IColumnSettings> | undefined => {
  if (field.fieldType === FieldType.USER)
    return {
      renderer: (td, row, user: unknown) => {
        if (!user) {
          return null
        }
        const userType = user as UserType
        return (
          <span title={userType.userName}>
            {userType.name} <small>{userType.email}</small>
          </span>
        )
      },
    }

  if (field.fieldType === FieldType.NUMERIC)
    return {
      renderer: (td, row, value: unknown) => {
        if (value === undefined || value === '' || value === null) return ''
        return value
      },
    }

  if (field.fieldType === FieldType.BOOLEAN)
    return {
      renderer: (td, row, value, { onChange }) => {
        const { readOnly } = field

        let label = <span>Unknown</span>
        let color: string | undefined = 'red'

        const handleChange =
          onChange && !readOnly ? () => onChange(!value) : undefined

        if (value === undefined || value === null) {
          label = <span>&mdash;</span>
          color = 'LightSlateGray'
        }

        if (value === false) {
          label = <span>No</span>
          color = 'LightSlateGray'
        }
        if (value === true) {
          label = <span>Yes</span>
          color = 'MediumSeaGreen'
        }

        return (
          <TextButton
            onClick={handleChange}
            style={{
              minWidth: '100%',
              minHeight: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color,
              borderBottomWidth: handleChange ? 1 : 0,
              paddingBottom: handleChange ? 0 : 1,
            }}
            gentle
            disabled={!handleChange}
          >
            {label}
          </TextButton>
        )
      },
    }

  return undefined
}

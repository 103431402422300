import { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import {
  useLoadTermsOfService,
  useSendTOSConfirmation,
  useTermsOfService,
} from '../termsOfService'
import { useCallOnIDTokenGranted } from '../common'
import {
  IReactParentPropTypes,
  RequestStatus,
  Variant,
} from '../../../common/types'
import { NewLineParagraph } from '../../../components/common/Layout'
import moment from 'moment'
import { isBBEUserOrSuperAdmin, useAuth } from '../auth'
import { displayAlerts } from '../../../components/helpers/displayAlerts'
import { isDefined } from '../../../utils/typeGuards'

export default function TOSProvider({ children }: IReactParentPropTypes) {
  const { status, payload: tosInfo, httpErrors } = useTermsOfService()
  const loadTOS = useLoadTermsOfService()
  const sendTOSConfirmation = useSendTOSConfirmation()
  const { isLoading, isAuthenticated, user } = useAuth()

  const [agreementChecked, setAgreementChecked] = useState(false)

  useCallOnIDTokenGranted(async () => {
    if (!isBBEUserOrSuperAdmin(user!) && status === RequestStatus.IDLE) {
      await loadTOS() // Load TOS except for super-admins
    }
  }, [user, status])

  const showChildren =
    !isLoading && !isAuthenticated
      ? true
      : isDefined(user) && isBBEUserOrSuperAdmin(user)
      ? true
      : status === RequestStatus.SUCCEEDED
      ? tosInfo.confirmed
      : false

  const showModal =
    !showChildren &&
    status !== RequestStatus.IDLE &&
    status !== RequestStatus.LOADING

  const formDisabled = status === RequestStatus.LOADING

  function handleAgreementChange() {
    setAgreementChecked(!agreementChecked)
  }

  function handleContinue() {
    sendTOSConfirmation({
      tosText: tosInfo?.details?.tosText,
      tosConfirmed: agreementChecked,
    }).catch(() => {
      throw new Error('sendTOSConfirmation failed')
    })
  }

  return (
    <>
      <Modal
        onHide={() => {
          return
        }}
        animation={false}
        show={showModal}
        centered
        size="lg"
      >
        <Modal.Body>
          <h4>Terms Of Service</h4>
          <NewLineParagraph className="text-black" />
          <p className="mt-1 small text-muted">
            {moment(tosInfo?.details?.dateModified).format('ll')}
          </p>
          {displayAlerts(
            httpErrors && httpErrors.errors && httpErrors.errors.length !== 0
              ? httpErrors.errors // Display httpErrors
              : [httpErrors?.message] // Fall back to error.message if no errors[] provided
          )}
          {displayAlerts(
            !!tosInfo &&
              tosInfo?.confirmed &&
              tosInfo?.confirmation?.tosConfirmed
              ? []
              : ['You have not agreed to the terms of service']
          )}
          <p
            dangerouslySetInnerHTML={{ __html: tosInfo?.details?.tosText }}
          ></p>
          <Form.Group className="fluid align-items-center justify-content-center">
            <Form.Check type="radio" name="user-role">
              <Form.Check.Label>
                <Form.Check.Input
                  type="checkbox"
                  className="mt-1"
                  name="terms of service confirmation"
                  checked={agreementChecked}
                  onChange={handleAgreementChange}
                  disabled={formDisabled}
                />
                I accept and agree to the Terms Of Service
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={Variant.PRIMARY}
            className="mx-auto"
            onClick={handleContinue}
            disabled={formDisabled || !agreementChecked}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
      {showChildren ? children : null}
    </>
  )
}

import React from 'react'
import Row from 'react-bootstrap/Row'
import styled from 'styled-components'
import { IReactParentPropTypes } from '../../common/types'

const SpacedMargin = styled.div`
  margin-bottom: 30px;
`

export function SpacedRow({ children }: IReactParentPropTypes) {
  return (
    <SpacedMargin>
      <Row>{children}</Row>
    </SpacedMargin>
  )
}

const SeparationMargin = styled.div`
  margin-bottom: 40px;
`

export function SeparationRow({ children }: IReactParentPropTypes) {
  return (
    <SeparationMargin>
      <Row>{children}</Row>
    </SeparationMargin>
  )
}

export const TextLeft = styled.div`
  text-align: left;
`

export const TextCenter = styled.div`
  text-align: center;
`

export const TextRight = styled.div`
  text-align: right;
`

export const TopCover = styled.div`
  margin-top: 15px;
`

export const RelativeContainer = styled.div`
  position: relative;
`

export const RightAlignedTwoColumns = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const NewLineParagraph = styled.p`
  white-space: pre-line;
`

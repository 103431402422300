import React, { useLayoutEffect, useMemo } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { RequestStatus } from '../../common/types'
import {
  getAttachmentFileName,
  useDownloadAttachment,
  IEntityPortalAttachment,
  useEntityPortalAttachments,
} from '../../state/modules/entityPortalAttachments'
import { IStoreContextValue, StateProvider } from '../../state/store'
import { stringEllipsis } from '../../utils/string'

interface IDownloadAttachmentLinkPropTypes {
  entityPortalAttachment?: IEntityPortalAttachment
}

export default function downloadAttachmentRenderer(
  context: IStoreContextValue,
  entityPortalAttachment: IEntityPortalAttachment
) {
  return (
    <StateProvider value={context}>
      <DownloadAttachmentLink entityPortalAttachment={entityPortalAttachment} />
    </StateProvider>
  )
}

function DownloadAttachmentLink({
  entityPortalAttachment,
}: IDownloadAttachmentLinkPropTypes) {
  const { status } = useEntityPortalAttachments()

  const fileName =
    entityPortalAttachment && getAttachmentFileName(entityPortalAttachment)

  const downloadAttachment = useDownloadAttachment()

  const handleClick = useMemo(
    () =>
      entityPortalAttachment
        ? async () => {
            await downloadAttachment(entityPortalAttachment)
          }
        : undefined,
    [downloadAttachment, entityPortalAttachment]
  )

  const tooltipId = `tooltip-${fileName}`

  useLayoutEffect(() => {
    if (fileName) {
      document.getElementById(tooltipId)?.remove() // Force remove old tooltip if component is reconstructed
    }
  }, [fileName, tooltipId])

  if (fileName === '') return <></>

  // Highlighting file extension by making it smaller
  const dot_index = fileName?.indexOf('.')
  const ext =
    dot_index === undefined || dot_index === -1
      ? fileName
      : fileName?.substring(dot_index + 1)

  const name = !!ext ? fileName?.substring(0, dot_index) : undefined
  const name_display =
    ext && name?.trim() ? (
      <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
        <div>
          {stringEllipsis(name, Math.max(30 - Math.min(ext.length, 10) - 1, 4))}
        </div>
        <div style={{ fontSize: 'small' }}>.{stringEllipsis(ext, 10)}</div>
      </div>
    ) : fileName ? (
      stringEllipsis(fileName, 30)
    ) : (
      fileName
    )

  const getOverlay = () => <Tooltip id={tooltipId}>{fileName}</Tooltip>

  return (
    <OverlayTrigger placement="top" overlay={getOverlay()}>
      <Button
        block
        disabled={status === RequestStatus.LOADING}
        onClick={handleClick}
        variant="link"
      >
        {name_display}
      </Button>
    </OverlayTrigger>
  )
}

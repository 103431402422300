import { Dispatch, ReactNode, useMemo } from 'react'
import { IField } from '../../common/types'

export type rendererTdArg =
  | (HTMLTableCellElement & { hashLength: number; hashSymbol: string })
  | null

export type ColumnSettingsRenderType =
  | 'checkbox'
  | 'dropdown'
  | 'string'
  | 'text-input'
  | 'password'
  | 'url'

export interface IColumnSettings
  extends Omit<IField, 'readOnly' | 'fieldType'> {
  fieldType?: string
  readOnly?: boolean | ((_: string, __: string) => boolean)
  renderer?: (
    td: rendererTdArg,
    row: string,
    value: unknown,
    opts: { onChange?: Dispatch<unknown>; rowData: object }
  ) => ReactNode
  columnStyle?: React.CSSProperties
  comment?:
    | { value: unknown }
    | ((propName: string, rowKey: string | number) => { value: unknown }) // tooltip comment
  source?: string[] // list of options for dropdown type
  type?: ColumnSettingsRenderType
  disableExport?: boolean
  hide?: boolean
}

export const columnSettingsFromField = (field: IField): IColumnSettings => ({
  ...field,
})

interface UseColumnSettingsFromFieldsOpts {
  defaultSettings?: Partial<IColumnSettings>
  getFieldSettings?: (field: IField) => Partial<IColumnSettings> | undefined
}

export const useColumnSettingsFromFields = (
  fields: readonly IField[],
  { defaultSettings, getFieldSettings }: UseColumnSettingsFromFieldsOpts = {}
): IColumnSettings[] =>
  useMemo(
    () =>
      fields.map((field) => ({
        ...defaultSettings,
        ...columnSettingsFromField(field),
        ...getFieldSettings?.(field),
      })),
    [fields, defaultSettings, getFieldSettings]
  )

enum SiteAction {
  /** We found an existing database record that matches this one; update the existing site */
  Update = 'Update Existing Site',
  /** Needs manual review */
  Review = 'Needs Review',
  /** This is an existing record in the database that did not appear in the proposed list */
  Sold = 'Mark as Sold',
  /** No matching records found in the database */
  New = 'Add as New',
  /** Don't import this row, don't validate this data */
  Ignore = 'Ignore',
}

export default SiteAction;

import { number } from '@black-bear-energy/black-bear-energy-common/dist/validations'
import { FieldType, IField } from '../../common/types'
import { ISiteRfp } from '../../state/modules/siteRfps'
import { BootstrapTableChangesMap } from '../BootstrapTable/RecordChangesHook'
import { ITableValidationError } from '../BootstrapTable/tableTypes'

export function validateProjectChanges(
  changesMap: BootstrapTableChangesMap<ISiteRfp, 'recordId'>,
  fields: IField[]
) {
  const validationErrors: ITableValidationError[] = []

  for (const change of Array.from(changesMap.values())) {
    const { recordId, ...patch } = change
    for (const propName in patch) {
      const row = recordId
      const newVal = patch[propName as keyof typeof patch]
      const field = fields.find((f) => f.propName === propName)
      if (field) {
        const { fieldType, label } = field
        const numericFieldTypes = [
          fieldType === FieldType.NUMERIC,
          fieldType === FieldType.NUMBER,
        ]
        let error
        if (numericFieldTypes.includes(true)) {
          error = number(label)(newVal)
        }
        if (error && typeof error === 'string') {
          validationErrors.push([error, row, propName])
        }
      }
    }
  }

  return validationErrors
}

export const formatPhoneNumber = (phone: string) => {
  // remove trailing whitespace and remove spaces in the name
  const phoneWithoutSpaces = phone.trim().split(' ').join('')

  const phoneWithoutSpecialCharacters = phoneWithoutSpaces
    .split('')
    .filter((value: string) => !isNaN(parseInt(value)))

  const phoneNumberArray: Array<string> = []

  phoneWithoutSpecialCharacters.forEach((number: string, index: number) => {
    if (index === 3 || index === 6) {
      phoneNumberArray.push('-')
    }

    phoneNumberArray.push(number)
  })

  return phoneNumberArray.join('')
}

export const validatePhoneNumber = (phone: string) => {
  // we add an extra length of two because we are inserting two (-) special characters
  if (phone === '' || phone.length === 12) {
    return true
  } else {
    throw new Error(`Phone number must be 10 characters long`)
  }
}

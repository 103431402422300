import React, { FunctionComponent } from 'react'
import BidHistoryTable from '../components/BidHistoryTable'

export const BidHistory: FunctionComponent = () => {
  return (
    <BidHistoryTable
      className="my-4 offset-xl-1 col-xl-10"
      style={{ flex: 1 }}
    />
  )
}

import qs from 'qs'
import { IAPIErrorFormat, IFile } from '../../common/types'
import { appendedFilename } from '../../utils/file'
import { getUrlPath } from '../../utils/string'

import { useApiWithIDToken, useDispatch } from './common'
import createSlice from './helpers/createSlice'

// Types & Interfaces
export enum EntityPortalAttachmentUploadDownload {
  UPLOAD = 'Upload',
  DOWNLOAD = 'Download',
}

export enum EntityPortalAttachmentAccess {
  ADMIN = 'Admin',
}

export enum EntityPortalAttachmentsProps {
  NAME = 'documentName',
  ATTACHMENT = 'attachment',
  DATE_MODIFIED = 'dateModified',
  UPLOAD_DOWNLOAD = 'uploadDownload',
}

export enum EntityPortalAttachmentsHeaders {
  NAME = 'Document Name',
  ATTACHMENT_NAME = 'Attachment Name',
  DATE_MODIFIED = 'Upload Date',
  UPLOAD_DOWNLOAD = '',
}

export interface IAttachmentCreator {
  email: string
  id: string
  name: string
  userName: string
}

export interface IAttachmentVersion {
  creator: IAttachmentCreator
  fileName: string
  uploaded: string
  versionNumber: number
}

export interface IAttachment {
  url: string
  versions: IAttachmentVersion[]
}

export interface IEntityPortalAttachment {
  recordId: number
  documentName?: string
  access?: EntityPortalAttachmentAccess
  uploadDownload?: EntityPortalAttachmentUploadDownload
  attachment?: IAttachment
  uploadedAttachment?: string
  relatedChannelPartner?: number
  channelPartnerName?: string
  relatedEntity?: number
  entityName?: string
  dateCreated?: Date
  dateModified?: Date
}

// Slice Name
export const sliceName = 'entityPortalAttachments'

// Slice URL
export const url = '/entity-portal-attachments'

// Abstract Slice Instance
export const entityPortalAttachmentsSlice = createSlice<
  IEntityPortalAttachment[]
>(sliceName, url)

// Initial state
export const initialState = entityPortalAttachmentsSlice.initialState

// Slice methods
export const getAttachmentFileName = (
  entityPortalAttachment: IEntityPortalAttachment
): string => {
  if (
    entityPortalAttachment.uploadDownload ===
    EntityPortalAttachmentUploadDownload.UPLOAD
  ) {
    return getUrlPath(entityPortalAttachment.uploadedAttachment!)
  } else {
    const versions: IAttachmentVersion[] | undefined =
      entityPortalAttachment?.attachment?.versions
    return versions?.length ? versions[versions.length - 1].fileName : ''
  }
}

function getVersionNumber(
  entityPortalAttachment: IEntityPortalAttachment
): number {
  if (
    entityPortalAttachment.uploadDownload ===
    EntityPortalAttachmentUploadDownload.DOWNLOAD
  ) {
    const versions: IAttachmentVersion[] | undefined =
      entityPortalAttachment?.attachment?.versions
    return versions?.length ? versions[versions.length - 1].versionNumber : -1
  } else {
    throw new Error('versions are only available for downlod attachments')
  }
}

// Slice Selectors Hooks
export const useEntityPortalAttachments = entityPortalAttachmentsSlice.useSlice

// Slice Actions Hooks
export const useLoadEntityPortalAttachments =
  entityPortalAttachmentsSlice.useLoadSlicePayload

export const useDismissEntityPortalAttachmentsHTTPErrors =
  entityPortalAttachmentsSlice.useDismissHTTPErrors

export const useDownloadAttachment = () => {
  const dispatch = useDispatch()
  const apiWithIDToken = useApiWithIDToken()
  return async (entityPortalAttachment: IEntityPortalAttachment) => {
    try {
      dispatch(entityPortalAttachmentsSlice.requestLoading())
      let downloadUrl
      if (
        entityPortalAttachment.uploadDownload ===
        EntityPortalAttachmentUploadDownload.DOWNLOAD
      ) {
        const response = await apiWithIDToken.get(
          `${url}/${
            entityPortalAttachment.recordId
          }/read-only-files?${qs.stringify({
            versionNumber: getVersionNumber(entityPortalAttachment),
          })}`,
          {
            responseType: 'arraybuffer',
          }
        )
        downloadUrl = window.URL.createObjectURL(
          new Blob([response.data as BlobPart])
        )
      } else {
        downloadUrl = entityPortalAttachment.uploadedAttachment
      }
      const fileName = getAttachmentFileName(entityPortalAttachment)
      const link = document.createElement('a')
      link.href = downloadUrl!
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
      dispatch(entityPortalAttachmentsSlice.requestLoaded())
    } catch (error) {
      dispatch(
        entityPortalAttachmentsSlice.requestFailed(error as IAPIErrorFormat)
      )
    }
  }
}

export const useUploadAttachment = () => {
  const dispatch = useDispatch()
  const apiWithIDToken = useApiWithIDToken()
  const loadEntityPortalAttachments = useLoadEntityPortalAttachments()
  return async (
    entityPortalAttachment: IEntityPortalAttachment,
    file: IFile
  ) => {
    try {
      dispatch(entityPortalAttachmentsSlice.requestLoading())
      const formData = new FormData() // Create a form data to attach the file
      formData.append('files', file, appendedFilename(file.name))
      await apiWithIDToken.put(
        `${url}/${entityPortalAttachment.recordId}/files`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      await loadEntityPortalAttachments() // Reload entity portal attachments to apply changes to documents table
    } catch (error) {
      dispatch(
        entityPortalAttachmentsSlice.requestFailed(error as IAPIErrorFormat)
      )
    }
  }
}

// Slice reducer
export default entityPortalAttachmentsSlice.reducer

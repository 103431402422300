/** .001 lat/long degrees is equivalent to 111m, or a football field */
export const latLongDiffThreshold = 0.001;

/**
 * The maximum request body size in bytes, equivalent to 5000 kB.
 * This is used in body-parser's [limit option](https://github.com/expressjs/body-parser?tab=readme-ov-file#limit-3).
 */
export const requestLimit = 5120000;

/** The rate limit per minute for requests to the Google Maps Geocoding API. */
export const geocodingRequestsPerMinuteLimit = 3000;

import { Reducer, useMemo, useState as useStateReact } from 'react'
import { AxiosError } from 'axios'
import { IAction, IAPIErrorFormat, RequestStatus } from '../../../common/types'
import { isDefined } from '../../../utils/typeGuards'
import { useApiWithIDToken, useDispatch, useState } from '../common'
import { IState } from '../../initialState'

export interface ISliceState<T> {
  status: RequestStatus
  payload: T
  httpErrors?: IAPIErrorFormat
}

export default function createSlice<T>(
  name: string,
  url = `/${name}`,
  // @ts-expect-error T might be an object or an array of objects, prefer empty array over null
  defaultPayload: T = []
) {
  // Types & Interfaces
  interface ISliceState {
    status: RequestStatus
    payload: T
    httpErrors?: IAPIErrorFormat
  }

  // Initial state
  const initialState: ISliceState = {
    status: RequestStatus.IDLE,
    payload: defaultPayload,
  }

  // Actions
  const REQUEST_LOADING = `${name}/REQUEST_LOADING`
  const REQUEST_LOADED = `${name}/REQUEST_LOADED`
  const REQUEST_FAILED = `${name}/REQUEST_FAILED`
  const HTTP_ERRORS_DISMISSED = `${name}/HTTP_ERRORS_DISMISSED`

  // Slice reducers
  const reducer: Reducer<ISliceState, IAction> = (
    state = initialState,
    { type, payload }
  ) => {
    switch (type) {
      case REQUEST_LOADING:
        return {
          ...state,
          status: RequestStatus.LOADING,
        }
      case REQUEST_LOADED:
        return {
          ...state,
          status: RequestStatus.SUCCEEDED,
          payload: isDefined(payload) ? (payload as T) : state.payload,
          httpErrors: undefined,
        }
      case REQUEST_FAILED:
        return {
          ...state,
          status: RequestStatus.FAILED,
          httpErrors: payload as IAPIErrorFormat,
        }
      case HTTP_ERRORS_DISMISSED:
        return {
          ...state,
          httpErrors: undefined,
        }
      default:
        return state
    }
  }

  // Action creators
  const requestLoading = () => {
    return { type: REQUEST_LOADING }
  }

  const requestLoaded = (payload?: T) => {
    return { type: REQUEST_LOADED, payload }
  }

  const requestFailed = (error: unknown) => {
    return { type: REQUEST_FAILED, payload: error as IAPIErrorFormat }
  }

  const httpErrorsDismissed = () => {
    return { type: HTTP_ERRORS_DISMISSED }
  }

  // API Selectors Hooks
  const useSlice = (): ISliceState =>
    useState()[name as keyof IState] as ISliceState

  // API Actions Hooks
  const useLoadSlicePayload = ({ params = {} } = {}) => {
    const dispatch = useDispatch()
    const apiWithIDToken = useApiWithIDToken()
    return async () => {
      dispatch(requestLoading())
      try {
        const response = await apiWithIDToken.get(url, { params })
        dispatch(requestLoaded(response.data as T))
      } catch (error) {
        dispatch(requestFailed((error as AxiosError)?.response?.data || error))
      }
    }
  }

  const useDismissHTTPErrors = () => {
    const dispatch = useDispatch()
    return () => {
      dispatch(httpErrorsDismissed())
    }
  }

  const useMutexAction = <T, A extends unknown[]>(
    action?: (...args: A) => Promise<T>,
    { disableReload }: { disableReload?: boolean } = {}
  ) => {
    const { status } = useSlice()

    const [running, setRunning] = useStateReact(false)

    const dispatch = useDispatch()

    const reload = useLoadSlicePayload()

    const run = useMemo(
      () =>
        status !== RequestStatus.LOADING && action
          ? async (...args: A) => {
              setRunning(true)
              dispatch(requestLoading())
              try {
                const result = await action(...args)
                dispatch(requestLoaded())
                setRunning(false)

                if (!disableReload) await reload()

                return result
              } catch (error) {
                dispatch(requestLoaded())
                setRunning(false)
                throw error
              }
            }
          : undefined,
      [setRunning, action, status, dispatch, disableReload, reload]
    )

    return [run, running] as const
  }

  return {
    initialState,
    REQUEST_LOADING,
    REQUEST_LOADED,
    REQUEST_FAILED,
    HTTP_ERRORS_DISMISSED,
    requestLoading,
    requestLoaded,
    requestFailed,
    httpErrorsDismissed,
    reducer,
    useSlice,
    useLoadSlicePayload,
    useDismissHTTPErrors,
    useMutexAction,
  }
}

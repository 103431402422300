/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import styled from 'styled-components'

import { IReactParentPropTypes, Variant } from '../../common/types'

const LightPadding = styled.div`
  padding: 5px;
`

const LeftCol = styled(Col)`
  padding-right: 0;
`

const RightCol = styled(Col)`
  padding-left: 0;
`

const SmallerBlackBearEnergyButton = styled(Button)`
  line-height: 0.9em;
`

const RelativeContainer = styled.div`
  position: relative;
`

const MouseEventHandler = styled.div`
  z-index: 99999999;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

type ISwitchPropTypes = {
  value?: boolean
  onSwitch?: (status: boolean) => void
  disabled?: boolean
  activeLabel?: string
  inactiveLabel?: string
  activeTooltipMessage?: string
  inactiveTooltipMessage?: string
  settings: { [key: string]: unknown }
}

export function Switch({
  value = false,
  // onSwitch,
  activeLabel = 'on',
  inactiveLabel = 'off',
  activeTooltipMessage,
  inactiveTooltipMessage,
  disabled = false,
  settings,
}: ISwitchPropTypes) {
  return (
    <LightPadding className={settings.classList as string | undefined}>
      {settings.innerText}
      {/* <Row>
        <LeftCol>
          <SwitchButton
            variant={!active ? Variant.PRIMARY : Variant.LIGHT}
            disabled={disabled}
            tooltipMessage={inactiveTooltipMessage}
            onClick={handleSwitch}
          >
            {inactiveLabel}
          </SwitchButton>
        </LeftCol>
        <RightCol>
          <SwitchButton
            variant={active ? Variant.PRIMARY : Variant.LIGHT}
            disabled={disabled}
            tooltipMessage={activeTooltipMessage}
            onClick={handleSwitch}
          >
            {activeLabel}
          </SwitchButton>
        </RightCol>
      </Row> */}
    </LightPadding>
  )
}

export default Switch

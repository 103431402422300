import { Dispatch, FunctionComponent, useMemo, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Asterisk } from 'react-bootstrap-icons'
import { useUser } from '../../state/modules/auth'
import { useImpersonatingUser } from '../../state/modules/impersonations'
import { IUserReport, IUserReportPatch } from '../../state/modules/userReports'
import { AppModal } from '../common/Modal'
import { TextButton } from '../common/TextButton'
import { getAccessesInfo } from './Access'
import { UserReportExplorer } from './Explorer'
import { ReportCard } from './ReportCard'

interface Props {
  onSetDefault?: Dispatch<IUserReport | undefined>
  defaultReport?: IUserReport
  onOpen?: Dispatch<IUserReport | null>
  onCreateNew?: () => void
  onClose?: () => void
  currentReport?: IUserReport | null
  patch?: IUserReportPatch
  onResetPatch?: () => void
}

export const UserReportPanel: FunctionComponent<Props> = (props) => {
  const {
    onSetDefault,
    defaultReport,
    onOpen,
    onCreateNew,
    onClose,
    currentReport,
    patch,
    onResetPatch,
  } = props

  const [mode, setMode] = useState<'catalog'>()

  const handleOpen = useMemo(
    () =>
      onOpen
        ? (newReport?: IUserReport) => {
            setMode(undefined)
            if (newReport) onOpen?.(newReport)
          }
        : undefined,
    [onOpen]
  )

  const user = useUser()
  const impersonatingUser = useImpersonatingUser()

  const currentReportAccess = useMemo(
    () =>
      currentReport && getAccessesInfo(currentReport, user, impersonatingUser),
    [currentReport, user, impersonatingUser]
  )

  const controlShow = !!(currentReport || patch)

  const currentReportCard = controlShow && (
    <ReportCard
      report={currentReport}
      accessInfo={currentReportAccess || undefined}
      isDefault={
        !!currentReport &&
        !!defaultReport &&
        currentReport._id === defaultReport?._id
      }
      patch={patch}
      onUpdate={onOpen}
      onResetPatch={onResetPatch}
      onSetDefault={onSetDefault}
      onClose={onClose}
      allowDelete={true}
      isSelected={true}
    />
  )

  const createNewButton = !currentReportCard && onCreateNew && (
    <div className="d-flex align-items-center justify-content-center">
      <Button onClick={onCreateNew}>Create new report</Button>
    </div>
  )

  const defaultControlContent = (
    <div style={{ flex: 1 }}>
      {!!defaultReport && (
        <div>
          <span title="Default report will be shown at start">
            <b>Default report: </b>
            {defaultReport.name}
          </span>
          &nbsp;
          <TextButton onClick={onSetDefault && (() => onSetDefault(undefined))}>
            Reset
          </TextButton>
        </div>
      )}
      {!defaultReport && <i>Default report is not set</i>}
      {!!defaultReport && !!onSetDefault && (
        <div>
          <TextButton onClick={() => handleOpen?.(defaultReport)}>
            Open default report
          </TextButton>
        </div>
      )}
    </div>
  )

  const dirtyMark = (
    <b
      style={{
        visibility: patch ? 'visible' : 'hidden',
        color: 'darkorange',
        zoom: 0.6,
      }}
    >
      &nbsp;
      <Asterisk />
    </b>
  )

  const reportDisplay = !!currentReport && (
    <b
      style={{
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '10em',
        textOverflow: 'ellipsis',
      }}
    >
      <i>{currentReport.name}</i>
    </b>
  )
  const stateDisplay = reportDisplay || <>Open report</>

  const catalogModal = mode === 'catalog' && (
    <AppModal show={true} centered onHide={() => setMode(undefined)}>
      <Modal.Body>
        <div
          style={{
            marginBottom: 32,
          }}
        >
          {currentReportCard}
          {createNewButton}
        </div>
        <UserReportExplorer
          onSelect={handleOpen}
          currentReport={currentReport}
          patch={patch}
          defaultReport={defaultReport}
          onSetDefault={onSetDefault}
        />
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        {defaultControlContent}
      </Modal.Footer>
    </AppModal>
  )

  return (
    <>
      <Button onClick={() => setMode('catalog')}>
        <div style={{ display: 'flex' }}>
          {stateDisplay}
          {dirtyMark}
        </div>
      </Button>
      {catalogModal}
    </>
  )
}

import createSlice from './helpers/createSlice'

// Types & Interfaces
export interface IChannelPartner {
  recordId: number
  channelPartnerName: string
  emailDomain: string
  enableProjectsPageCp: boolean
}

// Slice Name
export const sliceName = 'channelPartners'

// Slice URL
const url = '/channel-partners'

// Abstract Slice Instance
const channelPartnersSlice = createSlice<IChannelPartner[]>(sliceName, url)

// Initial state
export const initialState = channelPartnersSlice.initialState

// Slice Selectors Hooks
export const useChannelPartners = channelPartnersSlice.useSlice

// Slice Actions Hooks
export const useLoadChannelPartners = channelPartnersSlice.useLoadSlicePayload

export const useDismissChannelPartnersHTTPErrors =
  channelPartnersSlice.useDismissHTTPErrors

// Slice reducer
export default channelPartnersSlice.reducer

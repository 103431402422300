import styled from 'styled-components'
import { FunctionComponent } from 'react'
import { Icon } from './Icon'

export const IconLabel: FunctionComponent<IconLabelProps> = ({
  iconName,
  label,
}: IconLabelProps) => {
  return (
    <StyledIconLabel>
      <StyledIcon iconName={iconName} />
      <span>{label}</span>
    </StyledIconLabel>
  )
}

interface IconLabelProps {
  iconName: string
  label: string
}

const StyledIconLabel = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledIcon = styled(Icon)`
  margin-right: 0.5rem;
`

import { Reducer } from 'react'

import confirmationReducer, {
  sliceName as confirmationSliceName,
} from './modules/confirmation'
import notificationsReducer, {
  sliceName as notificationsSliceName,
} from './modules/notifications'
import authReducer, { sliceName as authSliceName } from './modules/auth'
import usersReducer, { sliceName as usersSliceName } from './modules/users'
import impersonationsReducer, {
  sliceName as impersonationsSliceName,
} from './modules/impersonations'
import channelPartnersReducer, {
  sliceName as channelPartnersSliceName,
} from './modules/channelPartners'
import entitiesReducer, {
  sliceName as entitiesSliceName,
} from './modules/entities'
import entityPortalAttachmentsReducer, {
  sliceName as entityPortalAttachmentsSliceName,
} from './modules/entityPortalAttachments'
import customerRfpsReducer, {
  sliceName as customerRfpsSliceName,
} from './modules/customerRfps'
import bidHistoryReducer, {
  sliceName as bidHistorySliceName,
} from './modules/bidHistory'
import userInviteTextReducer, {
  sliceName as userInviteTextSliceName,
} from './modules/userInviteText'
import termsOfServiceReducer, {
  sliceName as termsOfServiceSliceName,
} from './modules/termsOfService'
import portalUsersReducer, {
  sliceName as portalUsersSliceName,
} from './modules/portalUsers'
import siteRfpsReducer, {
  sliceName as siteRfpsSliceName,
} from './modules/siteRfps'
import siteRfpFieldsReducer, {
  sliceName as siteRfpFieldsSliceName,
} from './modules/siteRfpsFields'
import changeOrdersReducer, {
  sliceName as changeOrdersSliceName,
} from './modules/changeOrders'
import userReportsReducer, {
  sliceName as userReportsSliceName,
} from './modules/userReports'
import personalSettingsReducer, {
  sliceName as personalSettingsSliceName,
} from './modules/personalSettings'
import documentsReducer, {
  sliceName as documentsSliceName,
} from './modules/rfpDocumentSummaries'

import { IAction } from '../common/types'
import { IState } from './initialState'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function combineReducers(reducers: { [key: string]: Reducer<any, IAction> }) {
  return (state: IState, action: IAction): IState => {
    const keys = Object.keys(reducers) as Array<keyof IState>
    return keys.reduce(
      (newState: IState, key: keyof IState) => ({
        ...newState,
        [key]: reducers[key](state[key], action) as unknown,
      }),
      {} as IState
    )
  }
}

const reducer = combineReducers({
  [confirmationSliceName]: confirmationReducer,
  [notificationsSliceName]: notificationsReducer,
  [authSliceName]: authReducer,
  [usersSliceName]: usersReducer,
  [impersonationsSliceName]: impersonationsReducer,
  [channelPartnersSliceName]: channelPartnersReducer,
  [entitiesSliceName]: entitiesReducer,
  [entityPortalAttachmentsSliceName]: entityPortalAttachmentsReducer,
  [customerRfpsSliceName]: customerRfpsReducer,
  [bidHistorySliceName]: bidHistoryReducer,
  [userInviteTextSliceName]: userInviteTextReducer,
  [termsOfServiceSliceName]: termsOfServiceReducer,
  [portalUsersSliceName]: portalUsersReducer,
  [siteRfpsSliceName]: siteRfpsReducer,
  [siteRfpFieldsSliceName]: siteRfpFieldsReducer,
  [changeOrdersSliceName]: changeOrdersReducer,
  [userReportsSliceName]: userReportsReducer,
  [personalSettingsSliceName]: personalSettingsReducer,
  [documentsSliceName]: documentsReducer,
})

export default reducer

export function stringEllipsis(str: string, maxLength: number) {
  const l = str.length

  if (isNaN(maxLength))
    throw new Error(`maxLength for stringEllipsis should be not less than 4`)

  if (l <= maxLength) return str

  const trimmed = str.trimEnd()
  if (trimmed.length <= maxLength) return trimmed

  if (maxLength < 4)
    throw new Error(`maxLength for stringEllipsis should be not less than 4`)

  return str.substring(0, maxLength - 3).trimEnd() + '...'
}

export const withoutSlashes = (name: string) => {
  const index = name.search(/\/(?!.*\/)/g)
  return ~index ? name.substr(index + 1) : name
}

export const withoutTimestamp = (name: string) => {
  const t = name.match(/([0-9]*)-/)
  if (t) {
    const index = name.search(/([0-9]*)-/)

    return name.substr(index + t[0].length)
  }

  return name
}

export const withoutQuery = (name: string) => {
  const index = name.search(/\?/g)
  return ~index ? name.substr(0, index) : name
}

export function getUrlPath(url: string) {
  return withoutQuery(withoutTimestamp(withoutSlashes(url)))
}

export function stringToBoolean(strBoolean: unknown): boolean {
  const str = String(strBoolean)
  return !(
    str === 'false' ||
    str === '0' ||
    str === 'null' ||
    str === 'undefined' ||
    str === ''
  )
}

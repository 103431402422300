import React, {
  CSSProperties,
  Dispatch,
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { List, SortAlphaDown, SortAlphaDownAlt } from 'react-bootstrap-icons'
import { JsonEditor } from 'jsoneditor-react'
import { Card, ListGroup, Overlay } from 'react-bootstrap'
import styled from 'styled-components'
import { SortDirection } from '../helpers/query'
import { BootstrapTableProps } from './tableTypes'
import { IColumnSettings } from './ColumnSettings'
import { RegisterStickyColumn, useStickyColumnCell } from './useStickyColumns'
import { Filter } from './Filter/Filter'

const Units = styled('span')`
  opacity: 0.6;
`

enum DragDropType {
  TABLE_HEADER = 'TableHeader',
}

interface ITableHeaderProps
  extends Pick<
    BootstrapTableProps,
    | 'moveColumn'
    | 'stickyHeaders'
    | 'setStickyHeaders'
    | 'stickyColumns'
    | 'setStickyColumns'
  > {
  column: IColumnSettings
  index: number
  sortDirection?: SortDirection
  changeSortDirection?: Dispatch<SortDirection | undefined>
  filter?: string
  setFilter?: Dispatch<string | undefined>
  style?: Partial<CSSStyleDeclaration>
  setStyle?: Dispatch<Partial<CSSStyleDeclaration>>
  registerStickyColumn?: RegisterStickyColumn
}

export const TableHeader: FunctionComponent<ITableHeaderProps> = ({
  column,
  index,
  moveColumn,
  style,
  setStyle,
  stickyHeaders,
  setStickyHeaders,
  stickyColumns,
  setStickyColumns,
  sortDirection,
  changeSortDirection,
  filter,
  setFilter,
  registerStickyColumn,
}) => {
  const { label, propName, filterType, properties } = column

  const { units } = properties || {}

  const draggableRef = useRef<HTMLDivElement | null>(null)

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DragDropType.TABLE_HEADER,
      item: () => ({ propName }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [column]
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [collectProp, drop] = useDrop(() => {
    const dropFunction = () => {
      return
    }
    return {
      accept: DragDropType.TABLE_HEADER,
      canDrop: () => true,
      drop: dropFunction,
      collect: () => ({}),
      hover(dragItem: { propName: string }) {
        if (!moveColumn) {
          return
        }
        if (!draggableRef.current) {
          return
        }

        const hoverColumnPropName = column.propName
        const dragColumnPropName = dragItem.propName

        // Don't replace items with themselves
        if (dragColumnPropName !== hoverColumnPropName) {
          moveColumn(dragColumnPropName, hoverColumnPropName)
        }
      },
    }
  }, [moveColumn, column])

  const opacity = isDragging ? 0.2 : 1
  drag(drop(draggableRef))

  const stickyProps = useStickyColumnCell(
    {
      columnIndex: index,
      isHeader: true,
      leftBorderColor: '#dee2e6',
    },
    registerStickyColumn
  )

  const {
    ref: stickyRef,
    style: stickyStyle,
    ...passThroughStickyProps
  } = stickyProps || {}

  const { width = 'auto', resize = 'horizontal' } = style || {}

  const editableStyle = useMemo(
    () => ({
      width,
      resize,
    }),
    [width, resize]
  )

  const [cellEl, setCellEl] = useState<HTMLTableCellElement | null>(null)
  const [nextStyle, setNextStyle] = useState<Partial<CSSStyleDeclaration>>()
  const [offsetWidth, setOffsetWidth] = useState<number>()

  useEffect(() => {
    if (!cellEl) return undefined

    setOffsetWidth(cellEl.offsetWidth)
    const resizeObserver = new ResizeObserver(() => {
      const { offsetWidth } = cellEl
      setOffsetWidth(offsetWidth)
    })
    resizeObserver.observe(cellEl)

    return () => {
      resizeObserver.unobserve(cellEl)
    }
  }, [cellEl])

  useEffect(() => {
    if (!cellEl) return undefined

    const observer = new MutationObserver(() => {
      const { width, resize } = cellEl.style

      setNextStyle((value) => ({
        ...(value || style),
        width,
        resize,
      }))
    })

    observer.observe(cellEl, {
      attributeFilter: ['style'],
    })

    return () => {
      observer.disconnect()
    }
  }, [style, cellEl])

  const displayStyle = nextStyle || style || editableStyle
  const displayStyleWidth = displayStyle?.width
  const styleOverrides = useMemo(() => {
    const overrides: CSSProperties = {}

    if (offsetWidth && displayStyleWidth === 'auto') {
      const viewportWidth = window.innerWidth

      const maxAutoWidth = Math.floor(viewportWidth * 0.6)

      if (offsetWidth > maxAutoWidth) overrides.width = `${maxAutoWidth}px`
    }

    return overrides
  }, [offsetWidth, displayStyleWidth])

  useEffect(() => {
    if (!nextStyle || !setStyle) return undefined

    const t = setTimeout(() => {
      setStyle(nextStyle)
      setNextStyle(undefined)
    }, 1000)

    return () => {
      clearTimeout(t)
    }
  }, [setStyle, nextStyle])

  const combinedRef = (cell: HTMLTableCellElement) => {
    setCellEl(cell)
    stickyRef?.(cell)
  }

  const handleStickTo = useMemo(
    () =>
      setStickyColumns
        ? (unstick = false) =>
            () =>
              setStickyColumns(unstick ? 0 : index + 1)
        : undefined,
    [setStickyColumns, index]
  )

  const toggleStickyHeaders = useMemo(
    () =>
      setStickyHeaders ? () => setStickyHeaders(!stickyHeaders) : undefined,
    [setStickyHeaders, stickyHeaders]
  )

  const [showControl, setShowControl] = useState(false)
  const [controlBtn, setControlBtn] = useState<HTMLDivElement | null>(null)
  const control = (
    <List
      key="sort-toggle"
      className="gentle-control"
      onClick={() => setShowControl(!showControl)}
      role="button"
    />
  )

  const controls: ReactNode[] = []

  if (handleStickTo || toggleStickyHeaders) {
    controls.push(
      <ListGroup variant="flush">
        {stickyColumns !== index + 1 && handleStickTo && (
          <ListGroup.Item action onClick={handleStickTo()}>
            Freeze columns up to this (#{index + 1})
          </ListGroup.Item>
        )}
        {!!stickyColumns && handleStickTo && (
          <ListGroup.Item action onClick={handleStickTo(true)}>
            Unfreeze all columns
          </ListGroup.Item>
        )}
        {toggleStickyHeaders && (
          <ListGroup.Item action onClick={toggleStickyHeaders}>
            {stickyHeaders ? 'Unfreeze headers' : 'Freeze headers'}
          </ListGroup.Item>
        )}
      </ListGroup>
    )
  }
  if (sortDirection || changeSortDirection) {
    controls.push(
      <ListGroup>
        <ListGroup.Item
          action
          active={!sortDirection}
          onClick={() => changeSortDirection?.(undefined)}
        >
          No sort
        </ListGroup.Item>
        <ListGroup.Item
          action
          active={sortDirection === SortDirection.ASC}
          onClick={() => changeSortDirection?.(SortDirection.ASC)}
        >
          <SortAlphaDown />
          &nbsp;Ascending
        </ListGroup.Item>
        <ListGroup.Item
          action
          active={sortDirection === SortDirection.DESC}
          onClick={() => changeSortDirection?.(SortDirection.DESC)}
        >
          <SortAlphaDownAlt />
          &nbsp;Descending
        </ListGroup.Item>
      </ListGroup>
    )
  }

  const controlsPopover = showControl && controlBtn && (
    <Overlay
      target={controlBtn}
      show={true}
      placement="bottom-end"
      rootClose={true}
      onHide={() => setShowControl(false)}
    >
      <Card style={{ zIndex: 1000 }}>
        <Card.Body style={{ gap: '1rem' }}>
          {controls}
          <div className="mt-2 mx-2">
            <JsonEditor
              value={displayStyle || {}}
              onChange={(newStyle: Partial<CSSStyleDeclaration>) =>
                setNextStyle(newStyle)
              }
            />
          </div>
        </Card.Body>
      </Card>
    </Overlay>
  )

  const unitsDisplay = units && (
    <>
      &nbsp;<Units>{units}</Units>
    </>
  )

  const filterInput = !!filterType && (
    <Filter
      properties={properties}
      filterType={filterType}
      value={filter}
      onChange={setFilter}
      onReset={setFilter && (() => setFilter(undefined))}
    />
  )

  return (
    <th
      className={`th`}
      ref={combinedRef}
      style={{
        opacity,
        overflow: 'auto',
        resize: 'horizontal',
        position: 'relative',
        ...stickyStyle,
        ...(editableStyle as CSSProperties),
        ...(displayStyle as CSSProperties),
        ...styleOverrides,
      }}
      {...passThroughStickyProps}
    >
      <div style={{ display: 'flex', flexFlow: 'column', minHeight: '100%' }}>
        <div ref={draggableRef}>
          {controlsPopover}
          {controls.length > 0 && (
            <div className={'header-controls'} ref={setControlBtn}>
              {control}
            </div>
          )}
          {label}
          {unitsDisplay}
        </div>
        <div
          draggable={true}
          onDragStart={(event) => event.preventDefault()}
          style={{
            display: 'flex',
            flexFlow: 'column',
            flex: 1,
            justifyContent: 'flex-end',
          }}
        >
          {filterInput}
        </div>
      </div>
    </th>
  )
}

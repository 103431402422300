import { FunctionComponent } from 'react'
import classnames from 'classnames'
import { TextInput } from './TextInput'
import { ITableCellProps } from './tableProps'

interface ITextInputProps extends ITableCellProps {
  placeholder?: string
}

const tableDefaultStyle = {
  width: '100%',
}

export const Password: FunctionComponent<ITextInputProps> = (props) => {
  const { columnStyle, invalid, dirty, handleTableChanges, tdProps } = props
  if (handleTableChanges) {
    return (
      <TextInput
        {...props}
        value={props.value as string}
        placeholder={'********'}
      />
    )
  }
  return (
    <td
      {...tdProps}
      className={classnames(
        invalid && 'invalidCell',
        dirty && 'dirty-cell',
        tdProps?.className
      )}
      style={{ ...columnStyle, ...tdProps?.style }}
    >
      <div style={{ ...tableDefaultStyle, ...columnStyle }}>********</div>
    </td>
  )
}

import React, { FunctionComponent } from 'react'

export const FieldAssignmentHelp: FunctionComponent = () => (
  <dl>
    <dt>What is Assignment's Source and Mapping?</dt>
    <dd>
      <p>
        Source (or "Source field") is a Quickbase field from which the
        Assignment gets its data.
      </p>
      <p>
        Mapping ("Mapping field") is also a Quickbase field, to which the
        Assignment's changes will be stored (only when user is allowed to write
        to the Mapping).
      </p>
    </dd>

    <dt>
      What are <i>inherited</i> and <i>custom</i> Display properties?
    </dt>
    <dd>
      <p>
        Each assignment column has <b>Display properties</b> ("styling"), like
        units, or decimal places, or currency sign. By default, these properties
        will be <i>inherited</i> from the Source - meaning the data will be
        shown as it is configured in Quickbase.
      </p>

      <p>
        For example, if we have an Assignment column, which shows data from Site
        RFP's "System Size" field, by default, this column would <i>inherit</i>{' '}
        properties of number formatting from the "System Size" field (in the
        Site RFP table). And if the "System Size" styling changed, these{' '}
        <i>inherited</i> properties would update as well.
      </p>

      <p>
        If an Assignment has to be displayed differently than the Source field,
        its Display properties can be <i>customized</i>. In this case changes of
        Source styling won't affect <i>customized</i> properties. Note that the{' '}
        <i>customized</i> properties have no effect on Source styling, it only
        works for the Assignment to which it is specified.
      </p>
    </dd>

    <dt>
      How to tell, should I change styling for Assignment, or Source field?
    </dt>
    <dd>
      <p>
        Most of the time it's better to <i>inherit</i> styling from the Source,
        so, if possible, you'd be better updating Display properties for the
        Source field in Quickbase.
      </p>
      <p>
        However, if the styling of Source field should not be touched, you can{' '}
        <i>customize</i> these properties for Assignment column.
      </p>
      <p>
        Also, Assignments support some extra properties, that are not available
        in Quickbase (like "Zero means empty"). These you always change for
        Assignment.
      </p>
    </dd>

    <dt>
      I have <i>custom</i> Display property, how to reset it and make it{' '}
      <i>inherit</i> from the Source?
    </dt>
    <dd>
      <p>Right-click on the property in the editor, and select "Remove".</p>
    </dd>

    <dt>
      Display properties of the Source field is set, but Assignment should have
      it empty. How do I do that?
    </dt>
    <dd>
      <p>Double click on the property's value and type "null".</p>
      <p>
        Example: Source field has 2 decimal places. To make it empty, set it to
        "null" in the editor. This way the Assignment will have no fixed decimal
        places, and full fraction will be shown, without rounding.
      </p>
    </dd>
  </dl>
)

import createSlice from './helpers/createSlice'

// Types & Interfaces
export interface IUserInviteText {
  text: string
}

export interface IUserInviteTextModifiedState {
  payload: IUserInviteText
}

// Slice Name
export const sliceName = 'userInviteText'

// Slice URL
export const url = '/user-invite-text'

// Slice Default Payload
export const defaultPayload = undefined

// Abstract Slice Instance
const userInviteTextSlice = createSlice<IUserInviteText>(
  sliceName,
  url,
  defaultPayload
)

// Initial state
export const initialState = userInviteTextSlice.initialState

// Slice Selectors Hooks
export const useUserInviteText = userInviteTextSlice.useSlice

// Slice Actions Hooks
export const useLoadUserInviteText = userInviteTextSlice.useLoadSlicePayload

export const useDismissUserInviteTextHTTPErrors =
  userInviteTextSlice.useDismissHTTPErrors

// Slice reducer
export default userInviteTextSlice.reducer

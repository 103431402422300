/* eslint-disable max-lines */
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { isEmpty, isEqual, omitBy } from 'lodash'
import styled from 'styled-components'
import { Button, Form } from 'react-bootstrap'
import { stringToBoolean } from 'deep-cuts'
import { useQuery, useSyncQuery } from '../../state/modules/common'
import { IField, RequestStatus, Variant } from '../../common/types'
import { ErrorsAlertsDisplay } from '../helpers/displayAlerts'
import {
  ISiteRfp,
  useDownloadProjects,
  useDismissSiteRFPsHTTPErrors,
  useProjects,
  ISiteRfpQuery,
} from '../../state/modules/siteRfps'
import { IAuthUser, isBBEUserOrSuperAdmin } from '../../state/modules/auth'
import { BootstrapTable } from '../BootstrapTable/BootstrapTable'
import {
  BootstrapTableProps,
  ITableValidationError,
} from '../BootstrapTable/tableTypes'
import { IEntity } from '../../state/modules/entities'
import { RelatedEntitySelect } from '../invite-user/RelatedEntitySelect'
import { useImpersonations } from '../../state/modules/impersonations'
import { validateProjectChanges } from './helpers'
import { useProjectColumns, useProjectTableSettings } from './columns'
import { makeTableRecordChangesHook } from '../BootstrapTable/RecordChangesHook'
import { ProjectChangesControlPanel } from './ChangesControlPanel'
import { makeRecordsSnapshotHook } from '../BootstrapTable/RecordsSnapshotHook'
import { usePager } from '../Pager'
import { ComponentLoader } from '../common/Loaders'
import { UserReportPanel } from '../UserReport/Panel'
import {
  IUserReport,
  IUserReportPatch,
  userReportToQuery,
  userSettingsToReport,
} from '../../state/modules/userReports'
import {
  makePersonalSettingsHook,
  Settings,
} from '../../state/modules/personalSettings'
import { useFreshReport } from '../UserReport/useFreshReport'
import { UnaccessibleColumnsAlert } from './UnaccessibleColumnsAlert'
import {
  IExportToCSVSettings,
  ExportToCSVSettingsModal,
} from './ExportToCSVSettingsModal'

const useProjectsChanges = makeTableRecordChangesHook<ISiteRfp, 'recordId'>(
  'recordId'
)

const useRecordsSnapshot = makeRecordsSnapshotHook<ISiteRfp, 'recordId'>(
  'recordId'
)

function UserListDropdown({
  relatedEntity,
  setRelatedEntity,
}: {
  relatedEntity?: number
  setRelatedEntity: (entity?: IEntity) => void
}) {
  return (
    <div>
      <Form className="form-inline">
        <span className="text-primary">Client: </span>
        <Form.Group className="mx-2">
          <RelatedEntitySelect
            entity={relatedEntity}
            disabled={false}
            onEntityChange={setRelatedEntity}
            showLabel={false}
          />
        </Form.Group>
      </Form>
    </div>
  )
}

interface Props {
  user?: IAuthUser
  fields: IField[]
  actionButtons?: ReactNode
  readOnly?: boolean
  onOpenChangeOrder?: (_id: string) => void
}

const DEBOUNCE_TIMEOUT = 2000

const emptySettings: Settings = {}
const useSettings = makePersonalSettingsHook(`Projects`)

interface LocalState {
  currentReport?: IUserReport | null
  patch?: IUserReportPatch
}
const emptyLocalState: LocalState = {}

const ProjectTable: FunctionComponent<Props> = (props) => {
  const { user, fields, actionButtons, readOnly, onOpenChangeOrder } = props

  const columns = useProjectColumns(fields)

  const [settings, setSettingsField] = useSettings(emptySettings)
  const { defaultReportId } = settings || {}

  const [defaultReport, setFreshDefaultReport] = useFreshReport(defaultReportId)

  const setDefaultReport = useCallback(
    (report?: IUserReport) => {
      setSettingsField?.('defaultReportId')(report?._id)
        .then(() => {
          setFreshDefaultReport(report)
        })
        .catch(() => {
          throw new Error('setDefaultReport failed')
        })
    },
    [setSettingsField, setFreshDefaultReport]
  )

  const [localState, setLocalState] = useState<LocalState>(emptyLocalState)
  const { currentReport, patch } = localState || {}
  const currentReportId = currentReport?._id

  const setCurrentReport = useCallback(
    (report?: IUserReport | null) =>
      setLocalState(({ currentReport: prevReport, patch }) => ({
        currentReport: report,
        patch:
          !prevReport?._id || prevReport?._id === report?._id
            ? patch
            : undefined,
      })),
    []
  )

  const [currentReportFresh] = useFreshReport(currentReportId)
  useEffect(() => {
    if (currentReportFresh) setCurrentReport(currentReportFresh)
  }, [setCurrentReport, currentReportFresh])

  const [loadReportId, setLoadReportId] = useState<IUserReport['_id']>()
  const [loadReport] = useFreshReport(loadReportId)
  useEffect(() => {
    if (loadReport) {
      setCurrentReport(loadReport)
      setLoadReportId(undefined)
    } else if (defaultReport && currentReport === undefined && !patch) {
      setCurrentReport(defaultReport)
    }
  }, [
    loadReport,
    defaultReport,
    currentReport,
    patch,
    loadReportId,
    setCurrentReport,
  ])

  const setPatch = useCallback(
    (patch?: IUserReportPatch) =>
      setLocalState((prevState) => ({ ...prevState, patch })),
    []
  )
  useEffect(() => {
    if (!patch || !setPatch) return undefined

    if (isEmpty(patch)) return setPatch(undefined)

    const cleanPatch = omitBy(patch || {}, (v, f) =>
      isEqual(v, currentReport?.[f as keyof typeof currentReport])
    )
    if (isEqual(patch, cleanPatch)) return undefined

    setPatch(isEmpty(cleanPatch) ? undefined : cleanPatch)
  }, [patch, setPatch, currentReport])

  const handleOpenReport = useCallback(
    (report?: IUserReport | null) => {
      setCurrentReport(report || null)
      setPatch?.(undefined)
    },
    [setCurrentReport, setPatch]
  )

  const displayReport = useMemo(
    () => ({ ...currentReport, ...patch }),
    [currentReport, patch]
  )

  const handleCreateNewReport = useCallback(() => {
    setCurrentReport(null)
    setPatch(userSettingsToReport({}, columns))
  }, [setCurrentReport, setPatch, columns])

  const userSettings = useProjectTableSettings(columns, displayReport, setPatch)

  const { status: impersonationsStatus } = useImpersonations()

  const [rfpRelatedClient, setRfpRelatedClient] = useState<number>()

  const query = useQuery()

  const [showExportToCSVSettingsModal, setShowExportToCSVSettingsModal] =
    useState(false)

  const [reloadQuery, setReloadQuery] = useState(true)

  useEffect(() => {
    if (!reloadQuery) return undefined

    const queryReportId = query.get('report') || undefined
    if (!queryReportId) return undefined

    setLoadReportId(queryReportId)
    setReloadQuery(false)
  }, [reloadQuery, query])

  useSyncQuery(
    useMemo(
      () => ({
        report: loadReportId || currentReportId,
      }),
      [currentReportId, loadReportId]
    ),
    { exact: true }
  )

  const {
    records: siteRfps,
    totalRecord,
    siteRfpsStatus,
    siteRfpsHTTPErrors,
    loading,
    load,
    query: lastQuery,
  } = useProjects()

  const dismissErrors = useDismissSiteRFPsHTTPErrors()

  const downloadProjects = useDownloadProjects()

  function handleDownloadProjects() {
    setShowExportToCSVSettingsModal(true)
  }

  const pagerProps = usePager()
  const { page, pageSize } = pagerProps

  const [loadQuery, setLoadQuery] = useState<ISiteRfpQuery>()

  const currentQuery = useMemo(
    () =>
      ({
        ...userReportToQuery(displayReport),
        page,
        pageSize,
        rfpRelatedClient,
      } as ISiteRfpQuery),
    [displayReport, page, pageSize, rfpRelatedClient]
  )

  const scheduleLoad = useCallback(
    () => setLoadQuery(currentQuery),
    [currentQuery]
  )
  useEffect(() => {
    if (currentQuery && !isEqual(currentQuery, lastQuery))
      setLoadQuery(currentQuery)
  }, [currentQuery, lastQuery])

  useEffect(() => {
    if (loading || !load || !loadQuery) return undefined

    const timeout = setTimeout(() => {
      setLoadQuery(undefined)
      load(loadQuery).catch(() => {
        throw new Error('Loading failed')
      })
    }, DEBOUNCE_TIMEOUT)
    return () => clearTimeout(timeout)
  }, [loading, load, loadQuery])

  function handleExportToCSVCancel() {
    setShowExportToCSVSettingsModal(false)
  }

  const changesProps = useProjectsChanges({
    readOnly: siteRfpsStatus === RequestStatus.LOADING,
  })

  const changedRecordsSnapshot = useRecordsSnapshot(
    siteRfps || [],
    changesProps.changedRecordsKeys
  )

  const [validationErrors, setValidationErrors] = useState<
    readonly ITableValidationError[]
  >([])

  const topRightMenu: BootstrapTableProps['topRightMenu'] = (children) => (
    <>
      {user &&
        isBBEUserOrSuperAdmin(user) &&
        stringToBoolean(
          process.env
            .REACT_APP_FEATURE_FLAG_ENABLE_EXTERNAL_CLIENT_FILTER_FOR_PROJECTS
        ) && (
          <UserListDropdown
            relatedEntity={rfpRelatedClient}
            setRelatedEntity={(entity: IEntity | undefined) =>
              setRfpRelatedClient(entity?.recordId)
            }
          />
        )}
      <UserReportPanel
        defaultReport={defaultReport}
        onSetDefault={setDefaultReport}
        onOpen={handleOpenReport}
        onCreateNew={handleCreateNewReport}
        onClose={() => handleOpenReport(null)}
        onResetPatch={setPatch && (() => setPatch(undefined))}
        currentReport={currentReport}
        patch={patch}
      />
      {children}
      <Button
        onClick={handleDownloadProjects}
        variant={Variant.PRIMARY}
        disabled={siteRfpsStatus === RequestStatus.LOADING}
      >
        Export To CSV
      </Button>
      <ProjectChangesControlPanel
        fields={fields}
        changesMap={changesProps.changesMap}
        changedRecordsSnapshot={changedRecordsSnapshot}
        resetChanges={changesProps.resetChanges}
        disallowSubmit={validationErrors.length > 0}
        onOpen={onOpenChangeOrder}
        reload={scheduleLoad}
      />
      {actionButtons}
    </>
  )

  const {
    tableProps: { columnsSequence },
  } = userSettings

  const allColumnsSequence = useMemo(
    () => columns.map((c) => c.propName),
    [columns]
  )

  function handleExportToCSVSubmit({
    withFormats,
    exportAllColumns,
  }: IExportToCSVSettings) {
    if (currentQuery) {
      downloadProjects(currentQuery, {
        columnSequence: exportAllColumns ? allColumnsSequence : columnsSequence,
        withHeaders: true,
        withFormats,
      }).catch(() => {
        throw new Error('downloadProjects failed')
      })
    }
    setShowExportToCSVSettingsModal(false)
  }

  useEffect(
    () =>
      setValidationErrors(
        validateProjectChanges(changesProps.changesMap, fields)
      ),
    [changesProps.changesMap, fields]
  )

  if (impersonationsStatus === RequestStatus.LOADING) {
    return <ComponentLoader />
  }

  const siteRfpObjs = siteRfps
    ? siteRfps.map((siteRfp) =>
        Object.keys(siteRfp).reduce(
          (siteRfpObj, key) => ({
            ...siteRfpObj,
            [key]: siteRfp[key as keyof ISiteRfp],
          }),
          {} as { [key: string]: unknown }
        )
      )
    : []

  return (
    <>
      <BootstrapTable
        data={siteRfpObjs}
        validationErrors={validationErrors}
        {...userSettings.tableProps}
        {...changesProps}
        loading={siteRfpsStatus === RequestStatus.LOADING || !!loadQuery}
        headerChildren={
          <>
            <UnaccessibleColumnsAlert
              accessibleColumns={columns}
              displayColumns={currentReport?.columns}
            />
            <ErrorsAlertsDisplay
              errors={siteRfpsHTTPErrors}
              dismiss={dismissErrors}
              className="mb-2"
            />
          </>
        }
        pagerProps={{
          ...pagerProps,
          totalCount: totalRecord,
        }}
        topRightMenu={topRightMenu}
        columnSelection={true}
        sortMode="multi"
        horizontalMargins={'8.33333%'}
        width="max-content"
        readOnly={readOnly}
        scheduleLoad={scheduleLoad}
      />
      <ExportToCSVSettingsModal
        show={showExportToCSVSettingsModal}
        onCancel={handleExportToCSVCancel}
        onSubmit={handleExportToCSVSubmit}
      />
    </>
  )
}
export default styled(ProjectTable)`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .htDimmed {
    background-color: white;
  }
`

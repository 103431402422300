import { AxiosError } from 'axios'
import { FunctionComponent, HTMLAttributes, ReactNode } from 'react'
import { Alert, Button } from 'react-bootstrap'
import styled from 'styled-components'
import { IAPIErrorFormat } from '../../common/types'
import { mergeArrays } from '../../utils/array'
import { isDefined } from '../../utils/typeGuards'
import { isAssignmentFieldsError } from '../Assignment/AssignmentFieldsError'
import { AssignmentFieldsErrorNote } from '../Assignment/AssignmentFieldsErrorNote'

// Example errors = [[firstError, secondError], [], [thirdError]]
export function displayAlerts(...errors: unknown[][]) {
  return mergeArrays(...errors)
    .filter(isDefined) // Filter empty errors
    .map((error, key) => (
      <Alert key={key} variant={'danger'}>
        {String(error)}
      </Alert>
    ))
}

type ErrorItem = IAPIErrorFormat | Error | string | ReactNode

interface Props extends HTMLAttributes<HTMLDivElement> {
  errors?: ErrorItem | readonly ErrorItem[]
  dismiss?: () => void
}

const Wrapper = styled('div')`
  background: rgba(248, 215, 218, 0.6);
  border-radius: 0.25rem;
  & > .controls-row {
    margin: 0.25rem;
    text-align: right;
  }
`

export const ErrorsAlertsDisplay: FunctionComponent<Props> = (props) => {
  const { errors, dismiss, ...divProps } = props

  if (!errors) return null

  const errorsArr = Array.isArray(errors) ? errors : [errors]

  if (errorsArr.length === 0) return null

  const alerts = errorsArr.map((error, index) => {
    let titleNode: ReactNode
    let messageNode: ReactNode = 'Unknown error'
    if (isAssignmentFieldsError(error)) {
      return (
        <AssignmentFieldsErrorNote key={index} assignmentFieldsError={error} />
      )
    } else if (error instanceof AxiosError) {
      titleNode = error.response?.statusText
      const errorData = error.response?.data as { message: string } | undefined
      messageNode = errorData?.message || error.message
    } else if (error instanceof Error) {
      titleNode = error.name
      messageNode = error.message
    } else if (typeof error === 'string') {
      messageNode = error
    } else if (
      error &&
      typeof error === 'object' &&
      typeof (error as IAPIErrorFormat).message === 'string'
    ) {
      const { message, errors = [], status } = error as IAPIErrorFormat

      const messageStatus =
        status !== undefined ? (
          <>
            {message} <small>(status: ${}status)</small>
          </>
        ) : (
          message
        )

      if (Array.isArray(errors) && errors.length) {
        titleNode = messageStatus
        messageNode = (
          <ul>
            {errors.map((err, index) => (
              <li key={index}>{err}</li>
            ))}
          </ul>
        )
      } else {
        messageNode = messageStatus
      }
    } else if (error) {
      messageNode = error as ReactNode
    }

    return (
      <Alert key={index} variant={'danger'}>
        {!!titleNode && <Alert.Heading>{titleNode}</Alert.Heading>}
        {messageNode}
      </Alert>
    )
  })

  const controlsRow = dismiss && (
    <div className="controls-row">
      <Button onClick={dismiss}>Dismiss errors</Button>
    </div>
  )

  return (
    <Wrapper {...divProps}>
      {alerts}
      {controlsRow}
    </Wrapper>
  )
}

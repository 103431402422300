import { Reducer } from 'react'
import { IAction, Variant } from '../../common/types'
import { useDispatch, useState } from './common'

// Slice_name
export const sliceName = 'confirmation'

// Types & Interfaces
export enum ConfirmationStatus {
  REQUESTED = 'requested',
  APPROVED = 'approved',
  DECLINED = 'declined',
}

export interface IConfirmation {
  id: string
  title: string
  message: string
  status: ConfirmationStatus
  variant?: Variant
  approveLabel?: string
  declineLabel?: string
  withConfirmationText?: boolean
  confirmationText?: string
  metadata?: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

// Initial state
// eslint-disable-next-line no-var
export var initialState: IConfirmation | undefined

// Actions
const CONFIRMATION_REQUESTED = `${sliceName}/CONFIRMATION_REQUESTED`
const CONFIRMATION_APPROVED = `${sliceName}/CONFIRMATION_APPROVED`
const CONFIRMATION_DECLINED = `${sliceName}/CONFIRMATION_DECLINED`

// Slice reducers
const reducer: Reducer<IConfirmation | undefined, IAction> = (
  state = initialState,
  { type, payload }
): IConfirmation | undefined => {
  switch (type) {
    case CONFIRMATION_REQUESTED:
      return payload as IConfirmation
    case CONFIRMATION_APPROVED:
      return {
        ...state!,
        status: ConfirmationStatus.APPROVED,
        confirmationText: payload as string | undefined,
      }
    case CONFIRMATION_DECLINED:
      return {
        ...state!,
        status: ConfirmationStatus.DECLINED,
      }
    default:
      return state
  }
}

// Action creators
const confirmationRequested = (confirmation: IConfirmation) => {
  return { type: CONFIRMATION_REQUESTED, payload: confirmation }
}

const confirmationDeclined = () => {
  return { type: CONFIRMATION_DECLINED }
}

const confirmationApproved = (confirmationText?: string) => {
  return { type: CONFIRMATION_APPROVED, payload: confirmationText }
}

// API Selectors Hooks
export const useConfirmation = () => useState()[sliceName]

// API Actions Hooks
const generateID = () => new Date().toISOString()

export interface IConfirmationRequest extends Partial<IConfirmation> {
  title: string
  message: string
}
export const useSendConfirmation = () => {
  const dispatch = useDispatch()
  return (confirmationRequest: IConfirmationRequest) => {
    const id = generateID()
    dispatch(
      confirmationRequested({
        id,
        status: ConfirmationStatus.REQUESTED,
        ...confirmationRequest,
      })
    )
    return id
  }
}

export const useApproveConfirmation = () => {
  const dispatch = useDispatch()
  return (confirmationText?: string) => {
    dispatch(confirmationApproved(confirmationText))
  }
}

export const useDeclineConfirmation = () => {
  const dispatch = useDispatch()
  return () => {
    dispatch(confirmationDeclined())
  }
}

export default reducer

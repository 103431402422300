import {
  IAPIErrorFormat,
  PaginatedRecords,
  PaginatedQuery,
  Variant,
  RequestStatus,
} from '../../common/types'
import { TableName } from '../../components/BootstrapTable/tableTypes'
import { useApiWithIDToken, useDispatch } from './common'
import createSlice from './helpers/createSlice'
import { useNotifications } from './notifications'
import { useEditLevel, useIDToken } from './auth'
import { fullEncodeURIComponent } from '../../utils/url'
import { useMemo } from 'react'

// Types & Interfaces
export enum SiteRfpsProps {
  // NOTE - These are the properties we got from Sam on 3/25
  RFP_NAME = 'rfpName',
  PROPERTY_NAME = 'propertyName',
  SITE_NOTES = 'siteNotes',
  CLIENT_NEXT_ACTIONS = 'clientNextActions',
  CONSTRUCTION_NOTES = 'constructionNotes',
  KANBAN_STATUS_SITE_RFP = 'kanbanStatusSiteRfp',
  CURRENT_SYSTEM_SIZE_MW = 'currentSystemSizeMw',
  CONSTRUCTION_STATUS = 'constructionStatus',
  ROOF_STATUS = 'roofStatus',
  ROOF_INSTALL_YEAR = 'roofInstallYear',
  ROOF_AGE_BUILT_YEAR_INSTALL_REPLACE = 'roofAgeBuiltYearInstallReplace',
  ROOF_RIGHTS_TENANT_CONSENT_STATUS = 'roofRightsTenantConsentStatus',
  LENDER_CONSENT = 'lenderConsent',
  LENDER_NAME = 'lenderName',
  JOINT_VENTURE = 'jointVenture',
  ADDRESS = 'address',
  CITY = 'city',
  STATE = 'state',
  SOLAR_ARRAY_LOCATION = 'solarArrayLocation',
  ARRAY_AREA = 'arrayArea',
  NTP_DATE_ESTIMATE = 'ntpDateEstimate',
  MANUAL_PROSPECTOR_LINK = 'manualProspectorLink',

  // NOTE - These are the new default props, pre-reporting.
  CLIENT = 'rfpClientName',
  SITE_NAME = 'propertyNameObsf',
  UTILITY = 'relatedClientPropertyFinalUtilityName',
  // eslint-disable-next-line
  KANBAN = 'kanbanStatusSiteRfp',
  SYSTEM_SIZE = 'systemSizeBid',
  RENT_NOI = 'rentCurrent',
  SOLAR_PROVIDER = 'awardedChannelPartnerName',

  // NOTE - These are the legacy props, some may come back.
  RFP_STATUS = 'rfpStatus',
  DATE_CREATED = 'dateCreated',
  FEE = 'siteProjectFee',
}

export interface ISiteRfp {
  recordId: number
  rfpName: string
  rfpStatus: string
  dateCreated: string
  city: string
  currentSystemSizeMw: number
  siteProjectFee: number
  utility: string
}

export interface ISiteRfpQuery extends PaginatedQuery {
  rfpRelatedClient?: number
  sort?: string | null
  includeAll?: boolean
  withFormats?: boolean
  select?: string
  withHeaders?: boolean
}

// Slice Name
export const sliceName = 'siteRfps'

// Slice Name
export const fieldsSliceName = 'siteRfpsFields'

// Slice URL
export const url = '/site-rfps'

// Abstract Slice Instance
export const siteRfpsSlice = createSlice<null | PaginatedRecords<
  ISiteRfp,
  ISiteRfpQuery
>>(sliceName, url, null)
const reducer = siteRfpsSlice.reducer

// data {payload, status}
export const useSiteRfps = siteRfpsSlice.useSlice

// Initial state
export const initialState = siteRfpsSlice.initialState

export function useProjects() {
  const isReady = !!useIDToken()
  const dispatch = useDispatch()
  const apiWithIDToken = useApiWithIDToken()

  const {
    payload,
    status: siteRfpsStatus,
    httpErrors: siteRfpsHTTPErrors,
  } = useSiteRfps()

  const [editLevel] = useEditLevel()

  const load = useMemo(
    () =>
      isReady
        ? async (query: ISiteRfpQuery) => {
            dispatch(siteRfpsSlice.requestLoading())
            try {
              const siteRfpsResponse = await apiWithIDToken.get('/site-rfps', {
                params: {
                  ...query,
                  editLevel,
                },
              })
              dispatch(
                siteRfpsSlice.requestLoaded({
                  ...(siteRfpsResponse.data as PaginatedRecords<
                    ISiteRfp,
                    ISiteRfpQuery
                  >),
                  query,
                })
              )
            } catch (error) {
              dispatch(siteRfpsSlice.requestFailed(error as IAPIErrorFormat))
            }
          }
        : undefined,
    [isReady, dispatch, apiWithIDToken, editLevel]
  )

  return {
    ...payload,
    siteRfpsStatus,
    siteRfpsHTTPErrors,
    loading: siteRfpsStatus === RequestStatus.LOADING,
    load,
  }
}

export const useDismissSiteRFPsHTTPErrors = siteRfpsSlice.useDismissHTTPErrors

export const useDownloadProjects = () => {
  const apiWithIDToken = useApiWithIDToken()
  const { send: sendNotification } = useNotifications()
  const [editLevel] = useEditLevel()
  const downloadProjects = (query: ISiteRfpQuery) =>
    apiWithIDToken.get('/site-rfps/read-only-files', {
      params: { ...query, editLevel },
    })
  return async (
    siteRfpQuery: ISiteRfpQuery,
    {
      columnSequence,
      withHeaders,
      withFormats,
    }: {
      columnSequence: string[]
      withHeaders?: boolean
      withFormats?: boolean
    }
  ) => {
    sendNotification({
      title: 'Preparing download',
      message: 'Fetching projects data for download.',
      variant: Variant.INFO,
      timeoutLimit: 15000,
    })
    try {
      const select = fullEncodeURIComponent(columnSequence.join(','))
      const response = await downloadProjects({
        ...siteRfpQuery,
        select,
        withHeaders,
        withFormats,
      })
      const downloadUrl = window.URL.createObjectURL(
        new Blob([response.data as BlobPart])
      )
      const fileName = `${TableName.PROJECTS}.csv`
      const link = document.createElement('a')
      link.href = downloadUrl!
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      sendNotification({
        title: 'Download failed',
        message: 'Failed to download projects table!',
        variant: Variant.DANGER,
        metadata: error,
      })
    }
  }
}

export default reducer

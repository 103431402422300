/* eslint-disable max-lines */
import { Table, Row, Button, Alert } from 'react-bootstrap'
import { FunctionComponent, useState, useMemo, useRef } from 'react'
import useSize from '@react-hook/size'
import styled from 'styled-components'
import { FaSort } from 'react-icons/fa'
import { identity } from 'lodash'
import { ComponentLoader } from '../common/Loaders'
import { PRIMARY } from '../common/BlackBearEnergy'
import {
  ColGroup,
  SearchBar,
  ColumnSelection,
  TableRow,
} from '../tableComponents'
import { TableHeader } from './TableHeader'
import { BootstrapTableProps } from './tableTypes'
import { IColumnSettings } from './ColumnSettings'
import ExportToCSVButton from '../ExportToCSVButton'
import MultiColSortModal from '../MultiColSortModal'
import { useStickyColumns } from './useStickyColumns'
import { useBootstrapTableMarginBanners } from './MarginBanners'
import { useValidationErrors } from './useValidationErrors'
import { Pager } from '../Pager'
import { ISortFilter, SortDirection } from '../helpers/query'

const BootstrapTableCmp: FunctionComponent<BootstrapTableProps> = (props) => {
  const {
    tableName,
    columns,
    columnsSequence: columnsSequenceInput,
    loading,
    data,
    changesMap,
    handleTableChanges,
    moveColumn,
    toggleColumn,
    stickyHeaders = false,
    setStickyHeaders,
    stickyColumns = 0,
    setStickyColumns,
    sort,
    setSort,
    setColumnSort,
    filter,
    setColumnFilter,
    columnsStyles,
    setColumnStyle,
    rowKeyPropName,
    searchBar,
    columnSelection,
    enableExportToCSV,
    topRightMenu = identity,
    sortMode,
    horizontalMargins = '0px',
    width = `calc(100% - 2 * ${horizontalMargins})`,
    className,
    headerChildren,
    pagerProps,
    readOnly,
    scheduleLoad,
  } = props

  const columnsSequence = useMemo(
    () => columnsSequenceInput || columns.map((col) => col.propName),
    [columnsSequenceInput, columns]
  )

  const displayColumns = useMemo(
    () =>
      columnsSequence?.map(
        (name) =>
          columns.find((col) => col.propName === name) as IColumnSettings
      ) || columns,
    [columnsSequence, columns]
  )

  const {
    validationErrorsAlerts,
    hiddenColumnsWithErrors,
    showColumnsWithErrors,
    getCellValidationErrors,
  } = useValidationErrors(props)

  const [searchFilter, setSearchFilter] = useState<string>('')

  const pager = pagerProps && (
    <div>
      <Pager {...pagerProps} />
    </div>
  )

  const registerStickyColumn = useStickyColumns(
    stickyColumns,
    horizontalMargins
  )

  const headerRef = useRef<HTMLDivElement>(null)
  const headerSize = useSize(headerRef)
  const headerHeight = headerSize[1]

  const { tableRef, marginBanners } =
    useBootstrapTableMarginBanners(horizontalMargins)

  const rootClassName = `${className} ${stickyHeaders ? 'sticky-headers' : ''}`

  const columnSortDict = useMemo(
    () =>
      sort?.reduce(
        (dict, { key, direction }) => ({ ...dict, [key]: direction }),
        {} as Record<string, SortDirection>
      ),
    [sort]
  )
  const [showSortModal, setShowSortModal] = useState(false)
  const sortModalButton = sortMode === 'multi' && (
    <>
      <Button onClick={() => setShowSortModal(true)}>
        <FaSort />
      </Button>
      <MultiColSortModal
        show={showSortModal}
        onHide={() => setShowSortModal(false)}
        sort={sort}
        columns={columns}
        onSort={
          setSort &&
          ((sort: ISortFilter[]) => {
            setSort(sort)
            setShowSortModal(false)
            scheduleLoad?.()
          })
        }
      />
    </>
  )

  const header = (
    <div
      ref={headerRef}
      style={{
        paddingLeft: horizontalMargins,
        paddingRight: horizontalMargins,
      }}
      className={`${rootClassName} pb-2 header`}
    >
      {loading && <ComponentLoader className="loading" />}
      {headerChildren}
      <div
        className="header-controls"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <div style={{ gap: '1rem' }} className="d-flex justify-content-between">
          {pager}
          {searchBar && <SearchBar setSearchFilter={setSearchFilter} />}
        </div>
        <div className={'top-right-toolbar'} style={{ alignSelf: 'end' }}>
          {topRightMenu(
            <>
              {columnSelection && (
                <ColumnSelection
                  displayColumns={displayColumns}
                  columns={columns}
                  toggleColumn={toggleColumn}
                />
              )}
              {sortModalButton}
            </>
          )}
        </div>
      </div>
      {validationErrorsAlerts}
      {!!hiddenColumnsWithErrors?.length && (
        <Alert>
          Some columns with validation errors are hidden.
          {showColumnsWithErrors && (
            <Button onClick={showColumnsWithErrors}>Show these columns</Button>
          )}
        </Alert>
      )}
      {marginBanners}
    </div>
  )

  const table = (
    <Table
      ref={tableRef}
      size="sm"
      className={`btable ${rootClassName}`}
      style={{
        marginLeft: horizontalMargins,
        marginRight: horizontalMargins,
        width,
      }}
      cellSpacing={0}
    >
      <ColGroup columns={displayColumns} />

      <thead
        style={{
          top: stickyHeaders ? headerHeight || 0 : undefined,
        }}
      >
        <tr>
          {displayColumns?.map((column, index) => {
            return (
              <TableHeader
                key={column.propName}
                index={index}
                column={column}
                moveColumn={moveColumn}
                sortDirection={columnSortDict?.[column.propName]}
                changeSortDirection={setColumnSort?.(column.propName)}
                filter={filter?.[column.propName]}
                setFilter={setColumnFilter?.(column.propName)}
                style={columnsStyles?.[column.propName]}
                setStyle={setColumnStyle?.(column.propName)}
                registerStickyColumn={registerStickyColumn}
                stickyColumns={stickyColumns}
                setStickyColumns={setStickyColumns}
                stickyHeaders={stickyHeaders}
                setStickyHeaders={setStickyHeaders}
              />
            )
          })}
        </tr>
      </thead>

      <tbody>
        {data.map((rowData: { [key: string]: unknown }, rowIndex: number) => {
          return (
            <TableRow
              key={
                rowKeyPropName !== undefined
                  ? (rowData[rowKeyPropName] as string | number)
                  : rowIndex
              }
              rowIndex={rowIndex}
              columns={displayColumns}
              columnsStyles={columnsStyles}
              defaultColumnOrder={columns}
              rowData={rowData}
              change={changesMap?.get(
                rowKeyPropName !== undefined
                  ? (rowData[rowKeyPropName] as string | number)
                  : rowIndex
              )}
              handleTableChanges={readOnly ? undefined : handleTableChanges}
              getCellValidationErrors={getCellValidationErrors}
              rowKeyPropName={rowKeyPropName}
              searchFilter={searchFilter}
              registerStickyColumn={registerStickyColumn}
            />
          )
        })}
      </tbody>
    </Table>
  )

  return (
    <>
      {data?.length ? (
        enableExportToCSV ? (
          <ExportToCSVButton
            columns={columns}
            data={data}
            tableName={tableName}
          />
        ) : null
      ) : (
        <Row className="d-flex justify-content-center">No Results</Row>
      )}

      {header}

      {table}

      {!stickyHeaders && pager && (
        <div
          className={`${rootClassName} pagination-bottom`}
          style={{
            paddingLeft: horizontalMargins,
            paddingRight: horizontalMargins,
          }}
        >
          {pager}
        </div>
      )}
    </>
  )
}

export const BootstrapTable = styled(BootstrapTableCmp)`
  font-size: 0.6rem !important;

  &.table {
    border-collapse: separate;
  }

  &.header {
    display: flex;
    flex-flow: column;
    align-items: stretch;
    position: sticky;
    left: 0;
    width: 100%;
    background: white;
    z-index: 3;
  }

  &.header.sticky-headers {
    top: 0;
  }

  &.header .header-controls {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 1rem;
  }

  &.header > .loading {
    position: absolute;
    top: 0px;
    left: 50%;
  }

  & .top-right-toolbar {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
    justify-content: end;
  }

  &.table thead {
    position: relative;
    z-index: 2;
  }

  &.table.sticky-headers thead {
    position: sticky;
    background: white;
  }

  &.table tbody {
    position: relative;
    z-index: 1;
  }

  &.pagination-bottom {
    position: sticky;
    left: 0;
  }

  &.table td {
    vertical-align: middle;
    border-top-width: 0px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left-width: 0px;
    background-color: white;
  }

  &.table th {
    vertical-align: top;
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left-width: 0px;
    background: rgb(240, 240, 240);
  }

  &.table td:first-child,
  &.table th:first-child {
    border-left: 1px solid #ccc;
  }

  &.table td.dirty-cell {
    background: lightyellow;
  }

  &.table .disabled {
    cursor: not-allowed;
  }
  .pending-invitation-container {
    background-color: #f0f0f0;
    text-align: center;
  }
  &.table .no-border-top {
    border-top: None;
  }
  &.table .no-padding {
    padding: 0px;
  }
  &.table .gray-text {
    color: #777;
  }
  &.table .text-multi-line {
    white-space: pre-wrap;
    text-align: left;
  }

  &.table td.read-only {
    background-color: #f0f0f0;
  }

  &.table td.invalidCell {
    position: relative;
    color: #721c24;
    background-color: #f8d7da;
  }
  &.table td.invalidCell:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-left: 6px solid transparent;
    border-top: 6px solid ${PRIMARY};
  }

  &.table td.dirty-cell {
    background: lightyellow;
  }

  &.table th,
  &.table td {
    height: 30px;
    text-align: center;
  }
  &.table tr {
    cursor: cell;
  }
  &.table .input {
    box-shadow: 0 0 0 2px ${PRIMARY} inset;
  }
  &.table td:before,
  .wtBorder {
    background-color: ${PRIMARY} !important;
  }

  &.table th {
    position: relative;
  }

  &.table th .header-controls {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
  }

  &.table th .header-controls > [role='button'] {
    min-width: 2em;
    min-height: 1.8em;
    border: 1px solid transparent;
    border-radius: 0.3rem;
    margin: 0 0.3rem 0 0;
    background: rgb(240, 240, 240);
  }

  &.table th:focus-within .header-controls > [role='button'],
  &.table th:hover .header-controls > [role='button'] {
    border: 1px solid #aaa;
  }

  &.table th .gentle-control {
    opacity: 0;
  }

  &.table th:focus-within .gentle-control,
  &.table th:hover .gentle-control {
    opacity: 1;
  }

  &.loading-indicator {
    position: absolute;
    width: 100%;
    top: 40px;
    z-index: 1;
  }

  @media (min-width: 576px) {
    font-size: 0.7rem !important;
  }
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 0.8rem !important;
  }
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    font-size: 0.9rem !important;
  }
  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    font-size: 1rem !important;
  }
`

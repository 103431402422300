/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Container,
  Form,
} from 'react-bootstrap'
import { omit } from 'lodash'
import {
  FunctionComponent,
  useState,
  useEffect,
  useMemo,
  ChangeEvent,
  KeyboardEvent,
} from 'react'
import numeral from 'numeral'
import classnames from 'classnames'
import { TextInput } from './BootstrapTable/TextInput'
import { Password } from './BootstrapTable/Password'
import { Checkbox } from './BootstrapTable/Checkbox'
import { DropdownComponent } from './BootstrapTable/Dropdown'
import {
  ColGroupProps,
  SearchBarProps,
  TableCellProps,
  TableRowProps,
  ColumnSelectionProps,
  ColumnOptionProps,
  ColumnSelectionModalProps,
} from './BootstrapTable/tableProps'
import {
  applyCellFormat,
  joinArrayValues,
  displayBlankForNil,
} from './tableHelperFunctions'
import { FieldType } from '../common/types'
import { useStickyColumnCell } from './BootstrapTable/useStickyColumns'
import { UrlButton } from './BootstrapTable/UrlButton'
import { firstDefined } from '../utils/typeGuards'
import { IColumnSettings } from './BootstrapTable/ColumnSettings'
import { orderBy } from '../utils/array'
import { quickbaseNumberFormat } from '../utils/quickbaseNumberFormat'

const makeValueSanitizer = (
  column: IColumnSettings
):
  | ((rawValue: string | number | null | undefined) => string | undefined)
  | undefined => {
  const { fieldType } = column

  if (fieldType === 'numeric') {
    const { properties } = column

    const { decimalPlaces, zeroMeansEmpty = false } = properties || {}

    return (value, fancyFormat?: boolean) => {
      if (typeof value === 'string' && !value.trim()) return ''
      const num = numeral(value).value()
      if (num === null) return undefined
      if (isNaN(num)) return undefined

      if (!fancyFormat || !properties) {
        if (typeof decimalPlaces === 'number') return num.toFixed(decimalPlaces)
        else return num.toString()
      }
      if (num === 0 && zeroMeansEmpty) return ''

      return quickbaseNumberFormat(num, properties)
    }
  }

  return undefined
}

export const TableCell: FunctionComponent<TableCellProps> = (
  props: TableCellProps
) => {
  const {
    rowKey,
    cellIndex,
    column,
    style,
    cellData,
    rowData,
    dirty,
    handleTableChanges,
    registerStickyColumn,
    getCellValidationErrors,
  } = props

  const columnStyle = useMemo(
    () =>
      style
        ? // Removing styles that should be applied only to column's header.
          (omit(style, 'width', 'resize') as React.CSSProperties)
        : column.columnStyle,
    [style, column]
  )

  // eslint-disable-next-line prefer-const
  let { readOnly, comment, renderer, type, propName, fieldType, properties } =
    column

  if (typeof readOnly === 'function') {
    readOnly = readOnly(propName, rowKey)
  }

  if (typeof comment === 'function') {
    comment = comment(propName, rowKey)
  }

  const onChange = useMemo(
    () =>
      handleTableChanges
        ? (newValue: unknown) =>
            handleTableChanges([
              { rowKey, propName, oldValue: cellData, newValue },
            ])
        : undefined,
    [handleTableChanges, rowKey, propName, cellData]
  )

  let cellDataRendered = cellData
  if (renderer) {
    try {
      cellDataRendered = renderer(null, rowKey, cellData, {
        onChange,
        rowData,
      })
    } catch (err) {
      console.error({ err, propName, column })
    }
  }

  const validationErrors = getCellValidationErrors?.(rowKey, propName)

  const stickyProps = useStickyColumnCell(
    { columnIndex: cellIndex, leftBorderColor: '#dee2e6' },
    registerStickyColumn
  )

  const invalid = !!validationErrors?.length

  const cellProps = applyCellFormat(
    {
      value: displayBlankForNil(joinArrayValues(cellDataRendered)),
      handleTableChanges: readOnly ? undefined : handleTableChanges,
      rowKey,
      propName,
      columnStyle,
      invalid,
      comment,
      renderer,
      tdProps: {
        key: `${btoa(rowKey)} ${propName}`,
        ...stickyProps,
        className: readOnly ? 'read-only' : undefined,
      },
      dirty,
    },
    fieldType
  )

  const fieldTypeEditable = [
    fieldType === FieldType.TEXT,
    fieldType === FieldType.NUMERIC,
    // fieldType === FieldType.MULTCHOICE,
    fieldType === FieldType.DATE,
  ]

  if (type === 'url') {
    return <UrlButton {...cellProps} value={cellProps.value as string | URL} />
  } else if (type === 'checkbox' || fieldType === FieldType.CHECKBOX) {
    return <Checkbox {...cellProps} value={cellProps.value as boolean} />
  } else if (type === 'dropdown' || fieldType === FieldType.MULTCHOICE) {
    return (
      <DropdownComponent
        {...cellProps}
        options={(column.source || column?.properties?.choices) as string[]}
      />
    )
  } else if (type === 'text-input' || fieldTypeEditable.includes(true)) {
    return (
      <TextInput
        {...cellProps}
        value={cellProps.value as string}
        sanitizer={makeValueSanitizer(column)}
        selectOnFocus={fieldType === 'numeric'}
        type={fieldType === FieldType.DATE ? 'date' : undefined}
        properties={properties}
      />
    )
  } else if (type === 'password') {
    return <Password {...cellProps} />
  }
  // show tooltip when hover
  if (comment) {
    return (
      <td key={cellIndex} {...cellProps.tdProps}>
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id="disabled-until-customize">{comment.value}</Tooltip>
          }
        >
          <div>{cellProps.value}</div>
        </OverlayTrigger>
      </td>
    )
  }

  return (
    <td
      {...cellProps.tdProps}
      className={classnames(
        'gray-text',
        invalid && 'invalidCell',
        properties?.multiline && 'text-multi-line',
        dirty && 'dirty-cell',
        cellProps.tdProps?.className
      )}
      style={{ ...columnStyle, ...cellProps?.tdProps?.style }}
      key={`${rowKey}|${cellIndex}`}
    >
      {/* if renderer exist, cellData type is element and should not get stringified */}
      {!renderer && typeof cellProps.value !== 'string'
        ? JSON.stringify(cellProps.value)
        : cellProps.value}
    </td>
  )
}

export const TableRow: FunctionComponent<TableRowProps> = (
  props: TableRowProps
) => {
  const [hide, setHide] = useState<boolean>(false)
  const {
    columns,
    columnsStyles,
    rowIndex,
    rowData,
    change,
    handleTableChanges,
    searchFilter,
    registerStickyColumn,
    rowKeyPropName,
    getCellValidationErrors,
  } = props

  const cellsPropsArr = useMemo(
    () =>
      columns.map((column, cellIndex) => ({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        cellData: firstDefined(
          change?.[column.propName as keyof typeof change],
          rowData[column.propName]
        ),
        dirty: change?.[column.propName as keyof typeof change] !== undefined,
        column,
        style: columnsStyles?.[column.propName],
        rowIndex,
        cellIndex,
      })),
    [columns, columnsStyles, rowData, change, rowIndex]
  )

  const rowKey = rowKeyPropName
    ? (rowData[rowKeyPropName] as string)
    : rowIndex.toString()

  // search feature, will hide row that does not match
  useEffect(() => {
    setHide(true)
    if (searchFilter.length === 0 || cellsPropsArr.length === 0) {
      setHide(false)
      return
    }
    const regex = new RegExp(searchFilter, 'i')
    for (const item of cellsPropsArr) {
      const cellData = item.cellData as { text?: unknown }
      // for RFP object since the text data is inside an object
      if (cellData.text) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        item.cellData = cellData.text
      }
      if (regex.test(String(item.cellData))) {
        setHide(false)
      }
    }
  }, [searchFilter])

  return (
    <>
      {!hide && (
        <tr className={`tr`} key={rowKey}>
          {cellsPropsArr.map((cellProps, cellIndex) => {
            return (
              <TableCell
                key={cellIndex}
                {...cellProps}
                handleTableChanges={handleTableChanges}
                rowKey={rowKey}
                rowData={rowData}
                rowKeyPropName={rowKeyPropName}
                getCellValidationErrors={getCellValidationErrors}
                registerStickyColumn={registerStickyColumn}
              />
            )
          })}
        </tr>
      )}
    </>
  )
}

export const SearchBar: FunctionComponent<SearchBarProps> = ({
  setSearchFilter,
}) => {
  const [textValue, setTextValue] = useState<string>('')
  function handleSearch() {
    setSearchFilter(textValue)
    return
  }

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setTextValue(() => e.target.value)
  }

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setSearchFilter(textValue)
    }, 500)
    return () => clearTimeout(timeOutId)
  }, [textValue])

  return (
    <div className="d-flex">
      <Form.Control
        type="text"
        value={textValue}
        onChange={onChange}
        placeholder={'Search this page'}
        onKeyDown={handleKeyDown}
        className="my-2 "
      />
    </div>
  )
}

export const ColumnSelection: FunctionComponent<ColumnSelectionProps> = ({
  columns,
  displayColumns,
  toggleColumn,
}) => {
  const [show, setShow] = useState<boolean>(false)
  function openMenu() {
    setShow(!show)
  }

  return (
    <>
      <Button onClick={openMenu}>Columns</Button>
      <ColumnOptionList
        columns={columns}
        displayColumns={displayColumns}
        toggleColumn={toggleColumn}
        show={show}
        setShow={setShow}
      />
    </>
  )
}

export const ColumnOptionList: FunctionComponent<ColumnSelectionModalProps> = ({
  columns,
  displayColumns,
  toggleColumn,
  show,
  setShow,
}) => {
  const columnsSorted = useMemo(
    () => orderBy([...columns], ({ label }) => label),
    [columns]
  )

  const activeColumns = useMemo(
    () => displayColumns.map(({ propName }) => propName),
    [displayColumns]
  )

  const [searchText, setSearchText] = useState<string>('')

  const [columnListSearchFilter, setColumnListSearchFilter] =
    useState<string>('')

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setColumnListSearchFilter(searchText)
    }, 500)
    return () => clearTimeout(timeOutId)
  }, [searchText])

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setSearchText(() => e.target.value)
  }
  function closeMenu() {
    setShow(false)
  }

  return (
    <Modal animation={false} show={show} centered>
      <Modal.Header>
        <h4>Select Column</h4>
      </Modal.Header>
      <Modal.Body>
        <Container style={{ padding: '2px' }}>
          <Form.Control
            style={{
              width: '100%',
              marginBottom: '10px',
            }}
            type="text"
            value={searchText}
            onChange={onChange}
            placeholder={'Search...'}
          />
          <Container
            style={{ overflow: 'auto', height: '100%', maxHeight: '50vh' }}
            className="pl-0 pr-1"
          >
            {columnsSorted.map((column, index: number) => {
              return (
                <ColumnOption
                  column={column}
                  toggleColumn={toggleColumn}
                  columnListSearchFilter={columnListSearchFilter}
                  isChecked={activeColumns.includes(column.propName)}
                  key={`columnOption${index}`}
                />
              )
            })}
          </Container>
        </Container>
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button className="px-4" onClick={closeMenu} disabled={false}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const ColumnOption: FunctionComponent<ColumnOptionProps> = ({
  column,
  toggleColumn,
  columnListSearchFilter,
  isChecked,
}) => {
  const { label: columnLabel, propName } = column

  const [checked, setChecked] = useState<boolean>(isChecked || false)

  const show = useMemo(() => {
    if (columnListSearchFilter === '') return true
    const regex = new RegExp(columnListSearchFilter, 'i')
    if (regex.test(columnLabel)) {
      return true
    } else {
      return false
    }
  }, [columnLabel, columnListSearchFilter])

  const handleCheckbox = useMemo(
    () =>
      toggleColumn
        ? (e: ChangeEvent<HTMLInputElement>) => {
            setChecked(!isChecked)
            toggleColumn(propName, !!e.target.checked)
          }
        : undefined,
    [toggleColumn, propName]
  )

  return (
    <>
      {show && (
        <div className="d-flex" style={{ width: '100%' }}>
          <label
            style={{ display: 'block', cursor: 'pointer' }}
            className="px-1 py-1"
          >
            <input
              type="checkbox"
              checked={checked}
              onChange={handleCheckbox}
            />
            &nbsp;
            {columnLabel}
          </label>
        </div>
      )}
    </>
  )
}

export const ColGroup: FunctionComponent<ColGroupProps> = (props) => {
  const { columns } = props

  const defaultStyle: React.CSSProperties = {
    width: 'auto',
    textAlign: 'center',
  }

  return (
    <colgroup>
      {columns.map((setting, index) => {
        return (
          <col
            span={1}
            key={index}
            style={{ ...defaultStyle, ...setting?.columnStyle }}
          />
        )
      })}
    </colgroup>
  )
}

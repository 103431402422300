import * as Bootstrap from 'react-bootstrap'
import { IStyledComponentPropTypes, Variant } from '../../common/types'
import React, { useLayoutEffect } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import styled from 'styled-components'
import { rendererTdArg } from '../BootstrapTable/ColumnSettings'

interface ILinkWithTooltipPropTypes extends IStyledComponentPropTypes {
  text: string
  disabled: boolean
  onClick: () => void
}

const RFPLinkWithTooltip = styled(function ({
  text,
  disabled = false,
  onClick,
  className,
}: ILinkWithTooltipPropTypes) {
  const tooltipId = `tooltip-${text}`

  useLayoutEffect(() => {
    if (text) {
      document.getElementById(tooltipId)?.remove() // Force remove old tooltip if component is reconstructed
    }
  }, [text, tooltipId])

  if (text === '') return <></>

  const getOverlay = () => <Tooltip id={tooltipId}>{text}</Tooltip>

  return (
    <OverlayTrigger placement="top" overlay={getOverlay()}>
      <Bootstrap.Button
        className={`btn-link ${className}`}
        disabled={disabled}
        onClick={
          !disabled
            ? onClick
            : () => {
                return
              }
        }
      >
        {text}
      </Bootstrap.Button>
    </OverlayTrigger>
  )
})`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: inherit !important;
  text-align: left;
`

function createRfpRenderer({
  value,
  onClick,
  isDisabled,
}: {
  value: { text: string; token: string; active: boolean }
  onClick: () => void
  isDisabled?: () => boolean
}) {
  // eslint-disable-next-line react/display-name
  return () => {
    const { text } = value
    return (
      <RFPLinkWithTooltip
        text={text}
        disabled={isDisabled ? isDisabled() : false}
        onClick={onClick}
      />
    )
  }
}

interface IButtonPropTypes extends IStyledComponentPropTypes {
  text: string
  onClick: () => void
  active?: boolean
  disabled?: boolean
  idleVariant?: Variant
  activeVariant?: Variant
}

const Button = styled(function ({
  text,
  onClick,
  active = false,
  disabled = false,
  idleVariant = Variant.LIGHT,
  activeVariant = Variant.PRIMARY,
  className,
}: IButtonPropTypes) {
  function handleClick() {
    onClick()
  }

  return (
    <Bootstrap.Button
      block
      disabled={disabled}
      onClick={handleClick}
      variant={active ? activeVariant : idleVariant}
      className={className}
    >
      {text}
    </Bootstrap.Button>
  )
})`
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &.btn-light:focus {
    background-color: var(--white);
    color: var(--primary);
  }
  &.btn-primary:focus {
    background-color: var(--primary);
    color: var(--white);
  }
`

interface ICreateButtonRendererPropTypes extends IButtonPropTypes {
  width?: number
}

function createButtonRenderer({
  text,
  onClick,
  // eslint-disable-next-line
  width = 100,
  active,
  disabled,
  idleVariant,
  activeVariant,
}: ICreateButtonRendererPropTypes) {
  //eslint-disable-next-line
  return () => {
    return (
      <Button
        text={text}
        onClick={onClick}
        active={active}
        disabled={disabled}
        idleVariant={idleVariant}
        activeVariant={activeVariant}
      />
    )
  }
}

const dateRenderer = (td: rendererTdArg, row: string, timestamp: unknown) => {
  if (timestamp) {
    const date = new Date(timestamp as Date | string)
    return [
      date.getUTCMonth() + 1,
      date.getUTCDate(),
      date.getUTCFullYear(),
    ].join('/')
  }
  return ''
}
export { dateRenderer, createButtonRenderer, createRfpRenderer }

import React, { FunctionComponent, useLayoutEffect, useState } from 'react'
import qs from 'qs'
import { isProductionHost } from '../utils/enviroment'
import { useAuth, useImpersonatingUserOrUser } from '../state/modules/auth'

const HEADER_BUFFER = 40

const getIframeSrc = (token: string, clientId?: number) =>
  `${
    isProductionHost()
      ? process.env.REACT_APP_PROSPECTOR_MAP_URL
      : process.env.REACT_APP_STAGING_PROSPECTOR_MAP_URL
  }?${qs.stringify(clientId ? { token, clientid: clientId } : { token })}`

export const Dashboard: FunctionComponent = () => {
  // NOTE - These are separated because of the nuance of impersonation.
  const { accessToken } = useAuth()
  const user = useImpersonatingUserOrUser()
  const [height, setHeight] = useState<number>(window.outerHeight)

  useLayoutEffect(() => {
    const onResize = () => setHeight(window.innerHeight)
    window.addEventListener('resize', onResize)

    onResize()

    return () => window.removeEventListener('resize', onResize)
  }, [])

  return accessToken && user ? (
    <iframe
      src={getIframeSrc(String(accessToken), user?.relatedEntity)}
      title="Dashboard Map"
      height="100%"
      style={{ border: 'none', height: height - HEADER_BUFFER }}
    />
  ) : null
}

import { Reducer, useEffect, useReducer } from 'react'

/**
 * Helps to store certain records while user switches between pages.
 */
export const makeRecordsSnapshotHook = <T, K extends keyof T>(
  rowKeyPropName: K
) => {
  type Action = [snapshotKeys: readonly T[K][], recordsCut: readonly T[]]

  const reducer: Reducer<readonly T[], Action> = (
    state,
    [snapshotKeys, recordsCut]
  ) => {
    const newRecordsKeys = recordsCut.map((record) => record[rowKeyPropName])
    return [
      ...state.filter(
        (record) =>
          snapshotKeys.includes(record[rowKeyPropName]) &&
          !newRecordsKeys.includes(record[rowKeyPropName])
      ),
      ...recordsCut.filter((record) =>
        snapshotKeys.includes(record[rowKeyPropName])
      ),
    ]
  }

  /**
   *
   * @param recordsCut - Some records
   * @param snapshotKeys - Keys of records that should be stored once appear in recordsCut. Removing a key will also remove record from resulting snapshot.
   * @returns Records found by `snapshotKeys` in the `recordCut` arrays
   */
  const useRecordsSnapshot = (
    recordsCut: readonly T[],
    snapshotKeys: readonly T[K][]
  ) => {
    const [snapshot, reduceSnapshot] = useReducer(reducer, [])

    useEffect(() => {
      if (recordsCut.length) reduceSnapshot([snapshotKeys, recordsCut])
    }, [snapshotKeys, recordsCut])

    return snapshot
  }

  return useRecordsSnapshot
}

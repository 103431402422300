import { Dispatch } from 'react'
import { IField } from '../../../common/types'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FilterProps<T extends string = string, V = any> {
  properties: IField['properties']
  filterType: T
  value?: V
  onChange?: Dispatch<V>
  onReset?: () => void
}

export const isFilterPropsType =
  <P extends FilterProps>(filterType: P['filterType']) =>
  (props: FilterProps): props is P =>
    props.filterType === filterType

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ParseType<V = any> = (
  valueRes: string | V | undefined
) => V | undefined

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type StringifyType<V = any> = (value: V) => string

import React, { FunctionComponent, useState } from 'react'
import { Container, Row, Button, Toast, Col } from 'react-bootstrap'
import { SiteListUploader } from '../components/SiteListUpload/Uploader'
import { RelatedEntitySelect } from '../components/invite-user/RelatedEntitySelect'
import { IEntity } from '../state/modules/entities'
import { SiteListType } from '@black-bear-energy/black-bear-energy-common'

export type upsertCounts = { newCount: number; updateCount: number }

export const SiteUpload: FunctionComponent = () => {
  const [client, setClient] = useState<IEntity>()
  const [showModal, setShowModal] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [recordImportCount, setRecordImportCount] = useState<{
    newCount: number
    updateCount: number
  }>()
  const [uploadInProgress, setUploadInProgress] = useState(false)
  const [sitelistType, setSiteListType] = useState(SiteListType.Comprehensive)
  const [hasUploadError, setHasUploadError] = useState(false)

  function handleClientSelect(client?: IEntity) {
    setClient(client)
  }

  function handleUploadStart() {
    setHasUploadError(false)
    setUploadInProgress(true)
    setShowToast(true)
  }

  /**
   * If the modal is closed after a successful import,
   * display the counts of new and updated records (in the `counts` object)
   */
  function exitModal(counts?: upsertCounts) {
    setUploadInProgress(false)
    setHasUploadError(false)
    setRecordImportCount(counts)
    setShowModal(false)
    setShowToast(!!counts)
  }

  const listTypeRadioOptions = (
    Object.keys(SiteListType) as Array<keyof typeof SiteListType>
  ).map((key) => (
    <Col key={key}>
      <input
        type="radio"
        id={key}
        value={SiteListType[key]}
        checked={sitelistType === SiteListType[key]}
        onChange={() => setSiteListType(SiteListType[key])}
        style={{ marginRight: '5px' }}
      />
      <label htmlFor={key}>{SiteListType[key]}</label>
    </Col>
  ))

  function handleImportError() {
    setUploadInProgress(false)
    setHasUploadError(true)
    setShowModal(false)
    setShowToast(true)
  }

  function getToastMessage(
    isError: boolean,
    recordImportCount?: upsertCounts
  ): string {
    if (isError) {
      return 'An error occurred while uploading the site list'
    }
    if (uploadInProgress) {
      return 'Uploading site list...'
    }
    // handle a successful upload with no updates or new sites
    if (
      recordImportCount?.newCount === 0 &&
      recordImportCount.updateCount === 0
    ) {
      return 'Upload completed successfully. No new sites added and no changes made to existing sites.'
    }
    const newCountString = recordImportCount?.newCount
      ? `imported ${recordImportCount.newCount} new site${
          recordImportCount.newCount > 1 ? 's' : ''
        }`
      : ''
    const updateCountString = recordImportCount?.updateCount
      ? `updated ${recordImportCount.updateCount} existing site${
          recordImportCount.updateCount > 1 ? 's' : ''
        }`
      : ''

    return `Successfully ${newCountString}
              ${newCountString && updateCountString ? ' and ' : ''}
              ${updateCountString}`
  }

  const isError = hasUploadError
  const toastStyle = isError
    ? {
        backgroundColor: 'rgb(220, 53, 69)',
        color: '#dee2e6bf',
      }
    : {}

  return (
    <Container className="my-4 offset-xl-1 col-xl-10" style={{ flex: 1 }}>
      <div style={{ position: 'absolute', right: 0, top: 0 }}>
        <Toast
          style={{ position: 'relative', float: 'right' }}
          onClose={() => setShowToast(false)}
          show={showToast}
        >
          <Toast.Header
            style={{ fontWeight: 'bold', justifyContent: 'space-between' }}
          >
            Site List Upload Status
          </Toast.Header>
          <Toast.Body style={toastStyle}>
            <span style={{ fontSize: '1rem' }}>
              {getToastMessage(isError, recordImportCount)}
            </span>
          </Toast.Body>
        </Toast>
      </div>
      <Row className="my-4">
        <span className="mt-1">
          Please note that only addresses in USA and Canada are supported.
        </span>
      </Row>
      <Row className="my-4" style={{ maxWidth: '700px' }}>
        {listTypeRadioOptions}
      </Row>
      <Row className="my-4">
        <RelatedEntitySelect
          entity={client}
          onEntityChange={handleClientSelect}
          disabled={false}
        />
      </Row>
      <Row className="my-4">
        <Button
          className={'btn btn-primary'}
          disabled={!client}
          onClick={() => {
            setShowModal(true)
            setShowToast(false)
          }}
        >
          Upload Sites
        </Button>
        <SiteListUploader
          clientId={client?.recordId}
          siteListType={sitelistType}
          isOpen={showModal}
          onUploadStart={() => handleUploadStart()}
          onUploadError={() => handleImportError()}
          onClose={(counts?: upsertCounts) => exitModal(counts)}
        />
      </Row>
    </Container>
  )
}

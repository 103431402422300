import moment from 'moment'
import { ChangeEvent, FunctionComponent, useCallback } from 'react'
import { FormControl } from 'react-bootstrap'
import {
  FilterProps,
  isFilterPropsType,
  ParseType,
  StringifyType,
} from './Props'

export const filterType = 'Date Range' as const

const format = 'YYYY-MM-DD'
export type Value = readonly [from: string | undefined, to: string | undefined]

export const parseDateRange: ParseType<Value> = (valueRes) => {
  if (!valueRes) return undefined
  if (Array.isArray(valueRes)) return valueRes as Value

  if (typeof valueRes !== 'string') return undefined

  const [fromStr, toStr] = valueRes.split(',')

  const fromMoment = fromStr ? moment(fromStr, format) : undefined
  const toMoment = toStr ? moment(toStr, format) : undefined
  return [
    fromMoment?.isValid() ? fromMoment.format(format) : undefined,
    toMoment?.isValid() ? toMoment.format(format) : undefined,
  ]
}

export const stringifyDateRange: StringifyType<Value> = (value) =>
  value.join(',')

export type FilterPropsDateRange = FilterProps<typeof filterType, Value>

export const isFilterPropsDateRange =
  isFilterPropsType<FilterPropsDateRange>(filterType)

export const FilterDateRange: FunctionComponent<FilterPropsDateRange> = (
  props
) => {
  const { value: [from, to] = [], onChange, onReset } = props

  const onChangeFrom = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newFrom = event.target.value || undefined
      if (newFrom === undefined && !to && onReset) onReset()
      else onChange?.([newFrom, to])
    },
    [onChange, onReset, to]
  )

  const fromSelect = (
    <FormControl
      type="date"
      value={from || ''}
      onChange={onChangeFrom}
      placeholder="from"
    />
  )

  const onChangeTo = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newTo = event.target.value || undefined
      if (newTo === undefined && !from && onReset) onReset()
      else onChange?.([from, newTo])
    },
    [onChange, onReset, from]
  )

  const toSelect = (
    <FormControl
      type="date"
      value={to || ''}
      onChange={onChangeTo}
      placeholder="to"
    />
  )

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
      }}
    >
      <div className="input-date-shrinkable" style={{ flex: 1 }}>
        {fromSelect}
      </div>
      &mdash;
      <div className="input-date-shrinkable" style={{ flex: 1 }}>
        {toSelect}
      </div>
    </div>
  )
}

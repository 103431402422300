import React, { FunctionComponent, useState, useEffect, Dispatch } from 'react'
import { Form } from 'react-bootstrap'
import FlipMove from 'react-flip-move'
import useDebounce from '../../state/modules/common'
import {
  IUserReport,
  IUserReportPatch,
  useUserReports,
} from '../../state/modules/userReports'
import { ComponentLoader } from '../common/Loaders'
import { useUserReportsWithAccess } from './Access'
import { ReportCard } from './ReportCard'

export interface Props {
  onSelect?: Dispatch<IUserReport>
  currentReport?: IUserReport | null
  patch?: IUserReportPatch
  defaultReport?: IUserReport
  onSetDefault?: Dispatch<IUserReport | undefined>
}

export const UserReportExplorer: FunctionComponent<Props> = (props) => {
  const { onSelect, currentReport, defaultReport, onSetDefault } = props

  const { reports: reportsRaw, isLoading, load } = useUserReports()

  const [needReload, setNeedReload] = useState(true)
  useEffect(() => {
    if (needReload && load) {
      setNeedReload(false)
      load()
    }
  }, [needReload, load])

  const reports = useUserReportsWithAccess(reportsRaw)

  const [searchText, setSearchText] = useState('')
  const [visibleReportsList, setVisibleReports] = useState(reports)
  const searchTextDebouncedUC = useDebounce(searchText, 500).toUpperCase()

  useEffect(() => {
    let newVisibleReports = reports

    if (searchTextDebouncedUC)
      newVisibleReports = newVisibleReports.filter(([{ name }]) =>
        name?.toUpperCase().includes(searchTextDebouncedUC)
      )

    setVisibleReports(newVisibleReports)
  }, [searchTextDebouncedUC, reports])

  return (
    <div>
      {isLoading && (
        <ComponentLoader style={{ position: 'absolute', width: '100%' }} />
      )}
      <div
        style={{
          position: 'relative',
          overflow: 'auto',
          background: 'lightgrey',
          display: 'flex',
          flexFlow: 'column',
          gap: 5,
          margin: '0px -15px',
          padding: '5px 15px',
        }}
      >
        <FlipMove duration={1000} typeName={null}>
          <Form.Control
            style={{
              marginBottom: '5px',
            }}
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value || '')}
            placeholder={'Search...'}
          />
          {visibleReportsList.map(([report, accessInfo]) => (
            <div key={report._id}>
              <ReportCard
                report={report}
                accessInfo={accessInfo}
                isDefault={defaultReport?._id === report._id}
                onSetDefault={onSetDefault}
                allowDelete={true}
                light={true}
                onSelect={onSelect && (() => onSelect(report))}
                isSelected={currentReport?._id === report._id}
                layout="item"
              />
            </div>
          ))}
        </FlipMove>
      </div>
    </div>
  )
}

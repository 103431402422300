import React, { MouseEventHandler } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Variant } from '../../common/types'
import {
  isAdminOrBBEUserOrSuperAdmin,
  useImpersonatingUserOrUser,
} from '../../state/modules/auth'

interface ISaveResetButtonsProps {
  saveActive: boolean
  resetActive: boolean
  onTableSave: MouseEventHandler<HTMLElement>
  onTableReset: MouseEventHandler<HTMLElement>
}

function SaveResetButtons({
  saveActive,
  resetActive,
  onTableSave,
  onTableReset,
}: ISaveResetButtonsProps) {
  const user = useImpersonatingUserOrUser()

  return (
    <>
      {user && isAdminOrBBEUserOrSuperAdmin(user) ? (
        <Link to={`/manage/users/invite`} className="mr-2">
          <Button>+ Invite User</Button>
        </Link>
      ) : (
        ''
      )}
      <Button
        disabled={!saveActive}
        variant={saveActive ? Variant.PRIMARY : Variant.LIGHT}
        onClick={onTableSave}
        className="mr-2"
      >
        Save
      </Button>
      <Button
        disabled={!resetActive}
        variant={saveActive ? Variant.PRIMARY : Variant.LIGHT}
        onClick={onTableReset}
      >
        Reset
      </Button>
    </>
  )
}

export default SaveResetButtons

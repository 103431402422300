import React from 'react'
import { Toast, Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import { IStyledComponentPropTypes } from '../common/types'
import { useNotifications } from '../state/modules/notifications'

function NotificationsToast({ className }: IStyledComponentPropTypes) {
  const { data, dismiss } = useNotifications()

  function displayNotifications() {
    return data.map((notification) => (
      <Toast
        className={`bg-${notification.variant}`}
        onClick={() => dismiss(notification.id)}
        key={notification.id}
      >
        <Toast.Header>
          <strong className="me-auto">{notification.title}</strong>
        </Toast.Header>
        <Toast.Body>{notification.message}</Toast.Body>
      </Toast>
    ))
  }

  return (
    <Row className={className}>
      <Col md={6} className="mb-2">
        {displayNotifications()}
      </Col>
    </Row>
  )
}

export default styled(NotificationsToast)`
  & {
    position: fixed;
    width: 100vw;
    bottom: 1rem;
    left: 1rem;
    color: white;
    z-index: 1100;
  }

  .toast-header {
    justify-content: space-between;
  }

  & .close {
    z-index: 2;
  }
`

import React, { ComponentType } from 'react'
import { useHistory } from 'react-router-dom'
import {
  useAuth,
  useCallOnIsAuthenticated,
  useCallOnNotAuthenticated,
} from '../auth'
import { WithAuthenticationRequiredOptions } from '@auth0/auth0-react'
import { ScreenLoader } from '../../../components/common/Loaders'

export enum HistoryState {
  REDIRECTED_FROM_APP,
}

export default function WithAuthenticationRequired<P extends object>({
  Component,
  props,
  options,
  loading = false,
  redirectOn = () => false,
  redirectTo = '/',
}: {
  Component: ComponentType<P> | ComponentType
  props?: P
  options?: WithAuthenticationRequiredOptions
  loading?: boolean
  redirectOn?: () => boolean
  redirectTo?: string
}): JSX.Element {
  const history = useHistory()
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth()
  const { returnTo: loginPage = '/login' } = options || {}

  useCallOnNotAuthenticated(() => {
    history.push(loginPage as string, HistoryState.REDIRECTED_FROM_APP)
  }, [loginPage, history])

  useCallOnIsAuthenticated(() => {
    if (!loading && redirectOn()) {
      history.push(redirectTo, HistoryState.REDIRECTED_FROM_APP)
    }
  }, [redirectOn, loading])

  // NOTE - Super admin users do not have to agree to terms of service.
  if (isAuthLoading || !isAuthenticated || loading) {
    return <ScreenLoader />
  } else if (props) {
    return <Component {...props} />
  } else {
    // @ts-expect-error if props is not defined at this point so we can return a ComponentType
    return <Component />
  }
}

import { IField } from '../../common/types'

export const filterTypes = [
  'Contains',
  'List',
  'Numeric Range',
  'DateRange',
] as const
export type IFieldAssignmentFilterType = (typeof filterTypes)[number]

export const roles = ['Admin', 'BBE', 'Client', 'Channel Partner'] as const
export type IFieldAssignmentRole = (typeof roles)[number]

export interface IFieldAssignment {
  readonly recordId: number

  readonly tableId?: string | null
  readonly fieldId?: number | null

  readonly mappedTableId?: string | null
  readonly mappedFieldId?: number | null

  readonly whoViews: readonly IFieldAssignmentRole[]
  readonly whoEdits: readonly IFieldAssignmentRole[]

  /** JSON */
  readonly properties?: IField['properties'] | string

  readonly fieldDisplayName?: string | null

  readonly fieldType?: string | null
  readonly filterType?: string | null

  readonly turnOff?: boolean | null
}

import React, {
  ChangeEventHandler,
  FunctionComponent,
  useEffect,
  useMemo,
} from 'react'
import { Form } from 'react-bootstrap'
import { RequestStatus } from '../../common/types'
import {
  IChannelPartner,
  useChannelPartners,
  useLoadChannelPartners,
} from '../../state/modules/channelPartners'
import { useCallOnceOnIDTokenGranted } from '../../state/modules/common'
import { ComponentLoader } from '../common/Loaders'

interface Props {
  channelPartner?: IChannelPartner
  onChannelPartnerChange: (channelPartner?: IChannelPartner) => void
  autoSelectChannelPartnerId?: number
  disabled: boolean
  showLabel?: boolean
}

export const RelatedChannelPartnerSelect: FunctionComponent<Props> = (
  props
) => {
  const {
    channelPartner,
    onChannelPartnerChange,
    autoSelectChannelPartnerId,
    disabled,
    showLabel = true,
  } = props

  const {
    payload: channelPartners,
    status: channelPartnersStatus,
    httpErrors: channelPartnersHTTPErrors,
  } = useChannelPartners()
  const loadChannelPartners = useLoadChannelPartners()

  useEffect(() => {
    if (!autoSelectChannelPartnerId) return undefined
    if (!channelPartners) return undefined
    if (channelPartner) return undefined

    onChannelPartnerChange?.(
      channelPartners.find(
        ({ recordId }) => recordId === autoSelectChannelPartnerId
      )
    )
  }, [
    autoSelectChannelPartnerId,
    channelPartner,
    channelPartners,
    onChannelPartnerChange,
  ])

  const channelPartnerId = channelPartner?.recordId

  /**
   * Hook to load channelPartners.
   *
   * Note - useCallOnceOnIDTokenGranted, so that it only calls the api when the idToken is fetched!
   */
  useCallOnceOnIDTokenGranted(async () => {
    if (channelPartnersStatus === RequestStatus.IDLE) {
      await loadChannelPartners()
    }
  })

  const handleChange = useMemo<
    ChangeEventHandler<HTMLSelectElement> | undefined
  >(
    () =>
      onChannelPartnerChange
        ? (e) => {
            const idStr = e.currentTarget.value
            const newId = parseInt(idStr, 10)
            if (isNaN(newId))
              throw new Error(`Wrong channel partner id: ${idStr}`)

            const channel_partner = channelPartners.find(
              ({ recordId }) => recordId === newId
            )
            onChannelPartnerChange(channel_partner)
          }
        : undefined,
    [channelPartners, onChannelPartnerChange]
  )

  return (
    <fieldset>
      {showLabel && (
        <legend className="required">Related Channel Partner</legend>
      )}
      <Form.Group>
        {channelPartnersStatus === RequestStatus.LOADING ? (
          <ComponentLoader />
        ) : (
          <Form.Control
            name="channel-partner-select"
            as="select"
            value={
              channelPartnerId === undefined ? '' : channelPartnerId.toString()
            }
            onChange={handleChange}
            disabled={disabled}
          >
            <option disabled hidden style={{ display: 'none' }} value="" />
            {channelPartners
              .map(({ recordId, channelPartnerName }) =>
                channelPartnerName === '' ||
                channelPartnerName === undefined ? null : (
                  <option key={recordId} value={recordId}>
                    {channelPartnerName}
                  </option>
                )
              )
              .filter((val) => !!val)}
          </Form.Control>
        )}
        {channelPartnersStatus === RequestStatus.FAILED ? (
          <p>
            <small>{channelPartnersHTTPErrors?.message}</small>
          </p>
        ) : (
          ''
        )}
      </Form.Group>
    </fieldset>
  )
}

import { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { Variant } from '../common/types'
import { useQuery, useSyncQuery } from '../state/modules/common'

export interface PagerState {
  /** 0-based */
  page: number
  /** Shown to user */
  displayPage?: number | string
  pageSize: number
  totalCount?: number
  totalPages?: number
  hasNext?: boolean
  hasPrevious?: boolean
  /** Start record index is 0-based */
  pageRange?: [startRecordIndex: number, pageCount: number]
}

export interface PagerProps extends PagerState {
  setPage?: (page: number) => void
  setPageSize?: (pageSize: number) => void
}

const readIntegerFromQuery = (
  query: URLSearchParams,
  name: string
): number | undefined =>
  query.has(name) ? parseInt(query.get(name) || '0', 10) || 0 : undefined

export const DEFAULT_PAGE_SIZE =
  parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE || '20', 10) || 20

/**
 * `Pager` props tied up with location HTTP params
 */
export const useQueryPager = (
  defaultPageSize = DEFAULT_PAGE_SIZE,
  defaultPage = 0,
  disableQueryUpdate = false
): PagerProps => {
  const query = useQuery()

  const [pageSize, setPageSize] = useState(() =>
    readIntegerFromQuery(query, 'pageSize')
  )
  const [page, setPage] = useState(() => {
    const number = readIntegerFromQuery(query, 'page')
    if (number !== undefined) return number - 1
    else return defaultPage
  })
  const displayPage = (page || 0) + 1
  useSyncQuery(
    !disableQueryUpdate
      ? { page: displayPage !== 1 ? displayPage : undefined, pageSize }
      : undefined
  )

  return {
    page: page || 0,
    displayPage,
    setPage,
    pageSize: pageSize || defaultPageSize,
    setPageSize,
  }
}

/** Simple controls for `Pager` */
export const usePager = (
  defaultPageSize = DEFAULT_PAGE_SIZE,
  defaultPage = 0
): PagerProps => {
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [page, setPage] = useState(defaultPage)
  const displayPage = (page || 0) + 1

  return {
    page: page || 0,
    displayPage,
    setPage,
    pageSize: pageSize || defaultPageSize,
    setPageSize,
  }
}

const Root = styled('div')`
  display: flex;
  align-items: stretch;
  flex-flow: column;
  white-space: nowrap;
  gap: 0.5rem;
`

const Caption = styled('div')`
  display: flex;
  align-items: center;
`

const ButtonsRow = styled('div')`
  display: flex;
  gap: 0.5rem;
`

export const Pager: FunctionComponent<PagerProps> = (props) => {
  const {
    page,
    displayPage = page + 1,
    setPage,
    pageSize,
    totalCount,
    totalPages = totalCount !== undefined
      ? Math.ceil(totalCount / pageSize)
      : undefined,
    hasNext = !!totalPages &&
      totalPages >
        (typeof displayPage == 'number' ? displayPage : parseInt(displayPage)),
    hasPrevious = !!totalPages && !!page && page > 0,
    pageRange,
  } = props

  const [pageInputRaw, setPageInputRaw] = useState<string>()
  const pageInputNum = pageInputRaw ? parseInt(pageInputRaw, 10) : undefined
  const pageInputVal =
    typeof pageInputNum === 'number' && !isNaN(pageInputNum)
      ? pageInputNum
      : pageInputRaw

  useEffect(() => {
    if (typeof pageInputVal === 'number' && pageInputVal) {
      setPageInputRaw(undefined)
      setPage?.(pageInputVal - 1)
    }
  }, [pageInputVal, setPage])

  useEffect(() => {
    if (displayPage) setPageInputRaw(undefined)
  }, [displayPage])

  useEffect(() => {
    if (totalPages !== undefined && totalPages && totalPages <= page)
      setPage?.(totalPages - 1)
  }, [totalPages, page, setPage])

  if (!totalPages && !hasNext && !hasPrevious) return null

  const pageRangeCaption = pageRange && (
    <>
      {pageRange[0]}-{pageRange[1]}
    </>
  )
  const totalCountCaption = totalCount && <>{totalCount}</>

  const countCaption = (pageRangeCaption || totalCountCaption) && (
    <>
      ({pageRangeCaption}
      {pageRangeCaption && !totalCountCaption && ' shown'}
      {pageRangeCaption && totalCountCaption && ' of '}
      {!pageRangeCaption && totalCountCaption && 'Total: '}
      {totalCountCaption})
    </>
  )

  let caption: ReactNode

  if (totalPages && displayPage) {
    caption = (
      <Caption>
        {`Page ${displayPage} of ${totalPages}`} {countCaption}
      </Caption>
    )
  } else if (displayPage) {
    caption = (
      <Caption>
        {`Page ${displayPage}`} {countCaption}
      </Caption>
    )
  } else if (countCaption) {
    caption = countCaption
  }

  return totalPages === 0 ? null : (
    <Root>
      {caption}
      <ButtonsRow>
        <Button
          variant={!hasPrevious ? Variant.INFO : Variant.PRIMARY}
          onClick={() => setPage?.(page - 1)}
          disabled={!hasPrevious && !!setPage}
        >
          Previous
        </Button>
        <input
          type="number"
          style={{ width: '55px' }}
          value={pageInputVal !== undefined ? pageInputVal : displayPage}
          min={1}
          onChange={(e) => setPageInputRaw(e.target.value)}
          disabled={!setPage}
          onBlur={() => setPageInputRaw(undefined)}
        />
        <Button
          variant={!hasNext ? Variant.INFO : Variant.PRIMARY}
          onClick={() => setPage?.(page + 1)}
          disabled={!hasNext && !!setPage}
        >
          Next
        </Button>
      </ButtonsRow>
    </Root>
  )
}
